import accessList from './access-list';
import auth from './auth';
import authUsers from './auth-users';
import avatars from './avatars';
import defaultSettings from './defaultSettings';
import emails from './emails';
import environments from './environments';
import events from './events';
import invites from './invites';
import maps from './maps';
import pointsOfInterest from './pointsOfInterest';
import regions from './regions';
import rooms from './rooms';
import skins from './skins';
import skinsModel from './skinsModel';
import skinsPlayer from './skinsPlayer';
import spots from './spots';
import streamServices from './streamServices';
import tickets from './tickets';
import user from './user';

export const http = {
  auth,
  user,
  events,
  rooms,
  maps,
  spots,
  environments,
  regions,
  skins,
  invites,
  tickets,
  streamServices,
  defaultSettings,
  pointsOfInterest,
  skinsModel,
  avatars,
  skinsPlayer,
  authUsers,
  accessList,
  emails,
};
