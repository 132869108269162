declare global {
  interface Window {
    env: any;
  }
}

// change with your own variables
type EnvType = {
  REACT_APP_IM_API: string;
  REACT_APP_ADMIN_API: string;
  REACT_APP_SM_API: string;
  REACT_APP_SDK_ENV: string;
  REACT_APP_IMAGE_SERVER_URL: string;
  REACT_APP_JWT: string;
  REACT_APP_AUTH_TOKEN: string;
  REACT_APP_AUTH_ID: string;
  REACT_APP_AUTH_ROLE: string;
  REACT_APP_FIREBASE_KEY: string;
  REACT_APP_FIREBASE_AUTH_DOMAIN: string;
  REACT_APP_FIREBASE_PROJ_ID: string;
  REACT_APP_FIREBASE_BUCKET: string;
  REACT_APP_FIREBASE_SENDER_ID: string;
  REACT_APP_FIREBASE_APP_ID: string;
  REACT_APP_OKTA_ISSUER: string;
  REACT_APP_OKTA_CLIENT_ID: string;
  REACT_APP_FACEBOOK_SOCIAL_APP_ID: string;
  REACT_APP_GOOGLE_SOCIAL_APP_ID: string;
  REACT_APP_ENV: string;
  REACT_APP_GAMECAST_SCHEDULER: string;
  REACT_APP_DEFAULT_CALENDAR_REGION: string;
};

export const env: EnvType = { ...process.env, ...window.env };
