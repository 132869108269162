import { LoginForm } from './LoginForm';
import { AccountFormLayout } from 'layouts/AccountFormLayout';

export const LoginPage = () => {
  return (
    <AccountFormLayout>
      <LoginForm />
    </AccountFormLayout>
  );
};
