import axios from 'axios';
import { apiUrl } from 'configs/apiConfigs';
import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';

const emails = {
  async getEmailTemplate({ id, type }: { id: string; type: string }) {
    try {
      const { data } = await axios.get(`${apiUrl}/public/emails/${type}/${id}`);
      return data;
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default emails;
