import SendIcon from '@mui/icons-material/Send';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { clearEmailList } from 'redux/slices/emailsSlice';
import { setActualFilter, setSelected } from 'redux/slices/invitesSlice';
import { http } from 'services/http';
import { AcceptButtonWithDialog } from 'shared/components/buttons/AcceptButtonWithDialog';
import { CircularProgressWithLabel } from 'shared/components/progress/CircularProgressWithLabel';
import { useLoadParticipants } from 'shared/hooks/useLoadParticipants';
import { useAppDispatch } from 'shared/hooks/useStore';
import { InviteFilters } from 'shared/types/models/invite';
import { chunkArray } from 'shared/utils/chunkArray';

interface SendEmailProps {
  emailsToSend: string[];
  eventId: string;
  isIconButton?: boolean;
}

export default function SendEmails({
  emailsToSend,
  eventId,
  isIconButton,
}: SendEmailProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { get: reloadParticipants } = useLoadParticipants();

  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    return () => {
      setIsSending(false);
    };
  }, []);

  const handleSendEmails = async () => {
    // The api works better with 20 emails at time
    const NUMBER_OF_EMAILS_LIMIT = 20;

    setIsSending(true);

    if (emailsToSend.length <= NUMBER_OF_EMAILS_LIMIT) {
      submit();
      return;
    }

    setOpen(true);

    // to large email lists, need to split this list into smaller ones
    const newList = chunkArray(emailsToSend, NUMBER_OF_EMAILS_LIMIT);

    const submitingStepped = await submitStepped(newList);

    if (submitingStepped?.success) {
      dispatch(clearEmailList());
      dispatch(setSelected([]));
      enqueueSnackbar(
        "Our system is processing the order to send emails.\
        This could take some time.\
        Some email may end up in a participant's spam email box.",
        { variant: 'success', autoHideDuration: 3000 }
      );
      dispatch(setActualFilter(InviteFilters.All));
      reloadParticipants();
    }

    setIsSending(false);
    setOpen(false);
  };

  async function submit() {
    await http.invites
      .invite({
        emails: emailsToSend,
        event: eventId,
      })
      .then((res) => {
        dispatch(clearEmailList());
        dispatch(setSelected([]));
        enqueueSnackbar(res.message, { variant: 'success' });
        dispatch(setActualFilter(InviteFilters.All));
        reloadParticipants();
        setIsSending(false);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        setIsSending(false);
      });
  }

  const submitStepped = async (
    emails: string[][]
  ): Promise<{ success: boolean } | undefined> => {
    let success;
    let count = 0;

    for (const item of emails) {
      count++;
      const step: number = Math.round((count * 100) / emails.length);
      setProgress(step);

      await http.invites
        .invite({
          emails: item,
          event: eventId,
        })
        .then(() => {
          success = { success: true };
        })
        .catch((error) => {
          setError('Error during stepped send email proccess:' + error.message);
          success = { success: false };
        });
    }

    return success;
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!error.length && (
          <DialogContent>
            <>
              <DialogContentText>
                We are sending {emailsToSend.length} selected emails to the
                participants. This should take some time.
              </DialogContentText>
              <Stack
                justifyContent="center"
                alignItems="center"
                style={{ padding: 8 }}
              >
                <CircularProgressWithLabel value={progress} />
              </Stack>
            </>
          </DialogContent>
        )}

        {error.length > 0 && (
          <DialogContent>
            <Alert severity="error">{error}</Alert>
          </DialogContent>
        )}

        {error.length > 0 && (
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </DialogActions>
        )}
      </Dialog>

      {isIconButton ? (
        <>
          <Tooltip title="Send emails to all selected">
            <IconButton onClick={handleSendEmails} disabled={isSending}>
              <SendIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          <AcceptButtonWithDialog
            message="Are you sure you want to send e-mails to whole database?"
            buttonText={!isSending ? 'Send emails to all' : 'Sending...'}
            okModalButtonText="Yes"
            disabled={isSending}
            icon={<SendIcon fontSize="small" />}
            submitCallback={handleSendEmails}
          />
        </>
      )}
    </>
  );
}
