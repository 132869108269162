import { ChangeEvent } from 'react';
import { IconButton, InputBase, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { selectInvites, setPage, setQuery } from 'redux/slices/invitesSlice';

export const Search = () => {
  const { query } = useAppSelector(selectInvites);

  const dispatch = useAppDispatch();

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    dispatch(setPage(0));
    dispatch(setQuery(event.target.value));
  };

  return (
    <Paper
      component="form"
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 200,
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        value={query}
        onChange={handleQueryChange}
      />
      <IconButton sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};
