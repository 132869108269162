import SaveIcon from '@mui/icons-material/Save';
import UploadIcon from '@mui/icons-material/FileUpload';
import { Grid } from '@mui/material';
import { ExportButton, ImportButton } from '../styled';
import { useCallback, useState } from 'react';
import { useLoadRoomDetails } from 'shared/hooks/useLoadRoomDetails';
import ExportSpotsModal from './ExportSpotsModal';
import { selectRooms } from 'redux/slices/roomsSlice';
import { useAppSelector } from 'shared/hooks/useStore';
import ImportSpotsModal from './ImportSpotsModal';

export const ExportAndImportSpots = () => {
  const { exportationRoomSpots } = useLoadRoomDetails();
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);

  const { roomMap } = useAppSelector(selectRooms);

  const handleExport = useCallback(
    async (payload: any, setSubmitting: any) => {
      await exportationRoomSpots(payload, setSubmitting, () => {
        setOpenExportModal(false);
      });
    },
    [exportationRoomSpots]
  );

  return (
    <>
      <ExportSpotsModal
        mapId={String(roomMap?.id)}
        open={openExportModal}
        onSubmit={async (payload: any, setSubmitting: any) => {
          await handleExport(payload, setSubmitting);
        }}
        handleClose={() => setOpenExportModal(false)}
      />

      <ImportSpotsModal
        mapId={String(roomMap?.id)}
        open={openImportModal}
        handleClose={() => setOpenImportModal(false)}
      />

      <Grid xs={6} display="flex" justifyContent="right" alignItems="end" item>
        <ExportButton
          startIcon={<SaveIcon fontSize="small" />}
          onClick={() => setOpenExportModal(true)}
          color="success"
          variant="contained"
        >
          Save Room Media
        </ExportButton>
        <ImportButton
          startIcon={<UploadIcon fontSize="small" />}
          onClick={() => setOpenImportModal(true)}
          color="info"
          variant="contained"
        >
          Load Room Assets
        </ImportButton>
      </Grid>
    </>
  );
};
