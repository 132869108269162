import { FC, ReactNode } from 'react';
import { MainContent, MainWrapper, TopWrapper } from './styled';

interface AccountFormLayoutProps {
  children?: ReactNode;
}

export const AccountFormLayout: FC<AccountFormLayoutProps> = ({ children }) => {
  return (
    <MainWrapper>
      <MainContent>
        <TopWrapper>{children}</TopWrapper>
      </MainContent>
    </MainWrapper>
  );
};
