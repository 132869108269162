import { Box, Button } from '@mui/material';
import { UserMenu } from './UserMenu';

import { Logo } from './Logo';
import { BoxLogoWrapper, HeaderWrapper } from './styled';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { useEffect } from 'react';
import { selectUser, setUserData } from 'redux/slices/userSlice';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import { useLocation, useNavigate } from 'react-router-dom';
import { http } from 'services/http';
import { useSnackbar } from 'notistack';
import { useAuth } from 'shared/hooks/useAuth';
import useAccess from 'shared/hooks/useAccess';
import { appPaths } from 'app/router/appPaths';
import { isSpecialRole } from 'shared/utils/verifyRole';

export const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { signOut } = useAuth();
  const { user } = useAppSelector(selectUser);

  const { getConnectID, getConnectToken } = useAccess();

  const from = location.state?.from?.pathname || appPaths.public.account.login;

  const loadUser = () => {
    http.user
      .get(getConnectToken() as string, getConnectID() as string)
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          signOut(() => {
            navigate(from, { replace: true });
          });
          return;
        }

        const userData = {
          connectToken: getConnectToken(),
          connectID: getConnectID(),
          data: res.data,
        };
        dispatch(setUserData(userData));
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, []);

  return (
    <HeaderWrapper>
      <Box display="flex" alignItems="center">
        <BoxLogoWrapper>
          <Logo />
        </BoxLogoWrapper>
      </Box>
      <Box display="flex" alignItems="center">
        {user?.data?.role && isSpecialRole(user.data.role) && (
          <Button
            style={{ display: 'flex', gap: 4 }}
            onClick={() => navigate('/admin/dashboard')}
          >
            <BuildCircleIcon />
            <span>Admin Tools</span>
          </Button>
        )}
        <UserMenu />
      </Box>
    </HeaderWrapper>
  );
};
