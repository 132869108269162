import { Box, Grid, styled, Button } from '@mui/material';

export const Head = styled(Box)(
  ({ theme }) => `
    text-align: center;
    margin-bottom: ${theme.spacing(4)};
  `
);

export const UploadIconMapWrapper = styled(Box)({
  display: 'flex',
  width: '40px',
  height: '40px',
  backgroundColor: 'rgb(247, 248, 255)',
  borderRadius: '50px',
  border: '3px solid #5569ff',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
});

export const UploadIconWrapper = styled(Box)({
  position: 'absolute',
  right: '10px',
  top: '10px',
  display: 'flex',
  width: '35px',
  height: '35px',
  backgroundColor: 'rgb(203, 208, 252)',
  borderRadius: '50px',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 5,
});

export const RoomSpotCardContainer = styled(Grid)({
  overflowWrap: 'break-word',
  padding: '15px',
  borderRadius: 5,
  border: '1px solid rgba(0, 0, 0, 0.06)',
  marginBottom: '25px',

  img: {
    borderRadius: 3,
    overflow: 'hidden',
  },

  p: {
    margin: '2px 0',
    fontSize: 13,
  },
});

export const ImportButton = styled(Button)({
  background: '#254493',
  borderRadius: '6px',
  ':hover': {
    background: '#10214A',
  },
  marginLeft: '10px',
});

export const ExportButton = styled(Button)({
  background: '#429325',
  borderRadius: '6px',
  ':hover': {
    background: '#1B490B',
  },
  marginLeft: '10px',
});
