import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import useAsyncEffect from 'use-async-effect';
import { useParams } from 'react-router-dom';
import StreamPanelsService, {
  IStreamPanel,
} from 'services/http/streamPanelsService';
import StreamPanelCard from './StreamPanelCard/StreamPanelCard';
import StreamPanelInfo from './StreamPanelInfo/StreamPanelInfo';
import { useStyles } from './styles';
import LoadingProgress from 'components/LoadingProgress/LoadingProgress';

const StreamPanels = ({
  screensConfig,
  setScreensConfig,
}: {
  screensConfig: IStreamPanel[];
  setScreensConfig: React.Dispatch<React.SetStateAction<IStreamPanel[]>>;
}) => {
  const [currentStreamPanel, setCurrentStreamPanel] =
    useState<IStreamPanel | null>(null);
  const [loading, setLoading] = useState(true);
  const [streamPanelPreviewState, setStreamPanelPreviewState] = useState(false);
  const classes = useStyles();
  const { id: eventId, roomId } = useParams();

  useAsyncEffect(async () => {
    setLoading(true);
    if (!eventId) {
      return;
    }

    const screens = await StreamPanelsService.getStreamPanelConfig(
      eventId,
      roomId as string
    );

    if (screens.length) {
      setScreensConfig(screens);
    }
    setLoading(false);
  }, []);

  const handleClick = (screenName: string) => {
    const currentScreen = screensConfig.find(
      (s) => s.screenName === screenName
    );

    if (!currentScreen) {
      return;
    }

    setCurrentStreamPanel(currentScreen);

    setStreamPanelPreviewState(true);
  };

  const handleClose = () => {
    setCurrentStreamPanel(null);
    setStreamPanelPreviewState(false);
  };

  if (loading) {
    return <LoadingProgress />;
  }

  return (
    <Box sx={classes.root}>
      {screensConfig.length ? (
        screensConfig.map((s) => (
          <StreamPanelCard
            key={s.millicastStreamName}
            regionName={s.regionName}
            screenName={s.screenName}
            handleClick={handleClick}
          />
        ))
      ) : (
        <Typography>No active screens</Typography>
      )}
      {currentStreamPanel ? (
        <StreamPanelInfo
          currentStreamPanel={currentStreamPanel as IStreamPanel}
          handleClose={handleClose}
          openModal={streamPanelPreviewState}
          setScreensConfig={setScreensConfig}
        />
      ) : null}
    </Box>
  );
};

export default StreamPanels;
