import moment from 'moment';

export const formatDateToBackend = (date: string) => {
  return moment(date).unix();
};

export const formatDateToFrontend = (timestamp: number) => {
  const unixTime = parseInt(timestamp.toString().slice(0, 10));

  return moment.unix(unixTime).format('YYYY-MM-DDTHH:mm:ss');
};
