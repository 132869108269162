import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { selectEvents } from 'redux/slices/eventsSlice';
import { http } from 'services/http';
import { BootstrapDialogTitle } from 'shared/components/modals/BootstrapDialogTitle';
import { BoxDialog } from 'shared/components/modals/BoxDialogModal/styled';
import { useLoadEvents } from 'shared/hooks/useLoadEvents';
import { useAppSelector } from 'shared/hooks/useStore';

interface Values {
  inviteText?: string;
}

export const EditInviteModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { event } = useAppSelector(selectEvents);
  const { get: getEvent } = useLoadEvents();
  const [useDefaultInviteText, setUseDefaultInviteText] = useState(true);

  React.useEffect(() => {
    setUseDefaultInviteText(!event.inviteText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formikValues = {
    initialValues: {
      inviteText:
        event.inviteText ||
        'Hi {{{invitedUserName}}}, <br><br>You are invited to join the metaverse! <br> You have received an invitation from <b>{{{companyName}}}</b>. All you need to do is confirm your invitation by clicking on the link below. We will see you soon!',
    },
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values: Values) => {
    const payload = {
      eventId: id as string,
      data: {
        inviteText: useDefaultInviteText ? '' : values.inviteText,
      },
    };

    await http.events.update(payload).then((res) => {
      if (res.error) {
        handleClose();
        enqueueSnackbar(res.message, { variant: 'error' });
        return;
      }
      handleClose();
      getEvent();
      enqueueSnackbar(res.message, { variant: 'success' });
    });
  };

  const handleChangeUseDefaultInviteText = (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    setUseDefaultInviteText(!useDefaultInviteText);
    setFieldValue(
      'inviteText',
      'Hi {{{invitedUserName}}}, <br><br>You are invited to join the metaverse! <br> You have received an invitation from <b>{{{companyName}}}</b>. All you need to do is confirm your invitation by clicking on the link below. We will see you soon!'
    );
  };

  return (
    <BoxDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alias-create-dialog-title"
      scroll="body"
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Invite Settings
      </BootstrapDialogTitle>
      <Formik
        initialValues={formikValues.initialValues}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(formik) => (
          <Form>
            <DialogContent dividers>
              <Stack
                spacing={2}
                direction="column"
                style={{ minWidth: '556px' }}
              >
                <Typography variant="h5">Invite Text (E-mail):</Typography>
                <Alert severity="info" variant="outlined">
                  <AlertTitle>
                    <b>
                      You can use these substitution variables inside the
                      template:
                    </b>
                    <ul
                      style={{
                        paddingInlineStart: '20px',
                        marginTop: 5,
                        fontSize: 12,
                        color: '#2e2e2e',
                      }}
                    >
                      <li>
                        {' '}
                        <b>{'{{{invitedUserName}}}'}</b> | First name of the
                        person who was invited{' '}
                        <i>
                          (only available if the person already has an account
                          created)
                        </i>
                      </li>
                      <li>
                        <b>{'{{{eventName}}}'}</b> | The name of the event
                      </li>
                      <li>
                        <b>{'{{{companyName}}}'}</b> | The name of the company
                      </li>
                      <li>
                        <b>{'<br>'}</b> | Break line
                      </li>
                      <li>
                        <b>{'<b>'}</b> | Bold text
                      </li>
                    </ul>
                  </AlertTitle>
                </Alert>

                <TextField
                  label="Invite Text"
                  name="inviteText"
                  value={formik.values.inviteText}
                  onChange={formik.handleChange}
                  placeholder="You have been invited to {{{eventName}}} by {{{companyName}}}..."
                  multiline
                  rows={4}
                  disabled={useDefaultInviteText}
                  error={
                    formik.touched.inviteText &&
                    Boolean(formik.errors.inviteText)
                  }
                  helperText={
                    formik.touched.inviteText && formik.errors.inviteText
                  }
                />

                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={useDefaultInviteText} />}
                    label="Use Default Invite Text"
                    onChange={() =>
                      handleChangeUseDefaultInviteText(formik.setFieldValue)
                    }
                  />
                </FormGroup>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="outlined">
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </BoxDialog>
  );
};
