import { Stack, TextField } from '@mui/material';
export const DatePickersContainer = ({ formik }: any) => (
  <Stack
    direction="row"
    justifyContent="normal"
    alignItems="center"
    spacing={2}
    paddingBottom={4}
  >
    <Stack
      flex="auto"
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <span>Start time*</span>
      <TextField
        fullWidth
        type={'datetime-local'}
        margin="normal"
        id="startTime"
        name="startTime"
        value={formik.values.startTime}
        onChange={formik.handleChange}
        error={formik.touched.startTime && Boolean(formik.errors.startTime)}
        helperText={formik.touched.startTime && formik.errors.startTime}
      />
    </Stack>
    <Stack
      flex="auto"
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <span>End time*</span>
      <TextField
        fullWidth
        type={'datetime-local'}
        margin="normal"
        id="endTime"
        name="endTime"
        value={formik.values.endTime}
        onChange={formik.handleChange}
        error={formik.touched.endTime && Boolean(formik.errors.endTime)}
        helperText={formik.touched.endTime && formik.errors.endTime}
        disabled={!formik.values.startTime}
      />
    </Stack>
  </Stack>
);
