import { Button } from '@mui/material';
import { CreateRoomForm } from './CreateRoomForm';
import BoxDialogModal, {
  BoxDialogModalHandles,
} from 'shared/components/modals/BoxDialogModal';
import { FormikValues } from 'formik';
import { useAppSelector } from 'shared/hooks/useStore';
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import { selectRooms } from 'redux/slices/roomsSlice';

export interface CreateRoomModalHandles {
  handleOpen: () => void;
}

const CreateRoomModal: ForwardRefRenderFunction<CreateRoomModalHandles> = (
  props,
  ref
) => {
  const {
    utils: { isSendingCreateForm },
  } = useAppSelector(selectRooms);

  const boxDialogModalRef = useRef<BoxDialogModalHandles>(null);

  const handleOpen = () => {
    boxDialogModalRef.current?.handleOpen();
  };

  const handleClose = () => {
    boxDialogModalRef.current?.handleClose();
  };

  const createEventFormRef = useRef<FormikValues>();

  const handleSubmitForm = useCallback(() => {
    createEventFormRef.current?.handleSubmit();
  }, []);

  useImperativeHandle(ref, () => {
    return { handleOpen };
  });

  return (
    <BoxDialogModal
      ref={boxDialogModalRef}
      title="Create new room"
      submitButton={
        <Button
          autoFocus
          onClick={handleSubmitForm}
          variant="contained"
          disabled={isSendingCreateForm}
        >
          {!isSendingCreateForm ? 'Create' : 'Creating...'}
        </Button>
      }
    >
      <CreateRoomForm
        innerRef={createEventFormRef}
        onSubmitCallback={handleClose}
      />
    </BoxDialogModal>
  );
};

export default forwardRef(CreateRoomModal);
