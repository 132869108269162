import cookie from 'cookie';
import Cookies from 'js-cookie';
import { env } from '../../env';

/**
 * Manage user token web cookies to access the system
 */

export function parseCookies(req?: any) {
  if (!req || !req.headers) {
    return {};
  }

  return cookie.parse(req.headers.cookie || '');
}

export function setToken(
  key: string,
  value: string,
  options?: Cookies.CookieAttributes
) {
  Cookies.set(key, value, {
    ...options,
    secure: env.REACT_APP_ENV === 'production' ? true : false,
  });
}

export function deleteToken(key: string) {
  Cookies.remove(key);
}

export function getToken(key: string) {
  return Cookies.get(key);
}
