import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from './useStore';

import { setEvent } from 'redux/slices/eventsSlice';

import { http } from 'services/http';

export const useRefetchEvent = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(() => {
    http.events
      .get({ eventId: id as string })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        dispatch(setEvent(res.data));
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  }, [dispatch, enqueueSnackbar, id]);
};
