import { CircularProgress, Stack, Typography } from '@mui/material';
import { SimpleDialogModal } from 'shared/components/modals/SimpleDialogModal';
import React from 'react';

interface Props {
  open: boolean;
  confirmText?: string;
  cancelText?: string;
  confirmIcon?: React.ReactNode;
  cancelIcon?: React.ReactNode;
  disableConfirmButton?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const PublishEventModal = ({ ...props }: Props) => {
  return (
    <>
      <SimpleDialogModal title="Publish Event" {...props}>
        {props.disableConfirmButton ? (
          <>
            <Stack
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              spacing={2}
            >
              <Typography variant="h5">Publishing...</Typography>
              <Stack>
                <CircularProgress style={{ margin: '0 auto' }} />
              </Stack>
            </Stack>
          </>
        ) : (
          <Typography variant="h5">
            Would you like to publish the actual event? This action can&apos;t
            be undone.
          </Typography>
        )}
      </SimpleDialogModal>
    </>
  );
};
