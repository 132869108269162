import BoltIcon from '@mui/icons-material/Bolt';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { selectEvents } from 'redux/slices/eventsSlice';
import { selectRooms } from 'redux/slices/roomsSlice';
import { selectUser } from 'redux/slices/userSlice';
import { useLoadRooms } from 'shared/hooks/useLoadRooms';
import { useAppSelector } from 'shared/hooks/useStore';
import { useRefetchEvent } from '../../../../shared/hooks/useGetEvent';

interface Props {
  socket: any;
  open: boolean;
  onCancel: () => void;
}

export const DestroySessionsModal = ({ socket, open, onCancel }: Props) => {
  const { event } = useAppSelector(selectEvents);
  const { user } = useAppSelector(selectUser);
  const { rooms } = useAppSelector(selectRooms);
  const loadRooms = useLoadRooms();
  const getEvent = useRefetchEvent();

  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [message, setMessage] = useState<string>('');
  const [recreatedSessionData, setRecreatedSessionData] = useState<any[]>([]);
  const [recreatingSessions, setRecreatingSessions] = useState(false);
  const [freeSpace, setFreeSpace] = useState(0);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    loadRooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('connect', async () => {
        setIsSocketConnected(true);
      });

      socket.on('disconnect', async () => {
        setIsSocketConnected(false);
      });
    }
  }, [socket]);

  const clearStatus = () => {
    setMessage('');
    setRecreatedSessionData([]);
    setRecreatingSessions(false);
    setFreeSpace(0);
    setError(null);
  };

  const startRecreateSessions = () => {
    if (!rooms.length) {
      setMessage('This event has no rooms');
      return;
    }

    setRecreatedSessionData([]);
    setRecreatingSessions(true);

    socket.on('RECREATED_SESSIONS_SELFSERVICE_STATUS', (obj: any) => {
      setRecreatedSessionData((current) => [...current, obj]);

      if (obj.status === 'Waiting') {
        /** return example: { status: "Waiting", fleet: fleet, freeSpace: freeSpace } */
        setFreeSpace(obj.freeSpace);
      } else if (obj.status === 'Done') {
        /** return example: { status: "Done" } */
        setRecreatingSessions(false);
        setMessage('SESSIONS DESTROYED!');
        getEvent();
      } else if (
        obj.status === 'Error' ||
        obj.status === 'GameLiftError catch'
      ) {
        /** return example: { status: "Error", error: 'message' } */
        setRecreatingSessions(false);
        setError(obj.error);
      }
    });

    const data = {
      eventId: event.id,
      token: user?.connectToken,
      alias: event.aliasId, // send only first string
      minimumNecessary: rooms.length,
      regionName: event.region,
      onlyDestroy: true,
    };

    socket.emit('RECREATE_SELFSERVICE_SESSIONS', data);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {}} // disable close dialog when background click or escape from esc key
      aria-labelledby="recreate-sessions-title"
      aria-describedby="recreate-sessions-description"
      fullWidth
    >
      <DialogTitle id="recreate-sessions-title">Destroy Rooms</DialogTitle>
      <DialogContent dividers>
        <Alert severity="warning">This operation can&apos;t be undone.</Alert>

        <p>
          Socket Status: {isSocketConnected ? 'connected' : 'disconnected'}{' '}
        </p>

        {event?.id && <p>Event Id: {event.id}</p>}

        {!!rooms?.length && <p>Total rooms: {rooms.length}</p>}

        {event?.aliasId && <p>Alias ID: {event.aliasId}</p>}

        {event?.region && <p>Region: {event.region}</p>}

        {recreatingSessions && (
          <Box
            sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <CircularProgress />
          </Box>
        )}

        {!!recreatedSessionData.length &&
          recreatedSessionData.map((item) => {
            return (
              item?.room?.id?.length > 0 && (
                <p key={item.room.id}>Room id: {item.room.id}</p>
              )
            );
          })}

        {freeSpace > 0 && <p>Free Space: {freeSpace}</p>}

        {message.length > 0 && !message?.includes('SESSIONS DESTROYED!') && (
          <p>{message}</p>
        )}
        {message?.includes('SESSIONS DESTROYED!') && (
          <p style={{ color: 'green', fontSize: '18px' }}>{message}</p>
        )}

        {error && <p style={{ color: 'red' }}>Error: {error.message}</p>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            clearStatus();
            onCancel();
          }}
          color="error"
          disabled={isSocketConnected && recreatingSessions}
        >
          {!recreatingSessions ? 'Back to Dashboard' : ''}
        </Button>
        <Button
          onClick={startRecreateSessions}
          autoFocus
          startIcon={<BoltIcon />}
          variant="contained"
          disabled={isSocketConnected && recreatingSessions}
        >
          {!recreatingSessions ? 'Start' : 'Wait...'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
