import * as yup from 'yup';

export const validationSchema = yup.object({
  name: yup
    .string()
    .required('Room name is required')
    .matches(
      /^[A-Za-z0-9\s]*$/,
      'Room name can only contain letters, numbers and spaces'
    ),
  roomSelected: yup
    .string()
    .test(
      'isValid',
      'Need to choose an environment.',
      (value) => value !== 'all' || !value
    ),
  maxPlayers: yup
    .number()
    .min(1, 'Must be min 1.')
    .max(100, 'Must be max 100.'),
  maxReplicas: yup
    .number()
    .min(0, 'Must be min 0.')
    .max(100, 'Must be max 100.'),
});
