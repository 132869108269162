import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import axiosWithUserToken from './axiosInstances/axiosWithUserToken';
import { HttpResponseDefault } from './types/httpGeneric';
import { UpdateSkinRequest } from './types/skins';

const skins = {
  async listByAvatar({
    avatarId,
    eventId,
  }: {
    avatarId: string;
    eventId: string;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/skins/list/skinsByAvatar`,
        {
          params: {
            avatarId: avatarId,
            orderBy: 'name',
            eventId: eventId,
          },
        }
      );
      return { message: 'Loaded', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async getById({ skinId }: { skinId: string }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/skins/get/${skinId}`
      );
      return { message: 'Loaded', data: data.skin };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async create({
    event,
    idSkin,
    idAvatar,
  }: {
    event: string;
    idSkin?: string;
    idAvatar: string;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().post(`/cms/skins/create`, {
        event: event,
        idSkin: idSkin ?? '',
        idAvatar: idAvatar,
      });
      return { message: 'Skin Created.', data: data.skin };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async update({
    formData,
    skinId,
  }: {
    formData: UpdateSkinRequest;
    skinId: string;
  }): Promise<HttpResponseDefault> {
    const dataFormated = new FormData();

    Object.entries(formData).forEach(([key, value]) =>
      dataFormated.append(`${key}`, value as any)
    );

    try {
      const { data } = await axiosWithUserToken().put(
        `/cms/skins/update/${skinId}`,
        dataFormated,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return { message: `Skin Updated.`, data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async remove({ skinId }: { skinId: string }): Promise<HttpResponseDefault> {
    try {
      const res = await axiosWithUserToken().delete(
        `/cms/skins/delete/${skinId}`
      );
      return { message: `Skin ${skinId} Removed.`, data: res };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async setDefault({
    skinId,
    gender,
  }: {
    skinId: string;
    gender: string;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().patch(
        `/cms/skins/setDefault`,
        {
          gender: gender,
          skin: skinId,
        }
      );
      return { message: 'Loaded', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async removeAllSkinsByAvatar({
    eventId,
    avatarId,
  }: {
    eventId: string;
    avatarId: string;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().delete(
        `/cms/skins/removeAllSkinsByAvatar`,
        {
          data: {
            avatarId: avatarId,
            eventId: eventId,
          },
        }
      );
      return { message: 'Loaded', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default skins;
