import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { SyntheticEvent } from 'react';
import { MediaTypeIcon } from 'shared/components/MediaTypeIcon';
import { Spot } from 'shared/types/models/spot';
import { RoomSpotCardContainer, UploadIconWrapper } from './styled';

interface RoomSpotCardProps {
  item: Spot;
  onClick: () => void;
}

export const RoomSpotCard = ({ item, onClick }: RoomSpotCardProps) => {
  return (
    <Grid
      item
      key={item.spotModelId + item.displayName}
      xs={6}
      sm={3}
      md={2}
      mb={3}
    >
      <RoomSpotCardContainer>
        <Tooltip title="Upload media">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={onClick}
            sx={{
              padding: 0,
              overflow: 'hidden',
            }}
          >
            <UploadIconWrapper>
              <MediaTypeIcon media={item.mediaType as string} />
            </UploadIconWrapper>
            <img
              src={item.image}
              alt={`room-${item.id}`}
              style={{ width: '100%' }}
              onError={(event: SyntheticEvent<HTMLImageElement>) =>
                (event.currentTarget.src =
                  'https://tf-cms-prod-assets.mytaverse.com/placeholder/placeholder-image.jpg')
              }
            />
          </IconButton>
        </Tooltip>
        <Box mt={2}>
          <Chip
            label={item.mediaType}
            size="small"
            color="info"
            variant="outlined"
          />
          <Typography variant="h4" mb={1} mt={1} color="primary">
            {item.displayName}
          </Typography>
          <Divider sx={{ marginBottom: 1 }} />
          {item.description && <p>{item.description}</p>}
          <p>
            <b>Unreal ID:</b> {item.ueIdentifier}
          </p>
          <p>
            <b>Is Default?:</b> {item.isDefault ? 'Yes' : 'No'}
          </p>
          {item.assetMediaType && (
            <p>
              <b>Media Type:</b> {item.assetMediaType}
            </p>
          )}
        </Box>
      </RoomSpotCardContainer>
    </Grid>
  );
};
