import axios from 'axios';
import { apiUrl } from 'configs/apiConfigs';
import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import axiosWithUserToken from './axiosInstances/axiosWithUserToken';
import { HttpResponseDefault } from './types/httpGeneric';
import { UploadSpotsProps } from './types/spot';

const spots = {
  async updateFromFrontend({
    userToken,
    data,
    spotID,
  }: any): Promise<HttpResponseDefault> {
    const dataFormated = new FormData();

    Object.entries(data).forEach(([key, value]) =>
      dataFormated.append(`${key}`, value as any)
    );

    try {
      const { data } = await axios.put(
        `${apiUrl}/cms/spots/update/${spotID}`,
        dataFormated,
        {
          headers: {
            Authorization: `${userToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return { message: `Spot ${data.displayName} Updated.`, data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async resetFromFrontend({
    userToken,
    spotID,
  }: {
    userToken: string;
    spotID: string;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axios.patch(
        `${apiUrl}/cms/spots/reset/${spotID}`,
        { id: spotID },
        {
          headers: {
            Authorization: `${userToken}`,
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
      );
      return { message: `Spot ${data.displayName} Updated.`, data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async exportation(mapId: string | number): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/spots/export/map/${mapId}`
      );

      return { message: 'Loaded', data: data.spots };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async upload(payload: UploadSpotsProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().post(
        `/cms/spots/import/map/${payload.roomMapIdTo}`,
        payload
      );

      return { message: 'Created', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  //Admin routes
  async listByMapInAdmin({
    userToken,
    mapID,
  }: {
    userToken: string;
    mapID: string;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axios.get(
        `${apiUrl}/cms/spots-model/list/map/${mapID}`,
        {
          headers: {
            Authorization: `${userToken}`,
          },
        }
      );
      return { message: 'Spots Loaded', data: data.spotsModel };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async getInAdmin({
    userToken,
    spotID,
  }: {
    userToken: string;
    spotID: string;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axios.get(
        `${apiUrl}/cms/spots-model/get/${spotID}`,
        {
          headers: {
            Authorization: `${userToken}`,
          },
        }
      );
      return { message: 'Loaded', data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async createInAdmin({
    userToken,
    formData,
  }: any): Promise<HttpResponseDefault> {
    const dataFormated = new FormData();

    Object.entries(formData).forEach(([key, value]) =>
      dataFormated.append(`${key}`, value as any)
    );

    try {
      const { data } = await axios.post(
        `${apiUrl}/cms/spots-model/create`,
        dataFormated,
        {
          headers: {
            Authorization: `${userToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return { message: `Spot ${formData.displayName} Created.`, data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async updateInAdmin({
    userToken,
    formData,
    spotID,
  }: any): Promise<HttpResponseDefault> {
    const dataFormated = new FormData();

    Object.entries(formData).forEach(([key, value]) =>
      dataFormated.append(`${key}`, value as any)
    );

    try {
      const { data } = await axios.put(
        `${apiUrl}/cms/spots-model/update/${spotID}`,
        dataFormated,
        {
          headers: {
            Authorization: `${userToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return { message: `Spot ${formData.displayName} Updated.`, data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async removeInAdmin({
    userToken,
    spotID,
  }: {
    userToken: string;
    spotID: string;
  }): Promise<HttpResponseDefault> {
    try {
      const res = await axios.delete(
        `${apiUrl}/cms/spots-model/delete/${spotID}`,
        {
          headers: {
            Authorization: `${userToken}`,
          },
        }
      );
      return { message: `Spot ${spotID} Removed`, data: res };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default spots;
