import React from 'react';
import { Modal, Box, Typography, Button, TextField } from '@mui/material';
import { useStyles } from './styles';
import { useFormik } from 'formik';
import EventsService from 'services/http/eventsService';
import { useGameCast } from 'shared/hooks/admin/useGameCast';

const ConfigurationModal = ({
  open,
  handleCloseModal,
  currentCapacity,
  eventId,
  region,
  streamGroupId,
}: {
  open: boolean;
  handleCloseModal: () => void;
  currentCapacity?: number;
  eventId: string;
  region: string;
  streamGroupId: string;
}) => {
  const classes = useStyles();
  const { updateCurrentGroup } = useGameCast();
  const initialValues = {
    currentCapacity,
  };

  const handleUpdateGroup = async () => {
    const updatedGroup = await EventsService.updateGameCastGroup(eventId, {
      capacity: values.currentCapacity as number,
      region,
      streamGroupId,
    });

    if (updatedGroup) {
      updateCurrentGroup(updatedGroup);
      handleCloseModal();
    }
  };

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit: handleUpdateGroup,
    enableReinitialize: true,
  });

  return (
    <Modal open={open} onClose={handleCloseModal}>
      <Box sx={classes.modal}>
        <Typography fontWeight={700} fontSize={18}>
          Update Stream Group Configuration
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            label="Capacity"
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            value={values.currentCapacity}
            name="currentCapacity"
            onChange={handleChange}
          />
          <Button variant="contained" type="submit">
            Update Config
          </Button>
        </form>
        <Typography fontSize={12}>
          Time to apply the changes is approximately 5 minutes
        </Typography>
      </Box>
    </Modal>
  );
};

export default ConfigurationModal;
