import axios from 'axios';
import { instanceManagerApiUrl } from 'configs/apiConfigs';
import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import axiosWithUserToken from './axiosInstances/axiosWithUserToken';
import axiosWithUserTokenInstanceManager from './axiosInstances/axiosWithUserTokenInstanceManager';
import {
  CloneEventProps,
  CreateEventProps,
  GetEventsProps,
  GetTimedInvitationLinkProps,
  ListEventsProps,
  PublishSelfServiceProps,
  RemoveEventProps,
  UpdateEventProps,
} from './types/events';
import {
  HttpResponseDefault,
  HttpResponseCanPublish,
  HttpResponseCanCreate,
  HttpResponseGenericAccessLink,
} from './types/httpGeneric';

const events = {
  async list({ userId }: ListEventsProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/events/list/${userId}?subscribed=false`
      );

      return { data: data.events };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async get({ eventId }: GetEventsProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/events/get/${eventId}`
      );
      return { data: data.event };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async create(data: CreateEventProps): Promise<HttpResponseDefault> {
    try {
      const result = await axiosWithUserToken().post(
        '/cms/events/create',
        data
      );
      return { message: 'New Event Created', data: result.data.event };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async update({
    eventId,
    data,
  }: UpdateEventProps): Promise<HttpResponseDefault> {
    try {
      const result = await axiosWithUserToken().put(
        `/cms/events/update/${eventId}`,
        data
      );
      return { message: 'Event Updated', data: result.data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async remove({ eventId }: RemoveEventProps): Promise<HttpResponseDefault> {
    try {
      const result = await axiosWithUserToken().delete(
        `/cms/events/delete/${eventId}`
      );
      return { message: 'Event Removed', data: result.data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async getGenericAccessLink({
    eventId,
    expiresIn,
  }: GetTimedInvitationLinkProps): Promise<HttpResponseGenericAccessLink> {
    try {
      const { data } = await axiosWithUserToken().post(
        `/cms/events/timedInvitationLink/${eventId}`,
        { expiresIn }
      );

      return data;
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async canPublishEvent({
    eventId,
  }: GetEventsProps): Promise<HttpResponseCanPublish> {
    try {
      const { data } = await axiosWithUserTokenInstanceManager().post(
        `/event/canPublishEvent`,
        {
          eventId: eventId,
        }
      );
      return data;
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async canCreateRoom({
    eventId,
  }: GetEventsProps): Promise<HttpResponseCanCreate> {
    try {
      const { data } = await axiosWithUserTokenInstanceManager().post(
        `/event/canCreateRoom`,
        {
          eventId: eventId,
        }
      );
      return data;
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  // ADMIN TOOLS
  async listAll(): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get('/cms/events/list');
      return { data: data.events };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async cloneEvent(data: CloneEventProps): Promise<HttpResponseDefault> {
    try {
      const result = await axiosWithUserToken().post('/cms/events/clone', data);
      return { message: 'Success', data: result.data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async publishSelfService(
    payload: PublishSelfServiceProps
  ): Promise<HttpResponseDefault> {
    try {
      const result = await axios.post(
        `${instanceManagerApiUrl}/event/publishSelfService?UEVersion=4`,
        payload
      );
      return { message: 'Success', data: result.data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async setEventDefault(payload: {
    event: string;
    avatar: string;
  }): Promise<HttpResponseDefault> {
    try {
      const result = await axiosWithUserToken().patch(
        '/cms/events/setDefaultAvatar',
        payload
      );
      return { message: 'Event Updated', data: result.data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async makeModerator(subscriberId: string): Promise<HttpResponseDefault> {
    try {
      const result = await axiosWithUserToken().patch(
        `/cms/events/setModerator/${subscriberId}`,
        {
          isModerator: true,
        }
      );
      return { message: 'Event Updated', data: result.data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async listCustomUrls(): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get('/cms/events/customUrls');
      return { data: data.customUrls };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default events;
