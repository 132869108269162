import { Box, Button, TextField } from '@mui/material';
import { appPaths } from 'app/router/appPaths';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { http } from 'services/http';
import { validationSchema } from './validationSchema';

export const PasswordRecoveryForm = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setSubmitting] = useState(false);
  const [isEmailSent, setEmailSent] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      http.auth
        .passwordRecovery(values)
        .then((res) => {
          setSubmitting(false);
          if (res.error) {
            enqueueSnackbar(res.message, { variant: 'error' });
            return;
          }
          setUserEmail(values.email);
          setEmailSent(true);
        })
        .catch((error) => {
          setSubmitting(false);
          enqueueSnackbar(error.message, { variant: 'error' });
        });
    },
  });

  return (
    <>
      {!isEmailSent ? (
        <Box sx={{ width: '500px' }}>
          <h3>Recovery Password</h3>

          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <Button
              sx={{ mt: 3 }}
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
          </form>

          <p>
            <Link to={appPaths.public.account.login}>Got to Sign In</Link>
          </p>
        </Box>
      ) : (
        <Box sx={{ width: '500px' }}>
          <h3>Recovery Password</h3>

          <p>Email sent to: {userEmail}</p>

          <p>
            <Link to={appPaths.public.account.login}>Got to Sign In</Link>
          </p>
        </Box>
      )}
    </>
  );
};
