import { useCallback, useRef, useState } from 'react';
import { Button, Divider, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { AppDefaultLayout } from 'layouts/AppDefaultLayout';
import { deleteUserData, selectUser } from 'redux/slices/userSlice';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { http } from 'services/http';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'shared/hooks/useAuth';
import DialogAlert, {
  DialogAlertHandles,
} from 'shared/components/alerts/DialogAlert';
import { ProfileEditForm } from './ProfileEditForm';
import { PasswordEditForm } from './PasswordEditForm';

export const UserProfilePage = () => {
  const { user } = useAppSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();
  const [showProfileForm, setShowProfileForm] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { signOut } = useAuth();
  const dispatch = useAppDispatch();

  const handleDeleteAccount = () => {
    if (!user) return;

    const id = user.data.id;
    const token = user.connectToken;

    const from = location.state?.from?.pathname || '/';

    http.user.remove({ id, token }).then((res) => {
      if (res.error) {
        enqueueSnackbar(res.message, { variant: 'error' });
        return;
      }

      signOut(() => {
        navigate(from, { replace: true });
        enqueueSnackbar('User removed', { variant: 'success' });
        dispatch(deleteUserData());
      });
    });
  };

  const modalRef = useRef<DialogAlertHandles>(null);
  const handleOpenModal = useCallback(() => {
    modalRef.current?.handleOpen();
  }, []);

  return (
    <AppDefaultLayout>
      <DialogAlert
        title="Delete Account?"
        message="Are you sure you want to remove your account? This action cannot be
          undone."
        okButtonText="Delete Account"
        ref={modalRef}
        deleteCallback={() => {
          handleDeleteAccount();
        }}
      />

      <Grid>
        <h1>User Profile</h1>
        <p>First Name: {user && user.data.firstName}</p>
        <p>Last Name: {user && user.data.lastName}</p>
        <p>Email: {user && user.data.email}</p>
        <p>
          Email confirmed? {user && (user.data.confirmedEmail ? 'Yes' : 'No')}
        </p>
        <Divider />
        <p>
          <Button
            onClick={() => {
              setShowProfileForm(!showProfileForm);
              setShowPasswordForm(false);
            }}
          >
            <EditIcon fontSize="small" style={{ marginRight: '8px' }} />{' '}
            <span>Edit Profile Data</span>
          </Button>
          <Button
            onClick={() => {
              setShowPasswordForm(!showPasswordForm);
              setShowProfileForm(false);
            }}
          >
            <EditIcon fontSize="small" style={{ marginRight: '8px' }} />{' '}
            <span>Change Password</span>
          </Button>
          <Button onClick={handleOpenModal} color="error">
            <DeleteForeverIcon
              fontSize="small"
              style={{ marginRight: '8px' }}
            />{' '}
            <span>Delete Account</span>
          </Button>
        </p>
      </Grid>

      {showProfileForm && (
        <Grid>
          <Divider />
          <h2>Edit Profile Data</h2>
          <ProfileEditForm
            submitCallback={() => {
              setShowProfileForm(false);
            }}
          />
        </Grid>
      )}

      {showPasswordForm && (
        <Grid>
          <Divider />
          <h2>Change Password</h2>
          <PasswordEditForm
            submitCallback={() => {
              setShowPasswordForm(false);
            }}
          />
        </Grid>
      )}
    </AppDefaultLayout>
  );
};
