import { Button } from '@mui/material';
import { FormikValues } from 'formik';
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import BoxDialogModal, {
  BoxDialogModalHandles,
} from 'shared/components/modals/BoxDialogModal';
import { useAppSelector } from 'shared/hooks/useStore';
// ===========================================================
import { selectGenericAccessLink } from 'redux/slices/genericAccessLinkSlice';
import { GenericAccessLinkForm } from './GenericAccessLinkForm';

export interface GenericAccessLinkModalHandles {
  handleOpen: () => void;
}

const UpdateEventModal: ForwardRefRenderFunction<
  GenericAccessLinkModalHandles
> = (_, ref) => {
  const {
    utils: { isSendingUpdateForm: isSendingForm },
  } = useAppSelector(selectGenericAccessLink);

  const boxDialogModalRef = useRef<BoxDialogModalHandles>(null);

  const handleOpen = () => {
    boxDialogModalRef.current?.handleOpen();
  };

  const handleClose = (message: string) => {
    // Copy to clipboard
    navigator.clipboard.writeText(message).then(
      () => {},
      (err) => {
        // eslint-disable-next-line no-console
        console.warn('Could not copy text: ', err);
      }
    );

    boxDialogModalRef.current?.handleClose();
  };

  const genericAccessLinkFormRef = useRef<FormikValues>();

  const handleSubmitForm = useCallback(() => {
    genericAccessLinkFormRef.current?.handleSubmit();
  }, []);

  useImperativeHandle(ref, () => {
    return { handleOpen };
  });

  return (
    <BoxDialogModal
      ref={boxDialogModalRef}
      title="Generic Access Link"
      submitButton={
        <Button
          autoFocus
          onClick={handleSubmitForm}
          variant="contained"
          disabled={isSendingForm}
        >
          {!isSendingForm ? 'Copy' : 'Copying...'}
        </Button>
      }
    >
      <GenericAccessLinkForm
        innerRef={genericAccessLinkFormRef as any}
        onSubmitCallback={handleClose}
      />
    </BoxDialogModal>
  );
};

export default forwardRef(UpdateEventModal);
