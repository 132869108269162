import { Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { selectInvites } from 'redux/slices/invitesSlice';
import { useAppSelector } from 'shared/hooks/useStore';
import DataTable from './DataTable';
import { FilterBySpeakers } from './FilterBySpeakers';
import { Search } from './Search';
import { SortByField } from './SortByField';

export const InviteesContainer = () => {
  const { utils } = useAppSelector(selectInvites);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        marginBottom={2}
      >
        <span></span>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <SortByField />

          <FilterBySpeakers />

          <Search />
        </Stack>
      </Stack>

      {utils.isFetching ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <DataTable />
      )}
    </>
  );
};
