import {
  Box,
  LinearProgress,
  Stack,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import CloudIcon from '@mui/icons-material/Cloud';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ErrorIcon from '@mui/icons-material/Error';
import { ColorlibConnector, ColorlibStepIconRoot } from './styled';
import { ReactElement } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { CustomizedSteppersProps } from './types';

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className, error } = props;

  const icons: { [index: string]: ReactElement } = {
    1: <CloudIcon />,
    2: <CloudUploadIcon />,
    3: <CloudDoneIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active, error }}
      className={className}
    >
      {error ? (
        <ErrorIcon />
      ) : active ? (
        <CircularProgress style={{ color: 'white' }} />
      ) : (
        icons[String(props.icon)]
      )}
    </ColorlibStepIconRoot>
  );
}

const steps = ['Starting process', 'Uploading media', 'Saving data'];

export default function CustomizedSteppers({
  activeStep = 0,
  errorStep,
  uploadProgress,
}: CustomizedSteppersProps) {
  const isStepFailed = (step: number) => {
    return step === errorStep;
  };

  return (
    <>
      <Stack sx={{ width: '100%' }} spacing={4}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label, index) => {
            const labelProps: {
              error?: boolean;
            } = {};

            if (isStepFailed(index)) {
              labelProps.error = true;
            }

            return (
              <Step key={label}>
                <StepLabel {...labelProps} StepIconComponent={ColorlibStepIcon}>
                  {label}
                  {isStepFailed(index) && (
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ display: 'block' }}
                    >
                      Failed
                    </Typography>
                  )}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Stack>
      {!!uploadProgress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </Box>
      )}
    </>
  );
}
