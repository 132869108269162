export const roles = { specialRoles: ['admin', 'developer'] };

export const isSpecialRole = (role: string) => {
  return roles.specialRoles.some((element) => {
    return role.toLowerCase().includes(element);
  });
};

export const isDeveloperRole = (role: 'developer') => {
  return role.toLowerCase().includes('developer');
};
