import React, { useEffect, useState } from 'react';

import { ProcessedEvent } from '@aldabil/react-scheduler/types';
import {
  Box,
  Button,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';

import { DatePickersContainer } from 'modules/events/components/CreateEventModal/CreateEventForm/DatePickersContainer';
import { useAppSelector } from 'shared/hooks/useStore';
import { validationSchema, parseTimeToISO } from './helpers';
import { gameCastConfigItemSelector } from 'redux/slices/admin/gameCastSlice';
import { SchedulerDialogInit, SchedulerDialogProps } from './interfaces';
import { CustomProcessedEvent, StreamingProviders } from '../interface';
import { useStyles } from './styles';
import PurewebRegionsCapacity from '../PurewebRegionsCapacity/PurewebRegionsCapacity';
import { usePurewebRegions } from './hooks';
import { useParams, useSearchParams } from 'react-router-dom';
import { currentPurewebModelCalendarEventsSelector } from 'redux/slices/admin/purewebSlice';
import { REACT_APP_GAMECAST_SCHEDULER } from 'configs/appFeatures';

const SchedulerDialog = ({
  scheduler,
  handleConfirm,
}: SchedulerDialogProps) => {
  const currentGroup = useAppSelector(gameCastConfigItemSelector);
  const classes = useStyles();
  const [selectedStreamingProvider, setSelectedSteamingProvider] = useState(
    StreamingProviders.Pureweb
  );

  const [calendarInitialValues, setCalendarInitialValues] = useState<{
    [key: string]: number;
  } | null>(null);

  const {
    currentActiveRegions,
    purewebInitialValues,
    setCheckboxesState,
    handleScheduleRegions,
  } = usePurewebRegions(calendarInitialValues);

  const purewebCalendar = useAppSelector(
    currentPurewebModelCalendarEventsSelector
  );

  useEffect(() => {
    const currentEvent = purewebCalendar.find(
      (event) =>
        (event.start as unknown as string) ===
        String(scheduler.state.start.value)
    );

    if (currentEvent) {
      const activeRegions = (
        currentEvent as unknown as CustomProcessedEvent
      ).targetRegions?.reduce((acc: { [key: string]: boolean }, region) => {
        acc[region.region] = true;

        return acc;
      }, {});

      setCheckboxesState((prev) => {
        return { ...prev, ...activeRegions };
      });

      const initialValues = (
        currentEvent as unknown as CustomProcessedEvent
      ).targetRegions?.reduce((acc: { [key: string]: number }, region) => {
        acc[region.region] = region.capacity;

        return acc;
      }, {});

      if (!initialValues) {
        setCalendarInitialValues(null);

        return;
      }
      setCalendarInitialValues(initialValues);
    }

    return () => {
      setCalendarInitialValues(null);
    };
  }, [scheduler, purewebCalendar]);

  const [searchParams] = useSearchParams();
  const { eventId } = useParams();
  const modelId = searchParams.get('modelId');

  const formikValues = {
    initialValues: {
      capacity:
        selectedStreamingProvider === StreamingProviders.Pureweb
          ? purewebInitialValues
          : scheduler.edited?.capacity || currentGroup?.DesiredCapacity || 0,
      startTime: parseTimeToISO(scheduler.state.start.value),
      endTime: parseTimeToISO(scheduler.state.end.value),
      streamingProvider: selectedStreamingProvider,
    },
    validationSchema,
    onSubmit: async (values: SchedulerDialogInit) => {
      if (selectedStreamingProvider === StreamingProviders.Pureweb) {
        const capacity = values.capacity as { [key: string]: number };

        const targetRegions = Object.keys(capacity).reduce(
          (acc: { capacity: number; region: string }[], key) => {
            if (!capacity[key]) {
              return acc;
            }

            return [...acc, { capacity: capacity[key], region: key }];
          },
          []
        );

        handleScheduleRegions({
          eventId: eventId as string,
          end: new Date(values.endTime),
          modelId: modelId as string,
          newStartTime:
            values.startTime === parseTimeToISO(scheduler.state.start.value)
              ? null
              : values.startTime,
          start:
            values.startTime !== parseTimeToISO(scheduler.state.start.value)
              ? new Date(scheduler.state.start.value)
              : new Date(values.startTime),
          targetRegions,
        });

        return;
      }

      const timeline = {
        start:
          values.startTime !== parseTimeToISO(scheduler.state.start.value)
            ? new Date(scheduler.state.start.value)
            : new Date(values.startTime),
        newStartTime:
          values.startTime === parseTimeToISO(scheduler.state.start.value)
            ? null
            : values.startTime,
        end: new Date(values.endTime),
        event_id: new Date(values.startTime).getTime(),
        capacity: values.capacity,
        streamingProvider: StreamingProviders.GameCast,
      } as CustomProcessedEvent;

      await handleConfirm(
        timeline as unknown as ProcessedEvent,
        values.capacity as number
      );
    },
  };

  const handleChangeStreamingProvider = (event: SelectChangeEvent) => {
    setSelectedSteamingProvider(event.target.value as StreamingProviders);
  };

  return (
    <Dialog open onClose={scheduler.close}>
      <Box sx={classes.root}>
        <Typography component="p">Machines Scheduler</Typography>
        <Formik
          initialValues={formikValues.initialValues}
          validationSchema={formikValues.validationSchema}
          onSubmit={formikValues.onSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <FormControl>
                <InputLabel id="select-label">Streaming Provider</InputLabel>
                <Select
                  labelId="select-label"
                  id="demo-simple-select"
                  label="Streaming Provider"
                  value={formik.values.streamingProvider}
                  name="streamingProvider"
                  onChange={(event) => {
                    handleChangeStreamingProvider(event);
                    formik.setFieldValue(
                      'streamingProvider',
                      event.target.value as StreamingProviders
                    );
                  }}
                >
                  {REACT_APP_GAMECAST_SCHEDULER === 'true' ? (
                    <MenuItem value={StreamingProviders.GameCast}>
                      AWS cloud stream
                    </MenuItem>
                  ) : null}
                  <MenuItem value={StreamingProviders.Pureweb}>
                    Pureweb Pixel Stream
                  </MenuItem>
                </Select>
              </FormControl>
              <DatePickersContainer formik={formik} />
              {selectedStreamingProvider === StreamingProviders.Pureweb ? (
                <PurewebRegionsCapacity
                  currentActiveRegions={currentActiveRegions}
                  formik={formik}
                  setCheckboxesState={setCheckboxesState}
                />
              ) : (
                <TextField
                  label="Capacity"
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  value={formik.values.capacity}
                  name="capacity"
                  onChange={formik.handleChange}
                />
              )}
              <Button type="submit" variant="contained">
                Schedule
              </Button>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

export default SchedulerDialog;
