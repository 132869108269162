import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { setFetching, setInvitesList } from 'redux/slices/invitesSlice';
import { http } from 'services/http';
import { useAppDispatch } from 'shared/hooks/useStore';

export const useLoadParticipants = () => {
  const dispatch = useAppDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const get = useCallback(async () => {
    dispatch(setFetching(true));

    await http.invites
      .getParticipants({
        eventId: id as string,
      })
      .then((res) => {
        const invitesSorted = res.data?.sort((a: any, b: any) =>
          new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
        );
        if (invitesSorted) dispatch(setInvitesList(invitesSorted));
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        dispatch(setFetching(false));
      });
  }, [dispatch, enqueueSnackbar, id]);

  return { get };
};
