import { createSlice } from '@reduxjs/toolkit';
import { UpdateMediaProps } from 'shared/types/rooms';
import type { RootState } from '../store';

type actualRoomType = {
  [key: string]: string;
};

export type RoomMap = {
  id: string | number;
  displayName?: string;
  imageBaseMap?: string;
  mapName?: string;
  thumbnail?: string;
  mapId?: string;
};

export type RoomSpot = {
  mediaType: string;
  user: string;
  createdAt: string | number;
  asset: string;
  isActive: boolean;
  mapId: string | number;
  image: string;
  updatedAt: string | number;
  isDefault: boolean;
  description: string;
  id: string | number;
  spotModelId: string | number;
  displayName: string;
  ueIdentifier: string;
  assetMediaType: string | number;
};

const initialState = {
  isRoomsLoading: false,
  rooms: [],
  actualRoom: {} as actualRoomType,
  roomMap: {} as RoomMap,
  roomSpots: [] as RoomSpot[],
  roomSpotsToImport: [] as RoomSpot[],
  roomSpotsToExport: [] as RoomSpot[],
  actualSpot: {} as UpdateMediaProps,
  utils: {
    isSendingCreateForm: false,
    isSendingUpdateForm: false,
    isSendingMediaForm: false,
    isLoadingPage: true,
    hasRoom: false,
  },
};

export const roomsSlice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {
    setIsRoomsLoading: (state, { payload }) => {
      state.isRoomsLoading = payload;
    },
    setRoomList: (state, { payload }) => {
      state.rooms = payload;
    },
    setActualRoom: (state, { payload }) => {
      state.actualRoom = payload;
    },
    setRoomMap: (state, { payload }) => {
      state.roomMap = payload;
    },
    setRoomSpots: (state, { payload }) => {
      state.roomSpots = payload;
    },
    setRoomSpotsToExport: (state, { payload }) => {
      state.roomSpotsToExport = payload;
    },
    clearRoomSpotsToExport: (state) => {
      state.roomSpotsToExport = [];
    },
    setRoomSpotsToImport: (state, { payload }) => {
      state.roomSpotsToImport = payload;
    },
    clearRoomSpotsToImport: (state) => {
      state.roomSpotsToImport = [];
    },
    setActualSpot: (state, { payload }) => {
      state.actualSpot = payload;
    },
    setSendingCreateForm: (state, { payload }) => {
      state.utils.isSendingCreateForm = payload;
    },
    setSendingUpdateForm: (state, { payload }) => {
      state.utils.isSendingUpdateForm = payload;
    },
    setSendingMediaForm: (state, { payload }) => {
      state.utils.isSendingMediaForm = payload;
    },
    setLoadingPage: (state, { payload }) => {
      state.utils.isLoadingPage = payload;
    },
    setHasRoom: (state, { payload }) => {
      state.utils.hasRoom = payload;
    },
  },
});

export const selectRooms = (state: RootState) => state.roomsData;
export const {
  setIsRoomsLoading,
  setRoomList,
  setActualRoom,
  setRoomMap,
  setRoomSpots,
  setRoomSpotsToExport,
  clearRoomSpotsToExport,
  setRoomSpotsToImport,
  clearRoomSpotsToImport,
  setActualSpot,
  setSendingCreateForm,
  setSendingUpdateForm,
  setSendingMediaForm,
  setLoadingPage,
  setHasRoom,
} = roomsSlice.actions;

export default roomsSlice.reducer;
