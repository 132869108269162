import { Box, Button, Divider, Stack, Typography } from '@mui/material';

import InputIcon from '@mui/icons-material/Input';

import { RemoveEvent } from '../RemoveEvent';

import { IEventRow } from './interfaces';

const EventRow = ({ event, onConfigEvent }: IEventRow) => {
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography component="span">{event.name}</Typography>
        <Typography component="span">
          <Button
            color="primary"
            onClick={onConfigEvent}
            startIcon={<InputIcon fontSize="small" />}
          >
            Config Event
          </Button>
          <RemoveEvent event={event} />
        </Typography>
      </Stack>
      <Divider style={{ marginBottom: '16px' }} />
    </Box>
  );
};

export default EventRow;
