import HideSourceIcon from '@mui/icons-material/HideSource';
import { Button } from '@mui/material';
import { instanceManagerApiUrl } from 'configs/apiConfigs';
import { useState } from 'react';
import io from 'socket.io-client';
import { DestroySessionsModal } from './DestroySessionsModal';

export const DestroySessions = () => {
  const [openRecreateSessions, setOpenRecreateSessions] = useState(false);
  const [socket, setSocket] = useState<any>(false);

  const handleOpenRecreateSessionsModal = () => {
    connectWithWebsocket();
    setOpenRecreateSessions(true);
  };

  const handleCloseRecreateSessionsModal = () => {
    setOpenRecreateSessions(false);

    if (socket) {
      socket.disconnect();
      socket.close();
      setSocket(false);
    }
  };
  const connectWithWebsocket = () => {
    const serverUrl = instanceManagerApiUrl;

    setSocket(
      io(serverUrl, {
        reconnection: true,
        reconnectionDelay: 500,
        transports: ['websocket'],
      })
    );
  };

  return (
    <>
      <DestroySessionsModal
        socket={socket}
        open={openRecreateSessions}
        onCancel={handleCloseRecreateSessionsModal}
      />

      <Button
        color="error"
        onClick={handleOpenRecreateSessionsModal}
        startIcon={<HideSourceIcon fontSize="small" />}
      >
        Destroy Rooms
      </Button>
    </>
  );
};
