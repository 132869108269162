import {
  CircularProgress,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import { BoxDialog } from 'shared/components/modals/BoxDialogModal/styled';
import SaveIcon from '@mui/icons-material/Save';
import {
  ButtonCancelMedia,
  ButtonSaveMedia,
  CancelIconCustom,
  IconButtonExport,
} from '../styled';
import { Stack } from '@mui/system';
import { Formik } from 'formik';
import { validationSchema } from './validationSchema';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { CSVDownload } from 'react-csv';
import { useEffect } from 'react';
import { clearRoomSpotsToExport, selectRooms } from 'redux/slices/roomsSlice';

interface AddProps {
  open: boolean;
  mapId: string | number;
  onSubmit: (values: any, setSubmitting: any) => void;
  handleClose: () => void;
}

export default function ExportSpotsModal({
  open,
  mapId,
  handleClose,
  onSubmit,
}: AddProps) {
  interface Values {
    mapId: string | number;
  }

  const getInitialValues = () => {
    return {
      mapId: mapId,
    };
  };

  const formikValues = {
    initialValues: getInitialValues(),
    validationSchema: validationSchema,
  };

  const handleExport = (values: Values, setSubmitting: any) => {
    onSubmit(values, setSubmitting);
  };

  const dispatch = useAppDispatch();
  const { roomSpotsToExport } = useAppSelector(selectRooms);

  useEffect(() => {
    dispatch(clearRoomSpotsToExport());
  }, [open]);

  return (
    <BoxDialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="add-point-dialog-title"
      scroll="body"
      maxWidth="md"
    >
      {roomSpotsToExport.length > 0 && (
        <CSVDownload data={roomSpotsToExport} target="_blank" />
      )}

      <Formik
        initialValues={formikValues.initialValues}
        validationSchema={formikValues.validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleExport(values, setSubmitting);
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <DialogContent dividers style={{ minWidth: '450px' }}>
              <Grid container direction="column">
                <Grid
                  xs={12}
                  lg={12}
                  md={12}
                  justifyContent="center"
                  display="flex"
                  marginTop="20px"
                  item
                >
                  <IconButtonExport
                    color="primary"
                    aria-label="save"
                    step="normal"
                  >
                    <SaveIcon />
                  </IconButtonExport>
                </Grid>

                <Grid
                  xs={12}
                  lg={12}
                  md={12}
                  justifyContent="center"
                  display="flex"
                  item
                >
                  <Stack
                    justifyContent="center"
                    display="flex"
                    textAlign="center"
                    marginLeft={5}
                    marginRight={5}
                  >
                    <Typography variant="h4" component="span" marginTop="20px">
                      Download Current Room Media?
                    </Typography>

                    <Typography variant="body1" component="p">
                      All of your current media assets (images and videos) will
                      be saved
                    </Typography>
                    <Typography variant="body1" component="p">
                      to your computer as a .csv file. This file can be used to
                      easily
                    </Typography>
                    <Typography variant="body1" component="p">
                      re-upload your media at a later time.
                    </Typography>

                    <Typography variant="body1" component="p" marginTop="20px">
                      Do you want to proceed with saving your
                    </Typography>
                    <Typography variant="body1" component="p">
                      media assets to your computer?
                    </Typography>
                  </Stack>
                </Grid>

                <Grid
                  xs={12}
                  lg={12}
                  md={12}
                  justifyContent="center"
                  display="flex"
                  marginTop="20px"
                  marginBottom="20px"
                  item
                >
                  <ButtonSaveMedia
                    type="submit"
                    startIcon={
                      !formik.isSubmitting ? (
                        <SaveIcon fontSize="small" />
                      ) : (
                        <CircularProgress size="20px" />
                      )
                    }
                    color="success"
                    variant="contained"
                    disabled={formik.isSubmitting}
                  >
                    Yes, Save Media
                  </ButtonSaveMedia>
                  <ButtonCancelMedia
                    type="button"
                    startIcon={
                      <CancelIconCustom
                        fontSize="small"
                        className="cancelIcon"
                      />
                    }
                    onClick={() => {
                      handleClose();
                    }}
                    color="info"
                    variant="contained"
                  >
                    Cancel
                  </ButtonCancelMedia>
                </Grid>
              </Grid>
            </DialogContent>
          </form>
        )}
      </Formik>
    </BoxDialog>
  );
}
