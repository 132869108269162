import { combineReducers } from '@reduxjs/toolkit';

import userData from 'redux/slices/userSlice';
import eventsData from 'redux/slices/eventsSlice';
import skinsData from 'redux/slices/skinsSlice';
import emailsData from 'redux/slices/emailsSlice';
import invitesData from 'redux/slices/invitesSlice';
import ticketsData from 'redux/slices/ticketsSlice';
import roomsData from 'redux/slices/roomsSlice';
import pointsOfInterestData from 'redux/slices/pointsOfInterestSlice';
import avatarsData from 'redux/slices/avatarsSlice';

// ADMIN
import mapsData from 'redux/slices/admin/mapsSlice';
import spotsData from 'redux/slices/admin/spotsSlice';
import regionsData from 'redux/slices/admin/regionsSlice';
import eventsAdminData from 'redux/slices/admin/eventsAdminSlice';
import defaultSettingsData from 'redux/slices/admin/defaultSettingsSlice';
import streamServicesData from 'redux/slices/admin/streamServicesSlice';
import skinsModelData from 'redux/slices/admin/skinsModelSlice';
import accessListData from 'redux/slices/admin/accessListSlice';
import gameCastData from 'redux/slices/admin/gameCastSlice';
import purewebSlice from 'redux/slices/admin/purewebSlice';

const rootReducer = combineReducers({
  userData,
  eventsData,
  roomsData,
  mapsData,
  spotsData,
  skinsData,
  emailsData,
  invitesData,
  ticketsData,
  regionsData,
  eventsAdminData,
  defaultSettingsData,
  streamServicesData,
  pointsOfInterestData,
  gameCastData,
  skinsModelData,
  avatarsData,
  accessListData,
  purewebSlice,
});

export default rootReducer;
