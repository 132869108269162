import { Box, Tooltip } from '@mui/material';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';

const FinishedTutorial = ({ completed }: { completed?: boolean }) => {
  return (
    <>
      <Box
        style={{
          marginLeft: '18px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {completed ? (
          <Tooltip title="Tutorial finished">
            <PlaylistAddCheckOutlinedIcon color="success" />
          </Tooltip>
        ) : (
          <Tooltip title="Tutorial not finished">
            <AssignmentLateOutlinedIcon color="disabled" />
          </Tooltip>
        )}
      </Box>
    </>
  );
};

export default FinishedTutorial;
