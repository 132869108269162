import { FormControl, InputAdornment, MenuItem, Select } from '@mui/material';
import { Alias } from 'shared/types/models/region';
import CircularProgress from '@mui/material/CircularProgress';

export const AliasesSelector = ({
  aliases,
  values,
  touched,
  errors,
  handleChange,
  disabled,
  isLoadingAlias,
}: any) => {
  return (
    <FormControl fullWidth margin="normal">
      <span>Alias*</span>
      <Select
        id="aliasSelected"
        name="aliasSelected"
        onChange={handleChange}
        placeholder="Select alias"
        disabled={disabled}
        value={values.aliasSelected}
        fullWidth
        startAdornment={
          <>
            {isLoadingAlias && (
              <InputAdornment position="start">
                Loading...{' '}
                <CircularProgress size={20} sx={{ marginLeft: '5px' }} />
              </InputAdornment>
            )}
          </>
        }
      >
        {aliases ? (
          aliases.map((item: Alias, index: any) => (
            <MenuItem key={index} value={item.aliasId}>
              {item.displayName}
            </MenuItem>
          ))
        ) : (
          <MenuItem key={0} value="none" selected={true} disabled={true}>
            No alias available
          </MenuItem>
        )}
      </Select>
      {touched.aliasSelected && Boolean(errors.aliasSelected) && (
        <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-11h4n6a-MuiFormHelperText-root">
          {errors.aliasSelected}
        </p>
      )}
    </FormControl>
  );
};
