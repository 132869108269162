import styled from '@emotion/styled';
import { Stack } from '@mui/material';

export const Container = styled(Stack)({
  width: '100%',
  display: 'flex',
  height: '100vh',
  boxSizing: 'content-box',
  backgroundColor: '#F5F5F5',
});
