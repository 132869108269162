import { Box, styled } from '@mui/material';

export const MainWrapper = styled(Box)(
  () => `
    flex: 1 1 auto;
    display: flex;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  `
);

export const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

export const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 120px;
`
);
