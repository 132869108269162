import { PoiMediaType } from '../types/utils';
import { SpotMediaType } from '../types/rooms';

type PoiAcceptCriteriaFileType = {
  [key in PoiMediaType]?: string;
};

type SpotAcceptCriteriaFileType = {
  [key in SpotMediaType]?: string;
};

const POI_ACCEPT_CRITERIA_FILE: PoiAcceptCriteriaFileType = {
  [PoiMediaType.Image]: 'image/*',
  [PoiMediaType.Video]: 'video/mp4',
  [PoiMediaType.ThreeDObjectView]: '.glb',
  [PoiMediaType.ThreeDProp]: '.glb',
  [PoiMediaType.File]: '*',
};

const SPOT_ACCEPT_CRITERIA_FILE: SpotAcceptCriteriaFileType = {
  [SpotMediaType.Image]: 'image/*',
  [SpotMediaType.Video]: 'video/mp4',
  [SpotMediaType.Three_D]: '.glb',
  [SpotMediaType.Audio]: 'audio/*',
};

export const getAcceptCriteriaFile = (mediaType: PoiMediaType): string => {
  return POI_ACCEPT_CRITERIA_FILE[mediaType] || '';
};

export const getPoiAcceptCriteriaFile = (mediaType: PoiMediaType): string => {
  return POI_ACCEPT_CRITERIA_FILE[mediaType] || '';
};

export const getSpotAcceptCriteriaFile = (mediaType: SpotMediaType): string => {
  return SPOT_ACCEPT_CRITERIA_FILE[mediaType] || '';
};
