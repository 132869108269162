import { apiUrl } from 'configs/apiConfigs';
import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import axiosWithUserToken from './axiosInstances/axiosWithUserToken';

const authUsers = {
  async create(userEmail: string, eventId: string, role: string) {
    try {
      const { data } = await axiosWithUserToken().post(
        `${apiUrl}/cms/auth-users/create`,
        {
          user: userEmail,
          event: eventId,
          role: role,
        }
      );
      return { message: 'Success', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async removeByEvent(userEmail: string, eventId: string) {
    try {
      const { data } = await axiosWithUserToken().delete(
        `${apiUrl}/cms/auth-users/deleteByEvent`,
        {
          data: {
            user: userEmail,
            event: eventId,
          },
        }
      );
      return { message: 'Success', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default authUsers;
