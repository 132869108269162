import axios from 'axios';
import { apiUrl } from 'configs/apiConfigs';
import { CreateAdminData, UpdateAdminData } from 'shared/types/maps';
import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import { HttpReponseDefault } from 'shared/types/httpGeneric';
import { sortByDisplayName } from 'shared/utils/sortByDisplayName';

const maps = {
  //Admin routes
  async listInAdmin({ token }: { token: string }): Promise<HttpReponseDefault> {
    try {
      const { data } = await axios.get(`${apiUrl}/cms/maps-model/list`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      const sorteredByDisplayName = sortByDisplayName(data.mapsModel);

      return { message: 'Loaded', data: sorteredByDisplayName };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async getInAdmin({
    userToken,
    mapID,
  }: {
    userToken: string;
    mapID: string;
  }): Promise<HttpReponseDefault> {
    try {
      const { data } = await axios.get(
        `${apiUrl}/cms/maps-model/get/${mapID}`,
        {
          headers: {
            Authorization: `${userToken}`,
          },
        }
      );
      return { message: 'Loaded', data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async createInAdmin({
    userToken,
    formData,
  }: CreateAdminData): Promise<HttpReponseDefault> {
    const dataFormated = new FormData();

    Object.entries(formData).forEach(([key, value]) =>
      dataFormated.append(`${key}`, value as any)
    );

    try {
      const { data } = await axios.post(
        `${apiUrl}/cms/maps-model/create`,
        dataFormated,
        {
          headers: {
            Authorization: `${userToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return { message: `Map Created.`, data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async updateInAdmin({
    userToken,
    formData,
    mapId,
  }: UpdateAdminData): Promise<HttpReponseDefault> {
    const dataFormated = new FormData();

    Object.entries(formData).forEach(([key, value]) =>
      dataFormated.append(`${key}`, value as any)
    );

    try {
      const { data } = await axios.put(
        `${apiUrl}/cms/maps-model/update/${mapId}`,
        dataFormated,
        {
          headers: {
            Authorization: `${userToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return { message: `Map Updated.`, data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async uploadBaseImage({
    userToken,
    formData,
    mapID,
  }: {
    userToken: string;
    formData: any;
    mapID: string;
  }): Promise<HttpReponseDefault> {
    const dataFormated = new FormData();

    Object.entries(formData).forEach(([key, value]) =>
      dataFormated.append(`${key}`, value as any)
    );

    try {
      const { data } = await axios.put(
        `${apiUrl}/cms/maps-model/update/${mapID}`,
        dataFormated,
        {
          headers: {
            Authorization: `${userToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return { message: `Map Updated.`, data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async removeInAdmin({
    userToken,
    mapID,
  }: {
    userToken: string;
    mapID: string;
  }): Promise<HttpReponseDefault> {
    try {
      const res = await axios.delete(
        `${apiUrl}/cms/maps-model/delete/${mapID}`,
        {
          headers: {
            Authorization: `${userToken}`,
          },
        }
      );
      return { message: `Map Removed.`, data: res };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default maps;
