export interface CustomEventFormProps {
  subdomain: string;
}

export interface IImagesPreview {
  customerLogo?: string;
  backgroundImage?: string;
}

export enum CustomerEventTheme {
  Dark = 'DARK',
  Light = 'LIGHT',
}

export interface IUploadedImage {
  file: File;
  imagePreview: string;
}
