export const handleFileSizeLimit = ({
  originalSize,
  maxSizeInMb,
}: {
  originalSize: number;
  maxSizeInMb: number;
}) => {
  const size = originalSize / (1024 * 1024);

  return { newSize: size, isOutOfLimit: size > maxSizeInMb };
};
