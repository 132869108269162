import EditIcon from '@mui/icons-material/Edit';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import PublishIcon from '@mui/icons-material/Publish';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { appPaths } from 'app/router/appPaths';
import { AppDefaultLayout } from 'layouts/AppDefaultLayout';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { selectEvents } from 'redux/slices/eventsSlice';
import { http } from 'services/http';
import SessionBox from 'shared/components/boxes/SessionBox';
import { DefaultPageHeader } from 'shared/components/headers/DefaultPageHeader';
import { useLoadEvents } from 'shared/hooks/useLoadEvents';
import { useAppSelector } from 'shared/hooks/useStore';
import { DestroySessions } from './DestroySessions';
import { PublishEventModal } from './PublishEventModal';
import { RecreateSessions } from './RecreateSessions';
import UpdateEventModal, { UpdateEventModalHandles } from './UpdateEventModal';
import GenericAccessLinkModal, {
  GenericAccessLinkModalHandles,
} from './GenericAccessLinkModal';
import EventsService from 'services/http/eventsService';
import useAsyncEffect from 'use-async-effect';
import { StreamGroupConfig } from 'redux/slices/interfaces';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { StreamService } from 'shared/types/models/streamService';
import { usePureweb } from 'shared/hooks/admin/usePureweb';
import LoadingProgress from 'components/LoadingProgress/LoadingProgress';
import { useGameCast } from 'shared/hooks/admin/useGameCast';

export const EventDashboardPage = () => {
  const urlParams = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { event } = useAppSelector(selectEvents);
  const { get: getEvent } = useLoadEvents();
  const [publishingEvent, setPublishingEvent] = useState(false);
  const [openPublish, setOpenPublish] = useState(false);

  const updateEventModalRef = useRef<UpdateEventModalHandles>(null);
  const genericAccessLinkModalRef = useRef<GenericAccessLinkModalHandles>(null);
  const { loadCurrentPurewebModel, emptyCurrentPurewebModel } = usePureweb();
  const [streamServicesSelected, setStreamServicesSelected] =
    useState<StreamService>();
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const { emptyCurrentCalendar, emptyCurrentGroup } = useGameCast();
  const [streamGroupConfig, setStreamGroupConfig] =
    useState<StreamGroupConfig | null>(null);

  const handleOpenUpdateEventModal = () => {
    updateEventModalRef.current?.handleOpen();
  };

  const handleOpenGenericAccessLinkModal = () => {
    genericAccessLinkModalRef.current?.handleOpen();
  };

  const handleClosePublishEventModal = () => {
    setOpenPublish(false);
  };

  const handlePublishEvent = async () => {
    setPublishingEvent(true);
    if (!event.id) return;

    const result = await http.events.publishSelfService({
      eventId: String(event.id),
    });

    if (result.error) {
      enqueueSnackbar(result.message, {
        variant: 'error',
      });
      setPublishingEvent(false);
      return;
    }

    setOpenPublish(false);
    setPublishingEvent(false);
    setEventIsPublished();

    enqueueSnackbar(result.message, {
      variant: 'success',
    });
  };

  const setEventIsPublished = () => {
    http.events
      .update({
        eventId: event.id as string,
        data: {
          name: event.name,
          isPublished: true,
        },
      })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        getEvent();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  useEffect(() => {
    listServiceStreams();

    return () => {
      emptyCurrentPurewebModel();
      emptyCurrentCalendar();
      emptyCurrentGroup();
    };
  }, []);

  useAsyncEffect(async () => {
    setLoadingCalendar(true);
    if (!streamServicesSelected || !event) {
      return;
    }

    const streamGroup = await EventsService.getStreamGroup(
      urlParams.id as string
    );

    if (streamGroup) {
      setStreamGroupConfig(streamGroup);
    }

    await loadCurrentPurewebModel(
      streamServicesSelected.purewebModelId as string
    );

    setLoadingCalendar(false);
  }, [streamServicesSelected]);

  const listServiceStreams = useCallback(async () => {
    const result = await http.streamServices
      .listBySubdomain(event.domain, true)
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });

    if (result?.error) {
      enqueueSnackbar(result?.message, { variant: 'error' });
      return;
    }

    if (event.streamServiceId) {
      setStreamServicesSelected(
        result?.data.filter(
          (item: StreamService) => item.id === event.streamServiceId
        )[0]
      );
    } else {
      setStreamServicesSelected(
        result?.data.filter(
          (item: StreamService) => item.displayName.toLowerCase() === 'default'
        )[0]
      );
    }
  }, [event, enqueueSnackbar]);

  return (
    <AppDefaultLayout>
      <UpdateEventModal ref={updateEventModalRef} />
      <GenericAccessLinkModal ref={genericAccessLinkModalRef} />

      <PublishEventModal
        open={openPublish}
        onCancel={handleClosePublishEventModal}
        onConfirm={handlePublishEvent}
        confirmText="Publish Event"
        disableConfirmButton={publishingEvent}
        confirmIcon={<PublishIcon fontSize="small" />}
      />
      <DefaultPageHeader
        titleText={`${event.name}`}
        subTitleText={`Status: ${
          event.isPublished ? 'Published' : 'Not published'
        }`}
        linkTo={appPaths.private.home}
        linkToText="Back to Home"
      />

      <Divider />

      <Stack
        direction="row"
        justifyContent="end"
        alignItems="center"
        spacing={2}
        paddingBottom={4}
      >
        <Button
          color="primary"
          onClick={handleOpenGenericAccessLinkModal}
          startIcon={<ContentCopyIcon fontSize="small" />}
        >
          Generic access link
        </Button>

        <Button
          color="primary"
          onClick={handleOpenUpdateEventModal}
          startIcon={<EditIcon fontSize="small" />}
        >
          Edit Info
        </Button>

        {event.isPublished && <DestroySessions />}

        <RecreateSessions event={event} />
      </Stack>

      <Grid container spacing={2}>
        {event && event.role != 'COORDINATOR' && (
          <>
            <SessionBox
              linkTo={appPaths.private.rooms.dashboard.replace(
                ':id',
                urlParams.id as string
              )}
            >
              <MeetingRoomIcon fontSize="large" />
              <span>Rooms</span>
            </SessionBox>

            <SessionBox
              linkTo={appPaths.private.avatars.dashboard.replace(
                ':id',
                String(urlParams.id)
              )}
            >
              <SupervisedUserCircleIcon fontSize="large" />
              <span>Avatars</span>
            </SessionBox>
          </>
        )}

        <SessionBox
          linkTo={appPaths.private.invites.index.replace(
            ':id',
            String(urlParams.id)
          )}
        >
          <InsertInvitationIcon fontSize="large" />
          <span>Invites</span>
        </SessionBox>
        {!loadingCalendar ? (
          <SessionBox
            linkTo={`${appPaths.calendar.gameCastScheduler}/${
              streamGroupConfig?.eventId || urlParams.id
            }?streamGroupId=${streamGroupConfig?.streamGroupId || ''}&region=${
              streamGroupConfig?.region || event.region || ''
            }&modelId=${
              streamServicesSelected?.purewebModelId || ''
            }&fromEvent=true`}
          >
            <CalendarMonthIcon fontSize="large" />
            <Typography sx={{ fontSize: 12 }}>
              Virtual Machines Schedule
            </Typography>
          </SessionBox>
        ) : (
          <SessionBox linkTo="#">
            <LoadingProgress />
            <Typography sx={{ fontSize: 12 }}>
              Virtual Machines Schedule
            </Typography>
          </SessionBox>
        )}
      </Grid>
    </AppDefaultLayout>
  );
};
