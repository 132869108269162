import { Button, ButtonProps } from '@mui/material';
import { FC, ReactNode } from 'react';

interface SocialLoginButtonProps extends ButtonProps {
  children?: ReactNode;
  provider: string;
}

export const socialLogins = [
  {
    label: 'Google',
    provider: 'google',
  },
  {
    label: 'Facebook',
    provider: 'facebook',
  },
  {
    label: 'Apple',
    provider: 'apple',
  },
  {
    label: 'Microsoft',
    provider: 'microsoft',
  },
];

export const SocialLoginButton: FC<SocialLoginButtonProps> = ({
  children,
  ...props
}: SocialLoginButtonProps) => {
  return <Button {...props}>{children}</Button>;
};
