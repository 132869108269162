import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { sortByField } from 'redux/slices/invitesSlice';
import { useAppDispatch } from 'shared/hooks/useStore';

export const SortByField = () => {
  const dispatch = useAppDispatch();

  const [sortSelect, setSortSelet] = useState('email');

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    if (!value) return;

    setSortSelet(value);
    dispatch(sortByField(value));
  };

  return (
    <>
      <span>Sort By:</span>
      <span>
        <Select
          id="sortBy"
          name="sortBy"
          value={sortSelect}
          onChange={handleChange}
        >
          <MenuItem value={'email'}>Email</MenuItem>
          <MenuItem value={'createdAt'}>Date added</MenuItem>
          <MenuItem value={'firtName'}>First Name</MenuItem>
          <MenuItem value={'lastName'}>Last Name</MenuItem>
          <MenuItem value={'confirmed'}>Confirmed</MenuItem>
          <MenuItem value={'role'}>Role</MenuItem>
        </Select>
      </span>
    </>
  );
};
