import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Grid, Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import { selectPointsOfInterest } from 'redux/slices/pointsOfInterestSlice';
import { selectRooms } from 'redux/slices/roomsSlice';
import { selectUser } from 'redux/slices/userSlice';
import { usePointsOfInterest } from 'shared/hooks/usePointsOfInterest';
import { useAppSelector } from 'shared/hooks/useStore';
import { PointOfInterest } from 'shared/types/models/pointOfInterest';
import AddPointsOfInterestModal from './AddPointsOfInterestModal';
import EditPointsOfInterestModal from './EditPointsOfInterestModal';
import { PointOfInterestCard } from './PointOfInterestCard';

export const PointsOfInterest = () => {
  const { pointsOfInterest } = useAppSelector(selectPointsOfInterest);
  const { user } = useAppSelector(selectUser);
  const { actualRoom } = useAppSelector(selectRooms);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedPointOfInterest, setSelectedPointOfInterest] = useState<
    PointOfInterest | undefined
  >(undefined);

  const { create, remove, update } = usePointsOfInterest();

  function handleOpenModal(item: PointOfInterest) {
    setSelectedPointOfInterest(item);
    setOpenEditModal(true);
  }

  const handleCreate = useCallback(
    async (payload: any, setSubmitting: any) => {
      await create(payload, setSubmitting, () => {
        setOpenAddModal(false);
      });
    },
    [create]
  );

  const handleUpdate = useCallback(
    async (payload: any, setSubmitting?: any) => {
      const id = String(selectedPointOfInterest?.id);

      await update({ id, payload }, setSubmitting, () => {
        setOpenEditModal(false);
      });
    },
    [selectedPointOfInterest?.id, update]
  );

  const handleRemove = useCallback(
    async (itemId: string) => {
      await remove({ id: itemId });
    },
    [remove]
  );

  const handleChangeStatus = useCallback(
    async (item: PointOfInterest, isActive: boolean) => {
      const setSubmitting = () => {};

      const payload = {
        user: item.user,
        room: item.room as string,
        displayName: item.displayName,
        isActive: isActive,
        position: item.position,
        rotation: item.rotation,
      };
      await update({ id: item.id as string, payload }, setSubmitting);
    },
    [update]
  );

  return (
    <>
      <>
        <AddPointsOfInterestModal
          userEmail={String(user?.data.email)}
          roomId={String(actualRoom?.id)}
          open={openAddModal}
          onSubmit={(payload: any, setSubmitting: any) =>
            handleCreate(payload, setSubmitting)
          }
          handleClose={() => setOpenAddModal(false)}
        />

        {selectedPointOfInterest && (
          <EditPointsOfInterestModal
            pointOfInterest={selectedPointOfInterest}
            userEmail={String(user?.data.email)}
            roomId={String(actualRoom?.id)}
            open={openEditModal}
            onSubmit={(payload: any, setSubmitting: any) =>
              handleUpdate(payload, setSubmitting)
            }
            handleClose={() => {
              setOpenEditModal(false);
              setSelectedPointOfInterest(undefined);
            }}
          />
        )}

        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          marginBottom={2}
        >
          <Button
            onClick={() => setOpenAddModal(true)}
            startIcon={<AddCircleIcon fontSize="small" />}
          >
            Add Point Of Interest
          </Button>
        </Stack>
      </>

      <Grid container spacing={2} marginBottom={2}>
        {pointsOfInterest.length > 0 &&
          pointsOfInterest.map((item: any) => (
            <PointOfInterestCard
              key={item.id}
              item={item}
              onClick={() => handleOpenModal(item)}
              onClickRemove={() => {
                handleRemove(item.id);
              }}
              onChangeStatus={(isActive: boolean) =>
                handleChangeStatus(item, isActive)
              }
            />
          ))}
      </Grid>
    </>
  );
};
