import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import axiosWithUserToken from './axiosInstances/axiosWithUserToken';
import { UpdateDefaultSettingsProps } from './types/defaultSettings';
import { HttpResponseDefault } from './types/httpGeneric';

const defaultSettings = {
  async get(): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get('/cms/settings/get');

      return { data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async update(
    payload: UpdateDefaultSettingsProps
  ): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().put(
        '/cms/settings/update',
        payload
      );

      return { message: 'Success', data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default defaultSettings;
