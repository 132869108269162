export const useStyles = () => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    backgroundColor: '#FFFFFF',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    paddingBlock: 5,

    '& form': {
      display: 'flex',
      flexDirection: 'column',
      mt: 3,
      mb: 3,
      '& button': {
        width: 150,
        mt: 3,
      },
    },
  },
});
