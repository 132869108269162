import { Box, Container, styled } from '@mui/material';

export const MainWrapper = styled(Box)(
  () => `
        display: grid;
        grid-template-rows: auto 1fr;
`
);

export const MainContent = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        padding-bottom: ${theme.spacing(2)};
        flex: 1 1 auto;
        overflow-y: auto;
        overflow-x: hidden;
`
);

export const DevTag = styled('span')({
  position: 'fixed',
  left: '8px',
  backgroundColor: 'yellow',
  fontWeight: 'bold',
  padding: '8px',
});
