import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { AuthUserRoles } from 'configs/authUsersRoles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { setActualFilter } from 'redux/slices/invitesSlice';
import { http } from 'services/http';
import { useLoadParticipants } from 'shared/hooks/useLoadParticipants';
import { useAppDispatch } from 'shared/hooks/useStore';
import { InviteFilters } from 'shared/types/models/invite';
import { FormRole } from './style';

interface Participant {
  id: string | number;
  email: string;
  confirmed?: number;
  isSpeaker?: boolean;
  madeTutorial?: boolean;
  isOwner?: boolean;
  role?: string;
}

export const ChangeRole = ({ participant }: { participant: Participant }) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { get: reloadParticipants } = useLoadParticipants();
  const [age, setAge] = useState(
    participant.role === '' ? 'None' : participant.role
  );
  const isAdmin = participant.role === AuthUserRoles.Admin;
  const isModerator = participant.role === AuthUserRoles.Moderator;
  const isCoordinator = participant.role === AuthUserRoles.Coordinator;

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  const handleMakeModerator = async () => {
    await http.authUsers
      .create(participant.email, id as string, 'MODERATOR')
      .then((res) => {
        enqueueSnackbar(res.message, { variant: 'success' });
        dispatch(setActualFilter(InviteFilters.All));
        reloadParticipants();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleMakeAdmin = async () => {
    await http.authUsers
      .create(participant.email, id as string, 'ADMIN')
      .then((res) => {
        enqueueSnackbar(res.message, { variant: 'success' });
        dispatch(setActualFilter(InviteFilters.All));
        reloadParticipants();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleMakeCoordinator = async () => {
    await http.authUsers
      .create(participant.email, id as string, 'COORDINATOR')
      .then((res) => {
        enqueueSnackbar(res.message, { variant: 'success' });
        dispatch(setActualFilter(InviteFilters.All));
        reloadParticipants();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleRemoveAuthUser = async () => {
    if (!participant.role) return;
    await http.authUsers
      .removeByEvent(participant.email, id as string)
      .then((res) => {
        enqueueSnackbar(res.message, { variant: 'success' });
        dispatch(setActualFilter(InviteFilters.All));
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleToggleModerator = async () => {
    if (isModerator) {
      await handleRemoveAuthUser();
    } else {
      await handleMakeModerator();
    }
  };
  const handleToggleAdmin = async () => {
    if (isAdmin) {
      await handleRemoveAuthUser();
    } else {
      await handleMakeAdmin();
    }
  };
  const handleToggleCoordinator = async () => {
    if (isCoordinator) {
      await handleRemoveAuthUser();
    } else {
      await handleMakeCoordinator();
    }
  };

  return (
    <>
      <FormRole>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          IconComponent={AdminPanelSettingsIcon}
          inputProps={{ 'aria-label': 'Without label' }}
          onChange={handleChange}
          defaultValue="None"
        >
          <MenuItem value={AuthUserRoles.Admin} onClick={handleToggleAdmin}>
            Admin
          </MenuItem>
          <MenuItem
            value={AuthUserRoles.Moderator}
            onClick={handleToggleModerator}
          >
            Moderator
          </MenuItem>
          <MenuItem
            value={AuthUserRoles.Coordinator}
            onClick={handleToggleCoordinator}
          >
            Coordinator
          </MenuItem>
          <MenuItem value="None" selected onClick={handleRemoveAuthUser}>
            None
          </MenuItem>
        </Select>
      </FormRole>
    </>
  );
};
