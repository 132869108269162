import { Box, Button, TextField } from '@mui/material';
import { appPaths } from 'app/router/appPaths';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { http } from 'services/http';
import { validationSchema } from './validationSchema';

export const PasswordResetForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const urlParams = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || appPaths.public.account.login;

  const [isSubmitting, setSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: validationSchema,
    onSubmit: ({ password }) => {
      setSubmitting(true);
      http.auth
        .passwordReset({ password, token: urlParams.token as string })
        .then((res) => {
          setSubmitting(false);
          if (res.error) {
            enqueueSnackbar(res.message, { variant: 'error' });
            return;
          }

          enqueueSnackbar('Password Changed. Try login now.', {
            variant: 'success',
          });
          navigate(from, { replace: true });
        })
        .catch((error) => {
          setSubmitting(false);
          enqueueSnackbar(error.message, { variant: 'error' });
        });
    },
  });

  return (
    <Box sx={{ width: '500px' }}>
      <h3>Reset Password</h3>

      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          id="password"
          name="password"
          label="Password*"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <TextField
          fullWidth
          margin="normal"
          id="passwordConfirmation"
          name="passwordConfirmation"
          label="Repeat Password*"
          type="password"
          value={formik.values.passwordConfirmation}
          onChange={formik.handleChange}
          error={
            formik.touched.password &&
            Boolean(formik.errors.passwordConfirmation)
          }
          helperText={
            formik.touched.passwordConfirmation &&
            formik.errors.passwordConfirmation
          }
        />

        <Button
          sx={{ mt: 3 }}
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>
      </form>

      <p>
        <Link to={appPaths.public.account.login}>Got to Sign In</Link>
      </p>
    </Box>
  );
};
