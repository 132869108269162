import App from 'app/App';
import React from 'react';
import ReactDOM from 'react-dom';

ReactDOM.render(
  <React.StrictMode>
    <script src="/env.js"></script>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
