import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { selectRooms, setActualSpot } from 'redux/slices/roomsSlice';
import { selectUser } from 'redux/slices/userSlice';
import { http } from 'services/http';
import BoxDialogModal, {
  BoxDialogModalHandles,
} from 'shared/components/modals/BoxDialogModal';
import useAccess from 'shared/hooks/useAccess';
import { useLoadRoomDetails } from 'shared/hooks/useLoadRoomDetails';
import { useAppSelector } from 'shared/hooks/useStore';
import { ModalHandles } from 'shared/types/modal';
import { SpotDataWithAssetsProps } from './types';

const UploadBriefCaseModal: ForwardRefRenderFunction<ModalHandles> = (
  props,
  ref
) => {
  const { actualSpot } = useAppSelector(selectRooms);
  const [isSavingSpot, setIsSavingSpot] = useState(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [unrealCode, setUnrealCode] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAppSelector(selectUser);
  const { getConnectToken } = useAccess();
  const { loadRoomDetails } = useLoadRoomDetails();
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => {
    return { handleOpen, handleClose };
  });

  const boxDialogModalRef = useRef<BoxDialogModalHandles>(null);

  const handleOpen = () => {
    boxDialogModalRef.current?.handleOpen();
  };

  const handleClose = () => {
    boxDialogModalRef.current?.handleClose();
  };

  useEffect(() => {
    if (actualSpot?.isActive) setChecked(actualSpot.isActive);
  }, [actualSpot.isActive]);

  useEffect(() => {
    if (actualSpot?.asset) setUnrealCode(actualSpot.asset);
  }, [actualSpot.asset]);

  const handleSubmitForm = async () => {
    setIsSavingSpot(true);
    const spotID = actualSpot.id;
    const data: SpotDataWithAssetsProps = {
      user: user?.data.email as string,
      isActive: checked,
      isDefault: false,
      assetMediaType: 'unrealCode',
      asset: unrealCode,
    };

    http.spots
      .updateFromFrontend({
        userToken: getConnectToken() as string,
        spotID,
        data,
      })
      .then((res: any) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        enqueueSnackbar(res.message, { variant: 'success' });
        loadRoomDetails();
        handleClose();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setIsSavingSpot(false);
      });
  };

  const handleCloseDialog = () => {
    dispatch(setActualSpot({}));
    setChecked(false);
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleInputUnrealCode = (event: ChangeEvent<HTMLInputElement>) => {
    setUnrealCode(event.target.value);
  };

  return (
    <BoxDialogModal
      ref={boxDialogModalRef}
      title={
        'Update spot - ' +
        actualSpot?.displayName +
        ' [' +
        actualSpot?.mediaType +
        ']'
      }
      submitButton={
        <Button
          autoFocus
          onClick={handleSubmitForm}
          variant="contained"
          disabled={isSavingSpot}
        >
          {!isSavingSpot ? 'Save' : 'Saving...'}
        </Button>
      }
      onCloseDialog={handleCloseDialog}
    >
      <Stack spacing={2}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleCheckboxChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Active"
          />
        </FormGroup>
        <TextField
          placeholder="Unreal Code here..."
          value={unrealCode}
          multiline
          rows={5}
          onChange={handleInputUnrealCode}
          inputProps={{ 'aria-label': 'uncontrolled' }}
        />
      </Stack>
    </BoxDialogModal>
  );
};

export default forwardRef(UploadBriefCaseModal);
