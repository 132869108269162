import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Skin } from 'shared/types/models/skin';

const initialState = {
  isSkinsLoading: false,
  skins: [] as Skin[],
  actualSkin: {} as Skin,
  utils: {
    isCreatingSkin: false,
    isUploadingSkin: false,
    isDeletingSkin: false,
  },
};

export const skinsSlice = createSlice({
  name: 'skins',
  initialState,
  reducers: {
    setIsSkinListLoading: (state, { payload }) => {
      state.isSkinsLoading = payload;
    },
    setSkinList: (state, { payload }) => {
      state.skins = payload;
    },
    setActualSkin: (state, { payload }) => {
      state.actualSkin = payload;
    },
    setCreatingSkin: (state, { payload }) => {
      state.utils.isCreatingSkin = payload;
    },
    setUploadingSkin: (state, { payload }) => {
      state.utils.isUploadingSkin = payload;
    },
    setDeletingSkin: (state, { payload }) => {
      state.utils.isDeletingSkin = payload;
    },
  },
});

export const selectSkins = (state: RootState) => state.skinsData;

export const {
  setIsSkinListLoading,
  setSkinList,
  setActualSkin,
  setCreatingSkin,
  setUploadingSkin,
  setDeletingSkin,
} = skinsSlice.actions;

export default skinsSlice.reducer;
