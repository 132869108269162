import React from 'react';
import { CSVLink } from 'react-csv';

import { Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { useAppSelector } from '../../../../../shared/hooks/useStore';
import { selectInvites } from '../../../../../redux/slices/invitesSlice';
import { applyFilters } from '../../../../../shared/utils/applyFilters';

import { Headers } from 'react-csv/components/CommonPropTypes';

import { useStyles } from './styles';

const HEADERS: Headers = [
  { label: 'Email', key: 'email' },
  { label: 'Invite link', key: 'inviteLink' },
  { label: 'Invitation date', key: 'createdAt' },
];

const ExportToCSVButton = () => {
  const classes = useStyles();

  const { invites, query } = useAppSelector(selectInvites);
  const downloadedData = applyFilters(invites, query, ['email']);

  return (
    <Button
      startIcon={<FileDownloadIcon fontSize="small" />}
      disabled={!downloadedData.length}
      sx={classes.button}
    >
      <CSVLink
        headers={HEADERS}
        data={downloadedData}
        filename="invites.csv"
        target="_blank"
      >
        Export to CSV
      </CSVLink>
    </Button>
  );
};

export default ExportToCSVButton;
