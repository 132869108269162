import { createSlice } from '@reduxjs/toolkit';
import { InviteFilters } from 'shared/types/models/invite';
import { Participant } from 'shared/types/models/participant';
import { RootState } from '../store';

interface InitialState {
  invites: Participant[];
  query: string;
  selected: string[];
  page: number;
  actualFilter: InviteFilters;
  utils: {
    isFetching: boolean;
  };
}

const initialState: InitialState = {
  invites: [],
  query: '',
  selected: [],
  page: 0,
  actualFilter: InviteFilters.All,
  utils: {
    isFetching: false,
  },
};

export const invitesSlice = createSlice({
  name: 'invites',
  initialState,
  reducers: {
    setInvitesList: (state, { payload }) => {
      state.invites = payload;
    },
    sortByField: (state, { payload }) => {
      state.invites = state.invites.sort((a: any, b: any) =>
        a[payload] > b[payload] ? -1 : 1
      );
    },

    setQuery: (state, { payload }) => {
      state.query = payload;
    },
    setSelected: (state, { payload }) => {
      state.selected = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setFetching: (state, { payload }) => {
      state.utils.isFetching = payload;
    },
    setActualFilter: (state, { payload }) => {
      state.actualFilter = payload;
      switch (payload) {
        case InviteFilters.Speaker:
          {
            const filtered = state.invites.filter(
              (item) => item.isSpeaker === true
            );
            state.invites = filtered;
          }
          break;

        case InviteFilters.NotSpeaker:
          {
            const filtered = state.invites.filter(
              (item) => item.isSpeaker === false
            );
            state.invites = filtered;
          }
          break;

        case InviteFilters.Confirmed:
          {
            const filtered = state.invites.filter((item) => item.confirmed);
            state.invites = filtered;
          }
          break;

        case InviteFilters.NotConfirmed:
          {
            const filtered = state.invites.filter((item) => !item.confirmed);
            state.invites = filtered;
          }
          break;

        case InviteFilters.Admin:
          {
            const filtered = state.invites.filter((item) =>
              item.role.includes('ADMIN')
            );
            state.invites = filtered;
          }
          break;

        default:
          break;
      }
    },
  },
});

export const selectInvites = (state: RootState) => state.invitesData;

export const {
  setInvitesList,
  sortByField,
  setQuery,
  setSelected,
  setPage,
  setFetching,
  setActualFilter,
} = invitesSlice.actions;

export default invitesSlice.reducer;
