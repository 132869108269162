import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';

type ActualSpotType = {
  [key: string]: string;
};

interface InitialState {
  spots: any[];
  actualSpot: ActualSpotType;
  coordinates: {
    fromTop: number;
    fromLeft: number;
  };
  coordsEdit: {
    fromTop: number;
    fromLeft: number;
  };
  utils: {
    isNewSpot: boolean;
    isSendingCreateForm: boolean;
    isSendingUpdateForm: boolean;
    isEditingSpot: boolean;
    isDeletingSpot: boolean;
  };
}

const initialState: InitialState = {
  spots: [],
  actualSpot: {},
  coordinates: {
    fromTop: 0,
    fromLeft: 0,
  },
  coordsEdit: {
    fromTop: 0,
    fromLeft: 0,
  },
  utils: {
    isNewSpot: false,
    isSendingCreateForm: false,
    isSendingUpdateForm: false,
    isEditingSpot: false,
    isDeletingSpot: false,
  },
};

export const spotsSlice = createSlice({
  name: 'spots',
  initialState,
  reducers: {
    setSpotList: (state, { payload }) => {
      state.spots = payload;
    },
    setActualSpot: (state, { payload }) => {
      state.actualSpot = payload;
    },
    setIsNewSpot: (state, { payload }) => {
      state.utils.isNewSpot = payload;
    },
    setSendingCreateForm: (state, { payload }) => {
      state.utils.isSendingCreateForm = payload;
    },
    setSendingUpdateForm: (state, { payload }) => {
      state.utils.isSendingUpdateForm = payload;
    },
    setEditingSpot: (state, { payload }) => {
      state.utils.isEditingSpot = payload;
    },
    setDeletingSpot: (state, { payload }) => {
      state.utils.isDeletingSpot = payload;
    },
    setTopCoordinate: (state, { payload }) => {
      state.coordinates.fromTop = payload;
    },
    setLeftCoordinate: (state, { payload }) => {
      state.coordinates.fromLeft = payload;
    },
    setTopCoordsEdit: (state, { payload }) => {
      state.coordsEdit.fromTop = payload;
    },
    setLeftCoordsEdit: (state, { payload }) => {
      state.coordsEdit.fromLeft = payload;
    },
  },
});

export const selectSpots = (state: RootState) => state.spotsData;
export const {
  setSpotList,
  setActualSpot,
  setIsNewSpot,
  setSendingCreateForm,
  setSendingUpdateForm,
  setTopCoordinate,
  setLeftCoordinate,
  setTopCoordsEdit,
  setLeftCoordsEdit,
  setEditingSpot,
  setDeletingSpot,
} = spotsSlice.actions;

export default spotsSlice.reducer;
