import { AccountFormLayout } from 'layouts/AccountFormLayout';
import { ConfirmingMessageContainer } from './styled';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { http } from 'services/http';
import { useSnackbar } from 'notistack';
import { ID_AUTH_NAME, TOKEN_AUTH_NAME } from 'configs/authConfigs';
import { setToken } from 'shared/utils/tokenManager';

export const ConfirmEmailPage = () => {
  const urlParams = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [isEmailConfirmed, setEmailConfirmed] = useState(false);
  const [hasError, setError] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const goToApp = ({ token, id }: { token: string; id: string }) => {
    setTimeout(() => {
      setToken(TOKEN_AUTH_NAME, token);
      setToken(ID_AUTH_NAME, id);
      navigate(from, { replace: true });
    }, 2000);
  };

  useEffect(() => {
    http.auth.emailConfirm({ token: urlParams.token as string }).then((res) => {
      if (res.error) {
        enqueueSnackbar(res.message, { variant: 'error' });
        setError(true);
        return;
      }
      setError(false);
      setEmailConfirmed(true);
      goToApp({ token: urlParams.token as string, id: res.id });
    });
    // eslint-disable-next-line
  }, []);

  return (
    <AccountFormLayout>
      {!isEmailConfirmed ? (
        <ConfirmingMessageContainer>
          <h3>Email Confirmation</h3>
          <p>Please wait.</p>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </ConfirmingMessageContainer>
      ) : hasError ? (
        <p>Invalid access.</p>
      ) : (
        <ConfirmingMessageContainer>
          <h3>Confirmed</h3>
          <p>Redirecting.</p>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </ConfirmingMessageContainer>
      )}
    </AccountFormLayout>
  );
};
