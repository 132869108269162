import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from '../styles';

const StreamPanelCard = ({
  regionName,
  screenName,
  handleClick,
}: {
  regionName: string;
  screenName: string;
  handleClick: (screenName: string) => void;
}) => {
  const classes = useStyles();

  return (
    <Card sx={classes.card} onClick={() => handleClick(screenName)}>
      <CardContent>
        <Typography>Screen Name: {screenName}</Typography>
        <Typography>Region: {regionName}</Typography>
      </CardContent>
    </Card>
  );
};

export default StreamPanelCard;
