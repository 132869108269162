import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import { Box, Button, Tooltip } from '@mui/material';
import { instanceManagerApiUrl } from 'configs/apiConfigs';
import { useState } from 'react';
import io from 'socket.io-client';
import { RecreateSessionsModal } from './RecreateSessionsModal';
import PublishIcon from '@mui/icons-material/Publish';
import { http } from '../../../../services/http';

export const RecreateSessions = ({ event }: { event: any }) => {
  const [openRecreateSessions, setOpenRecreateSessions] = useState(false);
  const [socket, setSocket] = useState<any>(false);
  const canPublishEvent =
    event.streamServiceId?.trim() &&
    event.aliasId?.trim() &&
    event.region?.trim();

  const handleOpenRecreateSessionsModal = async () => {
    const result = await http.events.canPublishEvent({
      eventId: String(event.id),
    });

    if (result.canPublish) {
      connectWithWebsocket();
      setOpenRecreateSessions(true);
    } else if (!result.error) {
      const message = `
      Error: Unable to recreate the event.
      Reason: The event's rooms exceed the alias's available sessions.
      Details: 
      - Number of rooms in the event: ${result.numberOfEventRooms} 
      - Number of sessions available for the alias: ${result.activeServerProcessCount}
      Please adjust the number of rooms or sessions to proceed.`;

      alert(message);
    } else {
      const message = `
      An unexpected error occurred. Check the console for more details.
      Error: ${result.message}`;
      alert(message);
    }
  };

  const handleCloseRecreateSessionsModal = () => {
    setOpenRecreateSessions(false);

    if (socket) {
      socket.disconnect();
      socket.close();
      setSocket(false);
    }
  };
  const connectWithWebsocket = () => {
    const serverUrl = instanceManagerApiUrl;

    setSocket(
      io(serverUrl, {
        reconnection: true,
        reconnectionDelay: 500,
        transports: ['websocket'],
      })
    );
  };

  return (
    <>
      <RecreateSessionsModal
        socket={socket}
        open={openRecreateSessions}
        onCancel={handleCloseRecreateSessionsModal}
      />

      {event.isPublished ? (
        <Button
          color="primary"
          onClick={handleOpenRecreateSessionsModal}
          startIcon={<RunningWithErrorsIcon fontSize="small" />}
        >
          Recreate Rooms
        </Button>
      ) : (
        <Tooltip
          arrow
          title={
            canPublishEvent
              ? 'Publish Event'
              : "You can't publish an event without Region, Alias or Stream Service"
          }
        >
          <Box component="span">
            <Button
              color="primary"
              onClick={handleOpenRecreateSessionsModal}
              startIcon={<PublishIcon fontSize="small" />}
              disabled={!canPublishEvent}
            >
              Publish Event
            </Button>
          </Box>
        </Tooltip>
      )}
    </>
  );
};
