import { FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FormRole = styled(FormControl)({
  width: '150px',
  border: 'none !important',

  '.MuiOutlinedInput-root': {
    fontWeight: 700,
    color: '#6E759F',
    paddingRight: 10,
    textTransform: 'uppercase',
  },

  fieldset: {
    border: 0,
  },
});
