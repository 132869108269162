import { Box } from '@mui/system';
import { BoxLink, BoxPaper } from './styled';

const SessionBox = ({
  linkTo,
  children,
}: {
  linkTo: string;
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        '& > :not(style)': {
          m: 1,
          width: 128,
          height: 128,
          textAlign: 'center',
        },
      }}
      padding={4}
      alignItems={'center'}
      justifyItems={'center'}
    >
      <BoxPaper elevation={3} square>
        <BoxLink to={linkTo}>{children}</BoxLink>
      </BoxPaper>
    </Box>
  );
};

export default SessionBox;
