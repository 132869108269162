import { apiUrl } from 'configs/apiConfigs';
import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import { AccessListProps } from 'shared/types/accessList';
import axiosWithUserToken from './axiosInstances/axiosWithUserToken';
import { HttpResponseDefault } from './types/httpGeneric';

const accessList = {
  async createOrUpdate(payload: AccessListProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().post(
        `${apiUrl}/cms/access-list/upsert`,
        payload
      );
      return { message: 'Success', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async listByModuleId({
    moduleId,
  }: {
    moduleId: string;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `${apiUrl}/cms/access-list/list/moduleId/${moduleId}`
      );
      return { data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async list(): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().post(
        `${apiUrl}/cms/access-list/list`
      );
      return { message: 'Success', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default accessList;
