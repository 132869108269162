import axios from 'axios';
import { useState } from 'react';
import { apiUrl } from 'configs/apiConfigs';
import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import { HttpReponseDefault } from 'shared/types/httpGeneric';

interface UploadMediaPreSignedProps {
  file: File;
  foldername: 'images' | 'videos' | 'briefCases' | '3D';
  contentType: 'image' | 'video/mp4' | '*/*';
  userToken: string;
}

const convertToBase64 = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
};

const useUploadMedia = (): [
  ({
    file,
    foldername,
    contentType,
    userToken,
  }: UploadMediaPreSignedProps) => Promise<
    HttpReponseDefault & { url?: string }
  >,
  number
] => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const upload = async ({
    file,
    foldername,
    contentType,
    userToken,
  }: UploadMediaPreSignedProps): Promise<
    HttpReponseDefault & { url?: string }
  > => {
    try {
      const convertedFile = await convertToBase64(file);
      const data = {
        file: convertedFile,
        fileName: `${foldername}/${file.name}`,
        contentType,
      };

      const res = await axios.post(`${apiUrl}/cms/files/uploadAsset`, data, {
        headers: {
          Authorization: `${userToken}`,
          'Content-Type': 'application/json',
        },
        onUploadProgress: function (progressEvent) {
          const progress: number = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          setUploadProgress(progress);
        },
      });
      return res;
    } catch (error) {
      return errorsApiHandler(error);
    } finally {
      setUploadProgress(0);
    }
  };

  return [upload, uploadProgress];
};

export default useUploadMedia;
