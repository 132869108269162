import { env } from '../env';

if (!env.REACT_APP_OKTA_ISSUER) {
  throw new Error('Config REACT_APP_OKTA_ISSUER is missing');
}

if (!env.REACT_APP_OKTA_CLIENT_ID) {
  throw new Error('Config REACT_APP_OKTA_CLIENT_ID is missing');
}

export const oktaConfigs = {
  issuer: env.REACT_APP_OKTA_ISSUER,
  clientId: env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/callback`,
};
