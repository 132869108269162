import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { StreamService } from 'shared/types/models/streamService';

interface InitialState {
  streamServices: StreamService[];
}

const initialState: InitialState = {
  streamServices: [],
};

export const streamServicesSlice = createSlice({
  name: 'streamServices',
  initialState,
  reducers: {
    setStreamServices: (state, { payload }) => {
      state.streamServices = payload;
    },
  },
});

export const selectStreamServices = (state: RootState) =>
  state.streamServicesData;

export const { setStreamServices } = streamServicesSlice.actions;

export default streamServicesSlice.reducer;
