import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import { Alias, Region } from 'shared/types/models/region';
import { orderByProperty } from 'shared/utils/orderByProperty';
import axiosWithUserToken from './axiosInstances/axiosWithUserToken';
import { HttpResponseDefault } from './types/httpGeneric';
import {
  CreateAliasProps,
  CreateRegionProps,
  GetRegionProps,
  RemoveAliasProps,
  RemoveRegionProps,
  UpdateAliasProps,
  UpdateRegionProps,
} from './types/regions';

const regions = {
  async list(): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get('/cms/regions/list');

      const filtered = data.regions.filter((item: Region) => item.isActive);

      // sort filtered items
      const sorted = orderByProperty(filtered, 'displayName');

      // filter active alias
      sorted.forEach((item) => {
        const aliasFiltered = item.aliases.filter(
          (item: Alias) => item.isActive
        );
        item.aliases = aliasFiltered;
      });

      // sort aliases inside
      sorted.forEach((item) => {
        const byDisplayName = orderByProperty(item.aliases, 'displayName');
        item.aliases = byDisplayName;
      });

      return { message: 'Success', data: sorted };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  // ADMIN REGIONS
  async listAdmin(): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get('/cms/regions/list');

      return { message: 'Success', data: data.regions };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async createRegion(payload: CreateRegionProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().post(
        '/cms/regions/create/',
        payload
      );

      return { message: 'Created', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async updateRegion({
    regionId,
    payload,
  }: {
    regionId: string;
    payload: UpdateRegionProps;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().put(
        `/cms/regions/update/${regionId}`,
        payload
      );

      return { message: 'Updated', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async getRegionAdmin({
    regionId,
  }: GetRegionProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/regions/get/${regionId}`
      );

      return { message: 'Success', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async removeRegion({
    regionId,
  }: RemoveRegionProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().delete(
        `/cms/regions/delete/${regionId}`
      );

      return { message: 'Region Removed', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  // ADMIN ALIAS
  async createAlias(payload: CreateAliasProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().post(
        '/cms/regions-aliases/create/',
        payload
      );

      return { message: 'Alias Created', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async updateAlias({
    aliasId,
    payload,
  }: {
    aliasId: string;
    payload: UpdateAliasProps;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().put(
        `/cms/regions-aliases/update/${aliasId}`,
        payload
      );

      return { message: 'Alias Updated', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async removeAlias({
    aliasId,
  }: RemoveAliasProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().delete(
        `/cms/regions-aliases/delete/${aliasId}`
      );

      return { message: 'Alias Removed', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async listAliasesBySubdomainAndRegion({
    regionId,
    subdomain,
    readPublicAccess,
  }: {
    regionId: string;
    subdomain: string;
    readPublicAccess: boolean;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().post(
        `/cms/regions-aliases/list/subdomain/region`,
        {
          subdomain: subdomain,
          regionId: regionId,
        }
      );

      if (readPublicAccess) {
        const dataPublicAccess = await axiosWithUserToken().post(
          `/cms/regions-aliases/list/subdomain/region`,
          {
            subdomain: 'PUBLIC_ACCESS',
            regionId: regionId,
          }
        );

        dataPublicAccess.data?.aliases.map((item: any) => {
          data.aliases?.push(item);
        });
      }

      return { message: 'Success', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default regions;
