import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { useState } from 'react';
import { http } from 'services/http';
import { useSnackbar } from 'notistack';
import { useLoadParticipants } from 'shared/hooks/useLoadParticipants';
import { CircularProgressWithLabel } from 'shared/components/progress/CircularProgressWithLabel';
import { useAppDispatch } from 'shared/hooks/useStore';
import { setActualFilter, setSelected } from 'redux/slices/invitesSlice';
import { chunkArray } from 'shared/utils/chunkArray';
import { InviteFilters } from 'shared/types/models/invite';

export default function ToggleSpeakers({
  participants,
  eventId,
}: {
  participants: string[];
  eventId: string;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { get: reloadParticipants } = useLoadParticipants();
  const dispatch = useAppDispatch();

  const handleChangeSpeakers = async (changeSpeakerTo: boolean) => {
    if (!participants.length) return;

    const NUMBER_OF_EMAILS_LIMIT = 20;

    setOpen(true);
    setIsSending(true);

    const newList = chunkArray(participants, NUMBER_OF_EMAILS_LIMIT);

    const submiting = await submitStepped(newList, changeSpeakerTo);

    if (submiting?.success) {
      setOpen(false);
      enqueueSnackbar('Success', { variant: 'success' });
      dispatch(setActualFilter(InviteFilters.All));
      reloadParticipants();
      dispatch(setSelected([]));
    }
  };

  const submitStepped = async (
    participants: string[][],
    changeSpeakerTo: boolean
  ): Promise<{ success: boolean } | undefined> => {
    let success;
    let count = 0;

    for (const item of participants) {
      count++;

      const step: number = Math.round((count * 100) / participants.length);
      setProgress(step);

      await http.invites
        .updateMultipleParticipants({
          data: {
            event: eventId,
            isSpeaker: String(changeSpeakerTo),
            emails: item,
          },
        })
        .then(() => {
          success = { success: true };
        })
        .catch((error) => {
          setErrorMessage('Error during stepped proccess:' + error.message);
          success = { success: false };
        });
    }

    return success;
  };

  const [isSending, setIsSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!errorMessage.length && (
          <DialogContent>
            <>
              <DialogContentText>
                We are changing {participants.length} selected registers. This
                should take some time.
              </DialogContentText>
              <Stack
                justifyContent="center"
                alignItems="center"
                style={{ padding: 8 }}
              >
                <CircularProgressWithLabel value={progress} />
              </Stack>
            </>
          </DialogContent>
        )}

        {errorMessage.length > 0 && (
          <DialogContent>
            <Alert severity="error">{errorMessage}</Alert>
          </DialogContent>
        )}

        {errorMessage.length > 0 && (
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </DialogActions>
        )}
      </Dialog>

      <Tooltip title="Change to Speakers?">
        <IconButton
          onClick={() => handleChangeSpeakers(true)}
          disabled={isSending}
        >
          <MicIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Change to Non-Speakers?">
        <IconButton
          onClick={() => handleChangeSpeakers(false)}
          disabled={isSending}
        >
          <MicOffIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}
