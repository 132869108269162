import { LoginCallback } from '@okta/okta-react';
import { ConfirmEmailPage } from 'pages/account/ConfirmEmailPage';
import { LoginPage } from 'pages/account/LoginPage';
import { PasswordRecoveyPage } from 'pages/account/PasswordRecoveyPage';
import { PasswordResetPage } from 'pages/account/PasswordResetPage';
import RedirectOkta from 'pages/account/RedirectOkta';
import { RegisterPage } from 'pages/account/RegisterPage';
import { UserProfilePage } from 'pages/account/UserProfilePage';
import { AvatarsDashboardPage } from 'pages/avatars/AvatarsDashboardPage';
import ViewEmail from 'pages/emails/ViewEmailTemplate';
import { EventDashboardPage } from 'pages/events/EventDashboardPage';
import { AddInvitesPage } from 'pages/invites/AddInvitesPage';
import { InvitesPage } from 'pages/invites/InvitesPage';
import { RoomDetailsPage } from 'pages/rooms/RoomDetailsPage';
import { RoomsDashboardPage } from 'pages/rooms/RoomsDashboardPage';
import { SkinsDashboardPage } from 'pages/skins/SkinsDashboardPage';
import { Status404Page } from 'pages/Status404Page';
import { TicketsPage } from 'pages/tickets/TicketsPage';
import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppLoader from 'shared/components/AppLoader';
import { appPaths } from './appPaths';
import { RequireAuth } from './RequireAuth';
import { RequireAuthWithAdmin } from './RequireAuthWithAdmin';
import HomePage from '../../modules/events/pages/EventsPage';
import { RequireEventAuth } from './RequireEventAuth';
import GameCastConfigurePage from 'pages/admin/GameCastConfigurePage/GameCastConfigurePage';

// Lazy imports
const AdminDashboardPage = React.lazy(
  () => import('pages/admin/AdminDashboardPage')
);
const EventsAdminPage = React.lazy(() => import('pages/admin/EventsAdminPage'));
const MapsPage = React.lazy(() => import('pages/admin/MapsPage'));
const MapsDetailsPage = React.lazy(() => import('pages/admin/MapsDetailsPage'));
const RegionsPage = React.lazy(() => import('pages/admin/RegionsPage'));
const RegionDetailsPage = React.lazy(
  () => import('pages/admin/RegionDetailsPage')
);
const StreamServicesPage = React.lazy(
  () => import('pages/admin/StreamServicesPage')
);
const SkinsModelDashboardPage = React.lazy(
  () => import('pages/admin/SkinsDashboardPage')
);
const SkinsModelDetailsPage = React.lazy(
  () => import('pages/admin/SkinDetailsPage')
);
const DefaultSettingsPage = React.lazy(
  () => import('pages/admin/DefaultSettingsPage')
);
const GameCastPage = React.lazy(() => import('pages/admin/GameCastPage'));

const AvatarPage = React.lazy(() => import('pages/admin/AvatarPage'));

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path={appPaths.public.account.oktaCallback}
        element={<LoginCallback />}
      />
      <Route
        path={appPaths.public.account.oktaRedirect}
        element={<RedirectOkta />}
      />

      {/* REQUIRED AUTH ROUTES */}
      <Route path={appPaths.public.account.login} element={<LoginPage />} />

      <Route
        path={appPaths.private.home}
        element={
          <RequireAuth>
            <HomePage />
          </RequireAuth>
        }
      />
      <Route
        path={appPaths.user.profile}
        element={
          <RequireAuth>
            <UserProfilePage />
          </RequireAuth>
        }
      />

      {/* PRIVATE ROUTES */}
      <Route path={appPaths.private.home} element={<RequireEventAuth />}>
        <Route
          path={`${appPaths.private.event.dashboard}/:id`}
          element={
            <RequireAuth>
              <EventDashboardPage />
            </RequireAuth>
          }
        />
        <Route
          path={appPaths.private.rooms.dashboard}
          element={
            <RequireAuth>
              <RoomsDashboardPage />
            </RequireAuth>
          }
        />
        <Route
          path={appPaths.private.rooms.details}
          element={
            <RequireAuth>
              <RoomDetailsPage />
            </RequireAuth>
          }
        />
        <Route
          path={appPaths.private.invites.index}
          element={
            <RequireAuth>
              <InvitesPage />
            </RequireAuth>
          }
        />
        <Route
          path={appPaths.private.invites.add}
          element={
            <RequireAuth>
              <AddInvitesPage />
            </RequireAuth>
          }
        />
        <Route
          path={`${appPaths.private.tickets.index}/:id`}
          element={
            <RequireAuth>
              <TicketsPage />
            </RequireAuth>
          }
        />

        <Route
          path={`${appPaths.private.avatars.dashboard}`}
          element={
            <RequireAuth>
              <AvatarsDashboardPage />
            </RequireAuth>
          }
        />

        <Route
          path={`${appPaths.private.skins.dashboard}`}
          element={
            <RequireAuth>
              <SkinsDashboardPage />
            </RequireAuth>
          }
        />
      </Route>

      {/* ADMIN ROUTES */}
      <Route
        path={appPaths.admin.dashboard}
        element={
          <RouteAuthAdminWithFallback>
            <AdminDashboardPage />
          </RouteAuthAdminWithFallback>
        }
      />
      <Route
        path={appPaths.admin.events}
        element={
          <RouteAuthAdminWithFallback>
            <EventsAdminPage />
          </RouteAuthAdminWithFallback>
        }
      />
      <Route
        path={appPaths.admin.maps}
        element={
          <RouteAuthAdminWithFallback>
            <MapsPage />
          </RouteAuthAdminWithFallback>
        }
      />
      <Route
        path={`${appPaths.admin.mapDetails}/:id`}
        element={
          <RouteAuthAdminWithFallback>
            <MapsDetailsPage />
          </RouteAuthAdminWithFallback>
        }
      />
      <Route
        path={appPaths.admin.regions}
        element={
          <RouteAuthAdminWithFallback>
            <RegionsPage />
          </RouteAuthAdminWithFallback>
        }
      />
      <Route
        path={`${appPaths.admin.regionDetails}/:regionId`}
        element={
          <RouteAuthAdminWithFallback>
            <RegionDetailsPage />
          </RouteAuthAdminWithFallback>
        }
      />
      <Route
        path={appPaths.admin.streamServices}
        element={
          <RouteAuthAdminWithFallback>
            <StreamServicesPage />
          </RouteAuthAdminWithFallback>
        }
      />
      <Route
        path={appPaths.calendar.gameCastScheduler}
        element={
          <React.Suspense fallback={<AppLoader />}>
            <GameCastPage />
          </React.Suspense>
        }
      />
      <Route
        path={appPaths.calendar.gameCastSchedulerItem}
        element={
          <React.Suspense fallback={<AppLoader />}>
            <GameCastConfigurePage />
          </React.Suspense>
        }
      />
      <Route
        path={`${appPaths.admin.skins}/:avatarId`}
        element={
          <RouteAuthAdminWithFallback>
            <SkinsModelDashboardPage />
          </RouteAuthAdminWithFallback>
        }
      />
      <Route
        path={`${appPaths.admin.skinDetails}/:id`}
        element={
          <RouteAuthAdminWithFallback>
            <SkinsModelDetailsPage />
          </RouteAuthAdminWithFallback>
        }
      />
      <Route
        path={appPaths.admin.avatar}
        element={
          <RouteAuthAdminWithFallback>
            <AvatarPage />
          </RouteAuthAdminWithFallback>
        }
      />
      <Route
        path={appPaths.admin.defaultSettings}
        element={
          <RouteAuthAdminWithFallback>
            <DefaultSettingsPage />
          </RouteAuthAdminWithFallback>
        }
      />

      {/* ACCOUNT ROUTES (NO REQUIRE AUTH) */}
      <Route path={appPaths.public.account.login} element={<LoginPage />} />
      <Route
        path={appPaths.public.account.register}
        element={<RegisterPage />}
      />
      <Route
        path={appPaths.public.account.passwordRecovery}
        element={<PasswordRecoveyPage />}
      />
      <Route
        path={`${appPaths.public.account.passwordReset}/:token`}
        element={<PasswordResetPage />}
      />
      <Route
        path={`${appPaths.public.auth.confirmPassword}/:token`}
        element={<ConfirmEmailPage />}
      />

      <Route
        path={`${appPaths.public.emails.viewEmail}/:type/:id`}
        element={<ViewEmail />}
      />

      <Route path="*" element={<Status404Page />} />
    </Routes>
  );
};

function RouteAuthAdminWithFallback({ children }: { children: ReactElement }) {
  return (
    <React.Suspense fallback={<AppLoader />}>
      <RequireAuthWithAdmin>{children}</RequireAuthWithAdmin>
    </React.Suspense>
  );
}
