import { Button } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DialogAlert, {
  DialogAlertHandles,
} from 'shared/components/alerts/DialogAlert';
import { useCallback, useRef } from 'react';

type Props = {
  title?: string;
  message?: string;
  disabled?: boolean;
  buttonText?: string;
  cancelModalButtonText?: string;
  okModalButtonText?: string;
  deleteCallback?: (func: () => void) => void;
};

export const RemoveButtonWithDialog = ({
  title = 'title',
  message = 'body message',
  buttonText = 'Remove',
  disabled = false,
  cancelModalButtonText = 'Cancel',
  okModalButtonText = 'OK',
  deleteCallback,
}: Props) => {
  const dialogAlertRef = useRef<DialogAlertHandles>(null);
  const handleOpenDialogAlert = useCallback(() => {
    dialogAlertRef.current?.handleOpen();
  }, []);

  return (
    <>
      <DialogAlert
        title={title}
        message={message}
        okButtonText={okModalButtonText}
        cancelButtonText={cancelModalButtonText}
        ref={dialogAlertRef}
        deleteCallback={deleteCallback}
      />

      <Button
        color="error"
        onClick={handleOpenDialogAlert}
        startIcon={<DeleteForeverIcon fontSize="small" />}
        disabled={disabled}
      >
        {buttonText}
      </Button>
    </>
  );
};
