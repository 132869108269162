import { useRef, useState } from 'react';

import { Box, Button, Divider, Popover, Hidden, Avatar } from '@mui/material';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import { useAuth } from 'shared/hooks/useAuth';
import { Link, useNavigate } from 'react-router-dom';
import {
  MenuUserBox,
  UserBoxButton,
  UserBoxDescription,
  UserBoxLabel,
  UserBoxLinkMenu,
  UserBoxText,
} from './styled';
import { useAppSelector } from 'shared/hooks/useStore';
import { selectUser } from 'redux/slices/userSlice';
import { appPaths } from 'app/router/appPaths';

import { useOktaAuth } from '@okta/okta-react';
import { oktaAuth } from 'app/router';

export const UserMenu = () => {
  const { user } = useAppSelector(selectUser);

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const { signOut } = useAuth();
  const navigate = useNavigate();

  const { authState } = useOktaAuth();

  const handleSignOut = async () => {
    await signOut(() => {
      if (authState) oktaAuth.signOut();
      navigate(appPaths.public.account.login);
    });
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <Hidden smDown>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar src={user?.data.profileImage} />
        <ExpandMoreTwoToneIcon fontSize="small" sx={{ ml: 0.5 }} />
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar src={user?.data.profileImage} />
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {user && user.data.firstName}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user && user.data.email}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>

        <Divider />

        <UserBoxLinkMenu>
          <Link to={appPaths.user.profile}>
            <AccountBoxIcon sx={{ mr: 1 }} />
            <span>Profile</span>
          </Link>
        </UserBoxLinkMenu>

        <Divider />

        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleSignOut}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </Hidden>
  );
};
