export const applyFilters = (list: any[], query: string, filters: string[]) => {
  const newList: any[] = [];

  if (!query?.length) return list;

  list.filter((item) => {
    filters.filter((f) => {
      if (item[f]?.toLowerCase().includes(query.toLowerCase())) {
        newList.push(item);
      }
    });
  });

  return newList;
};
