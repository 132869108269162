import { createSlice } from '@reduxjs/toolkit';
import { PurewebState } from '../interfaces';
import { RootState } from 'redux/store';

const initialState: PurewebState = {
  currentPurewebModel: null,
  isLoading: false,
  isLoadingPurewebCalendar: false,
  purewebCalendarEvents: [],
  purewebLogs: [],
  loadingPurewebLogs: false,
  activeRegions: [],
};

export const purewebSlice = createSlice({
  name: 'pureweb',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setCurrentPurewebModel: (state, { payload }) => {
      state.currentPurewebModel = payload;
    },
    setIsLoadingPurewebCalendar: (state, { payload }) => {
      state.isLoadingPurewebCalendar = payload;
    },
    setPurewebCalendarEvents: (state, { payload }) => {
      state.purewebCalendarEvents = payload;
    },
    setPurewebLogs: (state, { payload }) => {
      state.purewebLogs = payload;
    },
    setLoadingPurewebLogs: (state, { payload }) => {
      state.loadingPurewebLogs = payload;
    },
    setActiveRegions: (state, { payload }) => {
      state.activeRegions = payload;
    },
  },
});

export const currentPurewebModelSelector = (state: RootState) =>
  state.purewebSlice.currentPurewebModel;
export const loadingPurewebCalendarSelector = (state: RootState) =>
  state.purewebSlice.isLoadingPurewebCalendar;
export const currentPurewebModelCalendarEventsSelector = (state: RootState) =>
  state.purewebSlice.purewebCalendarEvents;
export const loadingPurewebModelSelector = (state: RootState) =>
  state.purewebSlice.isLoading;
export const loadingPurewebLogsSelector = (state: RootState) =>
  state.purewebSlice.loadingPurewebLogs;
export const purewebLogsSelector = (state: RootState) =>
  state.purewebSlice.purewebLogs;
export const activeRegionsSelector = (state: RootState) =>
  state.purewebSlice.activeRegions;

export const {
  setCurrentPurewebModel,
  setIsLoading,
  setIsLoadingPurewebCalendar,
  setPurewebCalendarEvents,
  setLoadingPurewebLogs,
  setPurewebLogs,
  setActiveRegions,
} = purewebSlice.actions;

export default purewebSlice.reducer;
