type Props = {
  data: any;
  recreatedSessionData: any[];
  freeSpace: number;
  message: string;
  roomsLength: number;
  error: boolean;
};

export const DestroySessionsInfo = ({
  data,
  recreatedSessionData,
  freeSpace,
  message,
  roomsLength,
  error,
}: Props) => {
  return (
    <div>
      {data.eventId && <p>Event Id: {data.eventId}</p>}

      <p>Total rooms: {roomsLength}</p>

      {data.alias && <p>Alias ID: {data.alias}</p>}

      {data.regionName && <p>Region: {data.regionName}</p>}

      {!!recreatedSessionData.length &&
        recreatedSessionData.map((item: any) => {
          return item?.room?.id?.length > 0 && <p>Room id: {item.room.id}</p>;
        })}

      {freeSpace > 0 && <p>Free Space: {freeSpace}</p>}

      {message.length > 0 && !message?.includes('SESSIONS DESTROYED!') && (
        <p>{message}</p>
      )}
      {message?.includes('SESSIONS DESTROYED!') && (
        <p style={{ color: 'green', fontSize: '18px' }}>{message}</p>
      )}

      {error && <p style={{ color: 'red' }}>Error: {error}</p>}
    </div>
  );
};
