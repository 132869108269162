import React from 'react';
import useAsyncEffect from 'use-async-effect';
import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CustomEventFormProps, CustomerEventTheme } from './interfaces';
import CustomersService from 'services/http/customersService';
import ImageUploading from 'react-images-uploading';
import { useStyles } from './styles';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useCustomStyles } from './hooks';
import LoadingProgress from 'components/LoadingProgress/LoadingProgress';

const CustomEventForm = ({ subdomain }: CustomEventFormProps) => {
  const classes = useStyles();
  const {
    setImagesPreview,
    handleChangeUploadImage,
    imagesPreview,
    values,
    handleChange,
    handleSubmit,
    selectValue,
    handleSelectedValueChange,
    setEventStyles,
    logoPreview,
    handleChangeUploadLogo,
    logoUploading,
    backgroundImageUploading,
    backgroundImagePreview,
  } = useCustomStyles({ subdomain });

  useAsyncEffect(async () => {
    if (!subdomain) {
      return;
    }
    const customerEvent = await CustomersService.getCustomerEvent(subdomain);

    if (!customerEvent) {
      return;
    }

    const { customerLogo, backgroundImage } = customerEvent;

    if (customerLogo || backgroundImage) {
      setImagesPreview({
        backgroundImage,
        customerLogo,
      });
      setEventStyles(customerEvent);
    }
  }, [subdomain]);

  return (
    <Box>
      <Typography>Customize Event</Typography>
      <Stack flexDirection="row" columnGap={3}>
        <ImageUploading
          value={[]}
          onChange={handleChangeUploadImage}
          maxNumber={1}
        >
          {({ onImageUpload, isDragging, dragProps }) => (
            <Box
              component="div"
              sx={
                isDragging
                  ? { ...classes.tab, ...classes.dragging }
                  : classes.tab
              }
              onClick={onImageUpload}
              className="upload__image-wrapper"
              {...dragProps}
            >
              {!backgroundImageUploading ? (
                (imagesPreview && imagesPreview.backgroundImage) ||
                (backgroundImagePreview &&
                  backgroundImagePreview.imagePreview) ? (
                  <>
                    <img
                      className="customer__background"
                      src={
                        backgroundImagePreview?.imagePreview ||
                        imagesPreview?.backgroundImage
                      }
                      alt=""
                    />
                    <FileUploadIcon />
                    <Typography component="p">
                      Upload Background Image
                    </Typography>
                  </>
                ) : (
                  <>
                    <FileUploadIcon />
                    <Typography component="p">
                      Upload Background Image
                    </Typography>
                  </>
                )
              ) : (
                <Box>
                  <LoadingProgress />
                  <Typography>Uploading to the cloud</Typography>
                </Box>
              )}
            </Box>
          )}
        </ImageUploading>
        <ImageUploading
          value={[]}
          onChange={handleChangeUploadLogo}
          maxNumber={1}
        >
          {({ onImageUpload, isDragging, dragProps }) => (
            <Box
              component="div"
              sx={
                isDragging
                  ? { ...classes.tab, ...classes.dragging }
                  : classes.tab
              }
              onClick={onImageUpload}
              className="upload__image-wrapper"
              {...dragProps}
            >
              {!logoUploading ? (
                <>
                  {(imagesPreview && imagesPreview.customerLogo) ||
                  (logoPreview && logoPreview.imagePreview) ? (
                    <img
                      src={
                        logoPreview?.imagePreview || imagesPreview?.customerLogo
                      }
                      alt=""
                    />
                  ) : (
                    <FileUploadIcon />
                  )}
                  <Typography component="p">Upload Logo</Typography>
                </>
              ) : (
                <Box>
                  <LoadingProgress />
                  <Typography>Uploading to the cloud</Typography>
                </Box>
              )}
            </Box>
          )}
        </ImageUploading>
      </Stack>
      <form>
        <TextField
          fullWidth
          margin="normal"
          id="name"
          name="eventName"
          label="Login Page Title"
          value={values.eventName}
          onChange={handleChange}
        />
        <Select value={selectValue} onChange={handleSelectedValueChange}>
          <MenuItem value={CustomerEventTheme.Light}>Light Theme</MenuItem>
          <MenuItem value={CustomerEventTheme.Dark}>Dark Theme</MenuItem>
        </Select>
        <TextField
          fullWidth
          margin="normal"
          id="name"
          name="primaryColor"
          label="Primary Color"
          value={values.primaryColor}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          id="name"
          name="secondaryColor"
          label="Secondary color"
          value={values.secondaryColor}
          onChange={handleChange}
        />
        <Button
          type="button"
          sx={classes.saveStyles}
          onClick={() => handleSubmit()}
        >
          {logoUploading || backgroundImageUploading ? (
            <LoadingProgress />
          ) : (
            'Save Customization'
          )}
        </Button>
      </form>
    </Box>
  );
};

export default CustomEventForm;
