import {
  ISortLogsProps,
  SortingCriteriaTypes,
  SortingOrderTypes,
  StreamingProviders,
} from './interface';
import { User } from 'shared/types/models/user';
import UsersService from '../../../services/http/invites';
import { IStreamGroupLog } from 'redux/slices/interfaces';

export const getMergedLogs = async (
  eventId: string,
  logs: IStreamGroupLog[]
) => {
  if (!eventId) {
    return;
  }

  const participantsIds = Array.from(
    new Set(
      logs
        .filter((log) => !log.isLambdaAction && log.participantId)
        .map((log) => log.participantId) || []
    )
  );
  const lambdaLogs = logs.filter((log) => log.isLambdaAction);
  const participants = await UsersService.getParticipants({ eventId });

  if (!participants || !participants.data) {
    return;
  }

  const mergedParticipantsLogs = participantsIds.reduce(
    (acc: IStreamGroupLog[], id) => {
      const currentParticipant = participants.data.find(
        (p: User) => p.userId === id
      );

      if (!currentParticipant) {
        return acc;
      }

      const participantLogs = logs
        .filter((log) => log.participantId === id)
        .map((log) => ({
          ...log,
          email: currentParticipant.email as string,
        })) as IStreamGroupLog[];

      if (!participantLogs.length) {
        return acc;
      }

      return [...acc, ...participantLogs];
    },
    []
  );

  return [...mergedParticipantsLogs, ...lambdaLogs];
};

export const sortLogs = ({ criteria, order, logs }: ISortLogsProps) => {
  const { Capacity, EndTime, StartTime, Time } = SortingCriteriaTypes;
  const { DESC } = SortingOrderTypes;

  const sorting = {
    [Capacity]: logs
      .slice()
      .sort((a, b) =>
        order === DESC ? b.capacity - a.capacity : a.capacity - b.capacity
      ),
    [EndTime]: logs
      .slice()
      .sort((a, b) =>
        order === DESC
          ? (b.endTime || 0) - (a.endTime || 0)
          : (a.endTime || 0) - (b.endTime || 0)
      ),
    [StartTime]: logs
      .slice()
      .sort((a, b) =>
        order === DESC
          ? (b.startTime || 0) - (a.startTime || 0)
          : (a.startTime || 0) - (b.startTime || 0)
      ),
    [Time]: logs
      .slice()
      .sort((a, b) =>
        order === DESC
          ? b.timestamps - a.timestamps
          : a.timestamps - b.timestamps
      ),
  };

  return sorting[criteria];
};

export const getStreamingProviderFullName = (
  streamingProvider: StreamingProviders
) => {
  const providersNames = {
    [StreamingProviders.GameCast]: 'AWS cloud stream',
    [StreamingProviders.Pureweb]: 'Pureweb Pixel Stream',
  };

  return providersNames[streamingProvider] || '';
};
