import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = {
  emails: [] as string[],
};

export const emailsSlice = createSlice({
  name: 'emails',
  initialState,
  reducers: {
    setEmailList: (state, { payload }) => {
      state.emails = [...payload, ...state.emails];
    },
    setEmail: (state, { payload }) => {
      state.emails = [payload, ...state.emails];
    },
    clearEmailList: (state) => {
      state.emails = [];
    },
    removeEmail: (state, { payload }) => {
      const emailsFiltered = state.emails.filter((email) => email !== payload);
      state.emails = emailsFiltered;
    },
  },
});

export const selectEmails = (state: RootState) => state.emailsData;

export const { setEmailList, setEmail, clearEmailList, removeEmail } =
  emailsSlice.actions;

export default emailsSlice.reducer;
