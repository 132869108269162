import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import axiosWithUserToken from './axiosInstances/axiosWithUserToken';
import { HttpResponseDefault } from './types/httpGeneric';
import {
  GetParticipantsRequestProps,
  SubscribeConfirmProps,
  SubscribeRequestProps,
  UnsubscribeRequestProps,
  UpdateMultipleParticipantsProps,
  UpdateParticipantProps,
} from './types/invites';

const invites = {
  async getParticipants({
    eventId,
  }: GetParticipantsRequestProps): Promise<HttpResponseDefault> {
    try {
      const result = await axiosWithUserToken().get(
        `/cms/events/v2/participants/${eventId}?all=true`
      );

      const sorted = [...result.data.subscribers];

      if (sorted.length > 0)
        sorted.sort((a: any, b: any) => (a.email < b.email ? -1 : 1));

      return { message: 'Subscribed', data: sorted };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async updateParticipant({
    participantId,
    data,
  }: UpdateParticipantProps): Promise<HttpResponseDefault> {
    try {
      const result = await axiosWithUserToken().patch(
        `/cms/events/participant/${participantId}`,
        data
      );

      return { message: 'Success', data: result.data.subscribers };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async updateMultipleParticipants({
    data,
  }: UpdateMultipleParticipantsProps): Promise<HttpResponseDefault> {
    try {
      const result = await axiosWithUserToken().patch(
        `/cms/events/participants`,
        data
      );

      return { message: 'Success', data: result.data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async subscribe(data: SubscribeRequestProps): Promise<HttpResponseDefault> {
    try {
      const result = await axiosWithUserToken().post(
        `/cms/events/subscribe`,
        data
      );

      const message =
        result.data.subscribed > 1
          ? `${result.data.subscribed} emails saved`
          : 'Email saved';

      return {
        message,
        data: result.data,
      };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async unsubscribe(
    data: UnsubscribeRequestProps
  ): Promise<HttpResponseDefault> {
    try {
      const result = await axiosWithUserToken().post(
        `/cms/events/unsubscribe`,
        data
      );

      const message =
        result.data.unsubscribed > 1
          ? `${result.data.unsubscribed} emails removed`
          : `${result.data.unsubscribed} email removed`;

      return {
        message,
        data: result.data,
      };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async invite(data: SubscribeRequestProps): Promise<HttpResponseDefault> {
    try {
      const result = await axiosWithUserToken().post(
        `/cms/events/invite`,
        data
      );

      return {
        message: result.data.message || 'Invite sended',
        data: result.data,
      };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async inviteConfirm(
    data: SubscribeConfirmProps
  ): Promise<HttpResponseDefault> {
    try {
      const result = await axiosWithUserToken({ shared: true }).post(
        '/auth/subscribeConfirmManual',
        data
      );

      return {
        data: result.data,
      };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default invites;
