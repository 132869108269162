import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { PointOfInterest } from 'shared/types/models/pointOfInterest';

interface InitialState {
  pointsOfInterest: PointOfInterest[];
  pointsOfInterestToImport: PointOfInterest[];
  pointsOfInterestToExport: PointOfInterest[];
}

const initialState: InitialState = {
  pointsOfInterest: [],
  pointsOfInterestToImport: [],
  pointsOfInterestToExport: [],
};

export const pointsOfInterestsSlice = createSlice({
  name: 'pointsOfInterest',
  initialState,
  reducers: {
    setPointsOfInterest: (state, { payload }) => {
      state.pointsOfInterest = payload;
    },
    setPointsOfInterestToImport: (state, { payload }) => {
      state.pointsOfInterestToImport = payload;
    },
    clearPointsOfInterestToImport: (state) => {
      state.pointsOfInterestToImport = [];
    },
    setPointsOfInterestToExport: (state, { payload }) => {
      state.pointsOfInterestToExport = payload;
    },
    clearPointsOfInterestToExport: (state) => {
      state.pointsOfInterestToExport = [];
    },
  },
});

export const selectPointsOfInterest = (state: RootState) =>
  state.pointsOfInterestData;

export const {
  setPointsOfInterest,
  setPointsOfInterestToImport,
  clearPointsOfInterestToImport,
  setPointsOfInterestToExport,
  clearPointsOfInterestToExport,
} = pointsOfInterestsSlice.actions;

export default pointsOfInterestsSlice.reducer;
