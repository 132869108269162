import { FC, createContext } from 'react';
import { ThemeProvider as ThemeProviderMaterial } from '@mui/material';
import { AppTheme } from './AppTheme';

export const ThemeContext = createContext((): void => {});

export const ThemeProvider: FC = ({ children }) => {
  return (
    <ThemeProviderMaterial theme={AppTheme}>{children}</ThemeProviderMaterial>
  );
};
