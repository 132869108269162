import { colors } from 'layouts/theme/AppTheme/colors';

export const useStyles = () => ({
  contentWrapper: {
    display: 'grid',
    gridTemplateColumns: ' 1fr 2fr repeat(5, 1fr)',
    borderBottom: `1px solid ${colors.secondary.lighter}`,
    '& p': {
      textAlign: 'left',
      width: '100%',
      mt: 2,
      fontSize: 12,
    },
  },
});
