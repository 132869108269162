import { Tooltip } from '@mui/material';
import logoImg from 'assets/logo/mytaverse_logo.svg';
import { useNavigate } from 'react-router-dom';
import { LogoSignWrapper, LogoWrapper } from './styled';

export const Logo = () => {
  const navigate = useNavigate();
  return (
    <LogoWrapper>
      <Tooltip arrow placement="right" title="Mytaverse Admin Dashboard">
        <LogoSignWrapper
          onClick={() => {
            navigate('/');
          }}
        >
          <img src={logoImg} alt="logo mytaverse" />
        </LogoSignWrapper>
      </Tooltip>
    </LogoWrapper>
  );
};
