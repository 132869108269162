import * as yup from 'yup';
import { subdomainRegex } from '../../../../shared/utils/subdomain';

export const validationSchema = yup.object({
  name: yup.string().required('Event name is required'),
  company: yup.string().required('Company Name is required'),
  startTime: yup.string().required('Need to choose a start time'),
  endTime: yup.string().required('Need to choose an end time'),
  subdomain: yup
    .string()
    .required('Subdomain is required')
    .matches(subdomainRegex, 'Invalid domain'),
});
