import { useAuth } from 'shared/hooks/useAuth';
import { Navigate, useLocation } from 'react-router-dom';
import { appPaths } from './appPaths';

export const RequireAuthWithAdmin = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const location = useLocation();
  const { isAdminUser, signOut } = useAuth();

  if (!isAdminUser()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    signOut(() => {});
    return (
      <Navigate to={appPaths.public.account.login} state={{ from: location }} />
    );
  }

  return children;
};
