import {
  Checkbox,
  IconButton,
  Stack,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useLoadParticipants } from 'shared/hooks/useLoadParticipants';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import {
  selectInvites,
  setActualFilter,
  setSelected,
} from 'redux/slices/invitesSlice';
import { http } from 'services/http';
import SendEmails from './buttons/SendEmails';
import ToggleSpeakers from './buttons/ToggleSpeakers';
import { InviteFilters } from 'shared/types/models/invite';

interface EnhancedTableProps {
  numSelected: number;
  numTotal: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

export function EnhancedTableHead({
  onSelectAllClick,
  numSelected,
  rowCount,
  numTotal,
}: EnhancedTableProps) {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { get: reloadParticipants } = useLoadParticipants();

  const dispatch = useAppDispatch();
  const [isRemoving, setIsRemoving] = useState(false);
  const { selected } = useAppSelector(selectInvites);

  const handleRemove = async () => {
    setIsRemoving(true);

    if (!selected.length) return;

    await http.invites
      .unsubscribe({
        emails: selected,
        event: id as string,
      })
      .then((res) => {
        setIsRemoving(false);

        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }

        dispatch(setSelected([]));
        enqueueSnackbar(res.message, { variant: 'success' });
        dispatch(setActualFilter(InviteFilters.All));
        reloadParticipants();
      })
      .catch((error) => {
        setIsRemoving(false);
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all',
            }}
          />
        </TableCell>
        <TableCell>
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="caption"
            component="div"
          >
            {numSelected} / {numTotal} selected <i>(Check to select all)</i>
          </Typography>
        </TableCell>
        <TableCell padding="checkbox">
          {numSelected > 0 && (
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={1}
              sx={{ padding: '0 8px' }}
            >
              <ToggleSpeakers participants={selected} eventId={id as string} />

              <SendEmails
                emailsToSend={selected}
                eventId={id as string}
                isIconButton={true}
              />

              <Tooltip title="Remove all selected">
                <IconButton onClick={handleRemove} disabled={isRemoving}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
