import moment from 'moment';
import * as yup from 'yup';

export const validationSchema = yup.object({
  name: yup.string().required('Event name is required'),
  company: yup.string().required('Company Name is required'),
  startTime: yup
    .string()
    .test('isToday', "Can't start on the past.", (value) => {
      return moment(value).isAfter(moment());
    })
    .required('Need to choose a start time'),
  endTime: yup
    .string()
    .test('isValid', 'End Time has to be after Start Time.', function (value) {
      return moment(value).isAfter(moment(this.parent.startTime));
    })
    .required('Need to choose an end time'),
});
