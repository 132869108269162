import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Button,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { ChangeEvent, useRef, useState } from 'react';
import { removeEmail, selectEmails, setEmail } from 'redux/slices/emailsSlice';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { CSVReader } from './CSVReader';

const AddInvitesContainer = () => {
  const [emailInput, setEmailInput] = useState<{
    value: string;
    error: string | null;
  }>({ value: '', error: null });

  const dispatch = useAppDispatch();
  const { emails } = useAppSelector(selectEmails);

  const emailInputRef = useRef<HTMLInputElement>(null);

  const handleEmailInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailInput({ ...emailInput, value: event.target.value });
  };

  const handleEmailInputKeyDown = (event: any) => {
    if (['Enter', ' ', ','].includes(event.key)) {
      event.preventDefault();

      addEmail();
    }
  };

  const handleDeleteEmail = (emailToBeRemoved: string) => {
    dispatch(removeEmail(emailToBeRemoved));
  };

  const addEmail = () => {
    const email = emailInput.value.trim();
    const validate = isValid(email);

    if (validate.success === false) {
      setEmailInput({ ...emailInput, error: validate.message });
      return;
    }

    if (email) {
      dispatch(setEmail(email));
      setEmailInput({ value: '', error: null });
      inputEmailFocus();
    }
  };

  const inputEmailFocus = () => {
    emailInputRef.current?.focus();
  };

  const isEmail = (email: string) => {
    // eslint-disable-next-line no-useless-escape
    return /[\w\d\.-]+@[\w\d.-]+\.[\w\d\.-]+/.test(email);
  };

  const isEmailInList = (email: string, emails: string[]) =>
    emails.includes(email);

  const isValid = (email: string) => {
    if (!isEmail(email)) {
      return {
        success: false,
        message: `${email} is not a valid email address.`,
      };
    }

    if (isEmailInList(email, emails)) {
      return { success: false, message: `${email} has already been added.` };
    }

    return { success: true, message: '' };
  };

  return (
    <>
      <Stack mb={4}>
        <Typography variant="h6">
          You can hit enter, comma or spacebar to add a valid email.
        </Typography>
        <Stack mt={1}>
          <OutlinedInput
            error={!!emailInput.error}
            inputRef={emailInputRef}
            fullWidth
            value={emailInput.value}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end" onClick={addEmail}>
                  <AddCircleIcon />
                </IconButton>
              </InputAdornment>
            }
            onKeyDown={handleEmailInputKeyDown}
            onChange={handleEmailInputChange}
            style={{ marginBottom: 4 }}
          />
          {emailInput.error !== null && (
            <p style={{ color: 'red' }}>{emailInput.error}</p>
          )}
        </Stack>
      </Stack>

      <Divider>
        <Chip label="OR" size="small" />
      </Divider>

      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        mt={4}
      >
        <Stack>
          <Typography variant="h6">
            You can upload a .csv too.
            <a
              href="https://s3.amazonaws.com/assets.prod.mytaverse.com/doc/Spreadsheet_EmailsCSV.csv"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Button variant="outlined" size="small" sx={{ margin: '0 10px' }}>
                Download our guest template spreadsheet
              </Button>
            </a>
          </Typography>
          <p style={{ fontSize: 13, fontWeight: 400 }}>
            Necessary an &quot;email&quot; header. Delimiter has to be comma or
            semicolon.
          </p>
        </Stack>
      </Stack>
      <CSVReader />

      <Stack mt={4} mb={4}>
        <Divider />
      </Stack>

      {!!emails.length && <p>{emails.length} emails.</p>}

      <Stack direction="row" flexWrap={'wrap'}>
        {emails.length !== 0 &&
          emails.map((email, index) => (
            <Chip
              key={index}
              variant="outlined"
              label={email}
              onDelete={() => handleDeleteEmail(email)}
              style={{ margin: '4px' }}
            />
          ))}
      </Stack>
    </>
  );
};

export default AddInvitesContainer;
