import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  selectPointsOfInterest,
  setPointsOfInterest,
  setPointsOfInterestToExport,
} from 'redux/slices/pointsOfInterestSlice';
import { http } from 'services/http';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import {
  CreatePointsOfInterestProps,
  ExportPointsOfInterestProps,
  RemovePointsOfInterestProps,
  UpdatePointsOfInterestProps,
} from '../../services/http/types/poi';

interface UseDefaultSettingsProps {
  reload: () => Promise<void>;
  list: () => Promise<void>;
  create: (
    payload: CreatePointsOfInterestProps,
    setSubmiting?: any,
    onCreated?: () => void
  ) => Promise<void>;
  update: (
    payload: UpdatePointsOfInterestProps,
    setSubmiting?: any,
    onUpdated?: () => void
  ) => Promise<void>;
  remove: (payload: RemovePointsOfInterestProps) => Promise<void>;
  exportation: (
    payload: ExportPointsOfInterestProps,
    setSubmiting?: any,
    onCreated?: () => void
  ) => Promise<void>;
}

export const usePointsOfInterest = (): UseDefaultSettingsProps => {
  const dispatch = useAppDispatch();
  const { pointsOfInterest } = useAppSelector(selectPointsOfInterest);

  const { enqueueSnackbar } = useSnackbar();

  const { roomId } = useParams();

  const reload = useCallback(async () => {
    const result = await http.pointsOfInterest
      .listByRoom({ roomId: String(roomId) })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });

    if (result?.error) {
      enqueueSnackbar(result.message, { variant: 'error' });
      return;
    }

    const sorted = result?.data?.sort((a: any, b: any) =>
      a?.displayName?.localeCompare(b?.displayName || '')
    );

    if (sorted) dispatch(setPointsOfInterest(sorted));
  }, [dispatch, enqueueSnackbar, roomId]);

  const list = useCallback(async () => {
    if (pointsOfInterest.length > 0) return;

    reload();
  }, [pointsOfInterest.length, reload]);

  const create = useCallback(
    async (
      payload: CreatePointsOfInterestProps,
      setSubmiting: any,
      onCreated: any
    ) => {
      setSubmiting(true);

      await http.pointsOfInterest
        .create(payload)
        .then((res) => {
          setSubmiting(false);

          reload();

          if (onCreated) onCreated();

          enqueueSnackbar(res.message, { variant: 'success' });
        })
        .catch((error) => {
          setSubmiting(false);

          enqueueSnackbar(error.message, { variant: 'error' });
        });
    },
    [enqueueSnackbar, reload]
  );

  const update = useCallback(
    async (
      { id, payload }: UpdatePointsOfInterestProps,
      setSubmiting: any,
      onUpdated: any
    ) => {
      setSubmiting(true);

      await http.pointsOfInterest
        .update({ id, payload })
        .then((res) => {
          setSubmiting(false);

          reload();

          if (onUpdated) onUpdated();

          enqueueSnackbar(res.message, { variant: 'success' });
        })
        .catch((error) => {
          setSubmiting(false);

          enqueueSnackbar(error.message, { variant: 'error' });
        });
    },
    [enqueueSnackbar, reload]
  );

  const remove = useCallback(
    async ({ id }: RemovePointsOfInterestProps) => {
      await http.pointsOfInterest
        .remove({ id })
        .then((res) => {
          reload();

          enqueueSnackbar(res.message, { variant: 'success' });
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        });
    },
    [enqueueSnackbar, reload]
  );

  const exportation = useCallback(
    async (
      payload: ExportPointsOfInterestProps,
      setSubmiting: any,
      onReaded: any
    ) => {
      setSubmiting(true);

      const result = await http.pointsOfInterest
        .exportation(payload)
        .catch((error) => {
          setSubmiting(false);
          enqueueSnackbar(error.message, { variant: 'error' });
        });

      if (result?.error) {
        enqueueSnackbar(result.message, { variant: 'error' });
        return;
      }

      const sorted = result?.data?.sort((a: any, b: any) =>
        a.displayName?.localeCompare(b.displayName || '')
      );

      setSubmiting(false);

      if (onReaded) onReaded();

      if (sorted) dispatch(setPointsOfInterestToExport(sorted));
    },
    [enqueueSnackbar, reload]
  );

  return {
    list,
    create,
    update,
    remove,
    reload,
    exportation,
  };
};
