import React, { SetStateAction, useState, Dispatch } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import { IStreamGroupLog } from 'redux/slices/interfaces';
import { useStyles } from './styles';
import { useGameCast } from 'shared/hooks/admin/useGameCast';
import { TIME_RANGE } from './constants';
import {
  SortingOrderTypes,
  LogsFilterRange,
  ISortingConfig,
  SortingCriteriaTypes,
} from '../interface';
import LogsPreviewItem from '../LogsPreviewItem/LogsPreviewItem';
import { useAppSelector } from 'shared/hooks/useStore';
import { loadingLogsSelector } from 'redux/slices/admin/gameCastSlice';
import LoadingProgress from 'components/LoadingProgress/LoadingProgress';

const LogsPreview = ({
  logs,
  sortingConfig,
  setSortingConfig,
}: {
  logs: IStreamGroupLog[];
  sortingConfig: ISortingConfig | null;
  setSortingConfig: Dispatch<SetStateAction<ISortingConfig>>;
  eventId: string;
}) => {
  const classes = useStyles();
  const [startTimeState, setStartTimeState] = useState(SortingOrderTypes.DESC);
  const [endTimeState, setEndTimeState] = useState(SortingOrderTypes.DESC);
  const [capacityState, setCapacityState] = useState(SortingOrderTypes.DESC);
  const [timeState, setTimeState] = useState(SortingOrderTypes.DESC);
  const [logsFilterRange, setLogsFilterRange] = useState<LogsFilterRange>(
    LogsFilterRange.All
  );
  const loadingLogs = useAppSelector(loadingLogsSelector);

  const { getLogsWithFilters } = useGameCast();

  const handleStartTime = () => {
    setStartTimeState(
      startTimeState === SortingOrderTypes.ASC
        ? SortingOrderTypes.DESC
        : SortingOrderTypes.ASC
    );

    setSortingConfig({
      criteria: SortingCriteriaTypes.StartTime,
      order:
        startTimeState === SortingOrderTypes.ASC
          ? SortingOrderTypes.DESC
          : SortingOrderTypes.ASC,
    });
  };

  const handleEndTime = () => {
    setEndTimeState(
      endTimeState === SortingOrderTypes.ASC
        ? SortingOrderTypes.DESC
        : SortingOrderTypes.ASC
    );
    setSortingConfig({
      criteria: SortingCriteriaTypes.EndTime,
      order:
        endTimeState === SortingOrderTypes.ASC
          ? SortingOrderTypes.DESC
          : SortingOrderTypes.ASC,
    });
  };

  const handleCapacity = () => {
    setCapacityState(
      capacityState === SortingOrderTypes.ASC
        ? SortingOrderTypes.DESC
        : SortingOrderTypes.ASC
    );
    setSortingConfig({
      criteria: SortingCriteriaTypes.Capacity,
      order:
        capacityState === SortingOrderTypes.ASC
          ? SortingOrderTypes.DESC
          : SortingOrderTypes.ASC,
    });
  };

  const handleTime = () => {
    setTimeState(
      timeState === SortingOrderTypes.ASC
        ? SortingOrderTypes.DESC
        : SortingOrderTypes.ASC
    );
    setSortingConfig({
      criteria: SortingCriteriaTypes.Time,
      order:
        timeState === SortingOrderTypes.ASC
          ? SortingOrderTypes.DESC
          : SortingOrderTypes.ASC,
    });
  };

  const handleChangeRangeFilter = (event: SelectChangeEvent) => {
    setLogsFilterRange(event.target.value as LogsFilterRange);
    const timeRange = TIME_RANGE[event.target.value as LogsFilterRange];

    getLogsWithFilters({ timeRange });
  };

  return (
    <Box>
      <Box sx={classes.filterDropdown}>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Time interval</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={logsFilterRange || ''}
            label="Time interval"
            onChange={handleChangeRangeFilter}
          >
            <MenuItem value={LogsFilterRange.Hour}>1 hour</MenuItem>
            <MenuItem value={LogsFilterRange.Day}>24 hours</MenuItem>
            <MenuItem value={LogsFilterRange.Week}>1 week</MenuItem>
            <MenuItem value={LogsFilterRange.All}>All time</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={classes.header}>
        <Typography component="p">Actor</Typography>
        <Typography component="p">Message</Typography>
        <Button onClick={handleStartTime}>
          <Typography component="p">Start Time</Typography>
          {sortingConfig?.criteria === SortingCriteriaTypes.StartTime ? (
            startTimeState === SortingOrderTypes.ASC ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )
          ) : null}
        </Button>
        <Button onClick={handleEndTime}>
          <Typography component="p">End Time</Typography>
          {sortingConfig?.criteria === SortingCriteriaTypes.EndTime ? (
            endTimeState === SortingOrderTypes.ASC ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )
          ) : null}
        </Button>
        <Button onClick={handleCapacity}>
          <Typography component="p">Capacity</Typography>
          {sortingConfig?.criteria === SortingCriteriaTypes.Capacity ? (
            capacityState === SortingOrderTypes.ASC ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )
          ) : null}
        </Button>
        <Typography component="p">Region</Typography>
        <Button onClick={handleTime}>
          <Typography component="p">Time</Typography>
          {sortingConfig?.criteria === SortingCriteriaTypes.Time ? (
            timeState === SortingOrderTypes.ASC ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )
          ) : null}
        </Button>
      </Box>
      {!loadingLogs ? (
        logs.length ? (
          logs.map((log) => (
            <LogsPreviewItem log={log} key={log.id || log.timestamps} />
          ))
        ) : (
          <Typography>There are no logs</Typography>
        )
      ) : (
        <LoadingProgress />
      )}
    </Box>
  );
};

export default LogsPreview;
