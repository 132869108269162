import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios';

import { getToken } from 'shared/utils/tokenManager';

import { instanceManagerApiUrl } from 'configs/apiConfigs';
import { TOKEN_AUTH_NAME } from 'configs/authConfigs';

type AxiosCreateType = (params?: { shared?: boolean }) => AxiosInstance;

const axiosCreate: AxiosCreateType = ({ shared = false } = {}) => {
  const headers: AxiosRequestHeaders = {
    Authorization: `${getToken(TOKEN_AUTH_NAME)}`,
  };

  if (!shared) {
    headers['X-Client-App'] = 'console';
  }

  const config: AxiosRequestConfig = {
    baseURL: instanceManagerApiUrl,
    headers,
  };

  return axios.create(config);
};

export default axiosCreate;
