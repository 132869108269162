import DoneIcon from '@mui/icons-material/Done';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useState } from 'react';

type Props = {
  title?: string;
  message?: string;
  disabled?: boolean;
  buttonText?: string;
  cancelModalButtonText?: string;
  okModalButtonText?: string;
  submitCallback: () => void;
  icon?: React.ReactNode;
};

export const AcceptButtonWithDialog = ({
  title = 'Are you sure?',
  message = 'body message',
  buttonText = 'Accept',
  disabled = false,
  okModalButtonText = 'OK',
  submitCallback,
  icon,
}: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleSubmit = () => {
    handleCloseDialog();
    submitCallback();
  };

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="error">
            <span>Cancel</span>
          </Button>
          <Button onClick={handleSubmit} autoFocus>
            <DoneIcon fontSize="small" style={{ marginRight: '8px' }} />{' '}
            <span>{okModalButtonText}</span>
          </Button>
        </DialogActions>
      </Dialog>

      <Button
        color="primary"
        onClick={handleOpenDialog}
        startIcon={icon}
        disabled={disabled}
      >
        {buttonText}
      </Button>
    </>
  );
};
