import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import axiosWithUserToken from './axiosInstances/axiosWithUserToken';
import { HttpResponseDefault } from './types/httpGeneric';
import {
  CreatePointsOfInterestProps,
  ExportPointsOfInterestProps,
  GetPointsOfInterestProps,
  ListByRoomPointsOfInterestProps,
  RemovePointsOfInterestProps,
  UpdatePointsOfInterestProps,
  UploadPointsOfInterestProps,
} from './types/poi';

const streamServices = {
  async listByRoom({
    roomId,
  }: ListByRoomPointsOfInterestProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/points-interest/list/room/${roomId}`
      );

      return {
        message: 'Success',
        data: data.pointsInterest,
      };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async get({ id }: GetPointsOfInterestProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/points-interest/get/${id}`
      );

      return { message: 'Success', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async create(
    payload: CreatePointsOfInterestProps
  ): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().post(
        '/cms/points-interest/create/',
        payload
      );

      return { message: 'Created', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async update({
    id,
    payload,
  }: UpdatePointsOfInterestProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().put(
        `/cms/points-interest/update/${id}`,
        payload
      );

      return { message: 'Updated', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async remove({
    id,
  }: RemovePointsOfInterestProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().delete(
        `/cms/points-interest/delete/${id}`
      );

      return { message: 'Removed', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async upload(
    payload: UploadPointsOfInterestProps
  ): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().post(
        `/cms/points-interest/import/room/${payload.roomId}`,
        payload
      );

      return { message: 'Created', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async exportation({
    roomId,
  }: ExportPointsOfInterestProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/points-interest/export/room/${roomId}`
      );

      return { message: 'Loaded', data: data.pointsInterest };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default streamServices;
