import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Grid, IconButton, Typography } from '@mui/material';
import { UploadIconWrapper } from 'pages/rooms/RoomDetailsPage/styled';
import React, { ChangeEvent } from 'react';
import { selectEvents } from 'redux/slices/eventsSlice';
import { useAppSelector } from 'shared/hooks/useStore';
import { renameFileToUploadPattern } from 'shared/utils/renameFileToUploadPattern';
import { EventImagesProps } from './types';

export const EventImagesContainer = ({
  setFilesToSubmit: setFilesToSubmit,
  setImagesPreview: setImagesPreview,
  filesToSubmit: filesToSubmit,
  imagesPreview: imagesPreview,
}: {
  setFilesToSubmit: React.Dispatch<EventImagesProps>;
  setImagesPreview: React.Dispatch<EventImagesProps>;
  filesToSubmit: EventImagesProps;
  imagesPreview: EventImagesProps;
}) => {
  const { event } = useAppSelector(selectEvents);

  const handleUploadImageInput = (
    event: ChangeEvent<HTMLInputElement>,
    tag: string
  ) => {
    if (!event.target.files) return;

    let file = event.target.files[0];

    file = renameFileToUploadPattern(file);

    const reader = new FileReader();

    reader.onloadend = () => {
      handleFileLoaded(file, tag);
    };

    reader.readAsDataURL(file);

    event.target.value = '';
  };

  const handleFileLoaded = (file: File, tag: string) => {
    setFilesToSubmit({ ...filesToSubmit, [tag]: file });
    setImagesPreview({
      ...imagesPreview,
      [tag]: URL.createObjectURL(file),
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4} md={3}>
        <input
          accept="image/*"
          id="icon-button-file-1"
          type="file"
          style={{ display: 'none' }}
          onChange={(e) => handleUploadImageInput(e, 'logoImage')}
        />
        <label htmlFor="icon-button-file-1">
          <Typography variant="h6" gutterBottom>
            Logo Event
          </Typography>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            style={{
              width: '100%',
              padding: '0',
              borderRadius: '6px',
              overflow: 'hidden',
            }}
          >
            <UploadIconWrapper>
              <FileUploadIcon />
            </UploadIconWrapper>
            <img
              src={
                imagesPreview.logoImage ||
                event.logoImage ||
                'https://tf-cms-prod-assets.mytaverse.com/placeholder/placeholder-image.jpg'
              }
              style={{ width: '100%' }}
            />
          </IconButton>
        </label>
      </Grid>

      <Grid item xs={12} sm={3} md={3}>
        <input
          accept=".jpg, .png, .mp4, .jpeg"
          id="icon-button-file-2"
          type="file"
          style={{ display: 'none' }}
          onChange={(e) => handleUploadImageInput(e, 'bgScreenImage')}
        />
        <Typography variant="h6" gutterBottom>
          Background
        </Typography>
        <label htmlFor="icon-button-file-2">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            style={{
              width: '100%',
              padding: '0',
              borderRadius: '6px',
              overflow: 'hidden',
            }}
          >
            <UploadIconWrapper>
              <FileUploadIcon />
            </UploadIconWrapper>

            {event.bgScreenImage?.includes('.mp4') ? (
              <video width="100%" controls>
                <source src={event.bgScreenImage} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={
                  (imagesPreview?.bgScreenImage as string) ||
                  event?.bgScreenImage ||
                  'https://tf-cms-prod-assets.mytaverse.com/placeholder/placeholder-image.jpg'
                }
                style={{ width: '100%' }}
              />
            )}
          </IconButton>
        </label>
      </Grid>

      <Grid item xs={12} sm={3} md={3}>
        <input
          accept="image/*"
          id="icon-button-file-3"
          type="file"
          style={{ display: 'none' }}
          onChange={(e) => handleUploadImageInput(e, 'agendaImage')}
        />
        <Typography variant="h6" gutterBottom>
          Agenda
        </Typography>
        <label htmlFor="icon-button-file-3">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            style={{
              width: '100%',
              padding: '0',
              borderRadius: '6px',
              overflow: 'hidden',
            }}
          >
            <UploadIconWrapper>
              <FileUploadIcon />
            </UploadIconWrapper>
            <img
              src={
                imagesPreview.agendaImage ||
                event.agendaImage ||
                'https://tf-cms-prod-assets.mytaverse.com/placeholder/placeholder-image.jpg'
              }
              style={{ width: '100%' }}
            />
          </IconButton>
        </label>
      </Grid>

      <Grid item xs={12} sm={3} md={3}>
        <input
          accept="image/*"
          id="icon-button-file-4"
          type="file"
          style={{ display: 'none' }}
          onChange={(e) => handleUploadImageInput(e, 'customEventLogo')}
        />
        <Typography variant="h6" gutterBottom>
          Custom Logo
        </Typography>
        <label htmlFor="icon-button-file-4">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            style={{
              width: '100%',
              padding: '0',
              borderRadius: '6px',
              overflow: 'hidden',
            }}
          >
            <UploadIconWrapper>
              <FileUploadIcon />
            </UploadIconWrapper>
            <img
              src={
                imagesPreview.customEventLogo ||
                event.customEventLogo ||
                'https://tf-cms-prod-assets.mytaverse.com/placeholder/placeholder-image.jpg'
              }
              style={{ width: '100%' }}
            />
          </IconButton>
        </label>
      </Grid>
    </Grid>
  );
};
