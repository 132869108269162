import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { SkinModel } from 'shared/types/models/skinModel';

const initialState = {
  isSkinsModelsLoading: false,
  skinsModels: [] as SkinModel[],
  actualSkinModel: {} as SkinModel,
  utils: {
    isCreatingSkinModel: false,
    isUploadingSkinModel: false,
    isDeletingSkinModel: false,
  },
};

export const skinsModelSlice = createSlice({
  name: 'skinsModel',
  initialState,
  reducers: {
    setIsSkinsModelsLoading: (state, { payload }) => {
      state.isSkinsModelsLoading = payload;
    },
    setSkinsModelsList: (state, { payload }) => {
      state.skinsModels = payload;
    },
    setActualSkinModel: (state, { payload }) => {
      state.actualSkinModel = payload;
    },
    setCreatingSkinModel: (state, { payload }) => {
      state.utils.isCreatingSkinModel = payload;
    },
    setUploadingSkinModel: (state, { payload }) => {
      state.utils.isUploadingSkinModel = payload;
    },
    setDeletingSkinModel: (state, { payload }) => {
      state.utils.isDeletingSkinModel = payload;
    },
  },
});

export const selectSkinsModel = (state: RootState) => state.skinsModelData;

export const {
  setIsSkinsModelsLoading,
  setSkinsModelsList,
  setActualSkinModel,
  setCreatingSkinModel,
  setUploadingSkinModel,
  setDeletingSkinModel,
} = skinsModelSlice.actions;

export default skinsModelSlice.reducer;
