import { SubscribeRequestProps } from '../../../services/http/types/invites';
import { GetInviteInputDataType } from './interfaces';

export const getInviteInputData: GetInviteInputDataType = ({
  emails,
  eventId,
  sendInviteLink,
  expiresIn,
}) => {
  const inputData: SubscribeRequestProps = {
    emails,
    event: eventId,
    expiresIn,
  };

  if (!sendInviteLink) {
    inputData.sendInviteLink = sendInviteLink;
  }

  return inputData;
};
