import {
  CircularProgress,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import { BoxDialog } from 'shared/components/modals/BoxDialogModal/styled';
import {
  ButtonCancelMedia,
  ButtonReload,
  ButtonTryAgain,
  ButtonUploadMedia,
  CancelIconCustom,
  GridDropZone,
  IconButtonExport,
  LinearProgressUploading,
} from '../styled';
import { Stack } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import {
  clearPointsOfInterestToImport,
  selectPointsOfInterest,
  setPointsOfInterestToImport,
} from 'redux/slices/pointsOfInterestSlice';
import { useEffect, useState } from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import { useCSVReader } from 'react-papaparse';
import { useSnackbar } from 'notistack';
import { PointOfInterest } from 'shared/types/models/pointOfInterest';
import { chunkArray } from 'shared/utils/chunkArray';
import {
  CreatePointsOfInterestProps,
  ExportPointsOfInterestObjProps,
} from 'services/http/types/poi';
import { http } from 'services/http';
import { parsePositionAndRotation } from 'shared/utils/parsePositionAndRotation';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import { usePointsOfInterest } from 'shared/hooks/usePointsOfInterest';

interface AddProps {
  open: boolean;
  roomId: string;
  handleClose: () => void;
}

export default function ImportPointsOfInterestModal({
  open,
  roomId,
  handleClose,
}: AddProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const { CSVReader } = useCSVReader();
  const [importProgress, setImportProgress] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { pointsOfInterestToImport } = useAppSelector(selectPointsOfInterest);
  const { reload } = usePointsOfInterest();

  const extractPOIsFromCSVReader = (pois: CreatePointsOfInterestProps[]) => {
    if (pois.length == 0) {
      setHasError(true);
      enqueueSnackbar('The file must have a row to insert', {
        variant: 'error',
      });
      return;
    }

    setHasError(false);
    setIsSubmitting(true);
    setImportProgress(20);

    const isValid = (poi: CreatePointsOfInterestProps): boolean => {
      const requiredFields = [
        'room',
        'displayName',
        'position',
        'rotation',
        'scale',
        'asset',
        'description',
        'mediaType',
        'isInteractive',
      ];

      const populatedFields = requiredFields.filter((field) => poi[field]);

      return populatedFields.length === requiredFields.length;
    };

    const poisToRequest = pois.map(
      (poi: CreatePointsOfInterestProps, index: number) => {
        if (!isValid(poi)) {
          enqueueSnackbar(`POI ${index} is missing required data`, {
            variant: 'warning',
          });
          return;
        }

        const exportPoi: ExportPointsOfInterestObjProps = {};

        Object.keys(poi).forEach((key: string) => {
          if (poi[key] === null) {
            exportPoi[key] = '';
          } else {
            const exportPoiKeys: ExportPointsOfInterestObjProps = {
              position: parsePositionAndRotation(poi.position),
              rotation: parsePositionAndRotation(poi.rotation),
              scale: poi.scale ? parseFloat(poi.scale) : 0,
              createdAt: poi.createdAt.toISOString(),
              updatedAt: poi.updatedAt.toISOString(),
            };

            exportPoi[key] = exportPoiKeys[key] ? exportPoiKeys[key] : poi[key];
          }
        });

        return exportPoi;
      }
    );

    const activePOIsToRequest = poisToRequest.filter((spot: any) => spot);

    if (activePOIsToRequest.length === 0) {
      setHasError(true);
      enqueueSnackbar('No POIs have been imported', { variant: 'warning' });
      return;
    }

    dispatch(setPointsOfInterestToImport(activePOIsToRequest));
  };

  const handleImportPOIs = async () => {
    // The api works better with 20 pointsOfInterestToImport at time
    const NUMBER_OF_POIs_LIMIT = 20;

    if (pointsOfInterestToImport.length <= NUMBER_OF_POIs_LIMIT) {
      submit(pointsOfInterestToImport);
      return;
    }

    // to large email lists, need to split this list into smaller ones
    const newList = chunkArray(pointsOfInterestToImport, NUMBER_OF_POIs_LIMIT);

    const submitingStepped = await submitStepped(newList);

    if (submitingStepped?.success) {
      dispatch(clearPointsOfInterestToImport());

      setTimeout(() => {
        enqueueSnackbar(
          `${pointsOfInterestToImport.length} pointsOfInterestToImport saved`,
          {
            variant: 'success',
          }
        );
      }, 1000);
    } else if (!submitingStepped?.success) {
      dispatch(clearPointsOfInterestToImport());

      setTimeout(() => {
        enqueueSnackbar(
          `${pointsOfInterestToImport.length} roomSpotsToImport not saved ${
            submitingStepped?.message
              ? '[' + submitingStepped.message + ']'
              : ''
          }`,
          {
            variant: 'error',
          }
        );
      }, 1000);
    }

    setIsSubmitting(false);
  };

  const submitStepped = async (
    pointsOfInterestToImport: (PointOfInterest[] | string[])[]
  ): Promise<{ success: boolean; message: string | undefined } | undefined> => {
    let success;

    let progress = importProgress;
    const progressPlus = 80 / pointsOfInterestToImport.length;

    for (const item of pointsOfInterestToImport) {
      const POIs: CreatePointsOfInterestProps[] = Object.assign(item);

      await http.pointsOfInterest
        .upload({
          roomId: roomId,
          POIs: POIs,
        })
        .then((res) => {
          if (res.error) {
            success = { success: false, message: res.data.error?.message };
          } else {
            success = { success: true };
          }
        })
        .catch(() => {
          success = { success: false };
        });

      progress = progress + progressPlus;
      setImportProgress(progress);
    }

    setImportProgress(100);

    return success;
  };

  const submit = async (pointsOfInterestToImport: PointOfInterest[]) => {
    const POIs: CreatePointsOfInterestProps[] = Object.assign(
      pointsOfInterestToImport
    );

    setImportProgress(50);

    await http.pointsOfInterest
      .upload({
        roomId: roomId,
        POIs: POIs,
      })
      .then((res) => {
        if (res.error) {
          setHasError(true);
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }

        dispatch(clearPointsOfInterestToImport());
        setHasSuccess(true);
        enqueueSnackbar(res.message, { variant: 'success' });
      })
      .catch((error) => {
        setHasError(true);
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setIsSubmitting(false);
        setImportProgress(100);
      });
  };

  useEffect(() => {
    if (pointsOfInterestToImport.length > 0) {
      handleImportPOIs();
    }
  }, [pointsOfInterestToImport]);

  useEffect(() => {
    dispatch(clearPointsOfInterestToImport());
    setIsSubmitting(false);
    setImportProgress(0);
    setHasError(false);
    setHasSuccess(false);
  }, [open]);

  return (
    <BoxDialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="add-point-dialog-title"
      scroll="body"
      maxWidth="md"
    >
      <CSVReader
        onUploadAccepted={(results: any) => {
          extractPOIsFromCSVReader(results.data);
        }}
        config={{
          header: true,
          dynamicTyping: true,
        }}
      >
        {({ getRootProps, acceptedFile }: any) => (
          <>
            <DialogContent dividers style={{ minWidth: '450px' }}>
              <Grid container direction="column">
                <Grid
                  xs={12}
                  lg={12}
                  md={12}
                  justifyContent="center"
                  display="flex"
                  marginTop="20px"
                  item
                >
                  <IconButtonExport
                    color="primary"
                    aria-label="save"
                    step={
                      hasSuccess ? 'success' : hasError ? 'error' : 'normal'
                    }
                  >
                    {!hasSuccess && !hasError && <UploadIcon />}
                    {hasSuccess && <DoneIcon color="success" />}
                    {hasError && <CloseIcon />}
                  </IconButtonExport>
                </Grid>

                <Grid
                  xs={12}
                  lg={12}
                  md={12}
                  justifyContent="center"
                  display="flex"
                  item
                >
                  <Stack
                    justifyContent="center"
                    display="flex"
                    textAlign="center"
                    marginLeft={5}
                    marginRight={5}
                  >
                    <Typography
                      variant="h4"
                      component="span"
                      marginTop="20px"
                      marginBottom="20px"
                    >
                      {!hasSuccess && !hasError && 'Upload Room POIs From .csv'}
                      {hasSuccess && 'Upload Completed'}
                      {hasError && 'Upload Failed'}
                    </Typography>

                    <Typography variant="body1" component="p">
                      {!hasSuccess &&
                        !hasError &&
                        'Uploading a CSV with POIs from your files will overwrite'}
                      {hasSuccess &&
                        'Your new POIs .csv file was successfully uploaded.'}
                      {hasError &&
                        'Your new media .csv file upload has failed. '}
                    </Typography>
                    <Typography variant="body1" component="p">
                      {!hasSuccess &&
                        !hasError &&
                        'all your current Points of Interest.'}
                      {hasSuccess &&
                        'Please refresh the page to see the updates.'}
                      {hasError &&
                        'Please make sure you’re uploading the correct file format'}
                    </Typography>
                    <Typography variant="body1" component="p">
                      {hasError &&
                        'and your internet connection is working well.'}
                    </Typography>

                    {!acceptedFile && !hasError && !hasSuccess && (
                      <GridDropZone xs={12} lg={12} item>
                        <Typography variant="body1" component="p">
                          No CSV file selected yet
                        </Typography>
                      </GridDropZone>
                    )}

                    {acceptedFile && !hasError && !hasSuccess && (
                      <Grid
                        xs={12}
                        lg={12}
                        marginTop="20px"
                        marginBottom="20px"
                        item
                      >
                        <LinearProgressUploading
                          variant="determinate"
                          value={importProgress}
                          content={
                            acceptedFile
                              ? '...' +
                                acceptedFile.name.substring(
                                  acceptedFile.name.length - 25,
                                  acceptedFile.name.length
                                )
                              : 'No CSV file selected yet'
                          }
                        />
                      </Grid>
                    )}
                  </Stack>
                </Grid>

                <Grid
                  xs={12}
                  lg={12}
                  md={12}
                  justifyContent="center"
                  display="flex"
                  marginTop="20px"
                  marginBottom="20px"
                  item
                >
                  {!hasSuccess && !hasError && (
                    <>
                      <ButtonUploadMedia
                        {...getRootProps()}
                        startIcon={
                          !isSubmitting ? (
                            <UploadIcon fontSize="small" />
                          ) : (
                            <CircularProgress size="20px" />
                          )
                        }
                        color="success"
                        variant="contained"
                        disabled={isSubmitting}
                      >
                        {!isSubmitting ? 'Browse My Files...' : 'Uploading'}
                      </ButtonUploadMedia>
                      <ButtonCancelMedia
                        type="button"
                        startIcon={
                          <CancelIconCustom
                            fontSize="small"
                            className="cancelIcon"
                          />
                        }
                        onClick={() => {
                          handleClose();
                          setImportProgress(0);
                        }}
                        color="info"
                        variant="contained"
                      >
                        Cancel
                      </ButtonCancelMedia>
                    </>
                  )}

                  {hasSuccess && (
                    <>
                      <ButtonReload
                        startIcon={
                          <ReplayIcon className="replayIcon" fontSize="small" />
                        }
                        color="success"
                        variant="contained"
                        onClick={() => {
                          reload();
                          handleClose();
                        }}
                      >
                        Refresh Page
                      </ButtonReload>
                    </>
                  )}
                  {hasError && (
                    <>
                      <ButtonTryAgain
                        startIcon={
                          <ReplayIcon className="replayIcon" fontSize="small" />
                        }
                        color="success"
                        variant="contained"
                        onClick={() => {
                          setHasError(false);
                          setHasSuccess(false);
                          setImportProgress(0);
                        }}
                      >
                        Try Again
                      </ButtonTryAgain>
                    </>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
      </CSVReader>
    </BoxDialog>
  );
}
