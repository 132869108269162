import { Avatar, Checkbox, Stack, TableCell, TableRow } from '@mui/material';
import { Skin } from 'shared/types/models/skin';
import { RemoveButtonWithDialog } from 'shared/components/buttons/RemoveButtonWithDialog';
import { http } from 'services/http';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSkins } from '../hooks/useSkins';
import { useParams } from 'react-router-dom';

export const SkinTableRow = ({ row }: { row: Skin }) => {
  const urlParams = useParams();
  const [isRemoving, setIsRemoving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { reloadSkins } = useSkins();

  const handleRemoveSkin = async () => {
    setIsRemoving(true);

    if (row?.id) {
      await http.skins
        .remove({
          skinId: row.id,
        })
        .then((res) => {
          setIsRemoving(false);

          if (res.error) {
            enqueueSnackbar(res.message, { variant: 'error' });
            return;
          }

          enqueueSnackbar('Skin removed', { variant: 'success' });
          if (urlParams?.id) {
            reloadSkins({
              eventId: urlParams.id,
              avatarId: urlParams.avatarId as string,
            });
          } else {
            enqueueSnackbar('Error: Event ID is missing or undefined', {
              variant: 'error',
            });
          }
        })
        .catch((error) => {
          setIsRemoving(false);
          enqueueSnackbar(error.message, { variant: 'error' });
        });
    } else {
      enqueueSnackbar('Error: Skin ID is missing or undefined', {
        variant: 'error',
      });
    }
  };

  const handleSetSkinIsDefault = async () => {
    if (row?.id && row?.gender) {
      await http.skins
        .setDefault({
          skinId: row.id,
          gender: row.gender,
        })
        .then((res) => {
          setIsRemoving(false);

          if (res.error) {
            enqueueSnackbar(res.message, { variant: 'error' });
            return;
          }

          enqueueSnackbar('Updated', { variant: 'success' });
          if (urlParams?.id) {
            reloadSkins({
              eventId: urlParams.id,
              avatarId: urlParams.avatarId as string,
            });
          } else {
            enqueueSnackbar('Error: Event ID is missing or undefined', {
              variant: 'error',
            });
          }
        })
        .catch((error) => {
          setIsRemoving(false);
          enqueueSnackbar(error.message, { variant: 'error' });
        });
    } else {
      enqueueSnackbar('Error: Skin ID or gender is missing or undefined', {
        variant: 'error',
      });
    }
  };

  return (
    <TableRow>
      <TableCell scope="row" style={{ width: '50px' }}>
        <Avatar
          alt={row.name}
          src={row.previewImage || row.thumbnail || ''}
          sx={{ width: 50, height: 50 }}
        />
      </TableCell>
      <TableCell align="left">{row.name}</TableCell>
      <TableCell align="right">
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <span>
            <Checkbox
              color="primary"
              checked={row.isDefault}
              onClick={handleSetSkinIsDefault}
              inputProps={{
                'aria-labelledby': 'labelId',
              }}
            />
            Default
          </span>

          <RemoveButtonWithDialog
            title="Remove Skin"
            message="Remove this skin from your event?"
            deleteCallback={handleRemoveSkin}
            disabled={isRemoving}
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
};
