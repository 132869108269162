import { useSnackbar } from 'notistack';

import useAsyncEffect from 'use-async-effect';
import {
  gameCastConfigsSelector,
  setGameCastGroups,
  setIsGameCastItemsLoading,
  setCurrentGameCastGroup,
  setOpenConfigurationModal,
  setIsCalendarLoading,
  setCurrentCalendarItems,
  gameCastConfigItemSelector,
  setCurrentLogs,
  setLoadingLogs,
} from 'redux/slices/admin/gameCastSlice';

import { useAppDispatch, useAppSelector } from '../useStore';
import EventsGameCastService, {
  GetLogsFiltersType,
} from 'services/http/eventsService';
import EventsService from 'services/http/events';
import { GameCastConfig } from 'redux/slices/interfaces';
import SchedulerService from 'services/http/schedulerService';
import {
  CustomProcessedEvent,
  StreamingProviders,
} from 'pages/admin/GameCastConfigurePage/interface';
import { useParams } from 'react-router-dom';
import {
  currentPurewebModelSelector,
  setLoadingPurewebLogs,
  setPurewebLogs,
} from 'redux/slices/admin/purewebSlice';
import { getMergedLogs } from 'pages/admin/GameCastConfigurePage/helpers';

export const useGameCast = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { gameCastGroups } = useAppSelector(gameCastConfigsSelector);
  const currentGroup = useAppSelector(gameCastConfigItemSelector);
  const dispatch = useAppDispatch();
  const { eventId } = useParams();
  const currentPurewebModel = useAppSelector(currentPurewebModelSelector);

  const loadGameCastConfigs = async () => {
    if (gameCastGroups.length) {
      return;
    }

    dispatch(setIsGameCastItemsLoading(true));

    try {
      const configs = await EventsGameCastService.getGameCastGroups();

      if (configs) {
        const mergedConfigs = await Promise.all(
          configs.map(async (group) => {
            const event = await EventsService.get({ eventId: group.eventId });

            return {
              ...group,
              eventName: event.data.name,
            };
          })
        );

        dispatch(setGameCastGroups(mergedConfigs));
      }
    } catch (error) {
      enqueueSnackbar((error as Error).message);
    }

    dispatch(setIsGameCastItemsLoading(false));
  };

  const setGameCastGroup = (
    streamGroupId: string,
    group: GameCastConfig | null = null
  ) => {
    if (gameCastGroups.length && !group) {
      const currentGroup = gameCastGroups.find((g) => g.Id === streamGroupId);
      dispatch(setCurrentGameCastGroup(currentGroup));

      return;
    }

    dispatch(setCurrentGameCastGroup(group));
  };

  const emptyCurrentGroup = () => {
    dispatch(setCurrentGameCastGroup(null));
  };

  const handleOpenModal = (isOpen: boolean) => {
    dispatch(setOpenConfigurationModal(isOpen));
  };

  const updateCurrentGroup = (group: GameCastConfig) => {
    if (!group) {
      return;
    }

    dispatch(setCurrentGameCastGroup(group));
  };

  const getCurrentGroupCalendar = async (eventId: string) => {
    dispatch(setIsCalendarLoading(true));
    const calendar = await SchedulerService.getEventGameCastSchedulerDate(
      eventId
    );

    if (!calendar.length) {
      dispatch(setIsCalendarLoading(false));

      return;
    }

    const parsedCalendar = calendar.map<CustomProcessedEvent>((event) => {
      return {
        end: new Date(event.endTime).toString(),
        start: new Date(event.startTime).toString(),
        title: '',
        event_id: event.startTime,
        capacity: event.targetRegions[0].capacity,
        streamingProvider: StreamingProviders.GameCast,
      };
    });

    dispatch(setCurrentCalendarItems(parsedCalendar));
    dispatch(setIsCalendarLoading(false));
  };

  const emptyCurrentCalendar = () => {
    dispatch(setCurrentCalendarItems([]));
  };

  const getLogsWithFilters = async (params: GetLogsFiltersType) => {
    if (currentGroup) {
      dispatch(setLoadingLogs(true));
      const logs = await EventsGameCastService.getStreamGroupLogs(
        currentGroup.eventId,
        params
      );

      if (!logs) {
        return;
      }

      const gameCastLogs = await getMergedLogs(eventId as string, logs);

      dispatch(setCurrentLogs(gameCastLogs));
      dispatch(setLoadingLogs(false));
    }

    if (eventId && currentPurewebModel) {
      dispatch(setLoadingPurewebLogs(true));
      const purewebLogs = await EventsGameCastService.getPurewebLogs(
        eventId,
        params
      );

      const logs = await getMergedLogs(eventId as string, purewebLogs);

      dispatch(setPurewebLogs(logs));
      dispatch(setLoadingPurewebLogs(false));
    }
  };

  useAsyncEffect(async () => {
    if (!currentGroup) {
      dispatch(setCurrentLogs([]));
      return;
    }

    dispatch(setLoadingLogs(true));
    const logs = await EventsGameCastService.getStreamGroupLogs(
      currentGroup.eventId
    );

    if (!logs) {
      return;
    }
    const gameCastLogs = await getMergedLogs(eventId as string, logs);

    dispatch(setCurrentLogs(gameCastLogs));
    dispatch(setLoadingLogs(false));
  }, [currentGroup]);

  return {
    loadGameCastConfigs,
    setGameCastGroup,
    emptyCurrentGroup,
    handleOpenModal,
    updateCurrentGroup,
    getCurrentGroupCalendar,
    emptyCurrentCalendar,
    getLogsWithFilters,
  };
};
