import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { http } from 'services/http';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useAppDispatch } from 'shared/hooks/useStore';
import { setActualFilter, setSelected } from 'redux/slices/invitesSlice';
import { useLoadParticipants } from 'shared/hooks/useLoadParticipants';
import { InviteFilters } from 'shared/types/models/invite';

interface RemoveEmailProps {
  emailsToRemove: string[];
  eventId: string;
}

export default function RemoveEmail({
  emailsToRemove,
  eventId,
}: RemoveEmailProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [isRemoving, setIsRemoving] = useState(false);
  const dispatch = useAppDispatch();
  const { get: reloadParticipants } = useLoadParticipants();

  useEffect(() => {
    return () => {
      setIsRemoving(false);
    };
  }, []);

  const handleRemove = async () => {
    setIsRemoving(true);

    await http.invites
      .unsubscribe({
        emails: emailsToRemove,
        event: eventId,
      })
      .then((res) => {
        dispatch(setSelected([]));
        enqueueSnackbar(res.message, { variant: 'success' });
        dispatch(setActualFilter(InviteFilters.All));
        reloadParticipants();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        setIsRemoving(false);
      });
  };

  return (
    <Button
      onClick={() => handleRemove()}
      color="error"
      startIcon={<DeleteIcon fontSize="small" />}
      disabled={isRemoving}
    >
      {!isRemoving ? 'Uninvite' : 'Uninviting...'}
    </Button>
  );
}
