import { Box, Button, Card, Stack } from '@mui/material';
import { useSkinsModel } from 'pages/admin/SkinsDashboardPage/hooks/useSkinsModel';
import { useParams } from 'react-router-dom';
import { Avatar } from 'shared/types/models/avatar';
import { SelectedAvatarsProps } from '../AddAvatar';
import React from 'react';

export const AvailableAvatarsList = ({
  availableAvatars,
  setActualStep,
  setSelectedAvatar,
  selectedAvatar,
}: {
  availableAvatars: Avatar[];
  setActualStep: React.Dispatch<React.SetStateAction<number>>;
  setSelectedAvatar: React.Dispatch<React.SetStateAction<SelectedAvatarsProps>>;
  selectedAvatar: SelectedAvatarsProps;
}) => {
  const { loadSkinsModelsAvailableByAvatar } = useSkinsModel();
  const { id } = useParams();
  const handleAvatarSelected = async (avatarId: string) => {
    setActualStep(1);
    loadSkinsModelsAvailableByAvatar({
      eventId: id as string,
      avatarId: avatarId,
    });
    setSelectedAvatar({ ...selectedAvatar, avatarId: avatarId });
  };

  return (
    <Box>
      <Stack spacing={2}>
        {availableAvatars.map((availableAvatar: Avatar) => {
          return (
            <Card key={availableAvatar.id}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                style={{ padding: '20px' }}
              >
                {availableAvatar.name}
                <Button
                  variant="outlined"
                  onClick={() =>
                    handleAvatarSelected(availableAvatar.id as string)
                  }
                >
                  Select Avatar
                </Button>
              </Stack>
            </Card>
          );
        })}
      </Stack>
    </Box>
  );
};
