import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useState,
  useImperativeHandle,
} from 'react';
import LoadingProgress from '../../../components/LoadingProgress';
import { ModalProps } from '@mui/material/Modal';

export interface DialogAlertHandles {
  handleOpen: () => void;
}

type props = {
  title?: string;
  message?: string;
  cancelButtonText?: string;
  okButtonText?: string;
  deleteCallback?: (func: () => void) => void;
};

const DialogAlert: ForwardRefRenderFunction<DialogAlertHandles, props> = (
  {
    deleteCallback,
    title = 'title',
    message = 'body message',
    cancelButtonText = 'Cancel',
    okButtonText = 'OK',
  },
  ref
) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (loading && (reason === 'escapeKeyDown' || reason === 'backdropClick')) {
      return;
    }

    handleModalClose();
  };

  const handleModalClose = () => setOpen(false);

  useImperativeHandle(ref, () => {
    return {
      handleOpen,
    };
  });

  const handleDeleteClose = useCallback(() => {
    if (deleteCallback) {
      setLoading(true);
      deleteCallback(handleModalClose);
    }
  }, [deleteCallback]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose} disabled={loading}>
          {cancelButtonText}
        </Button>
        {loading ? (
          <LoadingProgress
            size={24}
            height="auto"
            classes={{ root: { width: 128 } }}
          />
        ) : (
          <Button onClick={handleDeleteClose} autoFocus color="error">
            <DeleteForeverIcon
              fontSize="small"
              style={{ marginRight: '8px' }}
            />{' '}
            <span>{okButtonText}</span>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(DialogAlert);
