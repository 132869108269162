import { Divider } from '@mui/material';
import { DefaultPageHeader } from 'shared/components/headers/DefaultPageHeader';
import { useAppSelector } from 'shared/hooks/useStore';
import { AppDefaultLayout } from 'layouts/AppDefaultLayout';
import { useParams } from 'react-router-dom';
import { selectEvents } from 'redux/slices/eventsSlice';
import { AddTicketButtonContainer } from './components/AddTicketButtonContainer';
import { useEffect } from 'react';
import { useLoadRooms } from 'shared/hooks/useLoadRooms';
import { useLoadTickets } from 'shared/hooks/useLoadTickets';
import { TicketListContainer } from './components/TicketListContainer';
import { appPaths } from 'app/router/appPaths';

export const TicketsPage = () => {
  const urlParams = useParams();
  const { event } = useAppSelector(selectEvents);
  const loadRooms = useLoadRooms();
  const loadTickets = useLoadTickets();

  useEffect(() => {
    loadRooms();
    loadTickets();
  }, [loadRooms, loadTickets]);

  return (
    <AppDefaultLayout>
      <DefaultPageHeader
        titleText={`${event.name} - Tickets`}
        subTitleText="Create tickets to your event"
        linkTo={`${appPaths.private.event.dashboard}/${urlParams.id}`}
        linkToText="Back to Event Dashboard"
      />

      <Divider />

      <AddTicketButtonContainer />
      <TicketListContainer />
    </AppDefaultLayout>
  );
};
