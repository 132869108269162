import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { CSSProperties, useState, useEffect, useCallback } from 'react';
import { useCSVReader } from 'react-papaparse';
import {
  clearEmailList,
  selectEmails,
  setEmailList,
} from 'redux/slices/emailsSlice';
import ClearIcon from '@mui/icons-material/Clear';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { SubscribeEmail } from './SubscribeEmail';
import { toLowerKeys } from 'shared/utils/toLowerKeys';

const styles = {
  acceptedFile: {
    border: '1px solid #ccc',
    lineHeight: 2.5,
    padding: 4,
    minWidth: '200px',
    minHeight: '43px',
  } as CSSProperties,
  progressBarBackgroundColor: {
    backgroundColor: 'red',
    height: '4px',
  } as CSSProperties,
};

export const CSVReader = () => {
  const { CSVReader } = useCSVReader();
  const dispatch = useAppDispatch();
  const { emails } = useAppSelector(selectEmails);
  // ===========================================================================
  const [expiresInEnabled, setExpiresInEnabled] = useState(false);
  const [expiresIsInvalid, setExpiresIsInvalid] = useState(false);
  const [expiresInInput, setExpiresInInput] = useState('');

  const isExpiresInInputValid = (input: string) => {
    const regex = /^(\d+)(h|d|w|m)$/;
    return regex.test(input);
  };

  useEffect(() => {
    if (expiresInEnabled && !isExpiresInInputValid(expiresInInput)) {
      setExpiresIsInvalid(true);
    } else {
      setExpiresIsInvalid(false);
    }
  }, [expiresInEnabled, expiresInInput]);

  const handleExpiresInInputChange = useCallback((value: string) => {
    setExpiresInInput(value);
  }, []);

  // ===========================================================================
  const extractEmailsFromCSVReader = (data: any) => {
    dispatch(clearEmailList());

    const lowerCaseKeysList = [];
    const list: string[] = [];

    for (const item of data) {
      lowerCaseKeysList.push(toLowerKeys(item));
    }

    lowerCaseKeysList.map((item: Record<string, any>) => {
      if (list.indexOf(item.email) < 0) list.push(item.email);
    });

    dispatch(setEmailList(list));
  };

  return (
    <CSVReader
      onUploadAccepted={(results: any) => {
        extractEmailsFromCSVReader(results.data);
      }}
      config={{ header: true }}
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
      }: any) => (
        <>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="start"
            spacing={2}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              style={{ width: '100%' }}
            >
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
              >
                <Button
                  {...getRootProps()}
                  variant="contained"
                  startIcon={<FileUploadIcon />}
                >
                  Browse File
                </Button>
                <div style={styles.acceptedFile}>
                  {acceptedFile && acceptedFile.name}
                </div>
                <Button
                  {...getRemoveFileProps()}
                  variant="outlined"
                  startIcon={<ClearIcon />}
                >
                  Clear Input
                </Button>
              </ButtonGroup>

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                {!!emails.length && (
                  <Button
                    onClick={() => {
                      dispatch(clearEmailList());
                    }}
                    startIcon={<ClearIcon />}
                    color="error"
                  >
                    Clear List
                  </Button>
                )}
                {!!emails.length && (
                  <SubscribeEmail
                    expiresInEnabled={expiresInEnabled}
                    expiresIsInvalid={expiresIsInvalid}
                    expiresInInput={expiresInInput}
                  />
                )}
              </Stack>
            </Stack>
            <ProgressBar style={styles.progressBarBackgroundColor} />

            {!!emails.length && (
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={expiresInEnabled}
                      onChange={(e) => setExpiresInEnabled(e.target.checked)}
                    />
                  }
                  label="Timed invites"
                />

                <Tooltip title="Examples: 3 minutes = 3m | 48 hours = 48h | 1 day = 1d | 2 weeks = 2w">
                  <TextField
                    id="expiresInCheckbox"
                    label="Expires In"
                    value={expiresInInput}
                    onChange={(e) => handleExpiresInInputChange(e.target.value)}
                    disabled={!expiresInEnabled}
                    error={expiresIsInvalid}
                    helperText={
                      expiresIsInvalid &&
                      'Invalid input: It must be a number followed by "h", "d", "w", or "m".'
                    }
                  />
                </Tooltip>
              </Stack>
            )}
          </Stack>
        </>
      )}
    </CSVReader>
  );
};
