import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LogoWrapper = styled(Box)(
  ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[100]};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

export const LogoSignWrapper = styled(Box)(
  ({ theme }) => `
        transform: scale(1);
        transition: ${theme.transitions.create(['transform'])};

        padding: ${theme.spacing(4)} 0;

        cursor: pointer;

        img {
          width: 200px;
        }

        &:hover {
        transform: scale(1.1);
        }
`
);
