import { createSlice } from '@reduxjs/toolkit';
import { Event } from 'shared/types/models/event';
import type { RootState } from '../../store';

interface InitialState {
  emails: string[];
  isEventsAdminLoading: boolean;
  eventsAdmin: Event[];
}

const initialState: InitialState = {
  emails: [],
  isEventsAdminLoading: false,
  eventsAdmin: [],
};

export const eventsAdminSlice = createSlice({
  name: 'eventsAdmin',
  initialState,
  reducers: {
    setEmails: (state, { payload }) => {
      state.emails = payload;
    },
    setIsEventsAdminLoading: (state, { payload }) => {
      state.isEventsAdminLoading = payload;
    },
    setEventsAdmin: (state, { payload }) => {
      state.eventsAdmin = payload;
    },
  },
});

export const selectEventsAdmin = (state: RootState) => state.eventsAdminData;
export const { setEmails, setIsEventsAdminLoading, setEventsAdmin } =
  eventsAdminSlice.actions;

export default eventsAdminSlice.reducer;
