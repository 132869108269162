import { Box, styled } from '@mui/material';

export const Input = styled('input')({
  display: 'none',
});

export const UploadIconWrapper = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  margin: 'auto',
  width: '40px',
  height: '40px',
  backgroundColor: 'rgb(203, 208, 252)',
  borderRadius: '50px',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 20,
});
