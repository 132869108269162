import React, { createContext } from 'react';
import { ID_AUTH_NAME, ROLE_NAME, TOKEN_AUTH_NAME } from 'configs/authConfigs';
import { http } from 'services/http';
import { deleteToken, getToken, setToken } from 'shared/utils/tokenManager';
import { SignInRequestProps, SignUpRequestProps } from 'shared/types/account';
import { useSnackbar } from 'notistack';
import { isSpecialRole } from 'shared/utils/verifyRole';
import firebase, { ProviderType } from 'services/http/firebase';

type AuthContextType = {
  isAuthenticated: () => boolean;
  isAdminUser: () => boolean;
  signIn: (data: SignInRequestProps) => Promise<any>;
  socialSignInUp: (provider: ProviderType) => Promise<any>;
  signUp: (data: SignUpRequestProps) => Promise<any>;
  // @ts-ignore
  signOut: (callback) => Promise<any>;
};

export const AuthContext = createContext({} as AuthContextType);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { enqueueSnackbar } = useSnackbar();

  function isValid(tokenRegistered: string | undefined) {
    if (!tokenRegistered?.length) return false;

    if (tokenRegistered === undefined || tokenRegistered === 'undefined') {
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });

      // eslint-disable-next-line no-console
      console.error('token value:', tokenRegistered);

      return false;
    }
    return true;
  }

  function isAuthenticated() {
    const token = getToken(TOKEN_AUTH_NAME);
    const userID = getToken(ID_AUTH_NAME);

    return isValid(token) && isValid(userID);
  }

  function isAdminUser() {
    const token = getToken(TOKEN_AUTH_NAME);
    const userID = getToken(ID_AUTH_NAME);
    const role = getToken(ROLE_NAME);

    return isValid(token) && isValid(userID) && isSpecialRole(String(role));
  }

  async function signIn(userData: SignInRequestProps) {
    const result = await http.auth.signIn(userData);

    if (result.error) {
      return {
        success: false,
        error: true,
        message: `Sign In error: ${result.error.message}`,
      };
    }

    if (result.token) {
      if (result?.user?.role && isSpecialRole(result.user.role)) {
        setToken(ROLE_NAME, result.user.role);
      }

      setToken(TOKEN_AUTH_NAME, result.token);
      setToken(ID_AUTH_NAME, result.user.id);

      return {
        tag: 42,
        success: true,
        message: 'Sign In Success!',
      };
    }
    return { ...result, tag: 43 };
  }

  async function socialSignInUp(provider: ProviderType) {
    const firebaseResponse = await firebase.signInWithProvider(provider);
    if (!firebaseResponse?.success) {
      return {
        success: false,
        error: true,
        message: `Sign In error: ${firebaseResponse?.error?.message}`,
        code: firebaseResponse?.error?.code ?? '',
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const result = await http.auth.socialSignInUp(firebaseResponse.user!);
    if (result.error) {
      return {
        success: false,
        error: true,
        message: `Sign In error: ${result.error.message}`,
      };
    }

    const newUser = !result.user.id;
    if (result.token) {
      if (result?.user?.role && isSpecialRole(result.user.role)) {
        setToken(ROLE_NAME, result.user.role);
      }
      setToken(TOKEN_AUTH_NAME, result.token);
      setToken(ID_AUTH_NAME, result.user.id);
      return {
        tag: 42,
        success: true,
        message: 'Sign In Success!',
        newUser,
      };
    }
    return { ...result, tag: 43 };
  }

  async function signUp(data: SignUpRequestProps) {
    const result: any = await http.auth.signUp(data);

    if (result.error) {
      return {
        success: false,
        error: true,
        message: `Register error: ${result.message}`,
      };
    }

    if (result.user.id) {
      return {
        success: true,
        message: 'Register Success! You can sign in now.',
      };
    }

    return result;
  }

  // @ts-ignore
  async function signOut(callback) {
    deleteToken(TOKEN_AUTH_NAME);
    deleteToken(ID_AUTH_NAME);
    deleteToken(ROLE_NAME);
    callback();
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAdminUser,
        signIn,
        signOut,
        signUp,
        socialSignInUp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
