import { FormControl, InputAdornment, MenuItem, Select } from '@mui/material';
import { Region } from 'shared/types/models/region';
import CircularProgress from '@mui/material/CircularProgress';

export const RegionsSelector = ({
  regions,
  values,
  touched,
  errors,
  handleChange,
  onChangeRegion,
  disabled,
  isLoadingRegions,
}: any) => {
  return (
    <FormControl fullWidth margin="normal">
      <span>Region*</span>
      <Select
        id="regionSelected"
        name="regionSelected"
        onChange={(e) => {
          handleChange(e);
          onChangeRegion(e);
        }}
        value={values.regionSelected}
        disabled={disabled}
        error={touched.regionSelected && Boolean(errors.regionSelected)}
        startAdornment={
          <>
            {isLoadingRegions && (
              <InputAdornment position="start">
                Loading...{' '}
                <CircularProgress size={20} sx={{ marginLeft: '5px' }} />
              </InputAdornment>
            )}
          </>
        }
      >
        {!!regions &&
          regions.map((item: Region, index: any) => (
            <MenuItem
              key={index}
              value={item.region}
              onClick={() => onChangeRegion(item)}
            >
              {item.displayName}
            </MenuItem>
          ))}
      </Select>

      {touched.regionSelected && Boolean(errors.regionSelected) && (
        <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-11h4n6a-MuiFormHelperText-root">
          {errors.regionSelected}
        </p>
      )}
    </FormControl>
  );
};
