import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import EventRow from '../EventRow';
import CreateEventModal, {
  CreateEventModalHandles,
} from '../CreateEventModal/CreateEventModal';

import { appPaths } from '../../../../app/router/appPaths';

import { IEventsPageLayout } from './interfaces';
import { Event } from '../../../../shared/types/models/event';

import { classes } from './styles';

const EventsPageLayout = ({ userEmail, events }: IEventsPageLayout) => {
  const createEventModalRef = useRef<CreateEventModalHandles>(null);

  const navigate = useNavigate();

  const showCreateButton = userEmail.includes('@mytaverse.com');

  const handleOpenCreateEventModal = () => {
    createEventModalRef.current?.handleOpen();
  };

  const handleConfigEvent = (event: Event) => {
    navigate(`${appPaths.private.event.dashboard}/${event.id}`);
  };

  return (
    <Box sx={classes.root}>
      {showCreateButton && (
        <Button
          onClick={handleOpenCreateEventModal}
          startIcon={<AddCircleIcon fontSize="small" />}
          sx={classes.createBtn}
        >
          Create New Event
        </Button>
      )}

      {events.length > 0 ? (
        events.map((event) => (
          <EventRow
            key={event.id}
            event={event}
            onConfigEvent={() => handleConfigEvent(event)}
          />
        ))
      ) : (
        <p style={{ textAlign: 'center' }}>No events.</p>
      )}
      <CreateEventModal ref={createEventModalRef} />
    </Box>
  );
};

export default EventsPageLayout;
