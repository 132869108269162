import { Button, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { http } from 'services/http';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useAppDispatch } from 'shared/hooks/useStore';
import { useLoadParticipants } from 'shared/hooks/useLoadParticipants';
import { setActualFilter, setSelected } from 'redux/slices/invitesSlice';
import { InviteFilters } from 'shared/types/models/invite';

interface SendEmailProps {
  emailToSend: string;
  eventId: string;
}

export default function SendEmail({ emailToSend, eventId }: SendEmailProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [isInviting, setIsInviting] = useState(false);
  const dispatch = useAppDispatch();
  const { get: reloadParticipants } = useLoadParticipants();

  useEffect(() => {
    return () => {
      setIsInviting(false);
    };
  }, []);

  const handleSendEmail = async () => {
    setIsInviting(true);

    await http.invites
      .invite({
        emails: [emailToSend],
        event: eventId,
      })
      .then((res) => {
        dispatch(setSelected([]));
        enqueueSnackbar(res.message, { variant: 'success' });
        dispatch(setActualFilter(InviteFilters.All));
        reloadParticipants();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        setIsInviting(false);
      });
  };

  return (
    <Tooltip title="Send email invite">
      <Button
        onClick={handleSendEmail}
        startIcon={<SendIcon fontSize="small" />}
        disabled={isInviting}
      >
        {!isInviting ? 'Send' : 'Sending...'}
      </Button>
    </Tooltip>
  );
}
