export const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  createBtn: {
    alignSelf: 'end',
    marginBottom: 4,
  },
};
