import { CreateAvatarSubmitProps } from 'pages/admin/AvatarPage/hooks/useAdminAvatars';
import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import { Avatar } from 'shared/types/models/avatar';
import axiosWithUserToken from './axiosInstances/axiosWithUserToken';
import { HttpResponseDefault } from './types/httpGeneric';

const avatars = {
  async create(payload: CreateAvatarSubmitProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().post(`/cms/avatars/create`, {
        event: payload.event,
        name: payload.name,
        isDefault: false,
        gender: payload.gender,
      });
      return { message: 'Avatar Created.', data: data.skin };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async list(): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get('/cms/avatars/list/');
      return { message: 'Loaded', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async listByEvent({
    eventId,
  }: {
    eventId: string;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/skins/list/avatarsByEvent`,
        {
          params: {
            eventId: eventId,
            orderBy: 'avatarName',
          },
        }
      );
      return { message: 'Loaded', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async getById({
    avatarId,
  }: {
    avatarId: string;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/avatars/get/${avatarId}`
      );
      return { message: 'Loaded', data: data.avatar };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async setDefault({
    avatarId,
    gender,
  }: {
    avatarId: string;
    gender: string;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().patch(
        `/cms/avatars/setDefault`,
        {
          avatar: avatarId,
          gender: gender,
        }
      );
      return { message: 'Loaded', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async listAvailables({
    eventId,
  }: {
    eventId: string;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/avatars/list-availables/${eventId}`
      );
      return { message: 'Loaded', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async remove({
    avatarId,
  }: {
    avatarId: string;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().delete(
        `/cms/avatars/delete/${avatarId}`
      );
      return { message: 'Loaded', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async listSkinsByAvatar({
    avatarId,
  }: {
    avatarId: string;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/avatars/skins/${avatarId}`
      );
      return { message: 'Loaded', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async update(payload: Avatar): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().put(
        `/cms/avatars/update/${payload.id}`,
        {
          event: payload.event,
          name: payload.name,
          isDefault: false,
          gender: payload.gender,
        }
      );
      return { message: 'Avatar Updated.', data: data.skin };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default avatars;
