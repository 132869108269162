import {
  Avatar,
  Button,
  Checkbox,
  Stack,
  TableCell,
  TableRow,
} from '@mui/material';
import { appPaths } from 'app/router/appPaths';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { setActualAvatar } from 'redux/slices/avatarsSlice';
import { selectEvents } from 'redux/slices/eventsSlice';
import { http } from 'services/http';
import { RemoveButtonWithDialog } from 'shared/components/buttons/RemoveButtonWithDialog';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { Avatar as AvatarModel } from 'shared/types/models/avatar';
import { useAvatars } from '../hooks/useAvatars';
import { isDeveloperRole } from 'shared/utils/verifyRole';
import { selectUser } from 'redux/slices/userSlice';

export const AvatarTableRow = ({ row }: { row: AvatarModel }) => {
  const { user } = useAppSelector(selectUser);
  const urlParams = useParams();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { event } = useAppSelector(selectEvents);
  const navigate = useNavigate();
  const { loadAvatars } = useAvatars();

  const handleDeleteAvatar = async () => {
    await http.skins
      .removeAllSkinsByAvatar({
        avatarId: row.idAvatar as string,
        eventId: urlParams.id as string,
      })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }

        if (urlParams && urlParams?.id) {
          loadAvatars({ eventId: urlParams.id });
        } else {
          enqueueSnackbar('Error: Event ID is missing or undefined', {
            variant: 'error',
          });
        }

        enqueueSnackbar('Avatar deleted', { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleGoToSkins = (avatar: AvatarModel) => {
    dispatch(setActualAvatar(avatar));
    navigate(
      appPaths.private.skins.dashboard
        .replace(':id', String(event.id))
        .replace(':avatarId', String(avatar.idAvatar))
    );
  };

  const handleSetAvatarEventDefault = async (row: AvatarModel) => {
    await http.events
      .setEventDefault({
        avatar: row.idAvatar as string,
        event: urlParams.id as string,
      })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }

        enqueueSnackbar('Updated', { variant: 'success' });

        if (urlParams && urlParams?.id) {
          loadAvatars({ eventId: urlParams.id });
        } else {
          enqueueSnackbar('Error: Event ID is missing or undefined', {
            variant: 'error',
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  return (
    <TableRow>
      <TableCell align="left">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar alt={row.avatarName} src={row.thumbnail} />
          <h4>
            {row.avatarName}
            {user?.data?.role && isDeveloperRole(user.data.role) && (
              <>
                ID: {row.id} | ID Avatar: {row.idAvatar}
              </>
            )}
          </h4>
        </Stack>
      </TableCell>
      <TableCell align="right">
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Button
            variant="outlined"
            onClick={() => {
              handleGoToSkins(row);
            }}
          >
            Configure Skins
          </Button>
          <Checkbox
            color="primary"
            checked={row.isEventDefault}
            onClick={() => handleSetAvatarEventDefault(row)}
            inputProps={{
              'aria-labelledby': 'labelId',
            }}
          />
          Event Default
          <RemoveButtonWithDialog
            title="Remove Avatar"
            message="Are you sure you want to remove this avatar from your event?"
            deleteCallback={handleDeleteAvatar}
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
};
