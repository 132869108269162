import { Button, Step, StepLabel, Stepper } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AddAvatarModal } from './AddAvatarModal';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAvatars } from '../hooks/useAvatars';
import {
  selectAvatars,
  setAvailableAvatarsList,
} from 'redux/slices/avatarsSlice';
import { useAppSelector } from 'shared/hooks/useStore';
import { useSnackbar } from 'notistack';
import { http } from 'services/http';
import { AvailableAvatarsList } from './AddAvatar/AvailableAvatarsList';
import { AvailableSkinsModelList } from './AddAvatar/AvailableSkinsModelList';
import LoadingProgress from '../../../components/LoadingProgress';

export interface SelectedAvatarsProps {
  avatarId: string;
  selectedSkinsModel: string[];
}
export const AddAvatar = () => {
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isAvailableAvatarsLoading, availableAvatars } =
    useAppSelector(selectAvatars);
  const { loadAvailableAvatars } = useAvatars();
  const steps = ['Select an avatar', 'Select avatar skins'];
  const [actualStep, setActualStep] = useState(0);
  const { loadAvatars } = useAvatars();
  const [selectedAvatar, setSelectedAvatar] = useState<SelectedAvatarsProps>({
    avatarId: '',
    selectedSkinsModel: [],
  });

  const handleOpenModal = () => {
    setOpen(true);
    loadAvailableAvatars({ eventId: id as string });
  };

  const handleCloseModal = () => {
    setOpen(false);
    dispatch(setAvailableAvatarsList([]));
    setActualStep(0);
    setSelectedAvatar({ avatarId: '', selectedSkinsModel: [] });
  };

  const handleSaveModal = async () => {
    // Create avatar without skins
    await http.skins
      .create({
        event: id as string,
        idAvatar: selectedAvatar.avatarId,
      })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });

    // Create skins of avatar
    for (const skinId of selectedAvatar.selectedSkinsModel) {
      await http.skins
        .create({
          event: id as string,
          idAvatar: selectedAvatar.avatarId,
          idSkin: skinId,
        })
        .then((res) => {
          if (res.error) {
            enqueueSnackbar(res.message, { variant: 'error' });
            return;
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        });
    }

    enqueueSnackbar('Avatar added', { variant: 'success' });
    loadAvatars({ eventId: id as string });
    dispatch(setAvailableAvatarsList([]));
    setActualStep(0);
    setSelectedAvatar({ avatarId: '', selectedSkinsModel: [] });
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleOpenModal}
        startIcon={<AddCircleIcon fontSize="small" />}
      >
        Add Avatars
      </Button>

      <AddAvatarModal
        open={open}
        onConfirm={handleSaveModal}
        onCancel={handleCloseModal}
        title="Available Skins"
        actualStep={actualStep}
        onGoBack={() => setActualStep(actualStep - 1)}
      >
        {isAvailableAvatarsLoading ? (
          <LoadingProgress />
        ) : availableAvatars.length > 0 ? (
          <>
            <Stepper
              activeStep={actualStep}
              alternativeLabel
              style={{ marginBottom: '20px' }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {actualStep === 0 ? (
              <AvailableAvatarsList
                availableAvatars={availableAvatars}
                setActualStep={setActualStep}
                setSelectedAvatar={setSelectedAvatar}
                selectedAvatar={selectedAvatar}
              />
            ) : (
              <AvailableSkinsModelList
                setSelectedAvatar={setSelectedAvatar}
                selectedAvatar={selectedAvatar}
              />
            )}
          </>
        ) : (
          <p>There&apos;s no available avatars to add</p>
        )}
      </AddAvatarModal>
    </>
  );
};
