import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { appPaths } from './appPaths';
import { oktaConfigs } from 'configs/oktaConfigs';

export const oktaAuth = new OktaAuth(oktaConfigs);

const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
  window.location.replace(
    toRelativeUrl(
      originalUri || appPaths.public.account.oktaRedirect,
      window.location.origin
    )
  );
};

export const AppRouter = () => {
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </Security>
  );
};
