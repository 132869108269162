import axios from 'axios';
import { spatialManager } from 'configs/apiConfigs';
import { getToken } from 'shared/utils/tokenManager';
import { TOKEN_AUTH_NAME } from 'configs/authConfigs';

export interface IStreamPanel {
  eventId: string;
  screenName: string;
  millicastStreamName: string;
  millicastSubscribeToken: string;
  millicastAccountId: string;
  regionName: string;
  roomId: string;
  owner: string;
}

export default class StreamPanelsService {
  static updateStreamPanelConfig({
    eventId,
    screenName,
    millicastAccountId,
    millicastStreamName,
    millicastSubscribeToken,
    regionName,
    roomId,
    owner,
  }: IStreamPanel): Promise<StreamPanelsService> {
    return axios
      .patch(
        `${spatialManager}api/v1/events/${eventId}/screens/${screenName}`,
        {
          millicastAccountId,
          millicastStreamName,
          millicastSubscribeToken,
          regionName,
          roomId,
          owner,
        },
        {
          headers: {
            Authorization: getToken(TOKEN_AUTH_NAME) || '',
          },
        }
      )
      .then((res) => res.data);
  }

  static getStreamPanelConfig(
    eventId: string,
    roomId: string
  ): Promise<IStreamPanel[]> {
    return axios
      .get(`${spatialManager}api/v1/events/${eventId}/screens/`, {
        headers: {
          Authorization: getToken(TOKEN_AUTH_NAME) || '',
        },
        params: {
          roomId,
        },
      })
      .then((res) => res.data);
  }

  static deleteStreamPanelConfig(
    eventId: string,
    screenName: string,
    roomId: string
  ): Promise<IStreamPanel[]> {
    return axios
      .delete(
        `${spatialManager}api/v1/events/${eventId}/screens/${screenName}/rooms/${roomId}`,
        {
          headers: {
            Authorization: getToken(TOKEN_AUTH_NAME) || '',
          },
        }
      )
      .then((res) => res.data);
  }
}
