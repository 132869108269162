import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import axiosWithUserToken from './axiosInstances/axiosWithUserToken';
import { HttpResponseDefault } from './types/httpGeneric';
import { CreateTicketsProps, ListTicketsProps } from './types/tickets';

const tickets = {
  async list({ eventId }: ListTicketsProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/tickets/list/event/${eventId}`
      );
      return { message: 'Loaded', data: data.tickets };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async create(payload: CreateTicketsProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().post(
        '/cms/tickets/create',
        payload
      );
      return { message: 'Ticket Created', data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default tickets;
