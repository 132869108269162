import {
  CircularProgress,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import { BoxDialog } from 'shared/components/modals/BoxDialogModal/styled';
import {
  ButtonCancelMedia,
  ButtonReload,
  ButtonTryAgain,
  ButtonUploadMedia,
  CancelIconCustom,
  GridDropZone,
  IconButtonExport,
  LinearProgressUploading,
} from '../styled';
import { Stack } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { useEffect, useState } from 'react';
import { useCSVReader } from 'react-papaparse';
import { useSnackbar } from 'notistack';
import { chunkArray } from 'shared/utils/chunkArray';
import { http } from 'services/http';
import UploadIcon from '@mui/icons-material/Upload';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import { CreateSpotProps } from 'services/http/types/spot';
import { Spot } from 'shared/types/models/spot';
import {
  clearRoomSpotsToImport,
  RoomSpot,
  selectRooms,
  setRoomSpotsToImport,
} from 'redux/slices/roomsSlice';
import { useLoadRoomDetails } from 'shared/hooks/useLoadRoomDetails';

interface AddProps {
  open: boolean;
  mapId: string;
  handleClose: () => void;
}

export default function ImportSpotsModal({
  open,
  mapId,
  handleClose,
}: AddProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const { CSVReader } = useCSVReader();
  const [importProgress, setImportProgress] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { roomSpotsToImport } = useAppSelector(selectRooms);
  const { loadRoomDetails } = useLoadRoomDetails();

  const extractSpotsFromCSVReader = (spots: CreateSpotProps[]) => {
    if (spots.length == 0) {
      setHasError(true);
      enqueueSnackbar('The file must have a row to insert', {
        variant: 'error',
      });
      return;
    }

    setHasError(false);
    setIsSubmitting(true);
    setImportProgress(20);

    const isValid = (spot: CreateSpotProps): boolean => {
      const requiredFields = [
        'mediaType',
        'user',
        'createdAt',
        'asset',
        'displayName',
        'isActive',
        'mapId',
        'image',
        'updatedAt',
        'isDefault',
        'id',
        'spotModelId',
        'ueIdentifier',
      ];

      const populatedFields = requiredFields.filter(
        (field: string) => spot[field]
      );

      return populatedFields.length === requiredFields.length;
    };

    const mapIdFrom =
      spots && spots.length > 0 && spots[0].mapId ? spots[0].mapId : null;

    const spotsToRequest = spots.map((spot: CreateSpotProps, index: number) => {
      if (mapIdFrom !== spot.mapId) {
        setHasError(true);
        enqueueSnackbar(`Spot ${index} Map Id is not the same`, {
          variant: 'warning',
        });
        return;
      }

      if (!isValid(spot)) {
        enqueueSnackbar(`Spot ${index} is missing required data`, {
          variant: 'warning',
        });
        return;
      }

      spot.isActive = spot.isActive && spot.isActive === 'false' ? false : true;
      spot.isDefault =
        spot.isDefault && spot.isDefault === 'false' ? false : true;

      return spot;
    });

    const activeSpotsToRequest = spotsToRequest.filter((spot: any) => spot);

    if (activeSpotsToRequest.length === 0) {
      setHasError(true);
      enqueueSnackbar('No Spots have been imported', { variant: 'warning' });
      return;
    }

    dispatch(setRoomSpotsToImport(activeSpotsToRequest));
  };

  const handleImportSpots = async () => {
    // The api works better with 20 roomSpotsToImport at time
    const NUMBER_OF_POIs_LIMIT = 20;

    if (roomSpotsToImport.length <= NUMBER_OF_POIs_LIMIT) {
      submit(roomSpotsToImport);
      return;
    }

    // to large email lists, need to split this list into smaller ones
    const newList = chunkArray(roomSpotsToImport, NUMBER_OF_POIs_LIMIT);

    const submitingStepped = await submitStepped(newList);

    if (submitingStepped?.success) {
      dispatch(clearRoomSpotsToImport());

      setTimeout(() => {
        enqueueSnackbar(`${roomSpotsToImport.length} roomSpotsToImport saved`, {
          variant: 'success',
        });
      }, 1000);

      setHasSuccess(true);
    } else if (!submitingStepped?.success) {
      dispatch(clearRoomSpotsToImport());

      setTimeout(() => {
        enqueueSnackbar(
          `${roomSpotsToImport.length} roomSpotsToImport not saved ${
            submitingStepped?.message
              ? '[' + submitingStepped.message + ']'
              : ''
          }`,
          {
            variant: 'error',
          }
        );
      }, 1000);

      setHasError(true);
    }

    setIsSubmitting(false);
  };

  const submitStepped = async (
    spots: (Spot[] | string[])[]
  ): Promise<{ success: boolean; message: string | undefined } | undefined> => {
    let success;

    let progress = importProgress;
    const progressPlus = 80 / spots.length;

    for (const item of spots) {
      const spotsObj: CreateSpotProps[] = Object.assign(item);

      const mapIdFrom =
        spotsObj && spotsObj.length > 0 ? spotsObj[0].mapId : '';

      await http.spots
        .upload({
          roomMapIdFrom: mapIdFrom,
          roomMapIdTo: mapId,
          spots: spotsObj,
        })
        .then((res) => {
          if (res.error) {
            success = { success: false, message: res.message };
          } else {
            success = { success: true };
          }
        })
        .catch((error) => {
          success = { success: false, message: error.message };
        });

      progress = progress + progressPlus;
      setImportProgress(progress);
    }

    setImportProgress(100);

    return success;
  };

  const submit = async (roomSpotsToImport: RoomSpot[]) => {
    const spots: CreateSpotProps[] = Object.assign(roomSpotsToImport);

    const mapIdFrom = spots && spots.length > 0 ? spots[0].mapId : '';

    setImportProgress(50);

    await http.spots
      .upload({
        roomMapIdFrom: mapIdFrom,
        roomMapIdTo: mapId,
        spots: spots,
      })
      .then((res) => {
        if (res.error) {
          setHasError(true);
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }

        dispatch(clearRoomSpotsToImport());
        setHasSuccess(true);
        enqueueSnackbar(res.message, { variant: 'success' });
      })
      .catch((error) => {
        setHasError(true);
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setIsSubmitting(false);
        setImportProgress(100);
      });
  };

  useEffect(() => {
    if (roomSpotsToImport.length > 0) {
      handleImportSpots();
    }
  }, [roomSpotsToImport]);

  useEffect(() => {
    dispatch(clearRoomSpotsToImport());
    setIsSubmitting(false);
    setImportProgress(0);
    setHasError(false);
    setHasSuccess(false);
  }, [open]);

  return (
    <BoxDialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="add-point-dialog-title"
      scroll="body"
      maxWidth="md"
    >
      <CSVReader
        onUploadAccepted={(results: any) => {
          extractSpotsFromCSVReader(results.data);
        }}
        config={{ header: true }}
      >
        {({ getRootProps, acceptedFile }: any) => (
          <>
            <DialogContent dividers style={{ minWidth: '450px' }}>
              <Grid container direction="column">
                <Grid
                  xs={12}
                  lg={12}
                  md={12}
                  justifyContent="center"
                  display="flex"
                  marginTop="20px"
                  item
                >
                  <IconButtonExport
                    color="primary"
                    aria-label="save"
                    step={
                      hasSuccess ? 'success' : hasError ? 'error' : 'normal'
                    }
                  >
                    {!hasSuccess && !hasError && <UploadIcon />}
                    {hasSuccess && <DoneIcon color="success" />}
                    {hasError && <CloseIcon />}
                  </IconButtonExport>
                </Grid>

                <Grid
                  xs={12}
                  lg={12}
                  md={12}
                  justifyContent="center"
                  display="flex"
                  item
                >
                  <Stack
                    justifyContent="center"
                    display="flex"
                    textAlign="center"
                    marginLeft={5}
                    marginRight={5}
                  >
                    <Typography
                      variant="h4"
                      component="span"
                      marginTop="20px"
                      marginBottom="20px"
                    >
                      {!hasSuccess &&
                        !hasError &&
                        'Upload Room Media From .csv '}
                      {hasSuccess && 'Upload Completed'}
                      {hasError && 'Upload Failed'}
                    </Typography>

                    <Typography variant="body1" component="p">
                      {!hasSuccess &&
                        !hasError &&
                        'Uploading a media CSV from your files will overwrite'}
                      {hasSuccess &&
                        'Your new media .csv file was successfully uploaded.'}
                      {hasError &&
                        'Your new media .csv file upload has failed. '}
                    </Typography>
                    <Typography variant="body1" component="p">
                      {!hasSuccess &&
                        !hasError &&
                        'all your current media assets (images and videos).        '}
                      {hasSuccess &&
                        'Please refresh the page to see the updates .'}
                      {hasError &&
                        'Please make sure you’re uploading the correct file format'}
                    </Typography>
                    <Typography variant="body1" component="p">
                      {hasError &&
                        'and your internet connection is working well.'}
                    </Typography>

                    {!acceptedFile && !hasError && !hasSuccess && (
                      <GridDropZone xs={12} lg={12} item>
                        <Typography variant="body1" component="p">
                          No CSV file selected yet
                        </Typography>
                      </GridDropZone>
                    )}

                    {acceptedFile && !hasError && !hasSuccess && (
                      <Grid
                        xs={12}
                        lg={12}
                        marginTop="20px"
                        marginBottom="20px"
                        item
                      >
                        <LinearProgressUploading
                          variant="determinate"
                          value={importProgress}
                          content={
                            acceptedFile
                              ? '...' +
                                acceptedFile.name.substring(
                                  acceptedFile.name.length - 25,
                                  acceptedFile.name.length
                                )
                              : 'No CSV file selected yet'
                          }
                        />
                      </Grid>
                    )}
                  </Stack>
                </Grid>

                <Grid
                  xs={12}
                  lg={12}
                  md={12}
                  justifyContent="center"
                  display="flex"
                  marginTop="20px"
                  marginBottom="20px"
                  item
                >
                  {!hasSuccess && !hasError && (
                    <>
                      <ButtonUploadMedia
                        {...getRootProps()}
                        startIcon={
                          !isSubmitting ? (
                            <UploadIcon fontSize="small" />
                          ) : (
                            <CircularProgress size="20px" />
                          )
                        }
                        color="success"
                        variant="contained"
                        disabled={isSubmitting}
                      >
                        {!isSubmitting ? 'Browse My Files...' : 'Uploading'}
                      </ButtonUploadMedia>
                      <ButtonCancelMedia
                        type="button"
                        startIcon={
                          <CancelIconCustom
                            fontSize="small"
                            className="cancelIcon"
                          />
                        }
                        onClick={() => {
                          handleClose();
                          setImportProgress(0);
                        }}
                        color="info"
                        variant="contained"
                      >
                        Cancel
                      </ButtonCancelMedia>
                    </>
                  )}

                  {hasSuccess && (
                    <>
                      <ButtonReload
                        startIcon={
                          <ReplayIcon className="replayIcon" fontSize="small" />
                        }
                        color="success"
                        variant="contained"
                        onClick={() => {
                          loadRoomDetails();
                          handleClose();
                          setIsSubmitting(false);
                        }}
                      >
                        Refresh Page
                      </ButtonReload>
                    </>
                  )}
                  {hasError && (
                    <>
                      <ButtonTryAgain
                        startIcon={
                          <ReplayIcon className="replayIcon" fontSize="small" />
                        }
                        color="success"
                        variant="contained"
                        onClick={() => {
                          setHasError(false);
                          setHasSuccess(false);
                          setImportProgress(0);
                          setIsSubmitting(false);
                        }}
                      >
                        Try Again
                      </ButtonTryAgain>
                    </>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
      </CSVReader>
    </BoxDialog>
  );
}
