type OBJ = {
  x: number;
  y: number;
  z: number;
  w?: number;
};

export const fromStringToPositionObject = (position: string) => {
  if (typeof position != 'string') return false;

  let arr;

  try {
    arr = JSON.parse(position);
  } catch (err) {
    return false;
  }

  // Check if converted are numbers
  const hasNaN = arr.some(
    (item: any) => isNaN(Number(item)) && parseFloat(item)
  );
  if (hasNaN) return false;

  const converted = arr.map((item: any) => Number(item));

  const obj: OBJ = {
    x: converted[0],
    y: converted[1],
    z: converted[2],
    w: converted[3],
  };

  return obj;
};

export const fromPositionObjectToString = (positionObject: OBJ) => {
  if (typeof positionObject != 'object') return false;

  let str;

  try {
    const arr = Object.values(positionObject);
    str = JSON.stringify(arr);
  } catch (err) {
    return false;
  }

  return str;
};
