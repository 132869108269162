import { env } from '../env';

if (!env.REACT_APP_AUTH_TOKEN) {
  throw new Error('Config REACT_APP_AUTH_TOKEN is missing');
}

if (!env.REACT_APP_AUTH_ID) {
  throw new Error('Config REACT_APP_AUTH_ID is missing');
}

if (!env.REACT_APP_AUTH_ROLE) {
  throw new Error('Config REACT_APP_AUTH_ROLE is missing');
}

export const TOKEN_AUTH_NAME = env.REACT_APP_AUTH_TOKEN;
export const ID_AUTH_NAME = env.REACT_APP_AUTH_ID;
export const ROLE_NAME = env.REACT_APP_AUTH_ROLE;
