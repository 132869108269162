import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import {
  selectSkinsModel,
  setActualSkinModel,
  setCreatingSkinModel,
  setIsSkinsModelsLoading,
  setSkinsModelsList,
} from 'redux/slices/admin/skinsModelSlice';
import { http } from 'services/http';
import { useAppSelector } from 'shared/hooks/useStore';

export interface CreateSkinSubmit {
  name: string;
  gender?: string;
  type?: string;
  avatar3DLink?: string;
  json?: string;
  rougness?: number;
  metalic?: number;
  specular?: number;
  albedo?: number;
  emissive?: number;
  avatar?: string;
  event?: string;
}

export const useSkinsModel = () => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const { skinsModels } = useAppSelector(selectSkinsModel);

  const loadSkins = async () => {
    if (skinsModels.length > 0) return;

    reloadSkins();
  };

  const reloadSkins = async () => {
    const res = await http.skinsModel.list();

    if (res.error) {
      enqueueSnackbar(res.message, { variant: 'error' });
      return;
    }

    dispatch(setSkinsModelsList(res.data));
  };

  const get = async (skinId: string) => {
    const res = await http.skinsModel.getById({
      skinId,
    });

    if (res.error) {
      enqueueSnackbar(res.message, { variant: 'error' });
      return;
    }

    dispatch(setActualSkinModel(res.data));
  };

  const create = async (payload: CreateSkinSubmit) => {
    dispatch(setCreatingSkinModel(true));

    const res = await http.skinsModel.create(payload);

    dispatch(setCreatingSkinModel(false));

    if (res.error) {
      enqueueSnackbar(res.message, { variant: 'error' });
      return;
    }

    enqueueSnackbar(res.message, { variant: 'success' });
  };

  const remove = async (id: string) => {
    const res = await http.skinsModel.remove({ skinId: id });

    if (res.error) {
      enqueueSnackbar(res.message, { variant: 'error' });
      return;
    }

    enqueueSnackbar(res.message, { variant: 'success' });
  };

  const loadSkinsModelsAvailableByAvatar = async ({
    avatarId,
    eventId,
  }: {
    avatarId: string;
    eventId: string;
  }) => {
    dispatch(setSkinsModelsList([]));
    dispatch(setIsSkinsModelsLoading(true));

    const res = await http.skinsModel.loadSkinsModelsAvailableByAvatar({
      avatarId: avatarId,
      eventId: eventId,
    });

    if (res.error) {
      enqueueSnackbar(res.message, { variant: 'error' });
      return;
    }

    dispatch(setSkinsModelsList(res.data));
    dispatch(setIsSkinsModelsLoading(false));
  };

  const listByAvatarId = async (avatarId: string) => {
    if (skinsModels.length > 0) return;

    reloadListByAvatarId(avatarId);
  };

  const reloadListByAvatarId = async (avatarId: string) => {
    dispatch(setIsSkinsModelsLoading(true));

    const res = await http.skinsModel.listByAvatar({ avatarId: avatarId });

    if (res.error) {
      enqueueSnackbar(res.message, { variant: 'error' });
      return;
    }

    dispatch(setSkinsModelsList(res.data));
    dispatch(setIsSkinsModelsLoading(false));
  };

  return {
    loadSkins,
    reloadSkins,
    get,
    create,
    remove,
    loadSkinsModelsAvailableByAvatar,
    listByAvatarId,
    reloadListByAvatarId,
  };
};
