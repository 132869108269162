import { Box, Stack, Checkbox, Avatar, Card } from '@mui/material';
import { SelectedAvatarsProps } from '../AddAvatar';
import { SkinModel } from 'shared/types/models/skinModel';
import { useAppSelector } from 'shared/hooks/useStore';
import { selectSkinsModel } from 'redux/slices/admin/skinsModelSlice';
import React, { useState } from 'react';
import LoadingProgress from '../../../../components/LoadingProgress';

export const AvailableSkinsModelList = ({
  setSelectedAvatar,
  selectedAvatar,
}: {
  setSelectedAvatar: React.Dispatch<React.SetStateAction<SelectedAvatarsProps>>;
  selectedAvatar: SelectedAvatarsProps;
}) => {
  const { isSkinsModelsLoading, skinsModels } =
    useAppSelector(selectSkinsModel);
  const [isCheckAll, setIsCheckAll] = useState(false);

  const handleSelectSkin = (
    event: React.ChangeEvent<HTMLInputElement>,
    skinId: string
  ) => {
    setIsCheckAll(false);
    if (event.target.checked) {
      setSelectedAvatar({
        ...selectedAvatar,
        selectedSkinsModel: [...selectedAvatar.selectedSkinsModel, skinId],
      });
    } else {
      if (selectedAvatar.selectedSkinsModel.includes(skinId)) {
        setSelectedAvatar({
          ...selectedAvatar,
          selectedSkinsModel: selectedAvatar.selectedSkinsModel.filter(
            (skin) => skin !== skinId
          ),
        });
      }
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setIsCheckAll(true);
      const selectedSkinsModels = skinsModels.map(
        (skinModel) => skinModel.id as string
      );
      setSelectedAvatar({
        ...selectedAvatar,
        selectedSkinsModel: selectedSkinsModels,
      });
    } else {
      setIsCheckAll(false);
      setSelectedAvatar({
        ...selectedAvatar,
        selectedSkinsModel: [],
      });
    }
  };

  return (
    <Box>
      {isSkinsModelsLoading ? (
        <LoadingProgress />
      ) : (
        <Stack spacing={2}>
          {skinsModels.map((skinModel: SkinModel) => {
            return (
              <Card key={skinModel.id}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  style={{ padding: '10px' }}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar
                      alt={skinModel.name}
                      src={skinModel.previewImage || skinModel.thumbnail || ''}
                      sx={{ width: 45, height: 45 }}
                    />

                    <h4>{skinModel.name}</h4>
                  </Stack>
                  <Checkbox
                    checked={selectedAvatar.selectedSkinsModel.includes(
                      skinModel.id as string
                    )}
                    onChange={(event) =>
                      handleSelectSkin(event, skinModel.id as string)
                    }
                  />
                </Stack>
              </Card>
            );
          })}
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Checkbox
              onChange={handleSelectAllClick}
              checked={
                isCheckAll ||
                selectedAvatar.selectedSkinsModel.length === skinsModels.length
              }
            />
            {selectedAvatar.selectedSkinsModel.length} / {skinsModels.length}{' '}
            selected <i>(Check to select all)</i>
          </Stack>
        </Stack>
      )}
    </Box>
  );
};
