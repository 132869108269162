import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

interface DefaultPageHeaderProps {
  titleText: string;
  subTitleText?: string;
  linkTo?: string;
  linkToText?: string;
}

export const DefaultPageHeader = ({
  linkTo,
  titleText,
  linkToText,
  subTitleText,
}: DefaultPageHeaderProps) => {
  return (
    <>
      <h2 style={{ textAlign: 'center' }}>{titleText}</h2>

      {subTitleText && <p style={{ textAlign: 'center' }}>{subTitleText}</p>}

      {linkTo && (
        <p>
          <Link to={linkTo} style={{ display: 'flex' }}>
            <ArrowBackIosIcon fontSize="small" /> {linkToText ?? 'Back'}
          </Link>
        </p>
      )}
    </>
  );
};
