import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useState } from 'react';
import useInstanceManager from 'services/http/useInstanceManager';
import { DestroySessionsInfo } from './DestroySessionsInfo';

interface Props {
  data: {
    eventId: string;
    token: string;
    alias: string;
    regionName: string;
    onlyDestroy: boolean;
  };
  roomsLength: number;
  open: boolean;
  onCancel: () => void;
  onRemoved: () => void;
}

export const DestroySessionsModal = ({
  data,
  open,
  roomsLength,
  onCancel,
  onRemoved,
}: Props) => {
  const [message, setMessage] = useState<string>('');
  const [recreatedSessionData, setRecreatedSessionData] = useState<any[]>([]);
  const [freeSpace, setFreeSpace] = useState(0);
  const [error, setError] = useState(false);
  const [removingEvent, setRemovingEvent] = useState(false);
  const { deleteSession } = useInstanceManager();

  const [alertMessage, setAlertMessage] = useState(
    'Are you sure to remove this event? This action can´t be undone.'
  );

  const clearStatus = () => {
    setMessage('');
    setRecreatedSessionData([]);
    setFreeSpace(0);
    setError(false);
    setRemovingEvent(false);
  };

  const destroySession = async () => {
    setRemovingEvent(true);
    setAlertMessage('Destroying session...');
    await deleteSession({
      data,
      onCancel,
      onRemoved,
      roomsLength,
      removeEvent: true,
    });
    setRemovingEvent(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => true} // disable close dialog when background click or escape from esc key
      aria-labelledby="recreate-sessions-title"
      aria-describedby="recreate-sessions-description"
      fullWidth
    >
      <DialogTitle id="recreate-sessions-title">Remove Event</DialogTitle>
      <DialogContent dividers>
        <Alert severity="warning">{alertMessage}</Alert>
        <DestroySessionsInfo
          data={data}
          error={error}
          freeSpace={freeSpace}
          message={message}
          roomsLength={roomsLength}
          recreatedSessionData={recreatedSessionData}
        />

        {removingEvent && (
          <Box
            sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={removingEvent}
          onClick={() => {
            clearStatus();
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={destroySession}
          disabled={removingEvent}
          autoFocus
          startIcon={<DeleteForeverIcon />}
          variant="contained"
          color="error"
        >
          {!removingEvent ? 'Remove' : 'Wait...'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
