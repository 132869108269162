import { FC, ReactNode } from 'react';
import { env } from '../../env';
import { Header } from './Header';
import { DevTag, MainContent, MainWrapper } from './styled';

interface AppDefaultLayoutProps {
  children?: ReactNode;
}

export const AppDefaultLayout: FC<AppDefaultLayoutProps> = ({ children }) => {
  return (
    <MainWrapper>
      <Header />
      <MainContent>
        {env.REACT_APP_ENV !== 'production' && (
          <DevTag>Mode: {env.REACT_APP_ENV}</DevTag>
        )}
        {children}
      </MainContent>
    </MainWrapper>
  );
};
