import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  selectRooms,
  setActualSpot,
  setSendingMediaForm,
} from 'redux/slices/roomsSlice';
import { selectUser } from 'redux/slices/userSlice';
import { http } from 'services/http';
import BoxDialogModal, {
  BoxDialogModalHandles,
} from 'shared/components/modals/BoxDialogModal';
import CustomizedSteppers from 'shared/components/steppers/CustomizedSteppers';
import useAccess from 'shared/hooks/useAccess';
import { useLoadRoomDetails } from 'shared/hooks/useLoadRoomDetails';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { useUploadAssetProccess } from 'shared/hooks/useUploadAssetProccess';
import { ModalHandles } from 'shared/types/modal';
import { getPoiAcceptCriteriaFile } from 'shared/utils/getAcceptCriteriaFile';
import { renameFileToUploadPattern } from 'shared/utils/renameFileToUploadPattern';
import { ResetSpot } from './ResetSpot';
import { Input } from './styled';
import { SpotDataWithAssetsProps } from './types';
import { PoiMediaType } from '../../../../shared/types/utils';

const UploadVideoModal: ForwardRefRenderFunction<ModalHandles> = (
  props,
  ref
) => {
  const { user } = useAppSelector(selectUser);
  const { actualSpot } = useAppSelector(selectRooms);
  const { getConnectToken } = useAccess();
  const { enqueueSnackbar } = useSnackbar();
  const { loadRoomDetails } = useLoadRoomDetails();

  const dispatch = useAppDispatch();
  const { uploadAssetProccess, uploadProgress } = useUploadAssetProccess();

  const [checked, setChecked] = useState<boolean>(false);
  const [asset, setAsset] = useState<any>();
  const [image, setImage] = useState<any>();

  const [mediaTypeSelect, setMediaTypeSelect] = useState<string>('video');
  const [urlPreview, setUrlPreview] = useState<string>('');

  const [uploadStep, setUploadStep] = useState(0); // step index to manage CustomizedSteppers component
  const [errorStep, setErrorStep] = useState<number | null>(null);

  const [inputUrl, setInputUrl] = useState<string>('');
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (!actualSpot?.asset?.includes('placeholder')) {
      setInputUrl(actualSpot.asset);
    }

    if (actualSpot?.isActive) {
      setChecked(actualSpot.isActive);
    }

    if (actualSpot.assetMediaType) {
      setMediaTypeSelect(actualSpot.assetMediaType);
    }

    return () => {
      setInputUrl('');
      setUrlPreview('');
      setImage(null);
      setAsset(null);
    };
  }, [actualSpot.isActive, actualSpot.asset, actualSpot.assetMediaType]);

  const boxDialogModalRef = useRef<BoxDialogModalHandles>(null);

  const handleOpen = () => {
    boxDialogModalRef.current?.handleOpen();
  };

  const handleClose = () => {
    boxDialogModalRef.current?.handleClose();
  };

  const handleCloseDialog = () => {
    dispatch(setActualSpot({}));
    dispatch(setSendingMediaForm(false));
    setIsUploading(false);
    setAsset(null);
    setMediaTypeSelect('video');
    setInputUrl('');
    setUploadStep(0);
    setErrorStep(null);
    setChecked(false);
  };

  const handleSubmitForm = async () => {
    setIsUploading(true);

    const spotID = actualSpot.id;

    let data: SpotDataWithAssetsProps = {
      user: user?.data.email as string,
      isActive: checked,
      isDefault: false,
      assetMediaType: mediaTypeSelect,
    };

    if (asset instanceof File) {
      const assetUrl = await uploadAssetProccess({
        asset: asset,
        foldername: 'videos',
        contentType: 'video/mp4',
      });

      if (!assetUrl) {
        return;
      }

      data = {
        ...data,
        asset: assetUrl,
      };
    }

    if (image instanceof File) {
      const assetUrl = await uploadAssetProccess({
        asset: image,
        foldername: 'images',
        contentType: 'image',
      });

      if (!assetUrl) {
        return;
      }

      data = {
        ...data,
        image: assetUrl,
      };
    }

    http.spots
      .updateFromFrontend({
        userToken: getConnectToken() as string,
        spotID,
        data,
      })
      .then((res: any) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        enqueueSnackbar(res.message, { variant: 'success' });
        loadRoomDetails();
        handleClose();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const handleChange = (event: SelectChangeEvent) => {
    setMediaTypeSelect(event.target.value as string);
  };

  const handleInputUrl = (event: ChangeEvent<HTMLInputElement>) => {
    if (mediaTypeSelect === 'stream') {
      setInputUrl(event.target.value);
      setAsset(event.target.value);
    }
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleUploadVideoInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    let file = event.target.files[0];
    const reader = new FileReader();

    file = renameFileToUploadPattern(file);

    reader.onloadend = () => {
      setAsset(file);
    };

    reader.readAsDataURL(file);

    event.target.value = '';
  };

  const handleUploadImageInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    let file = event.target.files[0];
    const reader = new FileReader();

    file = renameFileToUploadPattern(file);

    reader.onloadend = () => {
      setImage(file);
    };

    reader.readAsDataURL(file);

    event.target.value = '';
  };

  useImperativeHandle(ref, () => {
    return { handleOpen, handleClose };
  });

  useEffect(() => {
    let link;
    if (asset) {
      link = typeof asset === 'string' ? asset : URL.createObjectURL(asset);
    } else {
      link = actualSpot.asset as string;
    }

    setUrlPreview(link);
  }, [asset, actualSpot.asset]);

  return (
    <BoxDialogModal
      ref={boxDialogModalRef}
      title={
        'Update spot - ' +
        actualSpot?.displayName +
        ' [' +
        actualSpot?.mediaType +
        ']'
      }
      submitButton={
        <Button
          autoFocus
          onClick={handleSubmitForm}
          variant="contained"
          disabled={isUploading}
        >
          {!isUploading ? 'Save' : 'Saving...'}
        </Button>
      }
      onCloseDialog={handleCloseDialog}
    >
      {!isUploading ? (
        <Stack spacing={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheckboxChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Active"
            />
          </FormGroup>

          <FormControl fullWidth>
            <InputLabel id="media-select-label">Source</InputLabel>
            <Select
              labelId="media-select-label"
              id="media-select"
              value={mediaTypeSelect}
              label="Source"
              onChange={handleChange}
            >
              <MenuItem value={'video'}>Upload video</MenuItem>
              <MenuItem value={'stream'}>Url Stream</MenuItem>
            </Select>
          </FormControl>

          {mediaTypeSelect === 'video' && asset?.name && <p>{asset.name}</p>}

          <Stack>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {mediaTypeSelect === 'video' && (
                  <label htmlFor="icon-button-file">
                    <Input
                      accept={getPoiAcceptCriteriaFile(PoiMediaType.Video)}
                      id="icon-button-file"
                      type="file"
                      onChange={handleUploadVideoInput}
                    />
                    <Button
                      fullWidth
                      variant="outlined"
                      component="span"
                      startIcon={<FileUploadIcon />}
                      size="medium"
                    >
                      Upload Video
                    </Button>
                  </label>
                )}
                {mediaTypeSelect === 'stream' && (
                  <TextField
                    placeholder="Url here..."
                    value={inputUrl}
                    fullWidth
                    size="small"
                    onChange={handleInputUrl}
                    inputProps={{ 'aria-label': 'uncontrolled' }}
                  />
                )}

                {urlPreview && (
                  <Stack mt={2}>
                    <video
                      width="100%"
                      controls
                      key={urlPreview}
                      style={{ borderRadius: 4, overflow: 'hidden' }}
                    >
                      <source src={urlPreview} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>

                    <p>
                      <a href={urlPreview} target="_blank" rel="noreferrer">
                        link
                      </a>
                    </p>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={6}>
                <label htmlFor="icon-button-display-image">
                  <Input
                    accept="image/*"
                    id="icon-button-display-image"
                    type="file"
                    onChange={handleUploadImageInput}
                  />
                  <Button
                    fullWidth
                    variant="outlined"
                    component="span"
                    startIcon={<FileUploadIcon />}
                    size="medium"
                  >
                    Upload image preview
                  </Button>
                </label>

                <Stack mt={2}>
                  <img
                    src={image ? URL.createObjectURL(image) : actualSpot.image}
                    alt={`${actualSpot.displayName}`}
                    style={{ width: '100%' }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>

          {actualSpot?.assetMediaType && (
            <p>Custom media type: {actualSpot.assetMediaType}</p>
          )}

          {actualSpot.isDefault === false && (
            <ResetSpot
              actualSpot={actualSpot}
              onSuccess={() => handleClose()}
            />
          )}
        </Stack>
      ) : (
        <CustomizedSteppers
          activeStep={uploadStep}
          errorStep={errorStep}
          uploadProgress={uploadProgress}
        />
      )}
    </BoxDialogModal>
  );
};

export default forwardRef(UploadVideoModal);
