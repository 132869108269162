import { errorsMessage, firebaseConfig } from 'configs/firebaseConfigs';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithPopup,
  OAuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from 'firebase/auth';

export type ProviderType = 'facebook' | 'apple' | 'google' | 'microsoft';

interface FirebaseResponseProps {
  success: boolean;
  user?: {
    firstName: string;
    lastName: string;
    email: string;
    providerId: string | number;
    provider: ProviderType;
  };
  error?: {
    code: string;
    message: string;
  };
}

const firebase = {
  async signInWithProvider(provider: ProviderType) {
    initializeApp(firebaseConfig);
    const auth = getAuth();
    const authProvider = getAuthProvider(provider);
    const response: FirebaseResponseProps = {
      success: false,
    };

    if (!authProvider) return;
    await signInWithPopup(auth, authProvider)
      .then((result) => {
        if (result.user && result.user.providerData.length > 0) {
          const providerData = result.user.providerData[0];
          if (providerData) {
            const splitedNames = splitNames(
              result.user.displayName || (providerData.displayName as string)
            );
            response.user = {
              firstName: splitedNames.firstName,
              lastName: splitedNames.lastName,
              email: result.user.email || (providerData.email as string),
              provider: provider,
              providerId: providerData.uid,
            };
            response.success = true;
          }
        }
      })
      .catch(({ code, message }) => {
        response.error = {
          message: errorsMessage[code] || message,
          code: code,
        };
      });

    return response;
  },
};

const getAuthProvider = (providerName: string) => {
  switch (providerName) {
    case 'apple':
      {
        const appleProvider = new OAuthProvider('apple.com');
        appleProvider.addScope('email');
        appleProvider.addScope('name');
        return appleProvider;
      }
      break;
    case 'microsoft':
      {
        const microsoftProvider = new OAuthProvider('microsoft.com');
        microsoftProvider.addScope('email');
        return microsoftProvider;
      }
      break;
    case 'google':
      {
        const googleProvider = new GoogleAuthProvider();
        googleProvider.addScope('email');
        return googleProvider;
      }
      break;
    case 'facebook':
      {
        const facebookProvider = new FacebookAuthProvider();
        facebookProvider.addScope('email');
        return facebookProvider;
      }
      break;
  }
};

const splitNames = (fullName: string) => {
  const names: { firstName: string; lastName: string } = {
    firstName: '',
    lastName: '',
  };
  const [firstName, ...rest] = fullName.split(' ');
  names.firstName = firstName;
  if (rest.length > 0) {
    names.lastName = rest[rest.length - 1];
  } else {
    names.lastName = firstName;
  }
  return names;
};

export default firebase;
