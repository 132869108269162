import { useEffect } from 'react';
import { selectEvents } from 'redux/slices/eventsSlice';
import { useAppSelector } from 'shared/hooks/useStore';
import { useAvatars } from 'pages/avatars/hooks/useAvatars';
import { useParams } from 'react-router-dom';
import { AppDefaultLayout } from 'layouts/AppDefaultLayout';
import { DefaultPageHeader } from 'shared/components/headers/DefaultPageHeader';
import { Box, Divider, Grid, Stack } from '@mui/material';
import { appPaths } from 'app/router/appPaths';
import { selectAvatars } from 'redux/slices/avatarsSlice';
import { AvatarListContainer } from './AvatarListContainer';
import { AddAvatar } from './AddAvatar';
import LoadingProgress from '../../../components/LoadingProgress';

export const AvatarsDashboardPage = () => {
  const urlParams = useParams();
  const { event } = useAppSelector(selectEvents);
  const { avatarsByEvent, utils } = useAppSelector(selectAvatars);
  const { loadAvatars } = useAvatars();

  useEffect(() => {
    loadAvatars({ eventId: String(urlParams.id) });
    // eslint-disable-next-line
  }, []);

  return (
    <AppDefaultLayout>
      <DefaultPageHeader
        titleText={`${event.name} - Avatars`}
        subTitleText="Select your avatars"
        linkTo={`${appPaths.private.event.dashboard}/${urlParams.id}`}
        linkToText="Back to Event Dashboard"
      />

      <Divider />

      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        paddingBottom={4}
      >
        <AddAvatar />
      </Stack>

      <Grid container spacing={2}>
        {utils.isFetching ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <LoadingProgress />
          </Box>
        ) : (
          <>
            {avatarsByEvent.length > 0 ? (
              <AvatarListContainer avatars={avatarsByEvent} />
            ) : (
              <p>This event has no avatars.</p>
            )}
          </>
        )}
      </Grid>
    </AppDefaultLayout>
  );
};
