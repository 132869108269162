import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { DefaultSettings } from 'shared/types/models/defaultSettings';

interface InitialState {
  isDefaultSettingsLoading: boolean;
  defaultSettings: DefaultSettings;
}

const initialState: InitialState = {
  isDefaultSettingsLoading: false,
  defaultSettings: {},
};

export const defaultSettingsSlice = createSlice({
  name: 'defaultSettings',
  initialState,
  reducers: {
    setIsDefaultSettingsLoading: (state, { payload }) => {
      state.isDefaultSettingsLoading = payload;
    },
    setDefaultSettings: (state, { payload }) => {
      state.defaultSettings = payload;
    },
  },
});

export const selectDefaultSettings = (state: RootState) =>
  state.defaultSettingsData;

export const { setIsDefaultSettingsLoading, setDefaultSettings } =
  defaultSettingsSlice.actions;

export default defaultSettingsSlice.reducer;
