import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { BoxDialog } from 'shared/components/modals/BoxDialogModal/styled';
import StreamPanelsService, {
  IStreamPanel,
} from 'services/http/streamPanelsService';

const StreamPanelInfo = ({
  openModal,
  handleClose,
  currentStreamPanel,
  setScreensConfig,
}: {
  openModal: boolean;
  handleClose: () => void;
  currentStreamPanel: IStreamPanel;
  setScreensConfig: React.Dispatch<React.SetStateAction<IStreamPanel[]>>;
}) => {
  const {
    millicastAccountId,
    millicastStreamName,
    millicastSubscribeToken,
    regionName,
    screenName,
    eventId,
    roomId,
    owner,
  } = currentStreamPanel;

  const handleDelete = async () => {
    await StreamPanelsService.deleteStreamPanelConfig(
      eventId,
      screenName,
      roomId
    );

    const screens = await StreamPanelsService.getStreamPanelConfig(
      eventId,
      roomId
    );

    setScreensConfig(screens);
    handleClose();
  };

  return (
    <BoxDialog
      open={openModal}
      onClose={handleClose}
      scroll="body"
      maxWidth="md"
    >
      <Box
        sx={{
          paddingInline: 2,
          paddingBlock: 3,
          minWidth: 600,

          '& p': {
            fontSize: 16,
            mb: 1,
          },
        }}
      >
        <Typography component="p" textAlign="center" fontWeight="bold">
          Stream Panel Config
        </Typography>
        <Typography component="p">
          Millicast Account Id: {millicastAccountId}
        </Typography>
        <Typography component="p">
          Millicast Stream Name: {millicastStreamName}
        </Typography>
        <Typography component="p">
          Millicast Subscribe Token: {millicastSubscribeToken}
        </Typography>
        <Typography component="p">Region Name: {regionName}</Typography>
        <Typography component="p">Screen Name: {screenName} </Typography>
        <Typography component="p">Stream Owner: {owner} </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button onClick={handleDelete} variant="contained" color="error">
            Delete
          </Button>
        </Box>
      </Box>
    </BoxDialog>
  );
};

export default StreamPanelInfo;
