import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { IInitialGenericAccessLinkSliceState } from './interfaces';

const initialState: IInitialGenericAccessLinkSliceState = {
  isEventsLoading: false,
  events: [],
  isEventLoading: false,
  event: {},
  allEvents: [],
  utils: {
    isSendingCreateForm: false,
    isSendingUpdateForm: false,
  },
};

export const genericAccessLinkSlice = createSlice({
  name: 'GenericAccessLink',
  initialState,
  reducers: {
    setIsEventsLoading: (state, { payload }) => {
      state.isEventsLoading = payload;
    },
    setEvents: (state, { payload }) => {
      state.events = payload;
    },
    setIsEventLoading: (state, { payload }) => {
      state.isEventLoading = payload;
    },
    setEvent: (state, { payload }) => {
      state.event = payload;
    },
    setAllEventsList: (state, { payload }) => {
      state.allEvents = payload;
    },
    setSendingCreateForm: (state, { payload }) => {
      state.utils.isSendingCreateForm = payload;
    },
    setSendingUpdateForm: (state, { payload }) => {
      state.utils.isSendingUpdateForm = payload;
    },
  },
});

export const selectGenericAccessLink = (state: RootState) => state.eventsData;
export const {
  setIsEventsLoading,
  setEvents,
  setIsEventLoading,
  setEvent,
  setSendingCreateForm,
  setSendingUpdateForm,
  setAllEventsList,
} = genericAccessLinkSlice.actions;

export default genericAccessLinkSlice.reducer;
