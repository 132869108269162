import {
  Checkbox,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router-dom';
import { selectInvites, setSelected } from 'redux/slices/invitesSlice';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { ChangeRole } from './buttons/ChangeRole';
import RemoveEmail from './buttons/RemoveEmail';
import SendEmail from './buttons/SendEmail';
import ToggleSpeaker from './buttons/ToggleSpeaker';
import ConfirmedEmail from './ConfirmedEmail';
import FinishedTutorial from './FinishedTutorial';
import CopyInviteLink from './buttons/CopyInviteLink';

interface DataRow {
  [key: string]: any;
  id: string | number;
  email: string;
  confirmed?: number;
  isSpeaker?: boolean;
  madeTutorial?: boolean;
  isOwner?: boolean;
  role?: string;
  token?: string;
  createdAt?: string;
  sendInviteLink?: boolean;
  inviteLink?: string;
}

export const TableRowContainer = ({ row }: { row: DataRow }) => {
  const { id: eventId } = useParams<{ id: string }>();
  const { selected } = useAppSelector(selectInvites);
  const dispatch = useAppDispatch();
  const isSelected = (email: string) => selected.indexOf(email) !== -1;
  const rowDate = moment(row.createdAt as string).format('YYYY/MM/DD HH:mm');

  const isItemSelected = isSelected(row.email);
  const labelId = `enhanced-table-checkbox-${row.id}`;
  const currentEventId = eventId as string;

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    dispatch(setSelected(newSelected));
  };

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.email}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          onClick={(event) => handleClick(event, row.email)}
          inputProps={{
            'aria-labelledby': labelId,
          }}
        />
      </TableCell>
      <TableCell scope="row">
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="space-between"
        >
          <Typography>
            <b>{row.email}</b>
            {row.firstName && (
              <span style={{ fontSize: 13 }}>
                {' '}
                ({row.firstName} {row.lastName})
              </span>
            )}
          </Typography>

          <span style={{ color: '#adadad', fontSize: 13 }}>
            Date added: {rowDate}
          </span>
        </Stack>
      </TableCell>
      <TableCell scope="row">
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <ConfirmedEmail
            confirmation={row.confirmed || 0}
            eventId={currentEventId}
            email={row.email}
          />

          <FinishedTutorial completed={row.madeTutorial} />

          <ToggleSpeaker participant={row} />

          <SendEmail emailToSend={row.email} eventId={currentEventId} />

          <RemoveEmail emailsToRemove={[row.email]} eventId={currentEventId} />

          <CopyInviteLink inviteLink={row.inviteLink || ''} />

          <ChangeRole participant={row} />
        </Stack>
      </TableCell>
    </TableRow>
  );
};
