import { useSnackbar } from 'notistack';
import { http } from 'services/http';
import {
  selectSkins,
  setIsSkinListLoading,
  setSkinList,
} from 'redux/slices/skinsSlice';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/useStore';

export const useSkins = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { skins } = useAppSelector(selectSkins);

  const loadSkins = async ({
    avatarId,
    eventId,
  }: {
    avatarId: string;
    eventId: string;
  }) => {
    if (skins?.length) return;

    await reloadSkins({ avatarId, eventId });
  };

  const reloadSkins = async ({
    avatarId,
    eventId,
  }: {
    avatarId: string;
    eventId: string;
  }) => {
    dispatch(setIsSkinListLoading(true));
    await http.skins
      .listByAvatar({
        avatarId,
        eventId,
      })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        dispatch(setSkinList(res.data));
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        dispatch(setIsSkinListLoading(false));
      });
  };

  return {
    loadSkins,
    reloadSkins,
  };
};
