import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { StreamService } from 'shared/types/models/streamService';

export const StreamServicesSelector = ({
  streamServices,
  streamServicesSelected,
  handleChangeStreamService,
  disabled,
}: any) => {
  return (
    <div>
      <Typography variant="h5">Stream Service Config</Typography>
      <Typography variant="body1">
        Select a stream service config to your world.
      </Typography>
      <Typography variant="body2">
        <i>
          {`If you don't know what
            it is, you can left the "default" as selected.`}
        </i>
      </Typography>

      <FormControl fullWidth margin="normal">
        <Select
          id="streamServicesSelected"
          name="streamServicesSelected"
          value={String(streamServicesSelected?.id) || ''}
          onChange={handleChangeStreamService}
          disabled={disabled}
          fullWidth
        >
          {streamServices.length > 0 ? (
            streamServices.map((item: StreamService, index: any) => (
              <MenuItem key={index} value={item.id}>
                {item.displayName}
              </MenuItem>
            ))
          ) : (
            <MenuItem key={0} value="" disabled>
              No stream service available
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
};
