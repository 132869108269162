import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import { handleFileSizeLimit } from 'shared/utils/handleFileSizeLimit';

type actualMapType = {
  [key: string]: any;
};

interface InitialState {
  isMapsLoading: boolean;
  maps: actualMapType[];
  actualMap: actualMapType;
  utils: {
    isSendingCreateForm: boolean;
    isSendingUpdateForm: boolean;
    isSendingDeleteForm: boolean;
    isFileSizeBiggerThanLimit: boolean;
    fileSize: number;
  };
}

const initialState: InitialState = {
  isMapsLoading: false,
  maps: [],
  actualMap: {},
  utils: {
    isSendingCreateForm: false,
    isSendingUpdateForm: false,
    isSendingDeleteForm: false,
    isFileSizeBiggerThanLimit: false,
    fileSize: 0,
  },
};

export const mapsSlice = createSlice({
  name: 'maps',
  initialState,
  reducers: {
    setIsMapsLoading: (state, { payload }) => {
      state.isMapsLoading = payload;
    },
    setMapList: (state, { payload }) => {
      state.maps = payload;
    },
    setActualMap: (state, { payload }) => {
      state.actualMap = payload;
    },
    setSendingCreateForm: (state, { payload }) => {
      state.utils.isSendingCreateForm = payload;
    },
    setSendingUpdateForm: (state, { payload }) => {
      state.utils.isSendingUpdateForm = payload;
    },
    setSendingDeleteForm: (state, { payload }) => {
      state.utils.isSendingUpdateForm = payload;
    },
    setFileSize: (state, { payload }) => {
      state.utils.fileSize = payload;
    },
    setFileSizeBiggerThanLimit: (state, { payload }) => {
      state.utils.isFileSizeBiggerThanLimit = payload;
    },
    checkFileSize: (state, { payload }) => {
      const limit = handleFileSizeLimit({
        originalSize: payload.originalSize,
        maxSizeInMb: payload.maxSizeInMb,
      });

      state.utils.isFileSizeBiggerThanLimit = limit.isOutOfLimit;

      state.utils.fileSize = limit.newSize;
    },
  },
});

export const selectMaps = (state: RootState) => state.mapsData;
export const {
  setIsMapsLoading,
  setMapList,
  setActualMap,
  setSendingCreateForm,
  setSendingUpdateForm,
  setSendingDeleteForm,
  setFileSize,
  setFileSizeBiggerThanLimit,
  checkFileSize,
} = mapsSlice.actions;

export default mapsSlice.reducer;
