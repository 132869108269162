import { TextField } from '@mui/material';
import { appPaths } from 'app/router/appPaths';
import { Formik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { setSendingCreateForm } from 'redux/slices/eventsSlice';
import { selectUser } from 'redux/slices/userSlice';
import { http } from 'services/http';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { formatDateToBackend } from 'shared/utils/formatDate';
import { DatePickersContainer } from './DatePickersContainer';
import { validationSchema } from './validationSchema';

interface FormValues {
  name: string;
  startTime: string;
  endTime: string;
  user: string;
  regionSelected: string;
  aliasSelected: string;
  company: string;
}

export const CreateEventForm = ({
  onSubmitCallback,
  innerRef,
  ...props
}: {
  onSubmitCallback: () => void;
  innerRef: any;
}) => {
  const { user } = useAppSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (
    formValues: FormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true);
    dispatch(setSendingCreateForm(true));

    const formData = {
      name: formValues.name,
      startTime: formatDateToBackend(formValues.startTime),
      endTime: formatDateToBackend(formValues.endTime),
      user: user?.data?.email as string,
      company: formValues.company,
    };

    await http.events
      .create(formData)
      .then((res) => {
        dispatch(setSendingCreateForm(false));
        setSubmitting(false);

        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }

        navigate(`${appPaths.private.event.dashboard}/${res.data.id}`);
        onSubmitCallback();
        enqueueSnackbar(res.message, { variant: 'success' });
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const formikValues = {
    initialValues: {
      name: '',
      startTime: '',
      endTime: '',
      company: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { setSubmitting }: FormikHelpers<any>) => {
      handleSubmit(values, setSubmitting);
    },
  };

  return (
    <Formik
      innerRef={innerRef}
      initialValues={formikValues.initialValues}
      validationSchema={formikValues.validationSchema}
      onSubmit={formikValues.onSubmit}
      {...props}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            id="name"
            name="name"
            label="Event Name*"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />

          <TextField
            fullWidth
            margin="normal"
            id="company"
            name="company"
            label="Company Name*"
            value={formik.values.company}
            onChange={formik.handleChange}
            error={formik.touched.company && Boolean(formik.errors.company)}
            helperText={formik.touched.company && formik.errors.company}
          />

          <DatePickersContainer formik={formik} />
        </form>
      )}
    </Formik>
  );
};
