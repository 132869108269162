import { env } from '../env';

if (!env.REACT_APP_IMAGE_SERVER_URL) {
  throw new Error('Config REACT_APP_IMAGE_SERVER_URL is missing');
}

if (!env.REACT_APP_IM_API) {
  throw new Error('Config REACT_APP_IM_API is missing');
}

if (!env.REACT_APP_ADMIN_API) {
  throw new Error('Config REACT_APP_ADMIN_API is missing');
}

if (!env.REACT_APP_SM_API) {
  throw new Error('Config REACT_APP_SM_API is missing');
}

if (!env.REACT_APP_SDK_ENV) {
  throw new Error('Config REACT_APP_SDK_ENV is missing');
}

const imageServerUrl = env.REACT_APP_IMAGE_SERVER_URL;

const instanceManagerApiUrl = env.REACT_APP_IM_API;
const apiUrl = env.REACT_APP_ADMIN_API;
const spatialManager = env.REACT_APP_SM_API;
const SDK_ENV = env.REACT_APP_SDK_ENV;

export {
  imageServerUrl,
  instanceManagerApiUrl,
  apiUrl,
  spatialManager,
  SDK_ENV,
};
