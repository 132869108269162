import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { gameCastConfigItemSelector } from 'redux/slices/admin/gameCastSlice';

import { useAppSelector } from 'shared/hooks/useStore';
import { useStyles } from './styles';
import { currentPurewebModelSelector } from 'redux/slices/admin/purewebSlice';
import { REACT_APP_GAMECAST_SCHEDULER } from 'configs/appFeatures';

const GroupInfoPreview = () => {
  const classes = useStyles();
  const currentGroup = useAppSelector(gameCastConfigItemSelector);
  const currentPurewebModel = useAppSelector(currentPurewebModelSelector);

  const isUpdatingCapacity =
    currentGroup?.DesiredCapacity !== currentGroup?.ActiveCapacity;

  return (
    <Box sx={classes.root}>
      {isUpdatingCapacity ? (
        <Typography fontWeight={700}>
          Updating Capacity in progress...
        </Typography>
      ) : null}

      <Stack flexDirection="row" columnGap={3}>
        {currentGroup && REACT_APP_GAMECAST_SCHEDULER === 'true' ? (
          <Box>
            <Typography fontSize={24} fontWeight={700}>
              {currentGroup?.Description}
            </Typography>
            <Typography fontWeight="bold">AWS cloud stream</Typography>
            <Typography>
              Desired Capacity: {currentGroup?.DesiredCapacity || 0}
            </Typography>
            <Typography>
              Active Capacity: {currentGroup?.ActiveCapacity || 0}
            </Typography>
            <Typography>
              Idle Capacity: {currentGroup?.IdleCapacity || 0}
            </Typography>
            <Typography>
              Stream Class: {currentGroup?.StreamClass || 'Not selected'}
            </Typography>
            <Typography>
              Status: {currentGroup?.Status || 'NOT AVAILABLE'}
            </Typography>
          </Box>
        ) : null}
        {currentPurewebModel?.length ? (
          <Box>
            <Typography fontSize={18} fontWeight="bold">
              Pureweb Pixel Stream
            </Typography>
            <Box sx={classes.infoWrapper}>
              {currentPurewebModel.map((model) => (
                <Box key={model.name}>
                  <Typography fontWeight="bold">
                    Region: {model.region}
                  </Typography>
                  <Typography>
                    Desired Capacity: {model.desired || 0}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        ) : null}
      </Stack>
    </Box>
  );
};

export default GroupInfoPreview;
