import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface Ticket {
  [key: string]: any;
}

const initialState = {
  tickets: [] as Ticket[],
};

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setTicketsList: (state, { payload }) => {
      state.tickets = payload;
    },
  },
});

export const selectTickets = (state: RootState) => state.ticketsData;

export const { setTicketsList } = ticketsSlice.actions;

export default ticketsSlice.reducer;
