import { AccountFormLayout } from 'layouts/AccountFormLayout';
import { PasswordResetForm } from './PasswordResetForm';

export const PasswordResetPage = () => {
  return (
    <AccountFormLayout>
      <PasswordResetForm />
    </AccountFormLayout>
  );
};
