function errorsApiHandler(error: any) {
  let message;
  let status;

  // eslint-disable-next-line no-console
  console.error(error);

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    message = error.response.data.error.message;
    status = error.response.status;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    message = error.request.message || error.message;
    status = error.request.status || error.status;
  } else if (error.message === 'Network Error') {
    message = error.message;
  } else {
    // Something happened in setting up the request that triggered an Error
    message = error.message;
    status = error.status;
  }

  if (!status) status = 'UNKNOW STATUS';

  return {
    error: true,
    status,
    message,
  };
}

export default errorsApiHandler;
