import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Divider, Grid, Stack } from '@mui/material';
import { appPaths } from 'app/router/appPaths';
import { AppDefaultLayout } from 'layouts/AppDefaultLayout';
import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { selectEvents } from 'redux/slices/eventsSlice';
import { selectRooms } from 'redux/slices/roomsSlice';
import { DefaultPageHeader } from 'shared/components/headers/DefaultPageHeader';
import { useLoadRooms } from 'shared/hooks/useLoadRooms';
import { useAppSelector } from 'shared/hooks/useStore';
import RoomCard from './RoomCard';
import LoadingProgress from '../../../components/LoadingProgress';
import CreateRoomModal, { CreateRoomModalHandles } from './CreateRoomModal';
import { http } from '../../../services/http';

export const RoomsDashboardPage = () => {
  const urlParams = useParams();
  const loadRooms = useLoadRooms();
  const navigate = useNavigate();
  const { event } = useAppSelector(selectEvents);
  const { rooms, isRoomsLoading } = useAppSelector(selectRooms);

  useEffect(() => {
    loadRooms();
    // eslint-disable-next-line
  }, []);

  const createRoomModalRef = useRef<CreateRoomModalHandles>(null);

  const handleOpenCreateRoomModal = async () => {
    const result = await http.events.canCreateRoom({
      eventId: String(event.id),
    });

    if (result.canCreate) {
      createRoomModalRef.current?.handleOpen();
    } else if (!result.error) {
      const message = `
          Error: Unable to add more rooms.
          Reason: The number of rooms has reached its limit.
          Details: 
          - Number of rooms in the event: ${result.numberOfEventRooms} 
          - Number of sessions available for the alias: ${result.activeServerProcessCount}
          New rooms exceed alias's session limit.`;

      alert(message);
    } else {
      const message = `
      An unexpected error occurred. Check the console for more details.
      Error: ${result.message}`;
      alert(message);
    }
  };

  if (isRoomsLoading) {
    return (
      <AppDefaultLayout>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          padding={4}
        >
          <LoadingProgress />
        </Stack>
      </AppDefaultLayout>
    );
  }

  return (
    <>
      <AppDefaultLayout>
        <DefaultPageHeader
          titleText={`${event.name} - Rooms`}
          subTitleText="Create rooms to your event"
          linkTo={`${appPaths.private.event.dashboard}/${urlParams.id}`}
          linkToText="Back to Event Dashboard"
        />

        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          paddingBottom={4}
        >
          <span />
          <Button
            onClick={handleOpenCreateRoomModal}
            startIcon={<AddCircleIcon fontSize="small" />}
          >
            Create New Room
          </Button>
        </Stack>

        <Grid container spacing={2}>
          {!rooms.length ? (
            <p>This event has no rooms.</p>
          ) : (
            rooms.map((room: any) => {
              return (
                <Grid xs={12} sm={6} md={3} item key={room.id}>
                  <RoomCard
                    key={room.id}
                    event={event}
                    id={room.id}
                    image={room.image}
                    name={room.name}
                    isDefault={room.isDefaultRoom}
                    onClick={() => {
                      navigate(
                        appPaths.private.rooms.details
                          .replace(':id', event.id as string)
                          .replace(':roomId', room.id)
                      );
                    }}
                    gotToSetupButtonText="Room Setup"
                  />
                </Grid>
              );
            })
          )}
        </Grid>

        <CreateRoomModal ref={createRoomModalRef} />
      </AppDefaultLayout>
    </>
  );
};
