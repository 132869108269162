import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';

interface Props {
  open: boolean;
  title?: string;
  children?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  confirmIcon?: React.ReactNode;
  cancelIcon?: React.ReactNode;
  disableConfirmButton?: boolean;
  disableCancelButton?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const SimpleDialogModal = ({
  open,
  title,
  children,
  confirmText,
  cancelText,
  confirmIcon,
  cancelIcon,
  disableConfirmButton,
  disableCancelButton,
  onCancel,
  onConfirm,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="clone-dialog-title"
      aria-describedby="clone-dialog-description"
      fullWidth
    >
      <DialogTitle id="clone-dialog-title">
        {title || 'Default Title'}
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          startIcon={cancelIcon}
          color="error"
          disabled={disableCancelButton}
        >
          {cancelText || 'Cancel'}
        </Button>
        <Button
          onClick={onConfirm}
          autoFocus
          startIcon={confirmIcon}
          variant="contained"
          disabled={disableConfirmButton}
        >
          {confirmText || 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
