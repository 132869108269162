import * as yup from 'yup';

export const validationSchema = yup.object({
  displayName: yup.string().required('This field is required'),
  description: yup.string().required('This field is required'),
  mediaType: yup.string().required('This field is required'),
  posX: yup.number().required('This field is required'),
  posY: yup.number().required('This field is required'),
  posZ: yup.number().required('This field is required'),
  rotationX: yup.number().required('This field is required'),
  rotationY: yup.number().required('This field is required'),
  rotationZ: yup.number().required('This field is required'),
  scale: yup.number().required('This field is required'),
  browserId: yup.string().when('mediaType', {
    is: 'browserUrl',
    then: yup.string().required('This field is required'),
  }),
  browserUrl: yup
    .string()
    .url()
    .when('mediaType', {
      is: 'browserUrl',
      then: yup.string().required('This field is required'),
    }),
});
