import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import { HttpResponseDefault } from './types/httpGeneric';
import axiosWithUserToken from './axiosInstances/axiosWithUserToken';
import {
  CreateRoomProps,
  GetRoomProps,
  ListRoomsProps,
  RemoveRoomProps,
  UpdateRoomProps,
} from './types/rooms';

const rooms = {
  async list({ eventId }: ListRoomsProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/rooms/list/${eventId}`
      );
      return { message: 'Rooms Loaded', data: data.rooms };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async listByEvent({ eventId }: ListRoomsProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/rooms/list/event/${eventId}`
      );
      return { message: 'Rooms Loaded', data: data.rooms };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async get({ roomId }: GetRoomProps): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/rooms/get/${roomId}`
      );
      return { message: 'Room Loaded', data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async create({ data }: CreateRoomProps): Promise<HttpResponseDefault> {
    try {
      const result = await axiosWithUserToken().post('/cms/rooms/create', data);
      return { message: 'Room Created', data: result.data.room };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async update({
    data,
    roomId,
  }: UpdateRoomProps): Promise<HttpResponseDefault> {
    try {
      const dataFormated = new FormData();

      Object.entries(data).forEach(([key, value]) =>
        dataFormated.append(`${key}`, value as any)
      );

      const result = await axiosWithUserToken().put(
        `/cms/rooms/update/${roomId}`,
        dataFormated,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      return { message: 'Success', data: result.data.room };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async remove({ roomId }: RemoveRoomProps): Promise<HttpResponseDefault> {
    try {
      const result = await axiosWithUserToken().delete(
        `/cms/rooms/delete/${roomId}`
      );
      return { message: 'Room Removed', data: result.data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async setIsDefault({
    roomId,
  }: {
    roomId: string;
  }): Promise<HttpResponseDefault> {
    try {
      const result = await axiosWithUserToken().patch(
        `/cms/rooms/setDefault/${roomId}`
      );
      return { message: 'Room Updated', data: result.data.room };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default rooms;
