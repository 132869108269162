import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { selectUser } from 'redux/slices/userSlice';
import { http } from 'services/http';
import { useLoadEvents } from 'shared/hooks/useLoadEvents';
import { useAppSelector } from 'shared/hooks/useStore';
import { Event } from 'shared/types/models/event';
import { Room } from 'shared/types/models/room';
import { DestroySessionsModal } from './DestroySessionsModal';

type Props = {
  event: Event;
};

export const RemoveEvent = ({ event }: Props) => {
  const { listByConnectedId: loadEvents } = useLoadEvents();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const { user } = useAppSelector(selectUser);

  const [rooms, setRooms] = useState<Room[]>([]);

  const listRooms = async (eventId: string) => {
    await http.rooms
      .listByEvent({
        eventId,
      })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }

        setRooms(res.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleClick = () => {
    listRooms(String(event.id));
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleRemoved = () => {
    setOpen(false);
    loadEvents();
  };

  const destroySessionsModalProps = {
    data: {
      eventId: String(event.id),
      token: String(user?.connectToken),
      alias: String(event.aliasId),
      regionName: String(event.region),
      onlyDestroy: true,
    },
    removeEvent: true,
    roomsLength: rooms.length,
    open,
    onCancel: handleCancel,
    onRemoved: handleRemoved,
  };

  return (
    <>
      <DestroySessionsModal {...destroySessionsModalProps} />

      <Button
        color="error"
        onClick={handleClick}
        startIcon={<DeleteForeverIcon fontSize="small" />}
        disabled={false}
      >
        Remove
      </Button>
    </>
  );
};
