import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import BoxDialogModal, {
  BoxDialogModalHandles,
} from 'shared/components/modals/BoxDialogModal';
import { useFormik } from 'formik';
import {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { validationSchema } from './validationSchema';
import { useAppSelector } from 'shared/hooks/useStore';
import { selectRooms } from 'redux/slices/roomsSlice';
import { useParams } from 'react-router-dom';
import { selectUser } from 'redux/slices/userSlice';
import { http } from 'services/http';
import { useSnackbar } from 'notistack';

interface FormData {
  name: string;
}

export interface AddTicketModalHandles {
  handleOpen: () => void;
}

const AddTicketModal: ForwardRefRenderFunction<AddTicketModalHandles> = (
  props,
  ref
) => {
  const urlParams = useParams();
  const { rooms } = useAppSelector(selectRooms);
  const { user } = useAppSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();

  const [sending, setSending] = useState(false);

  const handleSubmit = (values: FormData) => {
    setSending(true);
    const data = {
      user: user?.data?.email as string,
      name: values.name,
      event: urlParams.id as string,
      rooms: roomNamesSelected,
    };

    http.tickets
      .create(data)
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        enqueueSnackbar(res.message, { variant: 'success' });
        boxDialogModalRef.current?.handleClose();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setSending(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  // Room name selected handler
  const [roomNamesSelected, setRoomNameSelected] = useState<string[]>([]);
  const handleChangeMultiple = (event: ChangeEvent<HTMLSelectElement>) => {
    const { options } = event.target;
    const value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setRoomNameSelected(value);
  };

  const getRoomNames = () => {
    const roomNames: string[] = [];
    rooms.map((item: Record<string, any>) => {
      if (item.name) roomNames.push(item.name);
    });

    return roomNames;
  };

  const formRef = useRef<HTMLFormElement>(null);
  const handleSubmitButton = () => {
    formik.handleSubmit();
  };

  const boxDialogModalRef = useRef<BoxDialogModalHandles>(null);
  const handleOpen = () => {
    boxDialogModalRef.current?.handleOpen();
  };

  useImperativeHandle(ref, () => {
    return { handleOpen };
  });

  return (
    <BoxDialogModal
      title="Add Ticket"
      ref={boxDialogModalRef}
      submitButton={
        <Button
          type="submit"
          onClick={handleSubmitButton}
          variant="contained"
          disabled={sending}
        >
          {!sending ? 'Save' : 'Saving...'}
        </Button>
      }
    >
      <form
        onSubmit={formik.handleSubmit}
        style={{ maxWidth: '600px' }}
        ref={formRef}
      >
        <TextField
          fullWidth
          id="name"
          name="name"
          label="Ticket Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <p>Select rooms above to your ticket.</p>
        <FormControl sx={{ minWidth: 120, width: '100%' }}>
          <InputLabel shrink htmlFor="select-multiple-native">
            Rooms
          </InputLabel>
          <Select
            multiple
            native
            value={roomNamesSelected}
            // @ts-ignore Typings are not considering `native`
            onChange={handleChangeMultiple}
            label="Native"
            inputProps={{
              id: 'select-multiple-native',
            }}
            sx={{ p: 1 }}
          >
            {getRoomNames().map((name, index) => (
              <option key={name + index} value={name}>
                {name}
              </option>
            ))}
          </Select>
        </FormControl>
        <Stack direction="row" spacing={1} style={{ marginTop: '16px' }}>
          {roomNamesSelected.map((item, index) => (
            <Chip key={index} label={item} variant="outlined" />
          ))}
        </Stack>
      </form>
    </BoxDialogModal>
  );
};

export default forwardRef(AddTicketModal);
