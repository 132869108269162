import { Box, Stack, styled, Switch } from '@mui/material';

export const POICard = styled(Stack)({
  overflowWrap: 'break-word',
  position: 'relative',
  padding: '15px',
  borderRadius: 5,
  background: 'rgba(0, 0, 0, 0.02)',

  img: {
    borderRadius: 3,
    overflow: 'hidden',
  },

  p: {
    margin: '2px 0',
    fontSize: 13,
  },
});

export const DisplayImageContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '150px',
  width: '100%',
  position: 'relative',

  img: {
    height: '100%',
    width: '100%',
  },
});

export const RemoveButtonContainer = styled(Stack)({
  margin: '5px 0 0 0',
});

export const Android12Switch = styled(Switch)({
  padding: 0,
  height: 18,
  width: 39,
  position: 'relative',
  overflow: 'hidden',

  '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    opacity: 1,
    background: 'rgba(0, 0, 0, 0.15)',
  },

  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    opacity: 1,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 14,
      height: 14,
      backgroundRepeat: 'no-repeat !important',
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 24 24"><path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: '4px',
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"  height="12" width="12" viewBox="0 0 24 24"><path d="M19,13H5V11H19V13Z" /></svg>')`,
      right: '4px',
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 14,
    height: 14,
    margin: 2,
  },

  '& .MuiSwitch-switchBase': {
    padding: 0,
    opacity: 1,
  },
});
