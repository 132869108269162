import { AppDefaultLayout } from 'layouts/AppDefaultLayout';
import { useParams } from 'react-router-dom';
import { Box, Divider, Grid, Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { useEffect } from 'react';
import { DefaultPageHeader } from 'shared/components/headers/DefaultPageHeader';
import { appPaths } from 'app/router/appPaths';
import { selectAvatars } from 'redux/slices/avatarsSlice';
import { useSkins } from '../hooks/useSkins';
import { selectEvents } from 'redux/slices/eventsSlice';
import { SkinListContainer } from './SkinListContainer';
import { selectSkins, setSkinList } from 'redux/slices/skinsSlice';
import { useGetAvatar } from 'shared/hooks/useGetAvatar';
import { AddSkinsButton } from './AddSkinsButton';
import LoadingProgress from '../../../components/LoadingProgress';
import { useSnackbar } from 'notistack';

export const SkinsDashboardPage = () => {
  const { avatarId, id } = useParams();
  const { actualAvatar } = useAppSelector(selectAvatars);
  const { event } = useAppSelector(selectEvents);
  const { isSkinsLoading, skins } = useAppSelector(selectSkins);
  const { loadSkins, reloadSkins } = useSkins();
  const getAvatar = useGetAvatar();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getAvatar();

    if (id) {
      loadSkins({
        eventId: id,
        avatarId: avatarId as string,
      });
    } else {
      enqueueSnackbar('Error: Event ID is missing or undefined', {
        variant: 'error',
      });
    }

    return () => {
      dispatch(setSkinList([]));
    };

    // eslint-disable-next-line
  }, []);

  const handleOnSkinsUpdate = () => {
    if (id) {
      reloadSkins({
        eventId: id,
        avatarId: avatarId as string,
      });
    } else {
      enqueueSnackbar('Error: Event ID is missing or undefined', {
        variant: 'error',
      });
    }
  };

  return (
    <AppDefaultLayout>
      <DefaultPageHeader
        titleText={`${event.name} - ${actualAvatar.name} - Skins`}
        subTitleText="Select your skins"
        linkTo={appPaths.private.avatars.dashboard.replace(':id', String(id))}
        linkToText="Back to avatars dashboard"
      />

      <Divider />

      <Box>
        {isSkinsLoading ? (
          <LoadingProgress />
        ) : (
          <>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
              paddingBottom={4}
            >
              <AddSkinsButton onSkinsUpdateCallback={handleOnSkinsUpdate} />
            </Stack>

            <Grid container spacing={2}>
              {skins?.length ? (
                <SkinListContainer skins={skins} />
              ) : (
                <p>This avatar has no skins.</p>
              )}
            </Grid>
          </>
        )}
      </Box>
    </AppDefaultLayout>
  );
};
