import { Room } from './models/room';

export type CreateRoomsData = Room;

export type UpdateRoomsData = CreateRoomsData;

export interface UpdateMediaProps {
  id: number | string;
  displayName: string;
  image: string;
  coords?: { top?: number; left?: number };
  isActive: boolean;
  mediaType: SpotMediaType;
  isDefault: boolean;
  assetMediaType?: string;
  asset?: any;
}

export enum SpotMediaType {
  Image = 'image',
  Video = 'video',
  Three_D = '3D',
  WebBrowser = 'webBrowser',
  UnrealCode = 'unrealCode',
  Audio = 'audio',
}
