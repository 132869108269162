import { Divider } from '@mui/material';
import { DefaultPageHeader } from 'shared/components/headers/DefaultPageHeader';
import { AppDefaultLayout } from 'layouts/AppDefaultLayout';
import { useParams } from 'react-router-dom';
import AddInvitesContainer from './AddInvitesContainer';
import { appPaths } from 'app/router/appPaths';

export const AddInvitesPage = () => {
  const urlParams = useParams();

  return (
    <AppDefaultLayout>
      <DefaultPageHeader
        titleText="Invite members to your event"
        linkTo={appPaths.private.invites.index.replace(
          ':id',
          String(urlParams.id)
        )}
        linkToText="Back to Invites page"
      />

      <Divider style={{ marginBottom: '16px' }} />

      <AddInvitesContainer />
    </AppDefaultLayout>
  );
};
