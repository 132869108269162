import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { useLoadRoomDetails } from 'shared/hooks/useLoadRoomDetails';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { http } from 'services/http';
import { selectEvents } from 'redux/slices/eventsSlice';
import { selectRooms, setSendingUpdateForm } from 'redux/slices/roomsSlice';
import { UpdateRoomsData } from 'shared/types/rooms';
import { formatDateToFrontend } from 'shared/utils/formatDate';
import { validationSchema } from './validationSchema';

interface FormValues {
  name: string;
  mapName: string;
  maxPlayers: number;
  maxReplicas: number;
  startTime: string;
  endTime: string;
  regionSelected: string;
  roomSelected: string;
  isInvisible: boolean;
}

export const UpdateRoomForm = ({
  onSubmitCallback,
  innerRef,
  ...props
}: {
  onSubmitCallback: () => void;
  innerRef: any;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { event } = useAppSelector(selectEvents);
  const { actualRoom } = useAppSelector(selectRooms);
  const dispatch = useAppDispatch();

  const [environments, setEnvironments] = useState([
    {
      mapName: 'all',
      displayName: 'Room Space',
      imageUrl: '',
    },
  ]);

  const [envImage, setEnvImage] = useState<string>('');

  const getEnvs = useCallback(() => {
    http.environments
      .listBySubdomain(event.subdomain as string, true)
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        setEnvironments([...environments, ...res.data]);
        setEnvImage(actualRoom.image);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getEnvs();
  }, [getEnvs]);

  const handleShowImage = (event: SelectChangeEvent<string>) => {
    const filtered = environments.filter(
      (item) => event.target.value === item.mapName
    )[0];

    if (filtered) {
      setEnvImage(filtered.imageUrl);
    }
  };

  const { loadRoomDetails } = useLoadRoomDetails();

  const handleSubmit = (
    formValues: FormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true);
    dispatch(setSendingUpdateForm(true));

    const filteredEnvironment = environments.filter(
      (env) => env.mapName === formValues.roomSelected
    )[0];

    const formData: UpdateRoomsData = {
      event: event.id as string,
      name: formValues.name,
      mapName: filteredEnvironment.mapName,
      maxPlayers: formValues.maxPlayers,
      maxReplicas: formValues.maxReplicas,
      isChild: false,
      isCopy: false,
      isInvisible: formValues.isInvisible,
      user: event.user as string,
    };

    http.rooms
      .update({
        data: formData,
        roomId: actualRoom.id,
      })
      .then((res) => {
        setSubmitting(false);
        dispatch(setSendingUpdateForm(false));
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        onSubmitCallback();
        loadRoomDetails();
        enqueueSnackbar(res.message, { variant: 'success' });
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const formikValues = {
    initialValues: {
      name: actualRoom.name,
      maxPlayers: actualRoom.maxPlayers,
      maxReplicas: actualRoom.maxReplicas,
      startTime: formatDateToFrontend(Number(actualRoom.startTime)),
      endTime: formatDateToFrontend(Number(actualRoom.endTime)),
      regionSelected: actualRoom.regionName,
      roomSelected: actualRoom.mapName,
      isInvisible: actualRoom.isInvisible,
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { setSubmitting }: FormikHelpers<any>) => {
      handleSubmit(values, setSubmitting);
    },
  };

  return (
    <Formik
      innerRef={innerRef}
      initialValues={formikValues.initialValues}
      validationSchema={formikValues.validationSchema}
      onSubmit={formikValues.onSubmit}
      {...props}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            id="name"
            name="name"
            label={
              event?.isPublished
                ? 'Room Name* (Event Published - Form Disabled'
                : 'Room Name*'
            }
            placeholder="Insert name to your room"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            disabled={event?.isPublished}
          />

          <FormControl fullWidth margin="normal">
            <span>Room Space*</span>
            <Select
              id="roomSelected"
              name="roomSelected"
              defaultValue=""
              value={formik.values.roomSelected}
              onChange={(e) => {
                formik.handleChange(e);
                handleShowImage(e);
              }}
              error={
                formik.touched.roomSelected &&
                Boolean(formik.errors.roomSelected)
              }
              disabled={event?.isPublished}
            >
              {environments.length &&
                environments.map((env) => (
                  <MenuItem key={env.mapName} value={env.mapName}>
                    {env.displayName}
                  </MenuItem>
                ))}
            </Select>
            {formik.touched.roomSelected &&
              Boolean(formik.errors.roomSelected) && (
                <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-11h4n6a-MuiFormHelperText-root">
                  {formik.errors.roomSelected}
                </p>
              )}
          </FormControl>

          {envImage?.length > 0 && (
            <Stack
              direction="row"
              justifyContent="normal"
              alignItems="center"
              spacing={2}
              paddingBottom={4}
            >
              <img
                src={envImage}
                alt="selected image"
                style={{ maxWidth: '100%' }}
              />
            </Stack>
          )}

          <Stack
            direction="row"
            justifyContent="normal"
            alignItems="center"
            spacing={2}
            paddingBottom={4}
          >
            <Stack
              flex="auto"
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <span>Users / Room*</span>
              <TextField
                fullWidth
                type={'number'}
                inputProps={{ min: 1, max: 100 }}
                margin="normal"
                id="maxPlayers"
                name="maxPlayers"
                value={formik.values.maxPlayers}
                onChange={formik.handleChange}
                error={
                  formik.touched.maxPlayers && Boolean(formik.errors.maxPlayers)
                }
                helperText={
                  formik.touched.maxPlayers && formik.errors.maxPlayers
                }
                disabled={event?.isPublished}
              />
            </Stack>
            <Stack
              flex="auto"
              width="50%"
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <span>Number of Replicas*</span>
              <TextField
                fullWidth
                type={'number'}
                inputProps={{ min: 0, max: 100 }}
                margin="normal"
                id="maxReplicas"
                name="maxReplicas"
                value={formik.values.maxReplicas}
                onChange={formik.handleChange}
                error={
                  formik.touched.maxReplicas &&
                  Boolean(formik.errors.maxReplicas)
                }
                helperText={
                  formik.touched.maxReplicas && formik.errors.maxReplicas
                }
                disabled={event?.isPublished}
              />
            </Stack>
          </Stack>

          <Stack
            direction="row"
            justifyContent="normal"
            alignItems="center"
            spacing={2}
            paddingBottom={4}
          >
            <Stack
              flex="auto"
              width="50%"
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <FormControl fullWidth>
                <span>Hidden Room*</span>
                <Select
                  id="isInvisible"
                  name="isInvisible"
                  value={formik.values.isInvisible}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.isInvisible &&
                    Boolean(formik.errors.isInvisible)
                  }
                  disabled={event?.isPublished}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        </form>
      )}
    </Formik>
  );
};
