export const useStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    rowGap: 3,
    columnGap: 3,
    flexWrap: 'wrap',
    pb: 5,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '20%',
    cursor: 'pointer',

    height: 120,
    '& svg': {
      width: '100%',
    },
  },
});
