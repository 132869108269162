import axios from 'axios';
import {
  ICustomerEvent,
  UpdateCustomerEventProps,
} from './types/customersService';
import { spatialManager } from 'configs/apiConfigs';
import { getToken } from 'shared/utils/tokenManager';
import { TOKEN_AUTH_NAME } from 'configs/authConfigs';

export default class CustomersService {
  static getCustomerEvent(customerId: string): Promise<ICustomerEvent> {
    return axios
      .get(`${spatialManager}api/v1/customers/${customerId}`, {
        headers: {
          Authorization: getToken(TOKEN_AUTH_NAME) || '',
        },
      })
      .then((res) => res.data.customer);
  }

  static updateCustomerEvent(
    customerId: string,
    data: UpdateCustomerEventProps
  ) {
    return axios
      .patch(
        `${spatialManager}api/v1/customers/${customerId}`,
        {
          eventName: data.eventName,
          backgroundImage: data.backgroundImage,
          loginButtonBackgroundColor: data.primaryColor,
          loginButtonBackgroundColorHover: data.secondaryColor,
          helperTextLinkColor: data.secondaryColor,
          submitButtonBackgroundColor: data.primaryColor,
          submitButtonBackgroundColorHover: data.secondaryColor,
          signFormSelectedTab: data.secondaryColor,
          customerLogo: data.customerLogo,
          colorTheme: data.colorTheme,
        },
        {
          headers: {
            Authorization: getToken('token') || '',
          },
        }
      )
      .then((res) => res.data.customer);
  }
}
