import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { selectRooms, setSendingMediaForm } from 'redux/slices/roomsSlice';
import { http } from 'services/http';
import useAccess from 'shared/hooks/useAccess';
import { useLoadRoomDetails } from 'shared/hooks/useLoadRoomDetails';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { ResetSpotProps } from './types';

export const ResetSpot = ({ actualSpot, onSuccess }: ResetSpotProps) => {
  const { getConnectToken } = useAccess();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();
  const {
    utils: { isSendingMediaForm },
  } = useAppSelector(selectRooms);
  const { loadRoomDetails } = useLoadRoomDetails();

  const handleResetSpot = () => {
    dispatch(setSendingMediaForm(true));

    const spotID = actualSpot.id as string;

    http.spots
      .resetFromFrontend({
        userToken: getConnectToken() as string,
        spotID,
      })
      .then((res: any) => {
        dispatch(setSendingMediaForm(false));
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        enqueueSnackbar(res.message, { variant: 'success' });
        loadRoomDetails();
        onSuccess();
      })
      .catch((error) => {
        dispatch(setSendingMediaForm(false));
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  return (
    <Button
      autoFocus
      onClick={handleResetSpot}
      disabled={isSendingMediaForm}
      variant="outlined"
    >
      {isSendingMediaForm ? 'Reseting Custom configs...' : 'Reset Spot'}
    </Button>
  );
};
