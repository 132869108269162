import RecommendIcon from '@mui/icons-material/Recommend';
import { Badge, Button, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { setActualFilter } from 'redux/slices/invitesSlice';
import { http } from 'services/http';
import { useLoadParticipants } from 'shared/hooks/useLoadParticipants';
import { InviteFilters } from 'shared/types/models/invite';

interface ConfirmedEmailProps {
  confirmation: number;
  eventId: string;
  email: string;
}

export default function ConfirmedEmail({
  confirmation,
  eventId,
  email,
}: ConfirmedEmailProps) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { get: reloadParticipants } = useLoadParticipants();

  const handleConfirmEmail = async () => {
    await http.invites
      .inviteConfirm({ eventId, email })
      .then(() => {
        dispatch(setActualFilter(InviteFilters.All));
        reloadParticipants();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  return (
    <>
      {confirmation && confirmation > 0 ? (
        <Tooltip title="Email confirmed">
          <RecommendIcon color="success" />
        </Tooltip>
      ) : (
        <Button
          onClick={handleConfirmEmail}
          sx={{ margin: 0, padding: 0, minWidth: 'auto' }}
        >
          <Tooltip title="Click to confirm manually">
            <Badge color="primary" variant="dot">
              <RecommendIcon color="disabled" />
            </Badge>
          </Tooltip>
        </Button>
      )}
    </>
  );
}
