import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { http } from 'services/http';
import useInstanceManager from 'services/http/useInstanceManager';
import DialogAlert, {
  DialogAlertHandles,
} from 'shared/components/alerts/DialogAlert';
import { useLoadRooms } from 'shared/hooks/useLoadRooms';
import { CardProps } from 'shared/types/cards';

const RoomCard = ({
  event,
  id,
  image,
  name,
  description,
  gotToSetupButtonText = 'Setup',
  isDefault,
  onClick,
}: CardProps) => {
  const { deleteSession } = useInstanceManager();
  const { enqueueSnackbar } = useSnackbar();
  const { id: eventId } = useParams();
  const loadRooms = useLoadRooms();

  const deleteRoomInDatabase = async () => {
    await http.rooms.remove({
      roomId: id,
    });
  };

  const clearGameSessionsInfo = async (eventId: string) => {
    const getRoomData = (room: any) => {
      delete room.id;
      delete room.createdAt;
      delete room.updatedAt;
      delete room.user;

      const data = {
        ...room,
        gameSessionId: '',
        gameSessionName: '',
      };
      return data;
    };

    await http.rooms
      .listByEvent({
        eventId,
      })
      .then(async (res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        const rooms = res.data;
        for (const room of rooms) {
          const roomId = room.id;
          await http.rooms.update({
            data: getRoomData(room),
            roomId,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });

    return;
  };

  const publishRooms = async (eventId: string) => {
    await http.events.publishSelfService({
      eventId: String(eventId as string),
    });
  };

  const deleteAndRecreate = async () => {
    await deleteRoomInDatabase();
    await clearGameSessionsInfo(eventId as string);
    if (event?.isPublish) {
      await publishRooms(eventId as string);
    }
    loadRooms();
    enqueueSnackbar('sucess', { variant: 'success' });
  };

  const listRooms = async (eventId: string) => {
    let length = 0;
    await http.rooms
      .listByEvent({
        eventId,
      })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        length = res.data.length;
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });

    return length;
  };

  const handleRemoveRoom = async () => {
    if (!event) return;
    const payload = {
      data: {
        eventId: eventId as string,
        token: String('1'),
        alias: event.aliasId as string,
        regionName: event.region as string,
        onlyDestroy: true,
      },
      removeEvent: false,
      roomsLength: await listRooms(eventId as string),
      onCancel: () => {},
      onRemoved: async () => {
        await deleteAndRecreate();
      },
    };

    await deleteSession(payload);
  };

  const handleSetDefault = (
    event: ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    http.rooms
      .setIsDefault({ roomId: id })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        loadRooms();
        enqueueSnackbar(res.message, { variant: 'success' });
      })
      .catch((error) => {
        loadRooms();
        enqueueSnackbar(error.message, { variant: 'success' });
      });
  };

  const dialogAlertRef = useRef<DialogAlertHandles>(null);
  const handleOpenDialogAlert = useCallback(() => {
    dialogAlertRef.current?.handleOpen();
  }, []);

  return (
    <>
      <DialogAlert
        title="Remove Room?"
        message="Are you sure to remove this room? This action can't be undone."
        okButtonText="Remove"
        ref={dialogAlertRef}
        deleteCallback={() => {
          handleRemoveRoom();
        }}
      />

      <Card>
        <CardMedia component="img" height="140" image={image} alt="room" />
        <CardContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={isDefault}
                onChange={(event) => handleSetDefault(event, id)}
              />
            }
            label="Default Room"
          />
          <Typography gutterBottom variant="h5" component="div">
            {name}
          </Typography>
          {description && (
            <Typography gutterBottom variant="body1" component="div">
              {description}
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Button size="small" variant="contained" onClick={onClick}>
              {gotToSetupButtonText}
            </Button>
            <Button
              size="small"
              variant="text"
              color="error"
              onClick={handleOpenDialogAlert}
              disabled={event?.isPublished}
            >
              Remove
            </Button>
          </Stack>
        </CardActions>
      </Card>
    </>
  );
};

export default RoomCard;
