import { themeColors } from '../../layouts/theme/AppTheme/colors';

import { ILoadingProgressStyles } from './interfaces';
import { IStyles } from '../../interfaces';

export const useStyles = ({
  fullHeight,
  height,
}: ILoadingProgressStyles): IStyles => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: fullHeight ? '100vh' : height,
  },
  spinner: {
    color: themeColors.blue,
  },
});
