import { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  gameCastConfigItemSelector,
  currentLogsSelector,
  gameCastModalState,
  isCalendarLoadingSelector,
  currentCalendarItemsSelector,
} from 'redux/slices/admin/gameCastSlice';

import { useGameCast } from 'shared/hooks/admin/useGameCast';
import { useAppSelector } from 'shared/hooks/useStore';
import {
  CustomProcessedEvent,
  GameCastTabValues,
  ISortingConfig,
  SortingCriteriaTypes,
  SortingOrderTypes,
} from './interface';

import EventsService from 'services/http/eventsService';
import useAsyncEffect from 'use-async-effect';
import { ProcessedEvent } from '@aldabil/react-scheduler/types';
import SchedulerService from 'services/http/schedulerService';
import { usePureweb } from 'shared/hooks/admin/usePureweb';
import { currentPurewebModelCalendarEventsSelector } from 'redux/slices/admin/purewebSlice';

export const useGameCastConfigure = () => {
  const currentGroup = useAppSelector(gameCastConfigItemSelector);
  const currentLogs = useAppSelector(currentLogsSelector);
  const modalState = useAppSelector(gameCastModalState);
  const isCalendarLoading = useAppSelector(isCalendarLoadingSelector);
  const currentGameCastEvents = useAppSelector(currentCalendarItemsSelector);
  const currentPurewebEvents = useAppSelector(
    currentPurewebModelCalendarEventsSelector
  );

  const [currentEvents, setCurrentEvents] = useState<ProcessedEvent[]>([]);

  const [searchParams] = useSearchParams();

  const [tabValue, setTabValue] = useState(GameCastTabValues.Calendar);
  const [loadingGroup, setLoadingGroup] = useState(false);
  const { eventId } = useParams();
  const region = searchParams.get('region');
  const streamGroupId = searchParams.get('streamGroupId');
  const modelId = searchParams.get('modelId');
  const isFromEvent = Boolean(searchParams.get('fromEvent'));

  const navigate = useNavigate();

  const isUpdatingCapacity =
    currentGroup?.DesiredCapacity !== currentGroup?.ActiveCapacity;

  const {
    emptyCurrentGroup,
    handleOpenModal,
    emptyCurrentCalendar,
    getCurrentGroupCalendar,
    setGameCastGroup,
  } = useGameCast();

  const { loadCurrentPurewebModel, getPurewebCalendar, emptyCurrentModelLogs } =
    usePureweb();

  const handleCloseModal = () => {
    handleOpenModal(false);
  };

  const handleConfirm = async (
    event: CustomProcessedEvent | ProcessedEvent,
    capacity: number
  ): Promise<void> => {
    if (!currentGroup) {
      return;
    }
    await SchedulerService.setEventGameCastSchedulerDate(currentGroup.eventId, {
      capacity,
      endTime: (event.end as Date).getTime(),
      startTime: (event.start as Date).getTime(),
      newStartTime: (event.newStartTime as Date)
        ? new Date(event.newStartTime as Date).getTime()
        : null,
      instanceType: currentGroup.StreamClass,
      region: currentGroup.region,
    });

    await getCurrentGroupCalendar(currentGroup.eventId);
  };

  const handleGetAndMergeCalendarEvents = async () => {
    if (!eventId) {
      return;
    }

    await getPurewebCalendar(eventId);
    await getCurrentGroupCalendar(eventId);
  };

  const handleChangeTabValue = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue as GameCastTabValues);
  };

  const [sortingConfig, setSortingConfig] = useState<ISortingConfig>({
    criteria: SortingCriteriaTypes.Time,
    order: SortingOrderTypes.DESC,
  });

  useEffect(() => {
    setCurrentEvents([...currentGameCastEvents, ...currentPurewebEvents]);
  }, [currentGameCastEvents, currentPurewebEvents]);

  useAsyncEffect(async () => {
    setLoadingGroup(true);

    if (!region || !eventId) {
      navigate('/admin/gamecast');
      setLoadingGroup(false);

      return;
    }

    if (streamGroupId) {
      const group = await EventsService.getStreamGroupConfig(
        eventId,
        streamGroupId as string,
        region
      );
      setGameCastGroup(group.Id, group);
    }

    if (modelId) {
      await loadCurrentPurewebModel(modelId);
    }

    setLoadingGroup(false);

    return () => {
      emptyCurrentGroup();
      handleCloseModal();
      emptyCurrentCalendar();
      emptyCurrentModelLogs();
    };
  }, []);

  useAsyncEffect(async () => {
    await handleGetAndMergeCalendarEvents();
  }, []);

  return {
    currentGroup,
    currentLogs,
    modalState,
    isCalendarLoading,
    currentGameCastEvents,
    currentEvents,
    isUpdatingCapacity,
    tabValue,
    loadingGroup,
    streamGroupId,
    isFromEvent,
    sortingConfig,
    setSortingConfig,
    handleCloseModal,
    setTabValue,
    setLoadingGroup,
    handleConfirm,
    handleChangeTabValue,
  };
};
