import { Button } from '@mui/material';

interface ICopyInviteLink {
  inviteLink: string;
}

const CopyInviteLink = ({ inviteLink }: ICopyInviteLink) => {
  const handleClick = () => {
    navigator.clipboard.writeText(inviteLink);
  };

  return (
    <Button
      onClick={handleClick}
      disabled={!inviteLink}
      sx={{ whiteSpace: 'nowrap' }}
    >
      Copy link
    </Button>
  );
};

export default CopyInviteLink;
