import VideoFileIcon from '@mui/icons-material/VideoFile';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

export const MediaTypeIcon = ({ media }: { media: string }) => {
  if (media === 'video') return <VideoFileIcon />;

  if (media === 'image') return <ImageIcon />;

  return <InsertDriveFileIcon />;
};
