import { LinearProgress, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { http } from 'services/http';
import { Container } from './style';

function ViewEmailTemplate() {
  const { type, id } = useParams();
  const [emailTemplate, setEmailTemplate] = useState('');
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchTemplate = async () => {
    setIsLoadingContent(true);
    await http.emails
      .getEmailTemplate({ id: id as string, type: type as string })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        setEmailTemplate(res.html);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setIsLoadingContent(false);
      });
  };

  useEffect(() => {
    fetchTemplate();
    // eslint-disable-next-line
  }, []);

  const getInnerHTML = () => {
    return {
      __html: emailTemplate,
    };
  };

  return (
    <Container>
      {isLoadingContent ? (
        <>
          <Stack>
            <LinearProgress />
          </Stack>
        </>
      ) : (
        <div dangerouslySetInnerHTML={getInnerHTML()} />
      )}
    </Container>
  );
}

export default ViewEmailTemplate;
