import { ID_AUTH_NAME, TOKEN_AUTH_NAME } from 'configs/authConfigs';
import { getToken } from 'shared/utils/tokenManager';

const useAccess = () => {
  const getConnectToken = () => getToken(TOKEN_AUTH_NAME);

  const getConnectID = () => getToken(ID_AUTH_NAME) || '';

  return { getConnectToken, getConnectID };
};

export default useAccess;
