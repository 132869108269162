import { StreamGroupLogsActions } from 'redux/slices/interfaces';

export const generateLogMessage = ({
  actionType,
}: {
  actionType: StreamGroupLogsActions;
}): string => {
  const {
    ActivatingMachines,
    Delete,
    ReserveMachines,
    StoppingMachines,
    Update,
  } = StreamGroupLogsActions;

  const log = {
    [ActivatingMachines]: `Virtual Machines machines started to turn on`,
    [Delete]: `Virtual Machines event has been deleted`,
    [ReserveMachines]: `Virtual Machines event has been reserved`,
    [StoppingMachines]: `Virtual Machines started to shut down`,
    [Update]: `User updated event config`,
  };

  return log[actionType] || '';
};
