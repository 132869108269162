import { Divider, Stack } from '@mui/material';
import { AppDefaultLayout } from 'layouts/AppDefaultLayout';
import { useAppSelector } from 'shared/hooks/useStore';
import { selectUser } from 'redux/slices/userSlice';
import { Head } from './styled';
import { useEffect } from 'react';
import { selectEvents } from 'redux/slices/eventsSlice';
import { useLoadEvents } from 'shared/hooks/useLoadEvents';
import LoadingProgress from '../../../../components/LoadingProgress';
import EventsPageLayout from '../../components/EventsPageLayout';

const HomePage = () => {
  const { listByConnectedId: loadEvents } = useLoadEvents();
  const { user } = useAppSelector(selectUser);
  const { isEventsLoading, events } = useAppSelector(selectEvents);

  useEffect(() => {
    loadEvents();
    // eslint-disable-next-line
  }, []);

  return (
    <AppDefaultLayout>
      <Head>
        <h1>
          Welcome{''}
          {user?.data && `, ${user?.data?.firstName} ${user?.data?.lastName}`}
        </h1>
        <p>This is your dashboard. Select an event or create a new one.</p>
      </Head>
      <Divider />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        paddingBottom={4}
      >
        {isEventsLoading ? (
          <LoadingProgress />
        ) : (
          <EventsPageLayout
            userEmail={user?.data?.email || ''}
            events={events}
          />
        )}
      </Stack>
    </AppDefaultLayout>
  );
};

export default HomePage;
