import {
  Divider,
  // FormControlLabel,
  // FormGroup,
  Grid,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useState, useEffect } from 'react';
import { RemoveButtonWithDialog } from 'shared/components/buttons/RemoveButtonWithDialog';
import { MediaTypeIcon } from 'shared/components/MediaTypeIcon';
import { PointOfInterest } from 'shared/types/models/pointOfInterest';
import { fromStringToPositionObject } from '../positionFormatter';
import { UploadIconWrapper } from '../styled';
import {
  Android12Switch,
  DisplayImageContainer,
  POICard,
  RemoveButtonContainer,
} from './styled';

interface PointOfInterestCardProps {
  item: PointOfInterest;
  onClick: () => void;
  onClickRemove?: () => void;
  onChangeStatus: (isActive: boolean) => void;
}

export const PointOfInterestCard = ({
  item,
  onClick,
  onClickRemove,
  onChangeStatus,
}: PointOfInterestCardProps) => {
  const [isActive, setIsActive] = useState(false);
  const position = fromStringToPositionObject(String(item.position));

  useEffect(() => {
    setIsActive(item.isActive || false);
  }, []);

  const handleOnChangeStatus = () => {
    setIsActive(!isActive);
    onChangeStatus(!isActive);
  };

  return (
    <Grid item key={item.id} xs={6} sm={3} md={2} sx={{ overflow: 'hidden' }}>
      <POICard>
        <Tooltip title="Update Point Of Interest">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={onClick}
            sx={{
              padding: 0,
              overflow: 'hidden',
            }}
          >
            <UploadIconWrapper>
              <MediaTypeIcon media="" />
            </UploadIconWrapper>
            <DisplayImageContainer>
              <img
                src={item.displayImage}
                alt={`points-of-interest-${item.id}`}
                style={{ width: '100%' }}
                onError={(event: SyntheticEvent<HTMLImageElement>) =>
                  (event.currentTarget.src =
                    'https://tf-cms-prod-assets.mytaverse.com/placeholder/placeholder-image.jpg')
                }
              />
            </DisplayImageContainer>
          </IconButton>
        </Tooltip>

        <Typography variant="h4" mt={2} color="primary">
          {item.displayName && item.displayName.length > 30
            ? item.displayName?.substring(0, 30) + '...'
            : item.displayName}
        </Typography>

        <Divider sx={{ margin: '6px 0' }} />

        <Stack>
          <p>
            <b>Description:</b> {item.description}
          </p>
          <p>
            <b>Media Type:</b>{' '}
            {item?.mediaType
              ?.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
              .replace(/^./, (str) => str.toUpperCase()) || 'N/D'}
          </p>
          <p>
            <b>Active:</b>{' '}
            <Android12Switch
              checked={isActive}
              onChange={handleOnChangeStatus}
            />
          </p>
        </Stack>

        {position && (
          <p>
            x: {position.x}, y: {position.y}, z: {position.z}, w: {position.w}
          </p>
        )}

        <Stack>
          {item.asset && (
            <Link target="_blank" href={item.asset} underline="none">
              Asset Link
            </Link>
          )}
        </Stack>

        <Stack>
          {item.description3dImage && (
            <Link
              target="_blank"
              href={item.description3dImage}
              underline="none"
            >
              Description 3D Image
            </Link>
          )}
        </Stack>

        <RemoveButtonContainer>
          <RemoveButtonWithDialog
            title="Remove Point Of Interest"
            message="Are you sure to remove this point? This action can't be undone."
            okModalButtonText="Remove"
            deleteCallback={onClickRemove}
          />
        </RemoveButtonContainer>
      </POICard>
    </Grid>
  );
};
