export enum PoiMediaType {
  Video = 'video',
  Website = 'websiteUrl',
  Image = 'image',
  File = 'file',
  ThreeDObjectView = '3dObjectView',
  ThreeDProp = '3dProp',
  BrowserId = 'browserId',
  BrowserUrl = 'browserUrl',
}
