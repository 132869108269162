import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { http } from 'services/http';
import { useAppDispatch } from './useStore';
import { setActualAvatar } from '../../redux/slices/avatarsSlice';

export const useGetAvatar = () => {
  const { avatarId } = useParams();

  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(() => {
    http.avatars
      .getById({ avatarId: avatarId as string })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        dispatch(setActualAvatar(res.data));
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  }, [dispatch, enqueueSnackbar, avatarId]);
};
