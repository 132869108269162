import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { setTicketsList } from 'redux/slices/ticketsSlice';
import { http } from 'services/http';
import { useAppDispatch } from './useStore';

export const useLoadTickets = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(() => {
    http.tickets
      .list({ eventId: id as string })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        dispatch(setTicketsList(res.data));
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  }, [dispatch, enqueueSnackbar, id]);
};
