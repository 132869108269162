export const useStyles = () => ({
  inputsGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: '2%',
    rowGap: 3,
    width: '100%',
    justifyContent: 'flex-start',

    '& .MuiFormControl-root': {
      width: '49%',
      '& .MuiOutlinedInput-root': {
        mb: 0,
      },
    },
  },
});
