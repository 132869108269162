import { RegisterForm } from './RegisterForm';
import { AccountFormLayout } from 'layouts/AccountFormLayout';

export const RegisterPage = () => {
  return (
    <AccountFormLayout>
      <RegisterForm />
    </AccountFormLayout>
  );
};
