import { colors } from 'layouts/theme/AppTheme/colors';

export const useStyles = () => ({
  header: {
    display: 'grid',
    gridTemplateColumns: ' 1fr 2fr repeat(5, 1fr)',
    rowGap: 1,
    '& p': {
      textAlign: 'left',
      width: '100%',
      mb: 2,
      fontSize: 14,
      fontWeight: 'bold',
    },
    '& button': {
      p: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      width: '100%',
      '&:hover': {
        background: 'none',
      },
      '& p': {
        textAlign: 'left',
        fontSize: 14,
        color: colors.alpha.black[100],
        width: 'auto',
        mb: 0,
      },

      '& svg': {
        mt: 0.3,
        width: 18,
        height: 18,
        color: colors.alpha.black[100],
      },
    },
  },
  filterDropdown: {
    width: 200,
    mb: 5,
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
});
