import { themeColors } from 'layouts/theme/AppTheme/colors';

export const useStyles = () => ({
  tab: {
    color: themeColors.white,
    width: '50%',
    height: 140,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: themeColors.secondary,
    borderRadius: 1,
    cursor: 'pointer',
    position: 'relative',
    '& svg': {
      color: themeColors.white,
      fontSize: 47,
      zIndex: 1,
    },
    '& p': {
      zIndex: 1,
    },
    '& input': {
      width: 158,
      height: 99,
      position: 'absolute',
      cursor: 'pointer',
      opacity: 0,
    },
    '& .customer__background': {
      width: '100%',
      maxHeight: 140,
      maxWidth: 250,
      position: 'absolute',

      left: 0,
      top: 0,
      zIndex: 0,
    },
    '& img': {
      maxHeight: 50,
      maxWidth: 250,
    },
  },

  dragging: {
    transform: 'scale(1.02)',
  },

  saveStyles: {
    background: themeColors.primary,
    color: themeColors.white,
  },
});
