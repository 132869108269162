import React, { useMemo } from 'react';
import { ImageListType } from 'react-images-uploading';
import { useFormik } from 'formik';
import { useUploadAssetProccess } from 'shared/hooks/useUploadAssetProccess';
import { renameFileToUploadPattern } from 'shared/utils/renameFileToUploadPattern';
import {
  CustomEventFormProps,
  CustomerEventTheme,
  IImagesPreview,
  IUploadedImage,
} from './interfaces';
import { ICustomerEvent } from 'services/http/types/customersService';
import { SelectChangeEvent } from '@mui/material';
import CustomersService from 'services/http/customersService';

export const useCustomStyles = ({ subdomain }: CustomEventFormProps) => {
  const { uploadAssetProccess } = useUploadAssetProccess();
  const [selectedFiles, setSelectedFiles] = React.useState();
  const [eventStyles, setEventStyles] = React.useState<Omit<
    ICustomerEvent,
    'backgroundImage' | 'customerLogo' | 'signFormSelectedTab'
  > | null>(null);

  const [selectValue, setSelectValue] = React.useState<CustomerEventTheme>(
    CustomerEventTheme.Dark
  );

  const [backgroundImagePreview, setBackgroundImagePreview] =
    React.useState<IUploadedImage | null>(null);
  const [backgroundImageUploading, setBackgroundImageUploading] =
    React.useState(false);

  const [logoPreview, setLogoPreview] = React.useState<IUploadedImage | null>(
    null
  );
  const [logoUploading, setLogoUploading] = React.useState(false);

  const [imagesPreview, setImagesPreview] =
    React.useState<IImagesPreview | null>(null);

  const initialValues = useMemo(
    () => ({
      eventName: eventStyles?.eventName || '',
      primaryColor: eventStyles?.loginButtonBackgroundColor || '',
      secondaryColor: eventStyles?.loginButtonBackgroundColorHover || '',
      colorTheme: eventStyles?.colorTheme || '',
    }),
    [eventStyles]
  );

  const handleChangeUploadLogo = (imageList: ImageListType) => {
    const file = imageList[0].file as File;
    const reader = new FileReader();
    const renamedFile = renameFileToUploadPattern(file);

    reader.onloadend = () => {
      setLogoPreview({
        file,
        imagePreview: imageList[0].dataURL as string,
      });
    };
    reader.readAsDataURL(renamedFile);
  };

  let backgroundUrl: string;
  let logoUrl: string;

  const onSubmit = async () => {
    if (backgroundImagePreview && backgroundImagePreview.file) {
      setBackgroundImageUploading(true);

      backgroundUrl = await publishToS3(backgroundImagePreview.file);

      setImagesPreview((prev) => ({ ...prev, backgroundImage: backgroundUrl }));
      setBackgroundImagePreview(null);

      setBackgroundImageUploading(false);
    }

    if (logoPreview && logoPreview.file) {
      setLogoUploading(true);

      logoUrl = await publishToS3(logoPreview.file);

      setImagesPreview((prev) => ({ ...prev, customerLogo: logoUrl }));
      setLogoPreview(null);

      setLogoUploading(false);
    }

    await CustomersService.updateCustomerEvent(subdomain, {
      ...values,
      backgroundImage:
        backgroundUrl || (imagesPreview?.backgroundImage as string),
      customerLogo: logoUrl || (imagesPreview?.customerLogo as string),
      colorTheme: values.colorTheme || selectValue,
    });
  };

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  const publishToS3 = async (file: File): Promise<string> => {
    const assetUrl = await uploadAssetProccess({
      asset: file,
      foldername: 'images',
      contentType: 'image',
    });

    return assetUrl || '';
  };

  const handleChangeUploadImage = (imageList: ImageListType) => {
    const file = imageList[0].file as File;
    const reader = new FileReader();
    const renamedFile = renameFileToUploadPattern(file);

    reader.onloadend = () => {
      setBackgroundImagePreview({
        file,
        imagePreview: imageList[0].dataURL as string,
      });
    };
    reader.readAsDataURL(renamedFile);
  };

  const handleSelectedValueChange = (event: SelectChangeEvent) => {
    setSelectValue(event.target.value as CustomerEventTheme);
  };

  return {
    imagesPreview,
    selectedFiles,
    values,
    backgroundImagePreview,
    backgroundImageUploading,
    selectValue,
    logoPreview,
    logoUploading,
    handleChangeUploadLogo,
    handleChangeUploadImage,
    setImagesPreview,
    setSelectedFiles,
    publishToS3,
    handleChange,
    handleSubmit,
    setEventStyles,
    handleSelectedValueChange,
  };
};
