import { Button, Tooltip } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { http } from 'services/http';
import { useLoadParticipants } from 'shared/hooks/useLoadParticipants';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { InviteFilters } from 'shared/types/models/invite';
import { setActualFilter } from 'redux/slices/invitesSlice';

export default function ToggleSpeaker({ participant }: { participant: any }) {
  const dispatch = useDispatch();
  const { get: reloadParticipants } = useLoadParticipants();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  const handleChangeSpeaker = async () => {
    setLoading(true);

    const data = {
      isSpeaker: String(!participant.isSpeaker),
    };

    http.invites
      .updateParticipant({
        participantId: participant.id,
        data,
      })
      .then((res) => {
        enqueueSnackbar(res.message, { variant: 'success' });
        dispatch(setActualFilter(InviteFilters.All));
        reloadParticipants();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        setLoading(false);
      });
  };

  return (
    <>
      {participant?.isSpeaker ? (
        <Tooltip title="Is Speaker?">
          <Button
            size="small"
            startIcon={<MicIcon />}
            color="success"
            onClick={handleChangeSpeaker}
            disabled={loading}
          >
            Yes
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title="Is Speaker?">
          <Button
            size="small"
            startIcon={<MicOffIcon />}
            color="secondary"
            onClick={handleChangeSpeaker}
            disabled={loading}
          >
            No
          </Button>
        </Tooltip>
      )}
    </>
  );
}
