import { useSnackbar } from 'notistack';
import { http } from 'services/http';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/useStore';
import {
  selectAvatars,
  setAvailableAvatarsList,
  setAvatarListByEvent,
  setFetching,
  setIsAvailableAvatarsLoading,
} from 'redux/slices/avatarsSlice';

export const useAvatars = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { availableAvatars } = useAppSelector(selectAvatars);

  const loadAvatars = async ({ eventId }: { eventId: string }) => {
    dispatch(setFetching(true));
    await http.avatars
      .listByEvent({
        eventId,
      })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        dispatch(setAvatarListByEvent(res.data));
      })
      .finally(() => {
        dispatch(setFetching(false));
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const loadAvailableAvatars = async ({ eventId }: { eventId: string }) => {
    if (availableAvatars?.length) return;

    await reloadAvailableAvatars({ eventId });
  };

  const reloadAvailableAvatars = async ({ eventId }: { eventId: string }) => {
    dispatch(setIsAvailableAvatarsLoading(true));

    await http.avatars
      .listAvailables({
        eventId,
      })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        dispatch(setAvailableAvatarsList(res.data));
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        dispatch(setIsAvailableAvatarsLoading(false));
      });
  };
  return {
    loadAvatars,
    loadAvailableAvatars,
    reloadAvailableAvatars,
  };
};
