import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import axiosWithUserToken from './axiosInstances/axiosWithUserToken';
import { HttpResponseDefault } from './types/httpGeneric';
import { CreateSkinRequest, UpdateSkinRequest } from './types/skins';

const skinsModel = {
  async list(): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get('cms/skins-model/list');
      return { message: 'Loaded', data: data.skinModel };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async getById({ skinId }: { skinId: string }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/skins-model/get/${skinId}`
      );
      return { message: 'Loaded', data: data.skinModel };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async create(payload: CreateSkinRequest): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().post(
        '/cms/skins-model/create',
        payload
      );
      return {
        message: `Skin ${payload.name} Created.`,
        data: data.skinModel,
      };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async update({
    formData,
    skinId,
  }: {
    formData: UpdateSkinRequest;
    skinId: string;
  }): Promise<HttpResponseDefault> {
    const dataFormated = new FormData();

    Object.entries(formData).forEach(([key, value]) =>
      dataFormated.append(`${key}`, value as any)
    );

    try {
      const { data } = await axiosWithUserToken().put(
        `/cms/skins-model/update/${skinId}`,
        dataFormated,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return { message: 'Skin Updated.', data: data.skinModel };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async remove({ skinId }: { skinId: string }): Promise<HttpResponseDefault> {
    try {
      const res = await axiosWithUserToken().delete(
        `/cms/skins-model/delete/${skinId}`
      );
      return { message: `Skin ${skinId} Removed.`, data: res };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async loadSkinsModelsAvailableByAvatar({
    eventId,
    avatarId,
  }: {
    eventId: string;
    avatarId: string;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `cms/skins-model/list/avatar-available/${eventId}/${avatarId}`
      );
      return { message: 'Loaded', data: data.skinsModel };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async listByAvatar({
    avatarId,
  }: {
    avatarId: string;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `cms/skins-model/list/avatar/${avatarId}`
      );
      return { message: 'Loaded', data: data.skinsModel };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default skinsModel;
