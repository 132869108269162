import { useAppDispatch } from 'shared/hooks/useStore';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { http } from 'services/http';
import {
  setAllEventsList,
  setEvent,
  setEvents,
  setIsEventLoading,
  setIsEventsLoading,
} from 'redux/slices/eventsSlice';
import useAccess from './useAccess';
import { useParams } from 'react-router-dom';

interface LoadEvents {
  listByConnectedId: () => void;
  list: () => void;
  get: () => void;
}

export const useLoadEvents = (): LoadEvents => {
  const { getConnectID } = useAccess();
  const urlParams = useParams();

  const dispatch = useAppDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const listByConnectedId = () => {
    dispatch(setIsEventsLoading(true));
    http.events
      .list({
        userId: getConnectID(),
      })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        dispatch(setEvents(res.data));
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        dispatch(setIsEventsLoading(false));
      });
  };

  const list = useCallback(() => {
    http.events
      .listAll()
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        dispatch(setAllEventsList(res.data));
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  }, [dispatch, enqueueSnackbar]);

  const get = useCallback(() => {
    if (!urlParams.id) return;
    dispatch(setIsEventLoading(true));
    http.events
      .get({ eventId: urlParams.id as string })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        dispatch(setEvent(res.data));
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'success' });
      })
      .finally(() => {
        dispatch(setIsEventLoading(false));
      });
  }, [urlParams.id, dispatch, enqueueSnackbar]);

  return { listByConnectedId, list, get };
};
