import { useAppDispatch } from 'shared/hooks/useStore';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { http } from 'services/http';
import { setIsRoomsLoading, setRoomList } from 'redux/slices/roomsSlice';

export const useLoadRooms = () => {
  const dispatch = useAppDispatch();

  const urlParams = useParams();
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(() => {
    dispatch(setIsRoomsLoading(true));

    http.rooms
      .listByEvent({
        eventId: urlParams.id as string,
      })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }

        dispatch(setRoomList(res.data));
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        dispatch(setIsRoomsLoading(false));
      });
  }, [dispatch, enqueueSnackbar, urlParams.id]);
};
