import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface UserStoreData {
  connectToken: string;
  connectID: string;
  data: {
    id: string;
    chatImage: string;
    confirmedEmail: number;
    email: string;
    firstName: string;
    lastName: string;
    profileImage: string;
    [key: string]: any;
  };
}

export type UserState = {
  user: UserStoreData | null;
  data: any;
  pending: boolean;
  error: boolean;
};

const initialState: UserState = {
  user: null,
  data: null,
  pending: false,
  error: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, { payload }) => {
      state.user = payload;
    },
    deleteUserData: (state) => {
      state.data = null;
    },
  },
});

export const selectUser = (state: RootState) => state.userData;
export const { setUserData, deleteUserData } = userSlice.actions;

export default userSlice.reducer;
