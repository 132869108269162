export const appPaths = {
  user: {
    profile: '/account/profile',
  },
  private: {
    home: '/',
    event: {
      dashboard: '/event/dashboard',
    },
    rooms: {
      dashboard: '/event/:id/rooms/dashboard',
      details: '/event/:id/room/:roomId/details',
    },
    skins: {
      dashboard: '/event/:id/avatar/:avatarId/skins/dashboard',
    },
    invites: {
      index: '/event/:id/invites',
      add: '/event/:id/invites/add',
    },
    tickets: {
      index: '/event/:id/tickets',
    },
    avatars: {
      dashboard: '/event/:id/avatars/dashboard',
    },
  },
  public: {
    account: {
      login: '/account/login',
      register: '/account/register',
      passwordRecovery: '/account/password_recovery',
      passwordReset: '/account/password_reset',
      oktaCallback: '/login/callback',
      oktaRedirect: '/redirect',
    },
    auth: {
      confirmPassword: '/auth/confirm_password',
    },
    invite: {
      confirmEventSubscribe: '/invite/confirm_event_subscribe',
    },
    emails: {
      viewEmail: '/emails',
    },
  },
  admin: {
    dashboard: '/admin/dashboard',
    maps: '/admin/maps',
    mapDetails: '/admin/map/details',
    regions: '/admin/regions',
    regionDetails: '/admin/region/detail',
    streamServices: '/admin/stream-services',
    events: '/admin/events',
    skins: '/admin/skins',
    skinDetails: '/admin/skin/details',
    avatar: '/admin/avatar',
    defaultSettings: '/admin/default_settings',
    whitelistBlacklist: '/admin/whitelist_blacklist',
  },
  calendar: {
    gameCastScheduler: '/scheduler',
    gameCastSchedulerItem: 'scheduler/:eventId',
  },
};
