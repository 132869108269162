import axios from 'axios';
import { apiUrl } from 'configs/apiConfigs';
import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import { HttpReponseDefault } from 'shared/types/httpGeneric';
import axiosCreate from './axiosInstances/axiosWithUserToken';

const user = {
  async list(): Promise<HttpReponseDefault> {
    try {
      const { data } = await axiosCreate().get('/cms/users/list');
      return { data: data.users };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async listAllEmailsFromUsers(): Promise<HttpReponseDefault> {
    try {
      const { data } = await axiosCreate().get('/cms/users/list');

      const users: string[] = [];

      for (const item of data.users) {
        users.push(item.email);
      }

      if (users.length > 0) users.sort();

      return { data: users };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async get(token: string, id: string): Promise<HttpReponseDefault> {
    try {
      const { data } = await axios.get(`${apiUrl}/cms/users/get/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      return { message: 'User data changed', data: data.user };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async update({
    token,
    id,
    data,
  }: {
    token: string;
    id: string;
    data: any;
  }): Promise<HttpReponseDefault> {
    try {
      const dataFormated = new FormData();

      Object.entries(data).forEach(([key, value]) =>
        dataFormated.append(`${key}`, value as any)
      );

      const result = await axios.put(
        `${apiUrl}/cms/users/update/${id}`,
        dataFormated,
        {
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return { message: 'User data changed', data: result.data.user };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async remove({ token, id }: { token: string; id: string }) {
    try {
      const result = await axios.delete(`${apiUrl}/cms/users/delete/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      return result.data;
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default user;
