import * as yup from 'yup';

export const validationSchema = yup.object({
  expiresIn: yup
    .string()
    .required('Expires is required')
    .matches(
      /^(\d+)(h|d|w|m)$/,
      `Invalid input: It must be a number followed by "h", "d", "w", or "m".`
    ),
});
