import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { CustomUserClaims, UserClaims } from '@okta/okta-auth-js';
import { http } from 'services/http';
import sign from 'jwt-encode';
import appAccess from 'configs/appAccess';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { setToken } from 'shared/utils/tokenManager';
import { ID_AUTH_NAME, ROLE_NAME, TOKEN_AUTH_NAME } from 'configs/authConfigs';
import { appPaths } from 'app/router/appPaths';
import { AccountFormLayout } from 'layouts/AccountFormLayout';
import { Box, CircularProgress, Stack } from '@mui/material';
import { isSpecialRole } from 'shared/utils/verifyRole';

const RedirectOkta = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaims<CustomUserClaims> | null>(
    null
  );

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      if (authState?.idToken?.claims) setUserInfo(authState.idToken.claims);

      // You can also get user information from the `/userinfo` endpoint
      /*oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });*/
    }
  }, [authState, oktaAuth]); // Update if authState changes

  useEffect(() => {
    if (userInfo) handleSubmitOktaInfo();
    // eslint-disable-next-line
  }, [userInfo]);

  const getToken = (payload: any) => {
    return sign(payload, appAccess);
  };

  const handleSubmitOktaInfo = async () => {
    const okta = {
      email: userInfo?.email,
      name: userInfo?.name,
      idOkta: userInfo?.aud,
      provider: 'okta',
    };

    const oktaToken = authState?.accessToken?.accessToken;
    const payload = { message: getToken(okta) };

    await http.auth.oktaSignIn({ oktaToken, payload }).then((result) => {
      if (result.error) {
        enqueueSnackbar(result.message, { variant: 'error' });
        return;
      }

      if (result.token) {
        if (result?.user?.role && isSpecialRole(result.user.role)) {
          setToken(ROLE_NAME, 'admin');
        }

        setToken(TOKEN_AUTH_NAME, result.token);
        setToken(ID_AUTH_NAME, result.user.id);

        setTimeout(() => {
          navigate(appPaths.private.home);
        }, 2000);
      }
    });
  };

  if (!userInfo) {
    return (
      <div>
        <p>Fetching user profile...</p>
      </div>
    );
  }

  return (
    <AccountFormLayout>
      <Stack direction="column" alignItems="center" justifyContent="center">
        <h3>Okta Authentication</h3>
        <p>Redirecting to app...</p>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      </Stack>
    </AccountFormLayout>
  );
};

export default RedirectOkta;
