import SaveIcon from '@mui/icons-material/Save';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
} from '@mui/material';
import { appPaths } from 'app/router/appPaths';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { clearEmailList, selectEmails } from 'redux/slices/emailsSlice';
import { http } from 'services/http';
import { CircularProgressWithLabel } from 'shared/components/progress/CircularProgressWithLabel';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { chunkArray } from '../../../shared/utils/chunkArray';
import { getInviteInputData } from './helpers';

interface SubscribeEmailProps {
  expiresInEnabled: boolean;
  expiresIsInvalid: boolean;
  expiresInInput: string;
}

const NUMBER_OF_EMAILS_LIMIT = 20;

export const SubscribeEmail = ({
  expiresInEnabled,
  expiresIsInvalid,
  expiresInInput,
}: SubscribeEmailProps) => {
  const { emails } = useAppSelector(selectEmails);
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isInviteLinkSent, setIsInviteLinkSent] = useState(false);
  const [isEmailSending, setSendingEmails] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>('');
  const [progress, setProgress] = useState(0);

  const handleSaveEmails = async (sendInviteLink: boolean) => {
    if (expiresInEnabled && expiresIsInvalid) {
      return;
    }

    setIsInviteLinkSent(sendInviteLink);
    setSendingEmails(true);

    if (emails.length <= NUMBER_OF_EMAILS_LIMIT) {
      submit(emails, sendInviteLink, expiresInInput);
      return;
    }

    setOpen(true);

    const newList = chunkArray(emails, NUMBER_OF_EMAILS_LIMIT);

    const submitSteppedResult = await submitStepped(newList, sendInviteLink);

    if (submitSteppedResult?.success) {
      dispatch(clearEmailList());

      setTimeout(() => {
        navigate(appPaths.private.invites.index.replace(':id', String(id)));

        enqueueSnackbar(`${emails.length} emails saved`, {
          variant: 'success',
        });
      }, 1000);
    }
  };

  const submitStepped = async (
    emails: string[][],
    sendInviteLink: boolean
  ): Promise<{ success: boolean } | undefined> => {
    let success;
    let count = 0;

    for (const item of emails) {
      count++;
      const step: number = Math.round((count * 100) / emails.length);
      setProgress(step);

      const inputData = getInviteInputData({
        emails: item,
        eventId: id as string,
        sendInviteLink,
      });

      try {
        await http.invites.invite(inputData);
        success = { success: true };
      } catch (error: any) {
        setError('Error during stepped send email process: ' + error.message);
        success = { success: false };
      }
    }

    return success;
  };

  const submit = (
    emails: string[],
    sendInviteLink: boolean,
    expiresIn: string
  ) => {
    const inputData = getInviteInputData({
      emails,
      eventId: id as string,
      sendInviteLink,
      expiresIn,
    });

    http.invites
      .invite(inputData)
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }

        dispatch(clearEmailList());

        navigate(appPaths.private.invites.index.replace(':id', String(id)));

        enqueueSnackbar(res.message, { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setSendingEmails(false);
      });
  };

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() => handleSaveEmails(true)}
            startIcon={<SaveIcon />}
            variant="contained"
          >
            {isEmailSending && isInviteLinkSent
              ? 'Saving...'
              : 'Save and send e-mails'}
          </Button>
          <Button
            onClick={() => handleSaveEmails(false)}
            startIcon={<SaveIcon />}
            variant="contained"
          >
            {isEmailSending && !isInviteLinkSent ? 'Saving...' : 'Save'}
          </Button>
        </Stack>
      </Stack>

      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!error.length && (
          <DialogContent>
            <>
              <DialogContentText>
                We are subscribing {emails.length} selected emails to the event.
                This should take some time.
              </DialogContentText>
              <Stack
                justifyContent="center"
                alignItems="center"
                style={{ padding: 8 }}
              >
                <CircularProgressWithLabel value={progress} />
              </Stack>
            </>
          </DialogContent>
        )}

        {error.length > 0 && (
          <DialogContent>
            <Alert severity="error">{error}</Alert>
          </DialogContent>
        )}

        {error.length > 0 && (
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
