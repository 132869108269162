import moment from 'moment';
import * as yup from 'yup';

export const validationSchema = yup.object({
  startTime: yup.string().required('Need to choose a start time'),
  endTime: yup
    .string()
    .test('isValid', 'End Time has to be after Start Time.', function (value) {
      return moment(value).isAfter(moment(this.parent.startTime));
    })
    .required('Need to choose an end time'),
});

export const parseTimeToISO = (time: Date) =>
  new Date(time.getTime() - time.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, -1);
