import { Button, DialogActions, DialogContent } from '@mui/material';
import { BoxDialog } from './styled';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';
import { BootstrapDialogTitle } from './BootstrapDialogTitle';

export interface BoxDialogModalHandles {
  handleOpen: () => void;
  handleClose: () => void;
}

interface BoxDialogModalProps {
  title: string;
  submitButton?: any;
  onCloseDialog?: () => void;
  children?: React.ReactNode;
}

const BoxDialogModal: ForwardRefRenderFunction<
  BoxDialogModalHandles,
  BoxDialogModalProps
> = ({ title = 'Box Title', submitButton, children, onCloseDialog }, ref) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (onCloseDialog) onCloseDialog();
    setOpen(false);
  };

  useImperativeHandle(ref, () => {
    return { handleOpen, handleClose };
  });

  return (
    <BoxDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      scroll="body"
      fullWidth
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {submitButton}
      </DialogActions>
    </BoxDialog>
  );
};

export default forwardRef(BoxDialogModal);
