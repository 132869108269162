import { Link, Outlet } from 'react-router-dom';
import { appPaths } from './appPaths';
import { useAppSelector } from 'shared/hooks/useStore';
import { selectUser } from 'redux/slices/userSlice';
import { selectEvents } from 'redux/slices/eventsSlice';
import { Button, Stack, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { isSpecialRole } from 'shared/utils/verifyRole';
import { useEffect } from 'react';
import LoadingProgress from 'components/LoadingProgress';
import { useLoadEvents } from 'shared/hooks/useLoadEvents';

export const RequireEventAuth = () => {
  const { get: getEvent } = useLoadEvents();
  const { user } = useAppSelector(selectUser);
  const { event, isEventLoading } = useAppSelector(selectEvents);

  useEffect(() => {
    getEvent();
  }, []);

  return (
    <>
      {isEventLoading ? (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          padding={4}
        >
          <LoadingProgress />
        </Stack>
      ) : (
        <>
          {(event && event.isOwner) ||
          event.isAuthUser ||
          (user?.data?.role && isSpecialRole(user.data.role)) ? (
            <>
              <Outlet />
            </>
          ) : (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              padding={4}
            >
              <Typography variant="h4">Oops.. </Typography>
              <p>
                Maybe this event no longer exists or you don&apos;t have
                permission to access it.
              </p>
              <p>
                <Link
                  to={appPaths.private.home}
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    textDecoration: 'none',
                  }}
                >
                  <Button variant="outlined">
                    <HomeIcon fontSize="small" sx={{ marginRight: 1 }} />
                    Home
                  </Button>
                </Link>
              </p>
            </Stack>
          )}
        </>
      )}
    </>
  );
};
