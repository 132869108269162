import { Box, Button, TextField } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useAuth } from 'shared/hooks/useAuth';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LoginFormValues, SignInRequestProps } from 'shared/types/account';
import { validationSchema } from './validationSchema';
import { appPaths } from 'app/router/appPaths';
import {
  SocialLoginButton,
  socialLogins,
} from 'shared/components/buttons/SocialButtons';
import { ProviderType } from 'services/http/firebase';

export const LoginForm = () => {
  const { signIn, socialSignInUp } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const location = useLocation();

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const from = location.state?.from?.pathname || appPaths.private.home;

  const handleSubmit = (
    data: SignInRequestProps,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true);
    signIn(data)
      .then((res) => {
        setSubmitting(false);

        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }

        enqueueSnackbar(res.message, { variant: 'success' });
        navigate(from, { replace: true });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        setSubmitting(false);
      });
  };

  const handleSocialLogin = (event: React.MouseEvent<HTMLButtonElement>) => {
    const provider = event.currentTarget.getAttribute('provider');
    if (provider) {
      setDisableSubmitButton(true);
      socialSignInUp(provider as ProviderType)
        .then((res) => {
          if (!res.success) {
            if (res.code !== 'auth/popup-closed-by-user') {
              enqueueSnackbar(res.message, { variant: 'error' });
            }
            setDisableSubmitButton(false);
            return;
          }
          setDisableSubmitButton(false);
          enqueueSnackbar(res.message, { variant: 'success' });
          navigate(from, { replace: true });
        })
        .catch((error) => {
          setDisableSubmitButton(false);
          enqueueSnackbar(error.message, { variant: 'error' });
        });
    }
  };

  // const handleOktaLogin = () => {
  //   oktaAuth.signInWithRedirect();
  // };

  // initial null value trigger TextField and fix problem with autofill
  const formik = useFormik({
    initialValues: {
      email: null,
      password: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }: FormikHelpers<LoginFormValues>) => {
      handleSubmit(values as SignInRequestProps, setSubmitting);
    },
  });

  return (
    <Box sx={{ width: '500px', textAlign: 'center' }}>
      <h3>Sign In with Social Media</h3>

      {/* <Button onClick={handleOktaLogin}>Login with Okta (DEV)</Button> */}
      {socialLogins.map((btn) => {
        return (
          <SocialLoginButton
            key={btn.provider}
            onClick={handleSocialLogin}
            provider={btn.provider}
          >
            {btn.label}
          </SocialLoginButton>
        );
      })}

      <h3>or Account</h3>

      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          fullWidth
          margin="normal"
          name="password"
          label="Password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        {disableSubmitButton || formik.isSubmitting ? (
          <Button
            sx={{ mt: 3 }}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled
          >
            Sending credentials...
          </Button>
        ) : (
          <Button
            sx={{ mt: 3 }}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
          >
            Sign In
          </Button>
        )}
      </form>

      <p>
        Don&apos;t you have account yet?{' '}
        <Link to={appPaths.public.account.register}>Register</Link>
      </p>

      <p>
        <Link to={appPaths.public.account.passwordRecovery}>
          Forgot Password?
        </Link>
      </p>
    </Box>
  );
};
