import { PoiMediaType } from '../../../shared/types/utils';
import { IMediaTypeDataItem } from './interfaces';

export const MEDIA_TYPE_DATA: IMediaTypeDataItem[] = [
  { label: 'Image', value: PoiMediaType.Image },
  { label: 'Video', value: PoiMediaType.Video },
  { label: '3D Object View', value: PoiMediaType.ThreeDObjectView },
  { label: '3D Prop', value: PoiMediaType.ThreeDProp },
  { label: 'File', value: PoiMediaType.File },
  { label: 'Website URL', value: PoiMediaType.Website },
  { label: 'Browser URL', value: PoiMediaType.BrowserUrl },
];
