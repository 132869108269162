import React from 'react';
import {
  Box,
  Checkbox,
  TextField,
  Select,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
} from '@mui/material';
import { PurewebRegionsCapacityProps } from './interfaces';
import { useStyles } from './styles';
import { useAppSelector } from 'shared/hooks/useStore';
import { activeRegionsSelector } from 'redux/slices/admin/purewebSlice';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PurewebRegionsCapacity = ({
  currentActiveRegions,
  setCheckboxesState,
  formik,
}: PurewebRegionsCapacityProps) => {
  const classes = useStyles();
  const [selectedRegion, setSelectedRegion] = React.useState<string[]>(
    currentActiveRegions ? currentActiveRegions.map((r) => r.code) : []
  );
  const mergedCalendarRegions = useAppSelector(activeRegionsSelector);

  const handleChange = (event: SelectChangeEvent<typeof selectedRegion>) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'string') {
      setCheckboxesState((prev) => ({
        ...prev,
        [value]: !prev[value],
      }));

      setSelectedRegion(value.split(','));
      return;
    }

    setCheckboxesState((prev) => {
      const activeRegions = mergedCalendarRegions.reduce(
        (
          acc: {
            [key: string]: boolean;
          },
          region
        ) => {
          acc[region.code] = value.includes(region.code);
          return acc;
        },
        {}
      );

      return {
        ...prev,
        ...activeRegions,
      };
    });

    setSelectedRegion(value);
  };

  return (
    <Box>
      <FormControl sx={{ width: '100%', mb: 3 }}>
        <InputLabel id="demo-multiple-checkbox-label">Regions</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedRegion}
          onChange={handleChange}
          input={<OutlinedInput label="Regions" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {mergedCalendarRegions.map((region) => (
            <MenuItem key={region.code} value={region.code}>
              <Checkbox checked={selectedRegion.indexOf(region.code) > -1} />
              <ListItemText primary={region.full_name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={classes.inputsGroup}>
        {currentActiveRegions.map(({ code, fullName }) => (
          <TextField
            key={code}
            label={`${fullName}`}
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            name={`capacity[${code}]`}
            value={(formik.values['capacity'] as any)[code]}
            onChange={formik.handleChange}
          />
        ))}
      </Box>
    </Box>
  );
};

export default PurewebRegionsCapacity;
