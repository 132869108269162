import { createSlice } from '@reduxjs/toolkit';
import { GameCastState } from '../interfaces';
import { RootState } from 'redux/store';

const initialState: GameCastState = {
  gameCastGroups: [],
  isLoading: false,
  currentGameCastGroup: null,
  openConfigurationModal: false,
  isCalendarLoading: false,
  currentCalendarItems: [],
  currentLogs: [],
  loadingLogs: false,
};

export const gameCastSlice = createSlice({
  name: 'gameCast',
  initialState,
  reducers: {
    setGameCastGroups: (state, { payload }) => {
      state.gameCastGroups = payload;
    },
    setIsGameCastItemsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setCurrentGameCastGroup: (state, { payload }) => {
      state.currentGameCastGroup = payload;
    },
    setOpenConfigurationModal: (state, { payload }) => {
      state.openConfigurationModal = payload;
    },
    setIsCalendarLoading: (state, { payload }) => {
      state.isCalendarLoading = payload;
    },
    setCurrentCalendarItems: (state, { payload }) => {
      state.currentCalendarItems = payload;
    },
    setCurrentLogs: (state, { payload }) => {
      state.currentLogs = payload;
    },
    setLoadingLogs: (state, { payload }) => {
      state.loadingLogs = payload;
    },
  },
});

export const gameCastConfigsSelector = (state: RootState) => state.gameCastData;
export const gameCastConfigItemSelector = (state: RootState) =>
  state.gameCastData.currentGameCastGroup;
export const gameCastModalState = (state: RootState) =>
  state.gameCastData.openConfigurationModal;
export const isCalendarLoadingSelector = (state: RootState) =>
  state.gameCastData.isCalendarLoading;
export const currentCalendarItemsSelector = (state: RootState) =>
  state.gameCastData.currentCalendarItems;
export const currentLogsSelector = (state: RootState) =>
  state.gameCastData.currentLogs;
export const loadingLogsSelector = (state: RootState) =>
  state.gameCastData.loadingLogs;

export const {
  setGameCastGroups,
  setIsGameCastItemsLoading,
  setCurrentGameCastGroup,
  setOpenConfigurationModal,
  setIsCalendarLoading,
  setCurrentCalendarItems,
  setCurrentLogs,
  setLoadingLogs,
} = gameCastSlice.actions;

export default gameCastSlice.reducer;
