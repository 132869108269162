export const parsePositionAndRotation = (coods: string): number[] => {
  let newCoords = [];
  try {
    newCoords = coods.split(',').map((cood: string) => {
      try {
        return parseFloat(cood);
      } catch (error) {
        return 0;
      }
    });
  } catch (error) {
    newCoords = [0, 0, 0, 0];
  }
  return newCoords;
};
