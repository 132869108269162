import { Button, TextField } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { useSnackbar } from 'notistack';
import { http } from 'services/http';
import { selectUser, setUserData } from 'redux/slices/userSlice';
import { validationSchema } from './validationSchema';

export const PasswordEditForm = ({
  submitCallback,
}: {
  submitCallback: () => void;
}) => {
  const { user } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (
    formValues: any,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: () => void
  ) => {
    setSubmitting(true);
    const id = user?.connectID as string;
    const token = user?.connectToken as string;
    const formData = {
      ...formValues,
      email: user?.data.email,
    };
    http.user
      .update({ id, token, data: formData })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          setSubmitting(false);
          return;
        }

        const userData = {
          connectToken: token,
          connectID: id,
          data: res.data,
        };

        enqueueSnackbar('Password Changed', { variant: 'success' });
        resetForm();
        setSubmitting(false);
        dispatch(setUserData(userData));
        submitCallback();
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }: FormikHelpers<any>) => {
      handleSubmit(values, setSubmitting, resetForm);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        margin="normal"
        id="password"
        name="password"
        label="Password*"
        type="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
      <TextField
        fullWidth
        margin="normal"
        id="passwordConfirmation"
        name="passwordConfirmation"
        label="Repeat Password*"
        type="password"
        value={formik.values.passwordConfirmation}
        onChange={formik.handleChange}
        error={
          formik.touched.password && Boolean(formik.errors.passwordConfirmation)
        }
        helperText={
          formik.touched.passwordConfirmation &&
          formik.errors.passwordConfirmation
        }
      />
      {formik.isSubmitting ? (
        <Button
          sx={{ mt: 3 }}
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          disabled
        >
          Sending credentials...
        </Button>
      ) : (
        <Button
          sx={{ mt: 3 }}
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
        >
          Change Password
        </Button>
      )}
    </form>
  );
};
