import React from 'react';
import { Scheduler } from '@aldabil/react-scheduler';
import { Box, Typography } from '@mui/material';
import SchedulerService from 'services/http/schedulerService';
import EventPreview from '../EventPreview';
import SchedulerDialog from '../SchedulerDialog';
import { useStyles } from '../styles';
import { CalendarComponentProps } from './interface';
import { CustomProcessedEvent, StreamingProviders } from '../interface';
import { useGameCast } from 'shared/hooks/admin/useGameCast';
import { usePureweb } from 'shared/hooks/admin/usePureweb';
import { colors } from 'layouts/theme/AppTheme/colors';
import { getStreamingProviderFullName } from '../helpers';
import { useSnackbar } from 'notistack';
import events from 'services/http/events';

export const Calendar = ({
  currentEvents,
  currentGroup,
  handleConfirm,
  eventId,
}: CalendarComponentProps) => {
  const classes = useStyles();
  const { getCurrentGroupCalendar } = useGameCast();
  const { getPurewebCalendar } = usePureweb();
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = async (startTime: number) => {
    const event = await events.get({ eventId: eventId as string });
    const isPublished = event.data?.isPublished;

    const currentEvent = currentEvents.find(
      (e) => new Date(e.start).getTime() === startTime
    ) as CustomProcessedEvent | undefined;

    if (!currentEvent) {
      return;
    }

    if (isPublished) {
      enqueueSnackbar(
        `Can't delete published event. Please change number of machines to 0`,
        { variant: 'error' }
      );

      return;
    }

    if (currentEvent.streamingProvider === StreamingProviders.GameCast) {
      if (!currentGroup) {
        return;
      }

      await SchedulerService.deleteEventGameCastSchedulerDate(
        currentGroup.eventId,
        startTime as number
      );

      getCurrentGroupCalendar(currentGroup.eventId);

      return startTime;
    }

    if (
      currentEvent.streamingProvider === StreamingProviders.Pureweb &&
      eventId
    ) {
      await SchedulerService.deleteEvenPurewebSchedulerDate(
        eventId,
        startTime as number
      );

      await getPurewebCalendar(eventId);

      return startTime;
    }
  };
  return (
    <Box sx={classes.scheduler}>
      <Scheduler
        hourFormat="24"
        day={{ startHour: 0, endHour: 23, step: 60 }}
        month={{
          startHour: 0,
          endHour: 23,
          weekStartOn: 1,
          weekDays: [0, 1, 2, 3, 4, 5, 6],
        }}
        week={{
          startHour: 0,
          endHour: 23,
          weekStartOn: 1,
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          step: 60,
        }}
        draggable={false}
        customEditor={(state) => (
          <SchedulerDialog scheduler={state} handleConfirm={handleConfirm} />
        )}
        events={currentEvents.map((item) => ({
          ...item,
          start: new Date(item.start),
          end: new Date(item.end),
          color:
            (item as unknown as CustomProcessedEvent).streamingProvider ===
            StreamingProviders.Pureweb
              ? colors.success.main
              : colors.warning.main,
        }))}
        viewerExtraComponent={(fields, event) => {
          return <EventPreview state={event} />;
        }}
        onDelete={handleDelete}
        eventRenderer={(event) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              background: event.event.color,
              paddingInline: 1,
              overflowY: 'scroll',
            }}
            onClick={event.onClick}
          >
            <Typography fontSize={12}>
              {getStreamingProviderFullName(
                (event.event as unknown as CustomProcessedEvent)
                  .streamingProvider
              )}
            </Typography>
            <Typography fontSize={12}>
              {`${new Date(event.event.start).toLocaleTimeString('default', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              })}`}
              -
              {`${new Date(event.event.end).toLocaleTimeString('default', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              })}`}
            </Typography>
          </Box>
        )}
      />
    </Box>
  );
};

export default Calendar;
