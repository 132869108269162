import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Divider, Stack } from '@mui/material';
import { appPaths } from 'app/router/appPaths';
import { AppDefaultLayout } from 'layouts/AppDefaultLayout';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { selectEvents } from 'redux/slices/eventsSlice';
import { selectInvites } from 'redux/slices/invitesSlice';
import { DefaultPageHeader } from 'shared/components/headers/DefaultPageHeader';
import { useLoadParticipants } from 'shared/hooks/useLoadParticipants';
import { useAppSelector } from 'shared/hooks/useStore';
import SendEmails from './buttons/SendEmails';
import { EditInviteModal } from './EditInviteModal';
import { InviteesContainer } from './InviteesContainer';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ExportToCSVButton from './buttons/ExportToCSVButton';

export const InvitesPage = () => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const { event } = useAppSelector(selectEvents);
  const { get: loadParticipants } = useLoadParticipants();
  const { invites } = useAppSelector(selectInvites);
  const [isEditInviteModalOpen, setIsEditInviteModalOpen] = useState(false);

  const filterInvitesNonConfirmed = () => {
    const items: string[] = [];

    invites.map((item) => {
      if (!item.confirmed) {
        items.push(item.email as string);
      }
    });

    return items;
  };

  useEffect(() => {
    loadParticipants();
    // eslint-disable-next-line
  }, []);

  return (
    <AppDefaultLayout>
      <DefaultPageHeader
        titleText={`${event.name} - Invites`}
        linkTo={`${appPaths.private.event.dashboard}/${urlParams.id}`}
        linkToText="Back to Event Dashboard"
      />

      <Divider />

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        paddingBottom={4}
      >
        <span />

        <Stack
          direction="row"
          justifyContent="right"
          alignItems="center"
          spacing={2}
        >
          <ExportToCSVButton />

          <Button
            onClick={() => setIsEditInviteModalOpen(true)}
            startIcon={<SettingsSuggestIcon fontSize="small" />}
          >
            Invite Settings
          </Button>

          {!!invites?.length && (
            <SendEmails
              emailsToSend={filterInvitesNonConfirmed()}
              eventId={urlParams.id as string}
            />
          )}

          <Button
            onClick={() =>
              navigate(
                appPaths.private.invites.add.replace(
                  ':id',
                  String(urlParams.id)
                )
              )
            }
            startIcon={<AddCircleIcon fontSize="small" />}
          >
            Add Invitees
          </Button>

          <EditInviteModal
            open={isEditInviteModalOpen}
            handleClose={() => {
              setIsEditInviteModalOpen(false);
            }}
          />
        </Stack>
      </Stack>

      <InviteesContainer />
    </AppDefaultLayout>
  );
};
