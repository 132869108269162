import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { ExportButton } from '../../styled';
import SettingsIcon from '@mui/icons-material/Settings';
import { BoxDialog } from 'shared/components/modals/BoxDialogModal/styled';
import { useFormik } from 'formik';
import StreamPanelsService, {
  IStreamPanel,
} from 'services/http/streamPanelsService';
import { useParams } from 'react-router-dom';

const StreamPanelsConfigure = ({
  setScreensConfig,
}: {
  setScreensConfig: React.Dispatch<React.SetStateAction<IStreamPanel[]>>;
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const { id, roomId } = useParams();

  const handleStreamPanelConfig = async () => {
    await StreamPanelsService.updateStreamPanelConfig({
      eventId: id as string,
      millicastAccountId: values.millicastAccountId,
      millicastStreamName: values.millicastStreamName,
      millicastSubscribeToken: values.millicastSubscribeToken,
      regionName: values.regionName,
      screenName: values.screenName,
      roomId: roomId as string,
      owner: values.owner,
    });

    const screens = await StreamPanelsService.getStreamPanelConfig(
      id as string,
      roomId as string
    );

    setScreensConfig(screens);

    setOpenModal(false);
  };

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      millicastStreamName: '',
      millicastSubscribeToken: '',
      millicastAccountId: '',
      regionName: '',
      screenName: '',
      owner: '',
    },
    onSubmit: handleStreamPanelConfig,
  });

  useEffect(() => {
    setDisabled(Object.values(values).some((value) => value === ''));
  }, [values]);

  return (
    <>
      <BoxDialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        scroll="body"
        maxWidth="md"
      >
        <Box
          sx={{
            padding: 5,
            minWidth: 600,
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 3,
              }}
            >
              <Typography textAlign="center" fontWeight="700" fontSize={18}>
                Stream Panel Config
              </Typography>
              <TextField
                id="outlined-basic"
                label="Region Name"
                variant="outlined"
                value={values.regionName}
                name="regionName"
                onChange={handleChange}
              />
              <TextField
                id="outlined-basic"
                label="Screen Name"
                variant="outlined"
                value={values.screenName}
                name="screenName"
                onChange={handleChange}
              />
              <TextField
                id="outlined-basic"
                label="Stream Owner"
                variant="outlined"
                value={values.owner}
                name="owner"
                onChange={handleChange}
              />
              <TextField
                id="outlined-basic"
                label="Stream Name"
                variant="outlined"
                value={values.millicastStreamName}
                name="millicastStreamName"
                onChange={handleChange}
              />
              <TextField
                id="outlined-basic"
                label="Millicast Token"
                variant="outlined"
                value={values.millicastSubscribeToken}
                name="millicastSubscribeToken"
                onChange={handleChange}
              />
              <TextField
                id="outlined-basic"
                label="Millicast Account Id"
                variant="outlined"
                value={values.millicastAccountId}
                name="millicastAccountId"
                onChange={handleChange}
              />
              <Button disabled={disabled} type="submit" variant="contained">
                Add Config
              </Button>
            </Box>
          </form>
        </Box>
      </BoxDialog>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          width: '50%',
        }}
      >
        <ExportButton
          startIcon={<SettingsIcon fontSize="small" />}
          onClick={() => setOpenModal(true)}
          color="success"
          variant="contained"
        >
          Add Stream Config
        </ExportButton>
      </Box>
    </>
  );
};

export default StreamPanelsConfigure;
