export const useStyles = () => ({
  root: {
    padding: '20px 40px',

    '& p': {
      fontSize: 18,
      textAlign: 'center',
      fontWeight: 700,
      mb: 2,
    },

    '& form': {
      display: 'flex',
      flexDirection: 'column',
      '& .MuiInputBase-root': {
        width: '100%',
      },
      '& button': {
        mt: 3,
      },
    },
  },
});
