import { Avatar, Box, Button, Card, Stack } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AddSkinsModal } from './AddSkinsModal';
import React, { useState } from 'react';
import { useAppSelector } from 'shared/hooks/useStore';
import { useParams } from 'react-router-dom';
import {
  selectSkinsModel,
  setSkinsModelsList,
} from 'redux/slices/admin/skinsModelSlice';
import { useSkinsModel } from 'pages/admin/SkinsDashboardPage/hooks/useSkinsModel';
import { useSnackbar } from 'notistack';
import { http } from 'services/http';
import { SkinModel } from 'shared/types/models/skinModel';
import { useDispatch } from 'react-redux';

export const AddSkinsButton = ({
  onSkinsUpdateCallback,
}: {
  onSkinsUpdateCallback: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const { avatarId, id } = useParams();
  const { skinsModels } = useAppSelector(selectSkinsModel);
  const [isAddingSkin, setIsAddingSkin] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { loadSkinsModelsAvailableByAvatar } = useSkinsModel();

  const handleOpenModal = () => {
    setOpen(true);
    loadSkinsModelsAvailableByAvatar({
      eventId: id as string,
      avatarId: avatarId as string,
    });
  };

  const handleCloseModal = () => {
    setOpen(false);
    dispatch(setSkinsModelsList([]));
  };

  const handleAddSkin = async (
    event: React.MouseEvent<HTMLButtonElement>,
    skinId: string
  ) => {
    if (isAddingSkin || event.currentTarget.disabled) return;
    event.currentTarget.disabled = true;
    setIsAddingSkin(true);
    await http.skins
      .create({
        event: id as string,
        idAvatar: avatarId as string,
        idSkin: skinId,
      })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        enqueueSnackbar(res.message, { variant: 'success' });
        loadSkinsModelsAvailableByAvatar({
          eventId: id as string,
          avatarId: avatarId as string,
        });
        onSkinsUpdateCallback();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setIsAddingSkin(false);
      });
  };

  return (
    <>
      <Button
        onClick={handleOpenModal}
        startIcon={<AddCircleIcon fontSize="small" />}
      >
        Add Skins
      </Button>

      <AddSkinsModal
        open={open}
        onConfirm={handleCloseModal}
        title="Available Skins"
      >
        {skinsModels.length > 0 ? (
          <Box>
            <Stack spacing={2}>
              {skinsModels.map((skinModel: SkinModel) => {
                return (
                  <Card key={skinModel.id}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                      style={{ padding: '20px' }}
                    >
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar
                          alt={skinModel.name}
                          src={
                            skinModel.previewImage || skinModel.thumbnail || ''
                          }
                          sx={{ width: 60, height: 60 }}
                        />

                        <h3>{skinModel.name}</h3>
                      </Stack>
                      <Button
                        variant="outlined"
                        disabled={isAddingSkin}
                        onClick={(event) =>
                          handleAddSkin(event, skinModel.id as string)
                        }
                      >
                        Add Skin
                      </Button>
                    </Stack>
                  </Card>
                );
              })}
            </Stack>
          </Box>
        ) : (
          <p>There&apos;s no available skins to add</p>
        )}
      </AddSkinsModal>
    </>
  );
};
