import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

interface InitialState {
  urlOptions: string[];
  customUrlList: string[];
  utils: {
    isFetchingList: boolean;
    isFetchingOptions: boolean;
  };
}

const initialState: InitialState = {
  urlOptions: [],
  customUrlList: [],
  utils: {
    isFetchingList: false,
    isFetchingOptions: false,
  },
};

export const accessListSlice = createSlice({
  name: 'accessList',
  initialState,
  reducers: {
    setUrlOptions: (state, { payload }) => {
      state.urlOptions = payload;
    },
    setCustomUrlList: (state, { payload }) => {
      state.customUrlList = payload;
    },
    setCustomUrl: (state, { payload }) => {
      state.customUrlList = [payload, ...state.customUrlList];
    },
    setFetchingList: (state, { payload }) => {
      state.utils.isFetchingList = payload;
    },
    setFetchingOptions: (state, { payload }) => {
      state.utils.isFetchingOptions = payload;
    },
    removeCustomUrl: (state, { payload }) => {
      const customUrlListFiltered = state.customUrlList.filter(
        (customUrl) => customUrl !== payload
      );
      state.customUrlList = customUrlListFiltered;
    },
  },
});

export const selectAccessList = (state: RootState) => state.accessListData;

export const {
  setCustomUrl,
  setUrlOptions,
  setFetchingList,
  setFetchingOptions,
  setCustomUrlList,
  removeCustomUrl,
} = accessListSlice.actions;

export default accessListSlice.reducer;
