import axios from 'axios';
import { spatialManager } from 'configs/apiConfigs';
import { getToken } from 'shared/utils/tokenManager';
import { TOKEN_AUTH_NAME } from 'configs/authConfigs';
import { IUpdateGameCastGroup } from './types/events';
import {
  GameCastConfig,
  IPurewebModel,
  IStreamGroupLog,
  StreamGroupConfig,
} from 'redux/slices/interfaces';

export interface GetLogsFiltersType {
  timeRange: number | null;
  [x: string]: number | null | string;
}

export default class EventsService {
  static getGameCastGroups(): Promise<GameCastConfig[]> {
    return axios
      .get(`${spatialManager}api/v1/gamecast/stream-session/config`, {
        headers: {
          Authorization: getToken(TOKEN_AUTH_NAME) || '',
        },
      })
      .then((res) => res.data);
  }

  static getGameCastGroup(streamGroupId: string): Promise<GameCastConfig> {
    return axios
      .get(
        `${spatialManager}api/v1/gamecast/stream-session/${streamGroupId}/config`,
        {
          headers: {
            Authorization: getToken(TOKEN_AUTH_NAME) || '',
          },
        }
      )
      .then((res) => res.data);
  }

  static updateGameCastGroup(
    eventId: string,
    data: IUpdateGameCastGroup
  ): Promise<GameCastConfig> {
    return axios
      .patch(
        `${spatialManager}api/v1/gamecast/stream-session/${eventId}/config`,
        data,
        {
          headers: {
            Authorization: getToken(TOKEN_AUTH_NAME) || '',
          },
        }
      )
      .then((res) => res.data);
  }

  static getStreamGroup(eventId: string): Promise<StreamGroupConfig> {
    return axios
      .get(`${spatialManager}api/v1/gamecast/stream-session/${eventId}`, {
        headers: {
          Authorization: getToken(TOKEN_AUTH_NAME) || '',
        },
      })
      .then((res) => res.data);
  }

  static getStreamGroupConfig(
    eventId: string,
    streamGroupId: string,
    region: string
  ): Promise<GameCastConfig> {
    return axios
      .get(
        `${spatialManager}api/v1/gamecast/stream-session/${eventId}/config?region=${region}&streamGroupId=${streamGroupId}`,
        {
          headers: {
            Authorization: getToken(TOKEN_AUTH_NAME) || '',
          },
        }
      )
      .then((res) => res.data);
  }

  static getStreamGroupLogs(
    eventId: string,
    params?: GetLogsFiltersType
  ): Promise<IStreamGroupLog[]> {
    return axios
      .get(`${spatialManager}api/v1/gamecast/logs/${eventId}`, {
        headers: {
          Authorization: getToken(TOKEN_AUTH_NAME) || '',
        },
        params,
      })
      .then((res) => res.data);
  }

  static getPurewebLogs(
    eventId: string,
    params?: GetLogsFiltersType
  ): Promise<IStreamGroupLog[]> {
    return axios
      .get(`${spatialManager}api/v1/pureweb/logs/${eventId}`, {
        headers: {
          Authorization: getToken(TOKEN_AUTH_NAME) || '',
        },
        params,
      })
      .then((res) => res.data);
  }

  static getPurewebModel(modelId: string): Promise<IPurewebModel[]> {
    return axios
      .get(`${spatialManager}api/v1/pureweb/${modelId}`, {
        headers: {
          Authorization: getToken(TOKEN_AUTH_NAME) || '',
        },
      })
      .then((res) => res.data);
  }
}
