import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  selectRooms,
  setActualSpot,
  setSendingMediaForm,
} from 'redux/slices/roomsSlice';
import { selectUser } from 'redux/slices/userSlice';
import { http } from 'services/http';
import BoxDialogModal, {
  BoxDialogModalHandles,
} from 'shared/components/modals/BoxDialogModal';
import CustomizedSteppers from 'shared/components/steppers/CustomizedSteppers';
import useAccess from 'shared/hooks/useAccess';
import { useLoadRoomDetails } from 'shared/hooks/useLoadRoomDetails';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { useUploadAssetProccess } from 'shared/hooks/useUploadAssetProccess';
import { ModalHandles } from 'shared/types/modal';
import { getSpotAcceptCriteriaFile } from 'shared/utils/getAcceptCriteriaFile';
import { renameFileToUploadPattern } from 'shared/utils/renameFileToUploadPattern';
import { ResetSpot } from './ResetSpot';
import { SpotDataWithAssetsProps } from './types';

const UploadBriefCaseModal: ForwardRefRenderFunction<ModalHandles> = (
  props,
  ref
) => {
  const { user } = useAppSelector(selectUser);
  const { actualSpot } = useAppSelector(selectRooms);
  const { getConnectToken } = useAccess();
  const { enqueueSnackbar } = useSnackbar();

  const {
    utils: { isSendingMediaForm },
  } = useAppSelector(selectRooms);
  const { loadRoomDetails } = useLoadRoomDetails();

  const dispatch = useAppDispatch();
  const { uploadAssetProccess, uploadProgress } = useUploadAssetProccess();

  const [checked, setChecked] = useState<boolean>(false);
  const [asset, setAsset] = useState<any>();

  const [uploadStep, setUploadStep] = useState(0); // step index to manage CustomizedSteppers component
  const [errorStep, setErrorStep] = useState<number | null>(null);

  const [isSendingFile, setSendingFile] = useState(false);

  useEffect(() => {
    if (actualSpot?.isActive) setChecked(actualSpot.isActive);
  }, [actualSpot.isActive]);

  const boxDialogModalRef = useRef<BoxDialogModalHandles>(null);

  const handleOpen = () => {
    boxDialogModalRef.current?.handleOpen();
  };

  const handleClose = () => {
    boxDialogModalRef.current?.handleClose();
  };

  const handleCloseDialog = () => {
    dispatch(setActualSpot({}));
    dispatch(setSendingMediaForm(false));
    setSendingFile(false);
    setAsset(null);
    setUploadStep(0);
    setErrorStep(null);
    setChecked(false);
  };

  const handleSubmitForm = async () => {
    dispatch(setSendingMediaForm(true));

    const spotID = actualSpot.id;

    let data: SpotDataWithAssetsProps = {
      user: user?.data.email as string,
      isActive: checked,
      isDefault: false,
      assetMediaType: actualSpot.mediaType,
      asset,
    };

    if (asset instanceof File) {
      const assetUrl = await uploadAssetProccess({
        asset,
        foldername: '3D',
        contentType: '*/*',
      });

      if (!assetUrl) {
        return;
      }

      data = {
        ...data,
        asset: assetUrl,
      };
    }

    http.spots
      .updateFromFrontend({
        userToken: getConnectToken() as string,
        spotID,
        data,
      })
      .then((res: any) => {
        dispatch(setSendingMediaForm(false));
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        enqueueSnackbar(res.message, { variant: 'success' });
        loadRoomDetails();
        handleClose();
      })
      .catch((error) => {
        dispatch(setSendingMediaForm(false));
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleUploadInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    let file = event.target.files[0];
    const reader = new FileReader();

    file = renameFileToUploadPattern(file);

    reader.onloadend = () => {
      setAsset(file);
    };

    reader.readAsDataURL(file);

    event.target.value = '';
  };

  const formatFileSize = (originalSize: number) => {
    const size = originalSize / (1024 * 1024);

    return size.toFixed(4) + 'MB';
  };

  useImperativeHandle(ref, () => {
    return { handleOpen, handleClose };
  });

  return (
    <BoxDialogModal
      ref={boxDialogModalRef}
      title={
        'Update spot - ' +
        actualSpot?.displayName +
        ' [' +
        actualSpot?.mediaType +
        ']'
      }
      submitButton={
        <Button
          autoFocus
          onClick={handleSubmitForm}
          variant="contained"
          disabled={isSendingMediaForm}
        >
          {!isSendingMediaForm ? 'Save' : 'Saving...'}
        </Button>
      }
      onCloseDialog={handleCloseDialog}
    >
      {!isSendingFile && (
        <Stack spacing={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheckboxChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Active"
            />
          </FormGroup>

          <Box>
            <label htmlFor="icon-spot-image-button-file">
              <input
                accept={getSpotAcceptCriteriaFile(actualSpot.mediaType)}
                id="icon-spot-image-button-file"
                type="file"
                style={{ display: 'none' }}
                onChange={handleUploadInput}
              />
              <Button
                variant="contained"
                component="span"
                startIcon={<FileUploadIcon />}
                size="large"
                fullWidth
              >
                Upload File
              </Button>
            </label>
          </Box>

          {asset?.name && (
            <Stack spacing={1}>
              <span>File: {asset.name}</span>
              <span>Size: {formatFileSize(asset.size)}</span>
            </Stack>
          )}

          {actualSpot?.assetMediaType && (
            <p>Custom media type: {actualSpot.assetMediaType}</p>
          )}

          {!actualSpot?.asset?.includes('placeholder') && (
            <p>Media address: {actualSpot?.asset}</p>
          )}

          {actualSpot.isDefault === false && (
            <ResetSpot
              actualSpot={actualSpot}
              onSuccess={() => handleClose()}
            />
          )}
        </Stack>
      )}
      {isSendingFile && (
        <CustomizedSteppers
          activeStep={uploadStep}
          errorStep={errorStep}
          uploadProgress={uploadProgress}
        />
      )}
    </BoxDialogModal>
  );
};

export default forwardRef(UploadBriefCaseModal);
