import { Button } from '@mui/material';
import { UpdateRoomForm } from './UpdateRoomForm';
import BoxDialogModal, {
  BoxDialogModalHandles,
} from 'shared/components/modals/BoxDialogModal';
import { FormikValues } from 'formik';
import { useAppSelector } from 'shared/hooks/useStore';
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import { selectRooms } from 'redux/slices/roomsSlice';

export interface UpdateRoomModalHandles {
  handleOpen: () => void;
}

const UpdateRoomModal: ForwardRefRenderFunction<UpdateRoomModalHandles> = (
  props,
  ref
) => {
  const {
    utils: { isSendingUpdateForm },
  } = useAppSelector(selectRooms);
  const boxDialogModalRef = useRef<BoxDialogModalHandles>(null);

  const handleOpen = () => {
    boxDialogModalRef.current?.handleOpen();
  };

  const handleClose = () => {
    boxDialogModalRef.current?.handleClose();
  };

  const createEventFormRef = useRef<FormikValues>();

  const handleSubmitForm = useCallback(() => {
    createEventFormRef.current?.handleSubmit();
  }, []);

  useImperativeHandle(ref, () => {
    return { handleOpen };
  });

  return (
    <BoxDialogModal
      ref={boxDialogModalRef}
      title="Update room info"
      submitButton={
        <Button
          autoFocus
          onClick={handleSubmitForm}
          variant="contained"
          disabled={isSendingUpdateForm}
        >
          {!isSendingUpdateForm ? 'Update' : 'Updating...'}
        </Button>
      }
    >
      <UpdateRoomForm
        innerRef={createEventFormRef}
        onSubmitCallback={handleClose}
      />
    </BoxDialogModal>
  );
};

export default forwardRef(UpdateRoomModal);
