import { createTheme, Components, PaletteOptions } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import {
  colors,
  colorsThemeOptions,
  paletteThemeOptions,
  themeColors,
} from './colors';
import { typography } from './typography';
import { components } from './components';
import { breakpoints } from './breakpoints';

// import '@mui/lab/themeAugmentation';

// import i18n from 'src/i18n/i18n';

export const AppTheme = createTheme({
  // direction: i18n.dir(),
  colors: colorsThemeOptions,

  general: {
    reactFrameworkColor: '#00D8FF',
    borderRadiusSm: '4px',
    borderRadius: '12px',
    borderRadiusLg: '10px',
    borderRadiusXl: '18px',
  },

  sidebar: {
    background: colors.layout.sidebar.background,
    textColor: colors.layout.sidebar.textColor,
    dividerBg: colors.layout.sidebar.dividerBg,
    menuItemColor: colors.layout.sidebar.menuItemColor,
    menuItemColorActive: colors.layout.sidebar.menuItemColorActive,
    menuItemBg: colors.layout.sidebar.menuItemBg,
    menuItemBgActive: colors.layout.sidebar.menuItemBgActive,
    menuItemIconColor: colors.layout.sidebar.menuItemIconColor,
    menuItemIconColorActive: colors.layout.sidebar.menuItemIconColorActive,
    menuItemHeadingColor: colors.layout.sidebar.menuItemHeadingColor,
    boxShadow:
      '2px 0 3px rgba(159, 162, 191, 0.18), 1px 0 1px rgba(159, 162, 191, 0.32)',
    width: '280px',
  },

  header: {
    height: '70px',
    background: themeColors.bgHeader,
    boxShadow: colors.shadows.cardSm,
    borderColor: colors.layout.header.borderColor,
    textColor: colors.secondary.main,
  },

  spacing: 8,

  palette: paletteThemeOptions as PaletteOptions,

  breakpoints: breakpoints,

  components: components as Components,

  shape: {
    borderRadius: 8,
  },

  typography: typography as TypographyOptions,

  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
});
