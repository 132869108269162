import DoneIcon from '@mui/icons-material/Done';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Chip,
  Divider,
  InputAdornment,
  SelectChangeEvent,
  Stack,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import { Formik, FormikHelpers, FormikValues, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { selectEvents, setSendingUpdateForm } from 'redux/slices/eventsSlice';
import { http } from 'services/http';
import CustomizedSteppers from 'shared/components/steppers/CustomizedSteppers';
import { useLoadEvents } from 'shared/hooks/useLoadEvents';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { useUploadAssetProccess } from 'shared/hooks/useUploadAssetProccess';
import { Alias, Region } from 'shared/types/models/region';
import { StreamService } from 'shared/types/models/streamService';
import {
  formatDateToBackend,
  formatDateToFrontend,
} from 'shared/utils/formatDate';
import { AliasesSelector } from './AliasesSelector';
import { EventImagesContainer } from './EventImagesContainer';
import { RegionsSelector } from './RegionsSelector';
import { StreamServicesSelector } from './StreamServicesSelector';
import { EventImagesProps, FormData, FormValues } from './types';
import { validationSchema } from './validationSchema';
import CustomEventForm from './CustomEventForm/CustomEventForm';

export const initialStateImages = {
  agendaImage: null,
  logoImage: null,
  bgScreenImage: null,
  customEventLogo: null,
};

export const HandleFormikErrors = ({
  setActualTab,
  actualTab,
}: {
  setActualTab: React.Dispatch<any>;
  actualTab: string | number;
}) => {
  const { isValid, errors } = useFormikContext();

  useEffect(() => {
    if (!isValid && parseInt(`${actualTab}`) > 1) {
      setActualTab('1');
    }
  }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export const UpdateEventForm = ({
  onSubmitCallback,
  innerRef,
}: {
  onSubmitCallback: () => void;
  innerRef: React.MutableRefObject<FormikValues>;
}) => {
  const { event } = useAppSelector(selectEvents);
  const { get: getEvent } = useLoadEvents();
  const [filesToSubmit, setFilesToSubmit] =
    useState<EventImagesProps>(initialStateImages);
  const [filesUrl, setFilesUrl] =
    useState<EventImagesProps>(initialStateImages);
  const [isStreamServiceSelectorDisabled, setIsStreamServiceSelectorDisabled] =
    useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const [regions, setRegions] = useState<Region[]>();
  const [aliases, setAliases] = useState<Alias[]>();
  const [subdomain, setSubdomain] = useState('');
  const [isLoadingAlias, setIsLoadingAlias] = useState(false);
  const [isLoadingRegions, setIsLoadingRegions] = useState(false);
  const [streamServices, setStreamServices] = useState<StreamService[]>([]);

  const [streamServicesSelected, setStreamServicesSelected] =
    useState<StreamService>();
  const [actualTab, setActualTab] = useState('1');

  const {
    uploadAssetProccess,
    isSendingFile,
    uploadStep,
    errorStep,
    uploadProgress,
  } = useUploadAssetProccess();

  const handleOnChangeSubdomain = (e: any) => {
    if (e.target.value) {
      setSubdomain(e.target.value);
    }
    setIsStreamServiceSelectorDisabled(!e.target.value);
  };

  const getRegions = async () => {
    setIsLoadingRegions(true);
    await http.regions
      .list()
      .then((res) => {
        setRegions(res.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setIsLoadingRegions(false);
      });
    // eslint-disable-next-line
  };

  const onChangeRegion = (region: Region) => {
    getAliases(region.id as string);
  };

  useEffect(() => {
    setAlias();
  }, [regions, subdomain]);

  const getAliases = async (regionId: string) => {
    if (regionId) {
      setIsLoadingAlias(true);
      await http.regions
        .listAliasesBySubdomainAndRegion({
          regionId: regionId,
          subdomain: subdomain || event.subdomain,
          readPublicAccess: true,
        })
        .then((res) => {
          setAliases(res.data.aliases);
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        })
        .finally(() => {
          setIsLoadingAlias(false);
        });
    }
  };

  const setAlias = () => {
    if (regions) {
      const region = regions.filter((item) => item.region === event.region)[0];

      if (region?.id) {
        getAliases(region.id as string);
      }
    }
  };

  useEffect(() => {
    listServiceStreams();
    // eslint-disable-next-line
  }, [subdomain]);

  useEffect(() => {
    listServiceStreams();
    getRegions();

    return () => {
      setStreamServices([]);
      setStreamServicesSelected(undefined);
    };
  }, []);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setActualTab(newValue);
  };

  const listServiceStreams = useCallback(async () => {
    const result = await http.streamServices
      .listBySubdomain(subdomain || event.subdomain, true)
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });

    if (result?.error) {
      enqueueSnackbar(result?.message, { variant: 'error' });
      return;
    }

    if (event.streamServiceId) {
      setStreamServicesSelected(
        result?.data.filter(
          (item: StreamService) => item.id === event.streamServiceId
        )[0]
      );
    } else {
      setStreamServicesSelected(
        result?.data.filter(
          (item: StreamService) => item.displayName.toLowerCase() === 'default'
        )[0]
      );
    }

    setStreamServices(result?.data);
  }, [event.streamServiceId, subdomain, enqueueSnackbar]);

  const handleSubmit = async (
    formValues: FormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true);
    dispatch(setSendingUpdateForm(true));

    const formData: FormData = {
      user: event.user as string,
      name: formValues.name,
      startTime: formatDateToBackend(formValues.startTime),
      endTime: formatDateToBackend(formValues.endTime),
      region: formValues.regionSelected,
      aliasId: formValues.aliasSelected,
      subdomain: formValues.subdomain || '',
      customUrl: `https://${formValues.subdomain}.mytaverse.com`,
      streamServiceId: String(streamServicesSelected?.id),
      customEventStyle: formValues.customEventStyle,
      company: formValues.company,
    };

    for (const [key, value] of Object.entries(filesToSubmit)) {
      const assetProccessed = await uploadAssetProccess({
        asset: value as File,
        foldername: 'briefCases',
      });

      if (typeof assetProccessed === 'string') {
        formData[key] = assetProccessed; // means it's the url returned after proccess
      }
    }

    submitForm(formData, setSubmitting);
  };

  function submitForm(
    formData: FormData,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    http.events
      .update({
        eventId: event.id as string,
        data: formData,
      })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }

        getEvent();
        // eslint-disable-next-line react/prop-types
        onSubmitCallback();
        enqueueSnackbar(res.message, { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setSubmitting(false);
        dispatch(setSendingUpdateForm(false));
      });
  }

  function handleChangeStreamService(event: SelectChangeEvent<string>) {
    const res = streamServices.filter(
      (item) => item.id === event.target.value
    )[0];

    setStreamServicesSelected(res);
  }

  const formikValues = {
    initialValues: {
      name: event.name,
      startTime: formatDateToFrontend(Number(event.startTime)),
      endTime: formatDateToFrontend(Number(event.endTime)),
      regionSelected: event.region || 'all',
      aliasSelected: event.aliasId || 'all',
      customEventStyle: event.customEventStyle || '',
      company: event.company || '',
      subdomain: event.subdomain || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { setSubmitting }: FormikHelpers<any>) => {
      handleSubmit(values, setSubmitting);
    },
  };

  return (
    <>
      {!isSendingFile && (
        <Formik
          initialValues={formikValues.initialValues}
          validationSchema={formikValues.validationSchema}
          onSubmit={formikValues.onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          innerRef={innerRef as any}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <HandleFormikErrors
                setActualTab={setActualTab}
                actualTab={actualTab}
              />
              <>
                <TabContext value={actualTab}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                    }}
                  >
                    <TabList onChange={handleChangeTab}>
                      <Tab label="Infos" value="1" />
                      <Tab label="Images" value="2" />
                      <Tab label="Custom Style" value="3" />
                    </TabList>
                  </Box>

                  <TabPanel value="1">
                    <Box paddingBottom={1}>
                      {event.isPublished ? (
                        <Chip
                          label="Published (Cannot edit)"
                          color="success"
                          icon={<DoneIcon />}
                        />
                      ) : (
                        <Chip
                          label="Not Published"
                          color="primary"
                          variant="outlined"
                          icon={<ModeEditIcon />}
                        />
                      )}
                    </Box>

                    <TextField
                      fullWidth
                      margin="normal"
                      id="name"
                      name="name"
                      label="Event Name*"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      disabled={event.isPublished}
                    />

                    <TextField
                      fullWidth
                      margin="normal"
                      id="company"
                      name="company"
                      label="Company Name*"
                      value={formik.values.company}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.company && Boolean(formik.errors.company)
                      }
                      helperText={
                        formik.touched.company && formik.errors.company
                      }
                      disabled={event.isPublished}
                    />
                    <Stack
                      direction="row"
                      justifyContent="normal"
                      alignItems="center"
                      spacing={2}
                      paddingBottom={1}
                    >
                      <Stack flex="auto" direction="column" spacing={2}>
                        <span>Start time*</span>
                        <TextField
                          fullWidth
                          type={'datetime-local'}
                          margin="normal"
                          id="startTime"
                          name="startTime"
                          value={formik.values.startTime}
                          onChange={formik.handleChange}
                          disabled={event.isPublished}
                          error={
                            formik.touched.startTime &&
                            Boolean(formik.errors.startTime)
                          }
                          helperText={
                            formik.touched.startTime && formik.errors.startTime
                          }
                        />
                      </Stack>
                      <Stack flex="auto" direction="column" spacing={2}>
                        <span>End time*</span>
                        <TextField
                          fullWidth
                          type={'datetime-local'}
                          margin="normal"
                          id="endTime"
                          name="endTime"
                          value={formik.values.endTime}
                          onChange={formik.handleChange}
                          disabled={
                            event.isPublished || !formik.values.startTime
                          }
                          error={
                            formik.touched.endTime &&
                            Boolean(formik.errors.endTime)
                          }
                          helperText={
                            formik.touched.endTime && formik.errors.endTime
                          }
                        />
                      </Stack>
                    </Stack>
                    <Divider style={{ margin: '15px 0' }} />

                    <Typography variant="h5">Custom Url</Typography>

                    <TextField
                      fullWidth
                      margin="normal"
                      id="subdomain"
                      name="subdomain"
                      label="Custom Event Url"
                      value={formik.values.subdomain}
                      onChange={(e: any) => {
                        handleOnChangeSubdomain(e);
                        formik.handleChange(e);
                      }}
                      error={
                        formik.touched.subdomain &&
                        Boolean(formik.errors.subdomain)
                      }
                      helperText={
                        formik.touched.subdomain && formik.errors.subdomain
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            https://
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="start">
                            .mytaverse.com
                          </InputAdornment>
                        ),
                      }}
                      disabled={event.isPublished}
                    />

                    <RegionsSelector
                      regions={regions}
                      values={formik.values}
                      touched={formik.touched}
                      errors={formik.errors}
                      handleChange={formik.handleChange}
                      onChangeRegion={onChangeRegion}
                      setFieldValue={formik.setFieldValue}
                      isLoadingRegions={isLoadingRegions}
                      disabled={
                        event.isPublished || isStreamServiceSelectorDisabled
                      }
                    />

                    <AliasesSelector
                      aliases={aliases}
                      values={formik.values}
                      touched={formik.touched}
                      errors={formik.errors}
                      handleChange={formik.handleChange}
                      disabled={
                        event.isPublished ||
                        isLoadingAlias ||
                        isStreamServiceSelectorDisabled
                      }
                      isLoadingAlias={isLoadingAlias}
                    />

                    <Divider style={{ margin: '8px 0' }} />

                    <StreamServicesSelector
                      streamServices={streamServices}
                      streamServicesSelected={streamServicesSelected}
                      handleChangeStreamService={handleChangeStreamService}
                      disabled={
                        event.isPublished || isStreamServiceSelectorDisabled
                      }
                    />
                  </TabPanel>

                  <TabPanel value="2">
                    <EventImagesContainer
                      filesToSubmit={filesToSubmit}
                      setFilesToSubmit={setFilesToSubmit}
                      setImagesPreview={setFilesUrl}
                      imagesPreview={filesUrl}
                    />
                  </TabPanel>

                  <TabPanel value="3">
                    <CustomEventForm subdomain={event.subdomain} />
                  </TabPanel>
                </TabContext>
              </>
            </form>
          )}
        </Formik>
      )}
      {isSendingFile && (
        <CustomizedSteppers
          activeStep={uploadStep}
          errorStep={errorStep}
          uploadProgress={uploadProgress}
        />
      )}
    </>
  );
};
