export const useStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,

    '& button': {
      mt: 3,
      width: 200,
    },
  },
  scheduler: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

    '& div': {
      width: '100%',
    },
  },
  tabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-end',
    },
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translate(-50%, -50 %)',
  },
});
