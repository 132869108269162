import axios from 'axios';
import { apiUrl } from 'configs/apiConfigs';
import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import { HttpReponseDefault } from 'shared/types/httpGeneric';
import { sortByDisplayName } from 'shared/utils/sortByDisplayName';
import axiosWithUserToken from './axiosInstances/axiosWithUserToken';

const environments = {
  async get({ userToken }: { userToken: string }): Promise<HttpReponseDefault> {
    try {
      const { data } = await axios.get(`${apiUrl}/cms/maps-model/list`, {
        headers: {
          Authorization: `${userToken}`,
        },
      });

      const mapped = data.mapsModel.map((item: any) => {
        return {
          displayName: item.displayName,
          mapName: item.mapName,
          imageUrl: item.thumbnail,
          isPublished: item.isPublished,
        };
      });

      const sorteredByDisplayName = sortByDisplayName(mapped);

      const filteredByIsPublished = sorteredByDisplayName.filter(
        (item) => item.isPublished
      );

      return { message: 'Loaded', data: filteredByIsPublished };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
  async listBySubdomain(
    subdomain: string,
    readPublicAccess: boolean
  ): Promise<HttpReponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/maps/list/subdomain?subdomain=${subdomain}`
      );

      const mapped = data.maps.map((item: any) => {
        return {
          displayName: item.displayName,
          mapName: item.mapName,
          imageUrl: item.thumbnail,
          isPublished: item.isPublished,
        };
      });

      if (readPublicAccess) {
        const dataPublicAccess = await axiosWithUserToken().get(
          '/cms/maps/list/subdomain?subdomain=PUBLIC_ACCESS'
        );

        dataPublicAccess.data?.maps.map((item: any) => {
          mapped.push({
            displayName: item.displayName,
            mapName: item.mapName,
            imageUrl: item.thumbnail,
            isPublished: item.isPublished,
          });
        });
      }

      const sorteredByDisplayName = sortByDisplayName(mapped);

      const filteredByIsPublished = sorteredByDisplayName.filter(
        (item) => item.isPublished
      );

      return { message: 'Loaded', data: filteredByIsPublished };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default environments;
