import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { Avatar } from 'shared/types/models/avatar';

const initialState = {
  avatarsByEvent: [] as Avatar[],
  actualAvatar: {} as Avatar,
  isAvailableAvatarsLoading: false,
  availableAvatars: [] as Avatar[],
  avatars: [] as Avatar[],
  query: '',
  utils: {
    isFetching: false,
  },
};

export const avatarsSlice = createSlice({
  name: 'avatars',
  initialState,
  reducers: {
    setAvatarList: (state, { payload }) => {
      state.avatars = payload;
    },
    setAvatarListByEvent: (state, { payload }) => {
      state.avatarsByEvent = payload;
    },
    setActualAvatar: (state, { payload }) => {
      state.actualAvatar = payload;
    },
    setIsAvailableAvatarsLoading: (state, { payload }) => {
      state.isAvailableAvatarsLoading = payload;
    },
    setAvailableAvatarsList: (state, { payload }) => {
      state.availableAvatars = payload;
    },
    setQuery: (state, { payload }) => {
      state.query = payload;
    },
    setFetching: (state, { payload }) => {
      state.utils.isFetching = payload;
    },
  },
});

export const selectAvatars = (state: RootState) => state.avatarsData;

export const {
  setAvatarList,
  setAvatarListByEvent,
  setActualAvatar,
  setIsAvailableAvatarsLoading,
  setAvailableAvatarsList,
  setQuery,
  setFetching,
} = avatarsSlice.actions;

export default avatarsSlice.reducer;
