import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import axiosWithUserToken from './axiosInstances/axiosWithUserToken';
import { HttpResponseDefault } from './types/httpGeneric';
import { SetDefaultEventSkin } from './types/skinsPlayer';

const skinsPlayer = {
  async setDefault(payload: SetDefaultEventSkin): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().patch(
        `/cms/skins-player/setDefault`,
        payload
      );
      return { message: 'Loaded', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default skinsPlayer;
