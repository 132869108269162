import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import { selectTickets } from 'redux/slices/ticketsSlice';
import { RemoveButtonWithDialog } from 'shared/components/buttons/RemoveButtonWithDialog';
import { useAppSelector } from 'shared/hooks/useStore';

export const TicketListContainer = () => {
  const { tickets } = useAppSelector(selectTickets);
  const formatDate = (date: string) => moment(date).format('LLLL');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleRemoveTicket = (id: string) => {};

  return (
    <Grid container spacing={2}>
      {!!tickets.length &&
        tickets.map((item: any, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h3" component="div">
                  {item.name}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  Creator: {item.user}
                </Typography>
                <Typography
                  style={{ marginBottom: '8px' }}
                  variant="subtitle2"
                  color="text.secondary"
                >
                  Created at: {formatDate(item.createdAt)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Stack direction="row" spacing={1}>
                    <Chip label={'Sala 1'} variant="outlined" />
                    <Chip label={'Sala 2'} variant="outlined" />
                    <Chip label={'Sala 3'} variant="outlined" />
                    <Chip label={'Sala 4'} variant="outlined" />
                  </Stack>
                </Typography>
              </CardContent>
              <CardActions>
                <Stack
                  width="100%"
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button
                    size="small"
                    startIcon={<EditIcon fontSize="small" />}
                  >
                    Update
                  </Button>
                  <RemoveButtonWithDialog
                    title="Remove Ticket?"
                    message="Are you sure to remove this ticket? This action can't be undone."
                    buttonText="Remove"
                    okModalButtonText="Remove"
                    deleteCallback={() => {
                      handleRemoveTicket(item.id);
                    }}
                  />
                </Stack>
              </CardActions>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
};
