import { ThemeProvider } from 'layouts/theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';

import { AuthProvider } from 'contexts/AuthContext';
import { AppRouter } from './router';
import { CloseSnackbar } from 'shared/components/snackbars/CloseSnackbar';
import { store } from 'redux/store';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={4}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          autoHideDuration={3000}
          action={CloseSnackbar}
        >
          <AuthProvider>
            <AppRouter />
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
}
export default App;
