import { Button, Stack } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useRef } from 'react';
import { BoxDialogModalHandles } from 'shared/components/modals/BoxDialogModal';
import { useSnackbar } from 'notistack';
import AddTicketModal from './AddTicketModal';
import { useAppSelector } from 'shared/hooks/useStore';
import { selectRooms } from 'redux/slices/roomsSlice';

export const AddTicketButtonContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { rooms } = useAppSelector(selectRooms);

  const addTicketsModalRef = useRef<BoxDialogModalHandles>(null);

  const eventHasRooms = () => {
    return rooms.length > 0;
  };

  const handleOpenAddTicket = async () => {
    if (!eventHasRooms()) {
      enqueueSnackbar(
        'You must create at least one room before creating a ticket.',
        {
          variant: 'warning',
        }
      );
      return;
    }
    addTicketsModalRef.current?.handleOpen();
  };

  return (
    <>
      <AddTicketModal ref={addTicketsModalRef} />

      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        paddingTop={1}
        paddingBottom={4}
      >
        <Button
          onClick={handleOpenAddTicket}
          startIcon={<AddCircleIcon fontSize="small" />}
        >
          Add Ticket
        </Button>
      </Stack>
    </>
  );
};
