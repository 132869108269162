import SaveIcon from '@mui/icons-material/Save';
import UploadIcon from '@mui/icons-material/FileUpload';
import { Grid } from '@mui/material';
import { ExportButton, ImportButton } from '../styled';
import { useAppSelector } from 'shared/hooks/useStore';
import { selectRooms } from 'redux/slices/roomsSlice';
import { useState } from 'react';
import ExportPointsOfInterestModal from './ExportPointsOfInterestModal';
import ImportPointsOfInterestModal from './ImportPointsOfInterestModal';

export const ExportAndImportPOIs = () => {
  const { actualRoom } = useAppSelector(selectRooms);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);

  return (
    <>
      <ExportPointsOfInterestModal
        open={openExportModal}
        roomId={String(actualRoom?.id)}
        handleClose={() => setOpenExportModal(false)}
      />

      <ImportPointsOfInterestModal
        open={openImportModal}
        roomId={String(actualRoom?.id)}
        handleClose={() => setOpenImportModal(false)}
      />

      <Grid xs={6} display="flex" justifyContent="right" alignItems="end" item>
        <ExportButton
          startIcon={<SaveIcon fontSize="small" />}
          onClick={() => setOpenExportModal(true)}
          color="success"
          variant="contained"
        >
          Save POIs
        </ExportButton>
        <ImportButton
          startIcon={<UploadIcon fontSize="small" />}
          onClick={() => setOpenImportModal(true)}
          color="info"
          variant="contained"
        >
          Upload .csv POIs
        </ImportButton>
      </Grid>
    </>
  );
};
