import axios from 'axios';
import { spatialManager } from 'configs/apiConfigs';
import { getToken } from 'shared/utils/tokenManager';
import { TOKEN_AUTH_NAME } from 'configs/authConfigs';
import {
  GetEventSchedulerDateRes,
  SetEventSchedulerDateProps,
} from './types/schedulerService';

export interface IPurewebTargetRegion {
  region: string;
  capacity: number;
}

export interface IUpdatePurewebCalendarProps {
  targetRegions: IPurewebTargetRegion[];
  startTime: number;
  endTime: number;
  newStartTime: number | null;
  modelId: string;
}

export default class SchedulerService {
  static setEventGameCastSchedulerDate(
    eventId: string,
    data: SetEventSchedulerDateProps
  ): Promise<void> {
    return axios
      .patch(`${spatialManager}api/v1/gamecast/calendar/${eventId}`, data, {
        headers: {
          Authorization: getToken(TOKEN_AUTH_NAME) || '',
        },
      })
      .then((res) => res.data);
  }

  static getEventGameCastSchedulerDate(
    eventId: string
  ): Promise<GetEventSchedulerDateRes[]> {
    return axios
      .get(`${spatialManager}api/v1/gamecast/calendar/${eventId}`, {
        headers: {
          Authorization: getToken(TOKEN_AUTH_NAME) || '',
        },
      })
      .then((res) => res.data);
  }

  static deleteEventGameCastSchedulerDate(
    eventId: string,
    startTime: number
  ): Promise<GetEventSchedulerDateRes[]> {
    return axios
      .delete(
        `${spatialManager}api/v1/gamecast/calendar/${eventId}/timeline/${startTime}`,
        {
          headers: {
            Authorization: getToken(TOKEN_AUTH_NAME) || '',
          },
        }
      )
      .then((res) => res.data);
  }

  static setEventPurewebSchedulerDate(
    eventId: string,
    data: IUpdatePurewebCalendarProps
  ): Promise<void> {
    return axios
      .patch(`${spatialManager}api/v1/pureweb/calendar/${eventId}`, data, {
        headers: {
          Authorization: getToken(TOKEN_AUTH_NAME) || '',
        },
      })
      .then((res) => res.data);
  }

  static getEventPurewebSchedulerDate(
    eventId: string
  ): Promise<Omit<GetEventSchedulerDateRes, 'instanceType'>[]> {
    return axios
      .get(`${spatialManager}api/v1/pureweb/calendar/${eventId}`, {
        headers: {
          Authorization: getToken(TOKEN_AUTH_NAME) || '',
        },
      })
      .then((res) => res.data);
  }

  static deleteEvenPurewebSchedulerDate(
    eventId: string,
    startTime: number
  ): Promise<GetEventSchedulerDateRes[]> {
    return axios
      .delete(
        `${spatialManager}api/v1/pureweb/calendar/${eventId}/timeline/${startTime}`,
        {
          headers: {
            Authorization: getToken(TOKEN_AUTH_NAME) || '',
          },
        }
      )
      .then((res) => res.data);
  }
}
