import { Avatar, Button, Grid, IconButton, TextField } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { useSnackbar } from 'notistack';
import { http } from 'services/http';
import { selectUser, setUserData } from 'redux/slices/userSlice';
import { validationSchema } from './validationSchema';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { UploadIconWrapper } from './styled';
import { ChangeEvent, useState } from 'react';
import { renameFileToUploadPattern } from 'shared/utils/renameFileToUploadPattern';

export const ProfileEditForm = ({
  submitCallback,
}: {
  submitCallback: () => void;
}) => {
  const { user } = useAppSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [imagePreview, setImagePreview] = useState('');
  const [profileImage, setProfileImage] = useState<File | null>(null);

  const handleUploadImageInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files) return;
    let file = event.currentTarget.files[0];
    const reader = new FileReader();
    file = renameFileToUploadPattern(file);

    reader.onloadend = () => {
      setImagePreview(URL.createObjectURL(file));
      setProfileImage(file);
    };

    reader.readAsDataURL(file);

    event.currentTarget.value = '';
  };

  const handleSubmit = async (
    formValues: any,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true);

    const id = user?.connectID as string;
    const token = user?.connectToken as string;

    const formData = {
      ...formValues,
      email: user?.data.email,
      profileImage: profileImage,
    };

    http.user
      .update({ id, token, data: formData })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          setSubmitting(false);
          return;
        }

        const userData = {
          connectToken: token,
          connectID: id,
          data: res.data,
        };

        enqueueSnackbar(res.message, { variant: 'success' });
        setSubmitting(false);
        dispatch(setUserData(userData));
        submitCallback();
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const formik = useFormik({
    initialValues: {
      firstName: user?.data.firstName || '',
      lastName: user?.data.lastName || '',
      profileImage: user?.data.profileImage,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }: FormikHelpers<any>) => {
      handleSubmit(values, setSubmitting);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid item style={{ position: 'relative', textAlign: 'center' }}>
        <input
          accept="image/*"
          id="icon-button-file-1"
          type="file"
          style={{ display: 'none' }}
          onChange={handleUploadImageInput}
        />
        <label htmlFor="icon-button-file-1">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            style={{
              width: '225px',
              height: '225px',
              padding: '0',
              borderRadius: '50%',
              overflow: 'hidden',
            }}
          >
            <UploadIconWrapper>
              <FileUploadIcon />
            </UploadIconWrapper>
            <Avatar
              alt={`${formik.values.firstName}  ${formik.values.lastName}`}
              src={imagePreview || formik.values.profileImage}
              sx={{ width: 200, height: 200 }}
            />
          </IconButton>
        </label>
      </Grid>
      <TextField
        fullWidth
        margin="normal"
        id="firstName"
        name="firstName"
        label="First Name*"
        value={formik.values.firstName}
        onChange={formik.handleChange}
        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
        helperText={formik.touched.firstName && formik.errors.firstName}
      />
      <TextField
        fullWidth
        margin="normal"
        id="lastName"
        name="lastName"
        label="Last Name*"
        value={formik.values.lastName}
        onChange={formik.handleChange}
        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
        helperText={formik.touched.lastName && formik.errors.lastName}
      />

      {formik.isSubmitting ? (
        <Button
          sx={{ mt: 3 }}
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          disabled
        >
          Sending credentials...
        </Button>
      ) : (
        <Button
          sx={{ mt: 3 }}
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
        >
          Update Data
        </Button>
      )}
    </form>
  );
};
