import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { BootstrapDialogTitle } from 'shared/components/modals/BootstrapDialogTitle';
import React from 'react';

interface Props {
  open: boolean;
  title?: string;
  children?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  confirmIcon?: React.ReactNode;
  cancelIcon?: React.ReactNode;
  disableConfirmButton?: boolean;
  disableCancelButton?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const AddSkinsModal = ({
  open,
  title,
  children,
  confirmText,
  confirmIcon,
  disableConfirmButton,
  onCancel,
  onConfirm,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="add-skins-title"
      aria-describedby="add-skins-description"
      fullWidth
    >
      <BootstrapDialogTitle id="add-skins-title" onClose={onCancel}>
        {title || 'Default Title'}
      </BootstrapDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button
          onClick={onConfirm}
          autoFocus
          startIcon={confirmIcon}
          variant="contained"
          disabled={disableConfirmButton}
        >
          {confirmText || 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
