import {
  styled,
  IconButton,
  Button,
  Grid,
  LinearProgress,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

type AditionalContenProps = {
  content: string;
};

type AditionalStepProps = {
  step: string;
};

export const IconButtonExport = styled(IconButton)<AditionalStepProps>(
  ({ step }) => ({
    color:
      step == 'success' ? '#5EA328' : step == 'error' ? '#E42D2D' : '#8D98A9',
    borderRadius: '50%',
    border:
      '1px solid ' +
      (step == 'success' ? '#5EA328' : step == 'error' ? '#E42D2D' : '#8D98A9'),
  })
);

export const ButtonSaveMedia = styled(Button)({
  background: '#429325',
  borderRadius: '6px',
  ':hover': {
    background: '#1B490B',
  },
  marginLeft: '10px',
});

export const CancelIconCustom = styled(CancelIcon)({
  color: 'red',
});

export const ButtonCancelMedia = styled(Button)({
  color: 'black',
  background: '#E8E8E8',
  border: '1px solid #E42D2D',
  borderRadius: '6px',
  '&:hover': {
    color: 'white',
    background: '#E42D2D',
    '& .cancelIcon': {
      color: 'white',
    },
  },
  marginLeft: '10px',
});

export const ButtonUploadMedia = styled(Button)({
  background: '#4554E5',
  borderRadius: '6px',
  ':hover': {
    background: '#1F266E',
  },
  marginLeft: '10px',
});

export const GridDropZone = styled(Grid)({
  marginTop: '20px',
  marginBottom: '20px',
  alignItems: 'center',
  border: '2px dashed #938888',
  background: '#ECECEC',
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  padding: 20,
});

export const LinearProgressUploading = styled(
  LinearProgress
)<AditionalContenProps>(({ content }) => ({
  height: '65px',
  backgroundColor: '#ECECEC',
  border: '2px dashed #938888',
  '& span': {
    backgroundColor: '#CBCEF8',
  },
  '&::before': {
    content: content ? '"' + content + '"' : '"No CSV file selected yet"',
    zIndex: 1,
    position: 'inherit',
    top: '30%',
    fontWeight: 'bold',
  },
}));

export const ButtonReload = styled(Button)({
  color: 'black',
  background: '#E8E8E8',
  border: '1px solid #5EA328',
  borderRadius: '6px',
  '& .replayIcon': {
    color: '#5EA328',
  },
  '&:hover': {
    border: '1px solid #1F266E',
    color: 'white',
    background: '#4554E5',
    '& .replayIcon': {
      color: 'white',
    },
  },
  marginLeft: '10px',
});

export const ButtonTryAgain = styled(Button)({
  color: 'black',
  background: '#E8E8E8',
  border: '1px solid #4554E5',
  borderRadius: '6px',
  '& .replayIcon': {
    color: '#4554E5',
  },
  '&:hover': {
    border: '1px solid #1F266E',
    color: 'white',
    background: '#4554E5',
    '& .replayIcon': {
      color: 'white',
    },
  },
  marginLeft: '10px',
});
