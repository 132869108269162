import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import {
  Box,
  Button,
  Divider,
  CircularProgress,
  Grid,
  Stack,
} from '@mui/material';
import { appPaths } from 'app/router/appPaths';
import { AppDefaultLayout } from 'layouts/AppDefaultLayout';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { setPointsOfInterest } from 'redux/slices/pointsOfInterestSlice';
import { selectRooms } from 'redux/slices/roomsSlice';
import { DefaultPageHeader } from 'shared/components/headers/DefaultPageHeader';
import { useLoadRoomDetails } from 'shared/hooks/useLoadRoomDetails';
import { usePointsOfInterest } from 'shared/hooks/usePointsOfInterest';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import MediaContainer from './MediaContainer';
import UpdateRoomModal, { UpdateRoomModalHandles } from './UpdateRoomModal';

export const RoomDetailsPage = () => {
  const {
    actualRoom,
    utils: { isLoadingPage, hasRoom },
  } = useAppSelector(selectRooms);
  const { loadRoomDetails } = useLoadRoomDetails();
  const { list } = usePointsOfInterest();
  const dispatch = useAppDispatch();

  useEffect(() => {
    loadRoomDetails();
    list();

    return () => {
      dispatch(setPointsOfInterest([]));
    };

    // eslint-disable-next-line
  }, []);

  const updateRoomModalRef = useRef<UpdateRoomModalHandles>(null);

  const handleOpenUpdateRoomModal = () => {
    updateRoomModalRef.current?.handleOpen();
  };

  if (isLoadingPage) {
    return (
      <AppDefaultLayout>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          padding={4}
        >
          <CircularProgress />
        </Stack>
      </AppDefaultLayout>
    );
  }

  return (
    <AppDefaultLayout>
      <UpdateRoomModal ref={updateRoomModalRef} />
      {hasRoom && !isLoadingPage ? (
        <>
          <DefaultPageHeader
            titleText={`${actualRoom?.name}`}
            subTitleText="Customize your room"
            linkTo={appPaths.private.rooms.dashboard.replace(
              ':id',
              actualRoom.event as string
            )}
            linkToText="Back to Rooms"
          />

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            paddingBottom={4}
          >
            <span></span>
            <Button
              onClick={handleOpenUpdateRoomModal}
              color="primary"
              startIcon={<EditIcon fontSize="small" />}
            >
              Edit Info
            </Button>
          </Stack>

          <Grid container spacing={2} marginBottom={4}>
            <Grid item xs={8}>
              <Stack spacing={2} direction="row">
                <img
                  src={actualRoom.image}
                  alt="room"
                  style={{ maxWidth: '400px' }}
                />
                <Box>
                  <p>
                    ID: <b>{actualRoom.id}</b>
                  </p>
                  <p>
                    Room Space: <b>{actualRoom.mapName}</b>
                  </p>
                  <p>
                    Max Users: <b>{actualRoom.maxPlayers}</b>
                  </p>
                  <p>
                    Max Replicas: <b>{actualRoom.maxReplicas}</b>
                  </p>
                  <p>
                    Is Hidden: <b>{actualRoom.isInvisible ? 'Yes' : 'No'}</b>
                  </p>
                </Box>
              </Stack>
            </Grid>
          </Grid>

          <Divider />

          <MediaContainer />
        </>
      ) : (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          padding={4}
        >
          <p>Can&apos;t find this room. Maybe this room no longer exists.</p>
          <p>
            <Link
              to={appPaths.private.home}
              style={{ display: 'flex', alignContent: 'center' }}
            >
              <HomeIcon fontSize="small" />
              Home
            </Link>
          </p>
        </Stack>
      )}
    </AppDefaultLayout>
  );
};
