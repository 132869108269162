import { instanceManagerApiUrl } from 'configs/apiConfigs';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { http } from '.';

interface WebSocketInstanceManagerProps {
  data: {
    eventId: string;
    token: string;
    alias: string;
    regionName: string;
    onlyDestroy: boolean;
  };
  removeEvent: boolean;
  roomsLength: number;
  onCancel: () => void;
  onRemoved: () => void;
}

const useInstanceManager = () => {
  const [socket, setSocket] = useState<any>(false);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    connectWebsocket();

    return () => {
      disconnectWebSocket();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('connect', async () => {
        setIsSocketConnected(true);
      });

      socket.on('disconnect', async () => {
        setIsSocketConnected(false);
      });
    }
  }, [socket]);

  const connectWebsocket = () => {
    const serverUrl = instanceManagerApiUrl;
    setSocket(
      io(serverUrl, {
        reconnection: true,
        reconnectionDelay: 500,
        transports: ['websocket'],
      })
    );
  };

  const disconnectWebSocket = () => {
    if (socket) {
      socket.disconnect();
      socket.close();
      setSocket(false);
    }
  };

  const deleteSession = async (props: WebSocketInstanceManagerProps) => {
    try {
      if (isSocketConnected) {
        await startDestroySessions(props)
          .then(() => {
            if (props.removeEvent) {
              handleRemoveEvent(props.data.eventId).then(() => {
                props.onRemoved();
              });
            }
          })
          .catch(() => {
            enqueueSnackbar('Session Destroyed', { variant: 'success' });
          })
          .finally(() => {
            if (!props.removeEvent) {
              props.onRemoved();
            }
          });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const startDestroySessions = (props: WebSocketInstanceManagerProps) => {
    return new Promise<void>((resolve, reject) => {
      try {
        if (!props.roomsLength || props.roomsLength <= 0) {
          return resolve();
        }

        socket.on('RECREATED_SESSIONS_SELFSERVICE_STATUS', (obj: any) => {
          if (obj.status === 'Waiting') {
            // eslint-disable-next-line no-console
            console.warn('Waiting: FLEET', obj.fleet);
          } else if (obj.status === 'Done') {
            enqueueSnackbar('Session Destroyed', { variant: 'success' });
            resolve();
          } else if (
            obj.status === 'Error' ||
            obj.status === 'GameLiftError catch'
          ) {
            const msg = `Error: RECREATED_SESSIONS_SELFSERVICE_STATUS ${obj.error}`;
            enqueueSnackbar(msg, {
              variant: 'error',
            });

            resolve();
          }
        });

        const payload = {
          ...props.data,
          minimumNecessary: props.roomsLength,
        };

        socket.emit('RECREATE_SELFSERVICE_SESSIONS', payload);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        reject();
      }
    });
  };

  const handleRemoveEvent = async (eventId: string) => {
    await http.events
      .remove({ eventId })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        enqueueSnackbar(res.message, { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  return { deleteSession };
};

export default useInstanceManager;
