import { Paper, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const BoxPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.colors.primary.dark,

  '& :hover': {
    color: theme.colors.primary.main,
  },
}));

export const BoxLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
  textDecoration: 'none',
  color: theme.colors.primary.dark,

  '& :hover': {
    color: theme.colors.primary.main,
  },
  '& :active': {
    color: theme.colors.primary.dark,
  },
}));
