import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  setActualRoom,
  setHasRoom,
  setLoadingPage,
  setRoomMap,
  setRoomSpots,
  setRoomSpotsToExport,
} from 'redux/slices/roomsSlice';
import { http } from 'services/http';
import { ExportSpotsProps } from 'services/http/types/rooms';
import { useAppDispatch } from 'shared/hooks/useStore';

export const useLoadRoomDetails = () => {
  const dispatch = useAppDispatch();

  const urlParams = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const loadRoomDetails = useCallback(async () => {
    http.rooms
      .get({
        roomId: urlParams.roomId as string,
      })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          dispatch(setLoadingPage(false));
          dispatch(setHasRoom(false));
          return;
        }
        dispatch(setActualRoom(res.data.room));

        if (res.data.map) dispatch(setRoomMap(res.data.map));
        if (res.data.spots) {
          const sorted = res?.data?.spots?.sort((a: any, b: any) =>
            a?.displayName?.localeCompare(b?.displayName || '')
          );

          if (sorted) dispatch(setRoomSpots(sorted));
        }

        dispatch(setLoadingPage(false));
        dispatch(setHasRoom(true));
      });
  }, [dispatch, enqueueSnackbar, urlParams.roomId]);

  const exportationRoomSpots = useCallback(
    async (payload: ExportSpotsProps, setSubmiting: any, onReaded: any) => {
      setSubmiting(true);

      const result = await http.spots
        .exportation(payload.mapId)
        .catch((error) => {
          setSubmiting(false);
          enqueueSnackbar(error.message, { variant: 'error' });
        });

      if (result?.error) {
        enqueueSnackbar(result.message, { variant: 'error' });
        return;
      }

      const sorted = result?.data?.sort((a: any, b: any) =>
        a.displayName?.localeCompare(b.displayName || '')
      );

      setSubmiting(false);

      if (onReaded) onReaded();

      if (sorted) dispatch(setRoomSpotsToExport(sorted));
    },
    [enqueueSnackbar]
  );

  return {
    loadRoomDetails,
    exportationRoomSpots,
  };
};
