import { ProcessedEvent } from '@aldabil/react-scheduler/types';
import { IStreamGroupLog } from 'redux/slices/interfaces';
import { IPurewebTargetRegion } from 'services/http/schedulerService';

export interface CustomProcessedEvent
  extends Omit<ProcessedEvent, 'start' | 'end'> {
  capacity?: number;
  region?: string;
  start: string | Date;
  end: string | Date;
  newStartTime?: string | Date;
  streamingProvider: StreamingProviders;
  targetRegions?: IPurewebTargetRegion[];
}

export enum GameCastTabValues {
  Calendar = 'CALENDAR',
  Logs = 'LOGS',
}

export enum SortingOrderTypes {
  ASC = 'ASC',
  DESC = 'DESK',
}

export enum LogsFilterRange {
  Hour = 'HOUR',
  Day = 'DAY',
  Week = 'WEEK',
  All = 'ALL',
}

export enum SortingCriteriaTypes {
  Capacity = 'CAPACITY',
  StartTime = 'START_TIME',
  EndTime = 'END_TIME',
  Time = 'TIME',
}

export enum StreamingProviders {
  Pureweb = 'PURE_WEB',
  GameCast = 'GAME_CAST',
}

export interface ISortingConfig {
  criteria: SortingCriteriaTypes;
  order: SortingOrderTypes;
}

export interface ISortLogsProps extends ISortingConfig {
  logs: IStreamGroupLog[];
}
