import { AccountFormLayout } from 'layouts/AccountFormLayout';
import { PasswordRecoveryForm } from './PasswordRecoveryForm';

export const PasswordRecoveyPage = () => {
  return (
    <AccountFormLayout>
      <PasswordRecoveryForm />
    </AccountFormLayout>
  );
};
