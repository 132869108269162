export const orderByProperty = (
  items: Record<string, any>[],
  property: string
) => {
  const arrayForSort = [...items];
  const sorted =
    arrayForSort.length > 0
      ? arrayForSort.sort((a, b) => (a[property] > b[property] ? 1 : -1))
      : arrayForSort;

  return sorted;
};
