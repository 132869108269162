export const useStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,

    '& button': {
      mt: 3,
      width: 200,
      mb: 3,
    },
  },
  infoWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    columnGap: 3,
    rowGap: 3,
    mt: 3,
    mb: 3,
  },
});
