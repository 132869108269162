import axios from 'axios';
import { apiUrl } from 'configs/apiConfigs';
import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import {
  EmailConfirmProps,
  OktaSignInProps,
  PasswordRecoveryProps,
  PasswordResetProps,
  SignInRequestProps,
  SignUpRequestProps,
  SocialSignInUpRequestProps,
} from 'shared/types/account';

const auth = {
  async signIn(userData: SignInRequestProps) {
    try {
      const { data } = await axios.post(`${apiUrl}/auth/signIn`, userData);
      return data;
    } catch (error) {
      return errorsApiHandler(error);
    }
  },

  async signUp(userData: SignUpRequestProps) {
    try {
      const { data } = await axios.post(`${apiUrl}/auth/signUp`, userData);
      return data;
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async socialSignInUp(userData: SocialSignInUpRequestProps) {
    try {
      const { data } = await axios.post(
        `${apiUrl}/auth/socialSignIn`,
        userData
      );
      return data;
    } catch (error) {
      return errorsApiHandler(error);
    }
  },

  async oktaSignIn({ oktaToken, payload }: OktaSignInProps) {
    try {
      const { data } = await axios.post(`${apiUrl}/auth/okta`, payload, {
        headers: {
          Authorization: oktaToken,
        },
      });
      return data;
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async passwordRecovery(userData: PasswordRecoveryProps) {
    try {
      const { data } = await axios.post(
        `${apiUrl}/auth/passwordReset`,
        userData
      );
      return data;
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async passwordReset(userData: PasswordResetProps) {
    try {
      const { data } = await axios.post(
        `${apiUrl}/auth/passwordChange`,
        userData
      );
      return data;
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async emailConfirm(userData: EmailConfirmProps) {
    try {
      const { data } = await axios.post(
        `${apiUrl}/auth/emailConfirm`,
        userData
      );
      return data;
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default auth;
