import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { Alias, Region } from 'shared/types/models/region';

interface InitialState {
  isRegionsLoading: boolean;
  regions: Region[];
  aliases: Alias[];
  actualRegion: Region;
}

const initialState: InitialState = {
  isRegionsLoading: false,
  regions: [],
  aliases: [],
  actualRegion: {},
};

export const regionsSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    setIsRegionsLoading: (state, { payload }) => {
      state.isRegionsLoading = payload;
    },
    setRegions: (state, { payload }) => {
      state.regions = payload;
    },
    setAliases: (state, { payload }) => {
      state.aliases = payload;
    },
    setActualRegion: (state, { payload }) => {
      state.actualRegion = payload;
    },
  },
});

export const selectRegions = (state: RootState) => state.regionsData;

export const { setIsRegionsLoading, setRegions, setAliases, setActualRegion } =
  regionsSlice.actions;

export default regionsSlice.reducer;
