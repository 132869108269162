import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useEffect } from 'react';
import { BootstrapDialogTitle } from 'shared/components/modals/BootstrapDialogTitle';

interface Props {
  open: boolean;
  title?: string;
  children?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  confirmIcon?: React.ReactNode;
  cancelIcon?: React.ReactNode;
  disableConfirmButton?: boolean;
  disableCancelButton?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  onGoBack: () => void;
  actualStep?: number;
}

export const AddAvatarModal = ({
  open,
  title,
  children,
  confirmText,
  confirmIcon,
  cancelIcon,
  disableConfirmButton,
  actualStep,
  onCancel,
  onConfirm,
  onGoBack,
}: Props) => {
  useEffect(() => {
    return () => {
      onGoBack();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="add-skins-title"
      aria-describedby="add-skins-description"
      fullWidth
    >
      <BootstrapDialogTitle id="add-skins-title" onClose={onCancel}>
        {title || 'Default Title'}
      </BootstrapDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          autoFocus
          startIcon={cancelIcon}
          variant="outlined"
        >
          Cancel
        </Button>
        {actualStep === 1 && (
          <>
            <Button
              onClick={onGoBack}
              autoFocus
              startIcon={confirmIcon}
              variant="outlined"
              disabled={disableConfirmButton}
            >
              {confirmText || 'Back'}
            </Button>
            <Button
              onClick={onConfirm}
              autoFocus
              startIcon={confirmIcon}
              variant="contained"
              disabled={disableConfirmButton}
            >
              {confirmText || 'Save'}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
