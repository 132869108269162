export const sortByDisplayName = (items: Record<string, string>[]) => {
  return items.length > 0
    ? items.sort(function (
        a: Record<string, string>,
        b: Record<string, string>
      ) {
        if (a.displayName > b.displayName) {
          return 1;
        }
        if (a.displayName < b.displayName) {
          return -1;
        }
        // a must be equal to b
        return 0;
      })
    : items;
};
