import { Box, Button, Stack, TextField } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useAuth } from 'shared/hooks/useAuth';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SignUpRequestProps } from 'shared/types/account';
import { validationSchema } from './validationSchema';
import { appPaths } from 'app/router/appPaths';
import {
  SocialLoginButton,
  socialLogins,
} from 'shared/components/buttons/SocialButtons';
import { ProviderType } from 'services/http/firebase';

type RegisterRequestProps = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
};

export const RegisterForm = () => {
  const { signUp, socialSignInUp } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const location = useLocation();

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const from = location.state?.from?.pathname || appPaths.public.account.login;

  const handleSubmit = async (
    data: SignUpRequestProps,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true);
    signUp(data)
      .then((res) => {
        setSubmitting(false);

        if (res.success === false) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }

        enqueueSnackbar(res.message, { variant: 'success' });
        navigate(from, { replace: true });
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleSocialLogin = (event: React.MouseEvent<HTMLButtonElement>) => {
    const provider = event.currentTarget.getAttribute('provider');
    if (provider) {
      setDisableSubmitButton(true);
      socialSignInUp(provider as ProviderType)
        .then((res) => {
          if (!res.success) {
            if (res.code !== 'auth/popup-closed-by-user') {
              enqueueSnackbar(res.message, { variant: 'error' });
            }
            setDisableSubmitButton(false);
            return;
          }
          setDisableSubmitButton(false);
          enqueueSnackbar(res.message, { variant: 'success' });
          navigate(from, { replace: true });
        })
        .catch((error) => {
          setDisableSubmitButton(false);
          enqueueSnackbar(error.message, { variant: 'error' });
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: validationSchema,
    onSubmit: (
      values,
      { setSubmitting }: FormikHelpers<RegisterRequestProps>
    ) => {
      handleSubmit(values, setSubmitting);
    },
  });

  return (
    <Box sx={{ width: '500px' }}>
      <h3>Sign up with Social Media</h3>

      <Stack
        direction="row"
        justifyContent="start"
        alignItems="center"
        spacing={2}
      >
        {socialLogins.map((btn) => {
          return (
            <SocialLoginButton
              key={btn.provider}
              onClick={handleSocialLogin}
              provider={btn.provider}
            >
              {btn.label}
            </SocialLoginButton>
          );
        })}
      </Stack>

      <h3>or Register</h3>

      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          id="firstName"
          name="firstName"
          label="First Name*"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextField
          fullWidth
          margin="normal"
          id="lastName"
          name="lastName"
          label="Last Name*"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
        <TextField
          fullWidth
          margin="normal"
          id="email"
          name="email"
          label="Email*"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          fullWidth
          margin="normal"
          id="password"
          name="password"
          label="Password*"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <TextField
          fullWidth
          margin="normal"
          id="passwordConfirmation"
          name="passwordConfirmation"
          label="Repeat Password*"
          type="password"
          value={formik.values.passwordConfirmation}
          onChange={formik.handleChange}
          error={
            formik.touched.password &&
            Boolean(formik.errors.passwordConfirmation)
          }
          helperText={
            formik.touched.passwordConfirmation &&
            formik.errors.passwordConfirmation
          }
        />
        {disableSubmitButton || formik.isSubmitting ? (
          <Button
            sx={{ mt: 3 }}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled
          >
            Sending credentials...
          </Button>
        ) : (
          <Button
            sx={{ mt: 3 }}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
          >
            Register
          </Button>
        )}
      </form>

      <p>
        Already have an account?{' '}
        <Link to={appPaths.public.account.login}>Sign In</Link>
      </p>

      <p>
        <Link to={appPaths.public.account.passwordRecovery}>
          Forgot Password?
        </Link>
      </p>
    </Box>
  );
};
