import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import React from 'react';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { selectInvites, setActualFilter } from 'redux/slices/invitesSlice';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { useLoadParticipants } from 'shared/hooks/useLoadParticipants';
import { InviteFilters } from 'shared/types/models/invite';

export const FilterBySpeakers = () => {
  const dispatch = useAppDispatch();
  const { actualFilter } = useAppSelector(selectInvites);
  const { get: loadParticipants } = useLoadParticipants();

  const handleChange = async (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (!newAlignment) return;
    await loadParticipants();
    dispatch(setActualFilter(newAlignment));
  };

  return (
    <>
      <span>Filter By:</span>
      <span>
        <ToggleButtonGroup
          color="primary"
          value={actualFilter}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton value="all">All</ToggleButton>
          <ToggleButton value={InviteFilters.Speaker}>
            <Tooltip title="Speaker">
              <MicIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={InviteFilters.NotSpeaker}>
            <Tooltip title="Not speaker">
              <MicOffIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={InviteFilters.Confirmed}>
            <Tooltip title="Confirmed">
              <CheckIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={InviteFilters.NotConfirmed}>
            <Tooltip title="Not confirmed">
              <DoDisturbIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={InviteFilters.Admin}>
            <Tooltip title="Admin Role">
              <AdminPanelSettingsIcon />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </span>
    </>
  );
};
