import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import { StreamService } from 'shared/types/models/streamService';
import { orderByProperty } from 'shared/utils/orderByProperty';
import axiosWithUserToken from './axiosInstances/axiosWithUserToken';
import { HttpResponseDefault } from './types/httpGeneric';
import { CreateStreamServiceProps } from './types/streamServices';

const streamServices = {
  async listToSelector(): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        '/cms/stream-services/list'
      );

      const filtered = data.streamServices.filter(
        (item: StreamService) => item.isActive
      );

      const sorted = orderByProperty(filtered, 'displayName');

      return { message: 'Success', data: sorted };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async listBySubdomain(
    subdomain: string,
    readPublicAccess: boolean
  ): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/stream-services/list/subdomain?subdomain=${subdomain}`
      );

      const filtered = data.streamServices.filter(
        (item: StreamService) => item.isActive
      );

      if (readPublicAccess) {
        const dataPublicAccess = await axiosWithUserToken().get(
          '/cms/stream-services/list/subdomain?subdomain=PUBLIC_ACCESS'
        );

        const filteredPublicAccess =
          dataPublicAccess.data?.streamServices.filter(
            (item: StreamService) => item.isActive
          );

        filteredPublicAccess.map((item: any) => {
          filtered.push(item);
        });
      }

      const sorted = orderByProperty(filtered, 'displayName');

      return { message: 'Success', data: sorted };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  // ADMIN TOOLS
  async list(): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        '/cms/stream-services/list'
      );

      return { message: 'Success', data: data.streamServices };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async get({ serviceId }: any): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().get(
        `/cms/stream-services/get/${serviceId}`
      );

      return { message: 'Success', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async create(
    payload: CreateStreamServiceProps
  ): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().post(
        '/cms/stream-services/create/',
        payload
      );

      return { message: 'Created', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async update({
    serviceId,
    payload,
  }: {
    serviceId: string;
    payload: any;
  }): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().put(
        `/cms/stream-services/update/${serviceId}`,
        payload
      );

      return { message: 'Updated', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },

  async remove(serviceId: string): Promise<HttpResponseDefault> {
    try {
      const { data } = await axiosWithUserToken().delete(
        `/cms/stream-services/delete/${serviceId}`
      );

      return { message: 'Removed', data: data };
    } catch (error: any) {
      return errorsApiHandler(error);
    }
  },
};

export default streamServices;
