import { Box, styled } from '@mui/material';

export const Input = styled('input')({
  display: 'none',
});

export const UploadIconWrapper = styled(Box)({
  position: 'absolute',
  right: '10px',
  top: '10px',
  display: 'flex',
  width: '40px',
  height: '40px',
  backgroundColor: 'rgb(203, 208, 252)',
  borderRadius: '50px',
  alignItems: 'center',
  justifyContent: 'center',
});
